export function convertToDDMMYYYY(dateInput:any) {
    let dateObj;
  
    // Check if the input is in ISO format
    if (dateInput?.includes('T')) {
      dateObj = new Date(dateInput);
    } else {
      // Assume the input is in DD/MM/YYYY format
      const [day, month, year] = dateInput?.split('/');
      dateObj = new Date(`${year}-${month}-${day}`);
    }
  
    const day = String(dateObj?.getDate())?.padStart(2, '0');
    const month = String(dateObj?.getMonth() + 1)?.padStart(2, '0');
    const year = dateObj?.getFullYear();
  
    return `${day}/${month}/${year}`;
  }