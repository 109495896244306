import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { CustomContainer } from "../../../components/CustomContainer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { CustomIconButton } from "../../../components/CustomIconButton";
import { CardComponent } from "../../../components/CardComponent";
import { ItineraryNotification } from "../../../components/ItineraryNotification";
import { colors } from "../../../styles/colors";
import { ReactComponent as ExpandIcon } from "../../../assets/icons/ExpandIcon.svg";
import dayjs from "dayjs";
import { findDisplayNameById } from "../../../helpers/findDisplayNameById";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/useAppReducer";

const DashboardComponent = (props: any) => {
  const navigate = useNavigate();
  const { userDetails } = useAppSelector((state) => state.userInformation);
  const { pastBookings, upcomingBookings, saveitItinerary } = props;
  const [expanded, setExpanded] = React.useState<boolean>(true);
  const [itemsToShow, setItemsToShow] = useState(6);
  const [historicalExpand, setHistoricalExpand] = useState(false);
  const [isSetHistoricalData, setisSetHistoricalData] = useState(false);

  const handleLoadMore = () => {
    setItemsToShow((prev) => prev + 6);
  };

  useEffect(() => {
    if (pastBookings.length > 0 && !isSetHistoricalData) {
      setisSetHistoricalData(true);
      setHistoricalExpand(true);
    }
  }, [pastBookings, isSetHistoricalData]);

  return (
    <Box>
      <Box
        bgcolor={colors.grey[20]}
        height="54px"
        display="flex"
        alignItems="center"
        mb="32px"
      >
        <CustomContainer>
          <Typography variant="h1" fontSize="28px" fontWeight={600}>
            Confirmed Bookings
          </Typography>
        </CustomContainer>
      </Box>
      <Box
        sx={{
          "& .MuiAccordionSummary-root": {
            justifyContent: {
              md: "flex-start",
              xs: "space-between",
            },
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            ml: "20px",
          },
          "& .MuiPaper-elevation": {
            bgcolor: "transparent",
          },
          "& .MuiAccordionSummary-content": {
            flexGrow: "inherit",
          },
          "& .Mui-expanded": {
            transform: "none !important",
            justifyContent: "flex-start !important",
            "&>svg": {
              transform: "rotate(90deg) !important",
            },
          },
          mb: 10,
        }}
      >
        <CustomContainer>
          <Box>
            <Box
              border={`1px solid ${colors.grey[30]}`}
              bgcolor={colors.basics.secondary}
              borderRadius="5px"
            >
              <Accordion
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
              >
                <AccordionSummary
                  expandIcon={<ExpandIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography color={colors.grey[10]} fontWeight={500}>
                    Upcoming Bookings
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {upcomingBookings && upcomingBookings.length > 0 ? (
                    upcomingBookings?.map((booking: any) => (
                      <Box
                        key={booking?.fold_no}
                        display={{ md: "grid", sm: "grid", xs: "block" }}
                        gridTemplateColumns={{
                          md: "repeat( 5, minmax(140px, 1fr) )",
                          sm: "repeat( 5, minmax(100px, 1fr) )",
                        }}
                        gap={"20px"}
                        justifyContent="space-between"
                        alignItems="center"
                        pb="10px"
                        borderBottom={`0.5px solid ${colors.grey[30]}`}
                        mb="10px"
                        textAlign={"left"}
                      >
                        {/* <Box display={{ lg: "block", xs: "block" }}>
                          <Typography
                          color={colors.grey[10]}
                          fontWeight={300}
                          lineHeight="130%"
                          variant="body2"
                          >
                          Booking:
                          </Typography>
                        </Box> */}
                        <Box display={{ lg: "block", xs: "block" }}>
                          <Typography
                            color={colors.grey[10]}
                            fontWeight={300}
                            lineHeight="130%"
                            variant="body2"
                          >
                            TSR{booking.fold_no ? booking.fold_no : "-"}
                          </Typography>
                        </Box>
                        <Box mb={{ md: "0", xs: "0" }}>
                          <Typography
                            color={colors.grey[10]}
                            fontWeight={300}
                            lineHeight="130%"
                            variant="body2"
                          >
                            {booking?.HotelInfo?.hotel_name
                              ? booking?.HotelInfo?.hotel_name
                              : ""}
                          </Typography>
                        </Box>
                        <Box display={{ lg: "block", xs: "block" }}>
                          <Typography
                            color={colors.grey[10]}
                            fontWeight={300}
                            lineHeight="130%"
                            variant="body2"
                          >
                            {findDisplayNameById(Number(booking?.location_id))
                              ? findDisplayNameById(
                                  Number(booking?.location_id)
                                )
                              : "-"}
                          </Typography>
                        </Box>
                        <Box mb={{ md: "0", xs: "0" }}>
                          <Typography
                            color={colors.grey[10]}
                            fontWeight={300}
                            lineHeight="130%"
                            variant="body2"
                          >
                            {dayjs(booking.start_date_time).format(
                              "DD MMM, YYYY"
                            )}{" "}
                            -{" "}
                            {dayjs(booking.end_date_time).format(
                              "DD MMM, YYYY"
                            )}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            textAlign: "end",
                            "& button": {
                              width: "auto !important",
                              "&>.MuiBox-root": {
                                "&:nth-of-type(1)": {
                                  width: "60%",
                                },
                                "&:nth-of-type(2)": {
                                  width: "40%",
                                },
                              },
                            },
                          }}
                        >
                          <CustomIconButton
                            label="View"
                            onClick={() =>
                              navigate(
                                `/booking-confirmation?folder=${
                                  booking?.fold_no
                                }&email=${
                                  userDetails?.CustomerUser?.email
                                }&isView=${true}`
                              )
                            }
                          />
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Typography
                      color={colors.grey[10]}
                      fontWeight={300}
                      lineHeight="130%"
                      variant="body2"
                      textAlign={"center"}
                    >
                      No Data Found.
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box
              border={`1px solid ${colors.grey[30]}`}
              bgcolor={colors.basics.secondary}
              borderRadius="5px"
              mt={3}
            >
              <Accordion
                expanded={historicalExpand}
                onChange={() => setHistoricalExpand(!historicalExpand)}
              >
                <AccordionSummary
                  expandIcon={<ExpandIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography color={colors.grey[10]} fontWeight={500}>
                    Historical Bookings
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {pastBookings && pastBookings?.length > 0 ? (
                    pastBookings?.map((booking: any) => (
                      <Box
                        key={booking.fold_no}
                        display={{ md: "grid", sm: "grid", xs: "block" }}
                        gridTemplateColumns={{
                          md: "repeat( 5, minmax(140px, 1fr) )",
                          sm: "repeat( 5, minmax(100px, 1fr) )",
                        }}
                        gap={"20px"}
                        justifyContent="space-between"
                        alignItems="center"
                        pb="10px"
                        borderBottom={`0.5px solid ${colors.grey[30]}`}
                        mb="10px"
                        textAlign="left"
                      >
                        {/* <Box display={{ lg: "block", xs: "block" }}>
                          <Typography
                            color={colors.grey[10]}
                            fontWeight={300}
                            lineHeight="130%"
                            variant="body2"
                          >
                            Booking:
                          </Typography>
                        </Box> */}
                        <Box display={{ lg: "block", xs: "block" }}>
                          <Typography
                            color={colors.grey[10]}
                            fontWeight={300}
                            lineHeight="130%"
                            variant="body2"
                          >
                            TSR{booking?.fold_no ? booking?.fold_no : "-"}
                          </Typography>
                        </Box>

                        <Box mb={{ md: "0", xs: "10px" }}>
                          <Typography
                            color={colors.grey[10]}
                            fontWeight={300}
                            lineHeight="130%"
                            variant="body2"
                          >
                            {booking?.HotelInfo?.hotel_name
                              ? booking?.HotelInfo?.hotel_name
                              : ""}
                          </Typography>
                        </Box>
                        <Box display={{ lg: "block", xs: "block" }}>
                          <Typography
                            color={colors.grey[10]}
                            fontWeight={300}
                            lineHeight="130%"
                            variant="body2"
                          >
                            {findDisplayNameById(Number(booking?.location_id))
                              ? findDisplayNameById(
                                  Number(booking?.location_id)
                                )
                              : "-"}
                          </Typography>
                        </Box>
                        <Box mb={{ md: "0", xs: "10px" }}>
                          <Typography
                            color={colors.grey[10]}
                            fontWeight={300}
                            lineHeight="130%"
                            variant="body2"
                          >
                            {booking?.start_date_time
                              ? `${dayjs(booking.start_date_time).format(
                                  "DD MMM, YYYY"
                                )} - ${dayjs(booking.end_date_time).format(
                                  "DD MMM, YYYY"
                                )}`
                              : ""}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            textAlign: "end",
                            "& button": {
                              width: "auto !important",
                              "&>.MuiBox-root": {
                                "&:nth-of-type(1)": {
                                  width: "60%",
                                },
                                "&:nth-of-type(2)": {
                                  width: "40%",
                                },
                              },
                            },
                          }}
                        >
                          <CustomIconButton
                            label="View"
                            onClick={() =>
                              navigate(
                                `/booking-confirmation?folder=${
                                  booking?.fold_no
                                }&email=${
                                  userDetails?.CustomerUser.email
                                }&isView=${true}`
                              )
                            }
                          />
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Typography
                      color={colors.grey[10]}
                      fontWeight={300}
                      lineHeight="130%"
                      variant="body2"
                      textAlign={"center"}
                    >
                      No Data Found.
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </CustomContainer>
      </Box>
      <Box
        bgcolor={colors.grey[20]}
        height="54px"
        display="flex"
        alignItems="center"
        mb="32px"
      >
        <CustomContainer>
          <Typography variant="h1" fontSize="28px" fontWeight={600}>
            Saved Itineraries
          </Typography>
        </CustomContainer>
      </Box>
      <Box display={{ lg: "none", xs: "none" }} mb="20px">
        <CustomContainer>
          <Box>
            <Box mb="20px">
              <ItineraryNotification />
            </Box>
            <Box mb="35px">
              <Typography variant="body2">
                Important: An expired itinerary will only be availale to edit
                for 7 days after expiry.
              </Typography>
            </Box>
          </Box>
        </CustomContainer>
      </Box>
      <Box>
        <CustomContainer>
          <Box>
            <Box
              mb="20px"
              sx={{
                "& .MuiPaper-root": {
                  width: { md: "auto", xs: "100%" },
                },
              }}
            >
              <CardComponent
                saveitItinerary={saveitItinerary}
                itemsToShow={itemsToShow}
              />
              {itemsToShow < saveitItinerary?.length && (
                <Box textAlign={"center"} width={"100%"}>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleLoadMore}
                    sx={{ justifyContent: "center" }}
                  >
                    Load More
                  </Button>
                </Box>
              )}
            </Box>{" "}
            <Box mb={10}>
              <Typography variant="body2">
                Important: An expired itinerary will only be availale to edit
                for 7 days after expiry.
              </Typography>
            </Box>
          </Box>
        </CustomContainer>
      </Box>
    </Box>
  );
};

export default DashboardComponent;
