import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useAppSelector } from '../hooks/useAppReducer';
import useWindowWidth from '../hooks/useWindowWidth';

export default function Loader() {
  const { loading } = useSelector((state: any) => state.loading);
  const dialogState = useAppSelector((state) => state.dialog.dialogState);
  const windowWidth = useWindowWidth();
  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
    if (dialogState.customiseModal && windowWidth <= 768) {
      if (loading) {
        document.body.style.overflow = "visible"
      } else {
        document.body.style.overflow = "hidden"
      }
    }
  }, [loading])

  return loading ? (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
        display: loading ? 'flex' : 'none',

        flexFlow: 'column wrap',
        justifyContent: 'space-around',
        alignItems: 'center',
        color: '#fff',
        zIndex: 9999,
      }}
    >
      <svg
        className='loader__icon__svg'
        width='120'
        height='30'
        viewBox='0 0 120 30'
        xmlns='http://www.w3.org/2000/svg'
        fill='#fff'
      >
        <circle cx='15' cy='15' r='15'>
          <animate
            attributeName='r'
            from='15'
            to='15'
            begin='0s'
            dur='0.8s'
            values='15;9;15'
            calcMode='linear'
            repeatCount='indefinite'
          ></animate>
          <animate
            attributeName='fillOpacity'
            from='1'
            to='1'
            begin='0s'
            dur='0.8s'
            values='1;.5;1'
            calcMode='linear'
            repeatCount='indefinite'
          ></animate>
        </circle>
        <circle cx='60' cy='15' r='9' fillOpacity='.3'>
          <animate
            attributeName='r'
            from='9'
            to='9'
            begin='0s'
            dur='0.8s'
            values='9;15;9'
            calcMode='linear'
            repeatCount='indefinite'
          ></animate>
          <animate
            attributeName='fillOpacity'
            from='.5'
            to='.5'
            begin='0s'
            dur='0.8s'
            values='.5;1;.5'
            calcMode='linear'
            repeatCount='indefinite'
          ></animate>
        </circle>
        <circle cx='105' cy='15' r='15'>
          <animate
            attributeName='r'
            from='15'
            to='15'
            begin='0s'
            dur='0.8s'
            values='15;9;15'
            calcMode='linear'
            repeatCount='indefinite'
          ></animate>
          <animate
            attributeName='fillOpacity'
            from='1'
            to='1'
            begin='0s'
            dur='0.8s'
            values='1;.5;1'
            calcMode='linear'
            repeatCount='indefinite'
          ></animate>
        </circle>
      </svg>
    </Box>
  ) : null;
}
