interface TabPanelProps {
  children?: JSX.Element;
  tab: string;
  currentTab: string;
  refs?: any;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, tab, currentTab, refs, ...other } = props;

  return (
    <div ref={refs} role='tabpanel' id={`simple-tabpanel-${tab}`} aria-labelledby={`simple-tab-${tab}`} {...other}>
      <div hidden={currentTab !== tab}>{children}</div>
    </div>
  );
};
