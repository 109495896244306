import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';

export const userRegisterAPI: any = createAsyncThunk('user-login', async (resp) => {
  const response = await API.post(API_METHOD.Misc.REGISTER, resp);
  return response.data;
});
type initialStateType = {
  passenger: Record<any, any>;
};
const initialState: initialStateType = {
  passenger: [],
};
const passengerDetails = createSlice({
  name: 'passengerDetail',
  initialState,
  reducers: {
    setPassengerState: (state, { payload }) => {
      state.passenger = payload;
    },
  },
});

export const { setPassengerState } = passengerDetails.actions;

export default passengerDetails.reducer;
