import { createContext, useContext, useEffect, useState } from "react";

import { API } from "../api/services";
import { API_METHOD } from "../api/services/apiMethods";
import axios from "axios";
import { checkUserEmailIsExist } from "../features/actions";
import dayjs from "dayjs";
import { fetchUserInformationAPI } from "../features/userAuthentication/userInformation";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { branchCode } from "../constants/branchCode";

const authContext = createContext();

export function AuthContextProvider({ children }) {
  //! Variables
  const key_name = "Access_Token";
  const tokenExpirationTimeInMinutes = 30;
  const dispatch = useDispatch();

  //! Hooks
  const [cookies, setCookie] = useCookies([key_name, "user-email"]);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isTokenSetToAxios, setIsTokenSetToAxios] = useState(false);
  const [logInUserDetails, setLogInUserDetails] = useState({});
  const [session_id, setSession_id] = useState("");
  const baseURL = process.env.REACT_APP_BASE_URL

  //! Functions
  //* Keep check that Token is presented in header or not
  useEffect(() => {
    let myInterval = setInterval(() => {
      const isSetAccess_Token = document.cookie
        .split("; ")
        .find((row) => row.startsWith(key_name))
        ?.split("=")[1];
      if (!isSetAccess_Token) {
        setAuthToken();
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  //* This functions will set Bearer token to the axios header once new token generated
  function setTokenToAxios() {
    let token = document.cookie
      .split("; ")
      .find((row) => row.startsWith(key_name))
      ?.split("=")[1];
    if (token) {
      API.interceptors.request.use(async (config) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      });
      setTimeout(() => {
        setIsTokenSetToAxios(true);
      }, 500);
    }
  }
  useEffect(() => {
    setTokenToAxios();
    // checkUserisLoggedIn();
  }, []);

  //* Set Token into cookie
  const setAuthToken = async () => {
    await axios
      .post(`${baseURL}${API_METHOD.Authentication.GENERATE_TOKEN}`, {
        userName: "John",
        password: "john@1",
      })
      .then((res) => {
        let token = res?.data?.token;
        if (token) {
          setCookie("Access_Token", token, {
            path: "/",
            httpOnly: false,
            expires: dayjs()
              .add(tokenExpirationTimeInMinutes, "minutes")
              .toDate(),
            // domain: window.location.origin,
            secure: false,
            sameSite: "strict",
          });
          setTokenToAxios();
        }
      })
      .catch((err) => console.log({ err }));
  };
  const checkUserisLoggedIn = async () => {
    const resp = await dispatch(checkUserEmailIsExist(cookies?.["user-email"]));
    if (resp?.payload === "1") {
      setIsUserLoggedIn(true);
      const response = await dispatch(
        fetchUserInformationAPI([cookies?.["user-email"], true, branchCode])
      );
    } else {
      setIsUserLoggedIn(false);
    }
  };
  //! Return
  if (!cookies?.Access_Token || isTokenSetToAxios === false) {
    return null;
  }
  return (
    <authContext.Provider
      value={{
        token: cookies.Access_Token,
        isUserLoggedIn,
        setIsUserLoggedIn,
        setLogInUserDetails,
        logInUserDetails,
        session_id,
        setSession_id,
      }}
    >
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}
