export const directFlights = [
  {
    dep_code: "LON",
    des_code: "ANU ;10430",
  },
  {
    dep_code: "LON",
    des_code: "NAS ;31302",
  },
  {
    dep_code: "LON",
    des_code: "BGI ;10785",
  },
  {
    dep_code: "LON",
    des_code: "BDA ;10714",
  },
  {
    dep_code: "LON",
    des_code: "GND ;12549",
  },
  {
    dep_code: "LON",
    des_code: "MBJ ;31636",
  },
  {
    dep_code: "LON",
    des_code: "CUN ;11623",
  },
  {
    dep_code: "LON",
    des_code: "SKB ;16943",
  },
  {
    dep_code: "LON",
    des_code: "UVF ;16984",
  },
  {
    dep_code: "LON",
    des_code: "TAB ;17311",
  },
  {
    dep_code: "LON",
    des_code: "DXB ;11945",
  },
  {
    dep_code: "MAN",
    des_code: "DXB ;11945",
  },
  {
    dep_code: "BHX",
    des_code: "DXB ;11945",
  },
  {
    dep_code: "NCL",
    des_code: "DXB ;11945",
  },
  {
    dep_code: "GLA",
    des_code: "DXB ;11945",
  },
  {
    dep_code: "LON",
    des_code: "AUH ;10559",
  },
  {
    dep_code: "MAN",
    des_code: "AUH ;10559",
  },
  {
    dep_code: "LON",
    des_code: "MCT ;14327",
  },
  {
    dep_code: "LON",
    des_code: "DOH ;11866",
  },
  {
    dep_code: "MAN",
    des_code: "DOH ;11866",
  },
  {
    dep_code: "BHX",
    des_code: "DOH ;11866",
  },
];

export const directFlightsIE = [
  {
    dep_code: "DUB",
    des_code: "AUH ;10559",
  },
  {
    dep_code: "DUB",
    des_code: "DXB ;11945",
  },
  {
    dep_code: "DUB",
    des_code: "DOH ;11866",
  },
  // {
  //   dep_code: "ORK",
  //   des_code: "AUH ;10559",
  // },
  // {
  //   dep_code: "ORK",
  //   des_code: "DXB ;11945",
  // },
  // {
  //   dep_code: "ORK",
  //   des_code: "DOH ;31541",
  // },
  // {
  //   dep_code: "SNN",
  //   des_code: "AUH ;10559",
  // },
  // {
  //   dep_code: "SNN",
  //   des_code: "DXB ;11945",
  // },
  // {
  //   dep_code: "SNN",
  //   des_code: "DOH ;31541",
  // },
  // {
  //   dep_code: "NOC",
  //   des_code: "AUH ;10559",
  // },
  // {
  //   dep_code: "NOC",
  //   des_code: "DXB ;11945",
  // },
  // {
  //   dep_code: "NOC",
  //   des_code: "DOH ;31541",
  // },
];
