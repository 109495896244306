import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Divider,
  SelectChangeEvent,
  Typography,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { colors } from '../../../styles/colors';
import { useAppSelector } from '../../../hooks/useAppReducer';
import { roundPrice } from '../../../helpers/RoundPrice';
import { useDispatch } from 'react-redux';
import { setFlightRoomState } from '../../../features/flightRoom/flightRoomSlice';
import { isEmpty } from '../../../helpers/isEmpty';
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirstLetter';
import CustomSelectInput from '../../../components/CustomSelectInput';
import parseHTML from '../../../helpers/parseHtml';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { RoomMealWrapper } from '../../../helpers/roomMealWrapper';
import { ReactComponent as Spoon } from '../../../assets/icons/Spoon.svg';
interface roomAvailableProps {
  setExpanded: any;
  setShowButtons: any;
  saveBasket: (state?: any) => void;
  setOnChangeMultiRooms: (state?: any) => void;
  onChangeMultiRoom: any
  accordionOpen: any;
  setRoomDisableButton?: any,
  roomDisableButton?: boolean
  setDisableButton?: any;
  showPreviousComponent?: any
  saveRoom?: any
  roomPrice?: any
  setRoomPrice?: any
  // setSelectedOptions?: any
  // selectedOptions?: any
}

export const RoomAvailable: FC<roomAvailableProps> = ({ setDisableButton, roomPrice, setRoomPrice, setRoomDisableButton, saveRoom, roomDisableButton, accordionOpen, setExpanded, saveBasket, showPreviousComponent, onChangeMultiRoom, setOnChangeMultiRooms, setShowButtons }) => {
  //! States
  const { flightRoom } = useAppSelector((state) => state.flightRoom);
  const { hotelDetails } = useAppSelector((state) => state.hotelDetails);
  const { adults, childrens, guest } = flightRoom.searchCriteria;
  //! Hooks

  const [selectedId, setSelectedId] = useState<any>([flightRoom?.multiRooms[0]?.id]);
  // eslint-disable-next-line
  const [flightPage, setFlightPage] = useState<number>(200);
  const [meals, setMeals] = useState<string[]>([]);

  const windowWidth = useWindowWidth()
  const totalGuest = adults + childrens
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL || ""
  const isPlataniumHotel = Number(hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel) !== 0

  useEffect(() => {
    let price: any = []
    flightRoom.multiRooms.map((f: any) => {
      price.push(f?.total_package)
      return <></>
    })
    setRoomPrice(price)
  }, [flightRoom.multiRooms]);
  //! Variables
  const dispatch = useDispatch();
  // const var = flightRoom.searchCriteria

  const rooms: any = useMemo(() => {
    const removeDuplicates: any = []

    if (saveRoom?.[0]?.SearchResult?.Rooms) {
      if (flightRoom.multiRooms.length > 0) {
        const flightMeals = RoomMealWrapper(flightRoom?.multiRooms[0]?.meal_name?.toLowerCase())
        if (!removeDuplicates.includes(flightMeals)) {
          removeDuplicates.push(flightMeals)
        }

      }
      const arr = Object.values(saveRoom?.[0]?.SearchResult?.Rooms)
      const roomNames = (arr[0] as any[])?.map((item: any) => RoomMealWrapper(item?.meal_name?.toLowerCase()))

      roomNames?.map((e: any) => {
        const parsedDocument = parseHTML(e);
        const textContent = parsedDocument.body.textContent
        if (!removeDuplicates.includes(textContent)) {
          removeDuplicates.push(textContent)
        }
        return <></>
      })
    }

    return removeDuplicates
  }, [saveRoom, flightRoom.multiRooms]);

  //! Functions

  const onSelectMeal = (event: SelectChangeEvent<typeof meals>, name: any, value: any) => {
    setMeals(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    if (rooms?.length > 0) {
      const filteredRooms = rooms.filter((item: any) => item !== undefined);
      setMeals([filteredRooms[0]]);
    } else {
      setMeals([])
    }
  }, [rooms]);

  // const filterDuplicateRoomNames = (data: string[]) => {
  //   const cleanString = (str: string) => {
  //     return str.split(/[ \W]+/).filter(Boolean).join(' ')
  //   };

  //   const sortingRoomNames = (str: string) => {
  //     return str.split(/[ \W]+/).filter(Boolean).sort().join(' ')
  //   }

  //   const removeDuplicates = (arr: string[]) => {
  //     const uniqueArray: string[] = [];
  //     const storeRoomNames: string[] = []

  //     arr.forEach((item: any) => {
  //       const cleanedItem = cleanString(isEmpty(item.room_name));

  //       if (!uniqueArray.some((room: any) => cleanString(room.room_name).includes(cleanedItem)) && !storeRoomNames.includes(sortingRoomNames(isEmpty(item.room_name)))) {
  //         uniqueArray.push(item);
  //         storeRoomNames.push(cleanedItem.split(/[ \W]+/).filter(Boolean).sort().join(' '));
  //       }
  //     });

  //     return uniqueArray;
  //   };

  //   return removeDuplicates(data);
  // }

  const filterDuplicateRoomNames = (data: string[]) => {
    function convertNumberToWord(text: string): string {
      // Ensure string input
      if (typeof text !== 'string') {
        throw new TypeError('Input must be a string.');
      }
      // Loop through each word in the string
      const words = text.trim().split(/\s+/);
      for (let i = 0; i < words?.length; i++) {
        const word = words[i];

        if (/^\d+$/.test(word)) {
          const number = parseInt(word, 10);
          if (number === 1) {
            words[i] = `One`;
          } else {
            const numberWords = new Map([
              [2, "Two"],
              [3, "Three"],
              [4, "Four"],
              [5, "Five"],
              [6, "Six"],
              [7, "Seven"],
              [8, "Eight"],
              [9, "Nine"],
            ]);
            words[i] = numberWords.get(number) || word; // Replace with word from map or original word
          }
        }
      }
      return words.join(' '); // Join the modified words back into a string
    }

    function formatText(text: string) {
      const regex = /([a-z])([A-Z])(?!\))/g;
      return text.replace(regex, '$1 $2');
    }

    const addSpaceToStaticWords = (str: any) => {
      const staticWords = {
        Seaview: 'Sea View',
        Gardenview: 'Garden View',
        Resortview: 'Resort View',
        Creekview: 'Creek View',
        Juniorsuite: 'Junior Suite',
        Seniorsuite: 'Senior Suite',
        Familyroom: 'Family Room',
        Familysuite: 'Family Suite',
        Poolview: 'Pool View',
      };
      let newStr = str;
      for (const [key, value] of Object.entries(staticWords)) {
        newStr = newStr.replace(new RegExp(key, 'gi'), value);
      }

      return newStr;
    };

    const cleanString = (str: string) => {
      return str?.split(/[ \W]+/).filter(Boolean).join(' ')
    };


    const sortingRoomNames = (str: string) => {
      return str.split(/[ \W]+/).filter(Boolean).sort().join(' ')
    }
    const removeDuplicates = (arr: string[]) => {
      const uniqueArray: string[] = [];
      const storeRoomNames: string[] = []

      arr.forEach((item: any) => {
        const b = addSpaceToStaticWords((isEmpty(item.room_name)))
        const c = convertNumberToWord(b)
        const a = formatText(c);
        const cleanedItem = cleanString(a)

        if (!uniqueArray.some((room: any) => cleanString(room?.room_name).includes(cleanedItem)) && !storeRoomNames.includes(sortingRoomNames(isEmpty(item?.room_name)))) {
          uniqueArray.push(item);
          storeRoomNames.push(cleanedItem.split(/[ \W]+/).filter(Boolean).sort().join(' '));
        }
      });
      return uniqueArray;
    };

    return removeDuplicates(data);
  }

  //   const removeDuplicates = (arr: string[]) => {
  //     const uniqueArray: string[] = [];
  //     const storeRoomNames: string[] = []

  //     arr.forEach((item: any) => {
  //       const a = convertNumberToWord(isEmpty(item.room_name));
  //       const cleanedItem = cleanString(a); // Apply cleanString first
  //       // const cleanedItem = cleanString(isEmpty(item.room_name))
  //       const formattedItem = formatText(cleanedItem);
  //       console.log('formattedItem :', formattedItem);
  //       if (!uniqueArray.some((room: any) => cleanString(room.room_name).includes(formattedItem)) && !storeRoomNames.includes(sortingRoomNames(formattedItem))) {
  //         uniqueArray.push(item);
  //         storeRoomNames.push(formattedItem.split(/[ \W]+/).filter(Boolean).sort().join(' '));
  //       }
  //     });

  //     return uniqueArray;
  //   };

  //   return removeDuplicates(data);
  // }

  const filteredRoomData = useCallback(() => {
    let data: any = []
    if (saveRoom?.[0]?.SearchResult?.Rooms) {
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(saveRoom?.[0]?.SearchResult?.Rooms)) {
        if (Array.isArray(value)) {
          const val = value?.filter((item: any, index: number) => {
            const parsedDocument = RoomMealWrapper(item?.meal_name.toLowerCase());
            return index < flightPage && (meals?.includes(parsedDocument) || meals?.length === 0);
          });
          data.push(val)
        }
      }
    }


    const filterUniqueData = data.map((d: any, i: number) => {
      let uniqueRoomNames: any = []
      let storeArr: any = []

      if (meals?.includes(RoomMealWrapper(flightRoom?.multiRooms[i]?.meal_name.toLowerCase())) || meals?.length === 0) {
        uniqueRoomNames.push(flightRoom?.multiRooms[i]?.room_name.toLowerCase())
        storeArr.push(flightRoom?.multiRooms[i])
      }

      function capitalizeWords(str: any) {
        return str.replace(/\w\S*/g, (txt: any) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
      }
      const capitalizeRoomNames = (storeArr: any) => {
        return storeArr?.map((item: any) => ({
          ...item,
          room_name: capitalizeWords(item.room_name),
        }));
      };
      filterDuplicateRoomNames(capitalizeRoomNames(d)).map((e: any) => {

        if (!uniqueRoomNames.includes(e.room_name.toLowerCase())) {
          uniqueRoomNames.push(e.room_name.toLowerCase())
          storeArr.push(e)
        }
        return <></>
      })
      return storeArr
    })
    return filterUniqueData;
  }, [saveRoom, meals]);

  useEffect(() => {
    filteredRoomData();
  }, [saveRoom, meals, flightRoom.multiRooms, flightPage]);

  const parseText = (text: string) => {
    // Create a dummy element to parse the HTML entities
    const dummyElement = document.createElement('div');
    dummyElement.innerHTML = text;

    // Extract the decoded text content
    const decodedText = dummyElement.textContent;
    return decodedText;
  };

  const handleChange = (item: any, i: any) => {
    setRoomDisableButton(true)
    const arr = [...onChangeMultiRoom]
    arr[i] = item
    setOnChangeMultiRooms(arr)
    let selectId = [...selectedId]
    selectId[i] = item.id
    setSelectedId(item.id);
    // let price: any = [...roomPrice]
    // price[i] = item.total_package
    // setRoomPrice(price);
    dispatch(setFlightRoomState({
      ...flightRoom,
      isNewFlightSelected: {
        ...flightRoom.isNewFlightSelected,
        room: true
      },
    }))
  }

  return (
    <Box bgcolor={colors.basics.secondary} sx={{ p: { sm: '23px', xs: 0 }, px: { sm: '5px', xs: '10px' }, }}>
      <Box display={windowWidth > 620 ? "flex" : "block"} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} sx={{
        '& .MuiBox-root svg path': {
          fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + "!important" : colors.basics.primary,
          // width: '20px',
          // height: '22px',
        },
      }}>
        <Box sx={{ mb: { sm: 0, xs: '10px' }, mt: { sm: 0, xs: '10px' }, }} >
          <Typography variant='h1' textAlign={'center'}>Available room and board options</Typography>
        </Box>

        <Box sx={{
          mb: { sm: 0, xs: '5px' }
        }}>
          <CustomSelectInput
            boxStyle={{
              minWidth: "180px"
            }}
            capitilize={true}
            data={rooms}
            ids="meals"
            onChange={(e: any, n: any, v: any) => onSelectMeal(e, n, v)}
            value={meals.length > 0 ? parseText(meals[0]) : []}
            multiple={false}
            checkbox={false}
            placeholder='Select Meal'
            isPlataniumHotel={isPlataniumHotel}
            StartIcon={<Spoon width={"18px"} height={"18px"} />}

          />
        </Box>
      </Box>
      <Box mt="5px">
        <>
          {/* <Typography variant='h1'>
                Room {i + 1}
              </Typography> */}
          {filteredRoomData().length > 0 ? filteredRoomData()?.map((items: any, i: number) => {
            return (
              <React.Fragment key={i}>
                <Typography mt="13px" variant='h1' sx={{ marginBottom: '13px' }}>
                  Room {i + 1} - {"("}  {guest[i].adults + " Adults "}
                  {guest[i].children?.length === 0
                    ? ''
                    : guest[i].children?.length === 1
                      ? `+ ${flightRoom?.searchCriteria?.guest[i]?.children?.length} Child `
                      : ` + ${flightRoom?.searchCriteria?.guest[i]?.children?.length} Childs `}
                  {`${guest[i]?.infants?.length > 0 ?
                    "+ " + guest[i]?.infants?.length + (guest[i]?.infants?.length === 1 ? " Infant" : " Infants") : ""}`} {")"}
                </Typography>
                {items?.map((item: any, index: number) => {
                  const price = (Number(item?.total_package) - Number(roomPrice[i])) / totalGuest
                  return (
                    <Box display={{ md: 'flex', xs: 'block' }} sx={{
                      outline:
                        onChangeMultiRoom[i]?.id === item?.id
                          ? `2px solid ${colors.grey[30]}`
                          : null,
                      borderRadius: '5px',
                      marginBottom: '20px',
                      paddingLeft: { md: "20px", xs: "10px" },
                      cursor: 'pointer',
                      backgroundColor: onChangeMultiRoom[i]?.id === item?.id ? isPlataniumHotel ? `${colors.basics.plataniumGreenColor}20 !important` : `${colors.basics.pink} !important` : colors.basics.white,
                    }} key={item.id}
                    onClick={() => {
                      handleChange(item, i)
                    }}>
                      <Box flex='0 0 40%' sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                      >
                        {/* {item.id} */}
                        <Typography
                          dangerouslySetInnerHTML={{ __html: isEmpty(item?.room_name) !== "" ? capitalizeFirstLetter(item?.room_name) : "" }}
                          fontSize={{ xs: "16px", sm: "18px" }} sx={{
                            textTransform: "capitalize"
                          }} variant='h1'>
                        </Typography>
                      </Box>
                      <Box display='block' width='100%' sx={{ ml: { lg: '20px' }, paddingTop: { lg: '0', xs: '0' } }}>
                        <Box
                          key={index + 1}
                        >
                          <Box
                            flex='0 0 70%'
                            p={{ md: '7px 17px 8px 25px', xs: "7px 17px 8px 10px" }}
                            borderRadius='5px'
                            sx={{
                              // backgroundColor: colors.basics.white,
                              // color: onChangeMultiRoom[i]?.id === item?.id ? colors.basics.grey[10] :
                              '&>.MuiGrid-root': {
                                mt: 0,
                                alignItems: 'center',

                                justifyContent: 'space-between',
                                '& .MuiGrid-item': {
                                  pt: 0,
                                  flex: '0 0 50%',
                                  paddingLeft: { md: '16px', xs: "5px" }
                                },
                              },
                              '&:hover': {
                                // background: `${colors.grey[50]}!important`,

                                '& .hoverColor': {
                                  '&>div': {
                                    // background: `${colors.grey[50]}!important`,
                                  },
                                },
                              },
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid item xl={7} lg={7} md={7} sm={7} xs={7}>
                                <Typography
                                  variant='body1'
                                  fontWeight={onChangeMultiRoom[i]?.id === item?.id ? 600 : 300}
                                  color={colors.grey[10]}
                                  lineHeight='130%'
                                  sx={{
                                    textTransform: "capitalize"
                                  }}
                                  dangerouslySetInnerHTML={{ __html: RoomMealWrapper(item?.meal_name.toLowerCase()) }}
                                />
                              </Grid>
                              <Grid
                                item
                                xl={5}
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                              >
                                <Box>
                                  <Typography variant='body1' fontWeight={onChangeMultiRoom[i]?.id === item?.id ? 600 : 300}
                                    color={colors.grey[10]} lineHeight='130%'>
                                    {price === 0 ? `+${signForPricing}0` : price > 0
                                      ? `+${signForPricing + roundPrice((Math.ceil(price)).toString())}`
                                      : price < 0 ? `-${signForPricing + roundPrice((Math.abs(Math.floor(price))).toString())}` :
                                        `-${signForPricing + price}`
                                    }{' '}
                                    pp
                                  </Typography>
                                </Box>
                                <FormControl>
                                  <FormControlLabel
                                    control={
                                      <Radio disableRipple
                                        checked={onChangeMultiRoom[i]?.id === item?.id}
                                        sx={{ p: 0, border: 'none !important', bgcolor: 'transparent !important',
                                        color: isPlataniumHotel ? colors.basics.plataniumGreenColor + "!important" : colors.basics.primary, }}
                                      />
                                    }
                                    value={selectedId}
                                    label=''
                                    sx={{ m: 0 }}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )
                })}
                {items?.length - 1 !== i && <Divider sx={{ my: '30px' }} />}
              </React.Fragment>
            )
          }) :
            <Typography variant='h1' my={5} textAlign='center' color={isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary}>
              No Rooms Found
            </Typography>}
        </>
      </Box>
      {/* <Divider sx={{ my: '30px' }} /> */}
      {/* {filteredRoomData()?.length > flightPage && (
        <Box
          sx={{
            '& .MuiButtonBase-root': { border: 'none' },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            variant='text'
            sx={{ fontWeight: 700, color: '#FF4B33' }}
            onClick={() => setFlightPage(flightPage + 10)}
          >
            More Rooms
          </Button>
        </Box>
      )} */}
      <Box
        display='flex'
        justifyContent='right'
        sx={{
          '& .MuiButtonBase-root': {
            width: '138px',
            marginLeft: 0,
            border: `1px solid ${colors.basics.primary}`,
          },
        }}
      >
        {/* <Button variant='outlined' sx={{ mr: '5px' }} onClick={() => {
          window.scrollTo(0, 1000)
          setExpanded(null)
          setShowButtons(false)
          accordionOpen(0)
          setRoomDisableButton(false)
          setDisableButton(false)
          // showPreviousComponent()
        }}>
          Cancel
        </Button> */}
        {/* <Button
          variant='contained'
          sx={{
            backgroundColor:
              selectedId !== 0 && roomDisableButton
                ? `${colors.basics.primary}!important`
                : 'transparent !important',
            color: selectedId === 0 || !roomDisableButton ? colors.basics.primary : colors.basics.white,
            border: !roomDisableButton ? '1px solid #0000001f !important' : `${colors.basics.primary}!important`
          }}
          onClick={handleChangeRoom}
          disabled={!roomDisableButton}
        >
          Confirm
        </Button> */}
      </Box>
    </Box >
  );
};
