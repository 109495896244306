import * as React from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, CardActions, Chip, Grid, IconButton } from "@mui/material";
import { colors } from "../styles/colors";
import Delete from "../assets/icons/Delete";
// import ShareDownload from "../assets/icons/ShareDownload";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MailIcon from "../assets/icons/Mail.png";
import LinkIcon from "../assets/icons/Link.png";
import FbIcon from "../assets/icons/Facebook.png";
import { ReactComponent as LeftChervon } from "../assets/icons/LeftChervon.svg";
import { ReactComponent as RightChervon } from "../assets/icons/RightChervon.svg";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { findDisplayNameById } from "../helpers/findDisplayNameById";
import { getFolderDetails } from "../features/profileDetails/getFolderSlice";
import { isEmpty } from "../helpers/isEmpty";
import { setLoading } from "../features/loading/loadingSlice";
import { generateUniqueSessionId } from "../helpers/randomSessionId";
import Image from "./Image";
import { addCommasToNumber } from "../helpers/commaSeparatedPrice";
import { useAppSelector } from "../hooks/useAppReducer";
import { ApplicationStateType } from "../features/store";

interface CardInterFace {
  saveitItinerary?: Record<string, any> | undefined;
  itemsToShow?: number
}
interface CABIN_CLASS_ENUM_TYPE {
  Any: string;
  Economy: string;
  Premium: string;
  'Business Class': string;
  'First Class': string;
}
interface Props {
  className?: string;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

interface Itinerary {
  fold_no?: string;
  branch_code?: string;
  HotelInfo: {
    hotel_id: string
  }
}

interface VisibleItem {
  fold_no: string;
  location_id: string;
  HotelInfo: {
    cover_image: string;
    hotel_name: string;
    hotel_id: string | number;
  };
  start_date_time: string;
  end_date_time: string;
  Payable: string;
  branch_code: string;
}

type SegmentType = {
  fi_type: string;
  start_date_time: string;
  end_date_time: string;
  linked_passengers: string;
  class_name: string;
};

type PageDataType = {
  Segment: SegmentType;
};

type PassengerData = {
  Passenger: {
    pax_type: string;
    fi_type: string;
    pax_no: string;
    age: number;
    birth_date: Date;
  };
};

const CABIN_CLASS_ENUM: CABIN_CLASS_ENUM_TYPE = {
  Any: '',
  Economy: 'M',
  Premium: 'W',
  'Business Class': 'C',
  'First Class': 'F',
};

function SampleNextArrow(props: Props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <RightChervon />
    </div>
  );
}

function SamplePrevArrow(props: Props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <LeftChervon />
    </div>
  );
}

export const CardComponent: React.FC<CardInterFace> = ({ saveitItinerary, itemsToShow }) => {
  //! Hooks
  const { loading } = useAppSelector((state: ApplicationStateType) => state.loading);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const visibleItems = saveitItinerary !== undefined ? saveitItinerary.slice(0, itemsToShow) : [];
  //! Variables
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL || ""

  //! Functions
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dateDiffInMonths = (a: Date, b: Date): number => {
    const monthsDiff = (b.getFullYear() - a.getFullYear()) * 12 + (b.getMonth() - a.getMonth());
    if (b.getDate() < a.getDate()) {
      return monthsDiff - 1;
    }
    return monthsDiff;
  }

  const calculateInfantAge = (date: Date): number => {
    const date1 = new Date(date)
    const date2 = new Date();
    const diffInMonths = dateDiffInMonths(date1, date2);
    return diffInMonths
  }

  const handleEdit = async (itinerary: VisibleItem): Promise<void> => {
    dispatch(setLoading(true))
    const { payload } = await dispatch(
      getFolderDetails([{ fold_no: itinerary?.fold_no, branch_code: itinerary.branch_code }])
    );

    if (payload) {
      const pageData = payload?.pagedata
      const paxData = payload?.pax
      const folder = payload?.folderDetails?.Folder
      let destination: string = "";
      let departure: string = "";
      let dateRange: string = "";
      let cabin_class: string = ""
      let room_count: string = ""
      let linkedPassesnger: string[] = [];
      let itineraryNumber = ""

      if (pageData.length > 0) {
        let dateArr = []
        itineraryNumber = pageData[0].Segment.itinerary_id

        const filterHotel: PageDataType[] = pageData.filter((p: PageDataType) => p.Segment.fi_type === "HOT")
        const filterAir: PageDataType[] = pageData.filter((p: PageDataType) => p.Segment.fi_type === "AIR")

        if (filterHotel.length > 0) {
          filterHotel.forEach((f) => {
            if (isEmpty(f.Segment.linked_passengers) !== "") {
              const dataArray = f.Segment.linked_passengers.split(',').map((item) => item.trim()).filter(Boolean);
              const uniqueSet = new Set(dataArray);
              const uniqueArray = Array.from(uniqueSet);
              linkedPassesnger.push(uniqueArray.join(','));
            }
          })
        }

        if (filterAir.length > 0) {
          dateArr.push(new Date(filterAir[0].Segment.start_date_time).toLocaleDateString())
          dateArr.push(new Date(filterAir[filterAir.length - 1].Segment.end_date_time).toLocaleDateString())

          let cabinClassObj = {
            Any: '',
            Economy: 'M',
            Premium: 'W',
            'Business': 'C',
            'First': 'F',
          }

          const foundClass: string | undefined = filterAir[0].Segment.class_name ? Object.keys(cabinClassObj).find(travelClass => {
            return filterAir[0].Segment.class_name.toLowerCase().includes(travelClass.toLowerCase())
          }) : "";

          let isAnyClassFound: string | undefined = ""
          if (foundClass !== undefined && foundClass !== "") {
            isAnyClassFound = Object.keys(CABIN_CLASS_ENUM).find(travelClass => {
              return travelClass.toLowerCase().includes(foundClass.toLowerCase())
            })
          }

          cabin_class = isAnyClassFound !== undefined && isAnyClassFound !== "" ? isAnyClassFound.split(" ").join("_") : ""

        }
        departure = pageData[0].Segment.start_point_code
        dateRange = dateArr.join(",")
        room_count = filterHotel.length.toString()
      }

      let childAge: { [key: number]: number }[] = [];
      let infantAge: { [key: number]: number }[] = [];
      let adult: number[] = [];
      let children_count: number[] = [];
      let infant_count: number[] = [];

      if (paxData.length > 0) {
        const childData: PassengerData[] = paxData.filter((p: PassengerData) => p.Passenger.pax_type === "CHD")
        const infantData: PassengerData[] = paxData.filter((p: PassengerData) => p.Passenger.pax_type === "INF")
        const adultData: PassengerData[] = paxData.filter((p: PassengerData) => p.Passenger.pax_type === "ADT")

        linkedPassesnger.forEach((p) => {
          let obj: { [key: number]: number } = {}
          let infantObj: { [key: number]: number } = {}
          let index = 0
          let infantIndex = 0

          let adultIndex = 0

          adultData.forEach((c) => {
            if (p.split(",").includes(c.Passenger.pax_no)) {
              adultIndex = adultIndex + 1
            }
          })

          adult.push(adultIndex)

          childData.forEach((c) => {
            if (p.split(",").includes(c.Passenger.pax_no)) {
              obj[index + 1] = c.Passenger.age
              index = index + 1
            }
          })

          children_count.push(index)

          infantData.forEach((c) => {
            if (p.split(",").includes(c.Passenger.pax_no)) {
              infantObj[infantIndex + 1] = calculateInfantAge(c.Passenger.birth_date)
              infantIndex = infantIndex + 1
            }
          })

          infant_count.push(infantIndex)
          infantAge.push(infantObj)
          childAge.push(obj)
        })
      }

      destination = folder.arrival_point_code + ";" + folder.destination_id
      dispatch(setLoading(false))
      const session_id = generateUniqueSessionId();
      const url = process.env.REACT_APP_LIVE_URL

      window.open(`${url}holiday-summary/${session_id}/?folder=${itinerary?.fold_no}&hotel_id=${itinerary.HotelInfo.hotel_id}&itinerary_number=${itineraryNumber}&departure=${departure}&destination=${destination}&daterange=${dateRange}&cabin_class=${cabin_class}&room_count=${room_count}&adult=${adult.join(",")}&children_count=${children_count.join(",")}&child_age=${JSON.stringify(childAge)}&infant_count=${infant_count.join(",")}&infant_age=${JSON.stringify(infantAge)}`, '_blank');
    } else {
      dispatch(setLoading(false))
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    fade: true,
  };

  return (
    <>
      <Grid
        container
        rowSpacing={4}
        columnSpacing={{ xs: 1, sm: 2, md: 5, lg: 5 }}
      >

        {visibleItems && visibleItems.length > 0 ? (
          visibleItems?.map((itinerary: VisibleItem, index: number) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <Card
                key={index}
                sx={{
                  backgroundColor: colors.basics.secondary,
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  padding: "20px",
                  transition: "all ease-in 0.2s",
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Box position="relative">
                  <Box
                    position="relative"
                    sx={{
                      "& .slick-prev": {
                        left: "28px",
                        zIndex: 1,
                      },
                      "& .slick-arrow": {
                        "&:before": {
                          display: "none",
                        },
                      },
                      "& .slick-next": {
                        right: "28px",
                        "&:before": {
                          display: "none",
                        },
                      },
                    }}
                  >
                    <Slider {...settings}>
                      {/* {[1, 2, 3]?.map((item) => { */}
                      {/* return ( */}
                      <Box sx={{ "& img": { width: "100%" } }} key={'1'}>
                        <Image src={itinerary?.HotelInfo?.cover_image} alt="img" />
                        {/* <img src={`https://cdn4.tropicalsky.co.uk/images/1800x600/${booking?.cover_image}`} alt='img' srcSet='' /> */}
                        {/* <p>{`https://cdn4.tropicalsky.co.uk/images/1800x600/${booking?.cover_image}`}</p> */}
                      </Box>
                      {/* ); */}
                      {/* })} */}
                    </Slider>
                  </Box>
                  {!true && (
                    <Chip
                      label={"Price Change"}
                      sx={{
                        borderRadius: "20px",
                        // height: "21px",
                        fontSize: "11px",
                        fontWeight: "400",
                        lineHeight: "13px",
                        position: "absolute",
                        bottom: "32px",
                        left: "12px",
                        border: "none",
                        padding: "none",
                        color: colors.basics.secondary,
                        background: colors.basics.primary,
                      }}
                    />
                  )}
                </Box>
                <Box
                  display="flex"
                  p={0}
                  m={0}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography
                      display="flex"
                      mr="20px"
                      textAlign="left"
                      alignItems="center"
                      justifyContent="center"
                      sx={
                        {
                          // opacity: opacityCard === true ? 0.5 : null,
                        }
                      }
                      variant="subtitle2"
                    >
                      TSR{itinerary.fold_no}
                      {/* {itinerary.ItinVendor.vendor_name} */}
                    </Typography>
                  </Box>
                  <Box sx={{ flexShrink: '0' }}>
                    <IconButton
                      aria-label="Delete"
                      sx={{
                        width: "40px",
                        // height: "50px",
                        padding: "8px 5px"
                        // opacity: opacityCard === true ? 0.5 : null
                      }}
                    >
                      <Delete />
                    </IconButton>
                    {/* <IconButton
                      aria-label="Share-Download"
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      sx={{
                        width: "40px",
                        // height: "50px",
                        padding: "8px 5px",
                        flexShrink: '0'
                        // opacity: opacityCard === true ? 0.5 : null
                      }}
                    >
                      <ShareDownload />
                    </IconButton> */}
                  </Box>
                </Box>
                <Typography
                  variant="h1"
                // sx={{ opacity: opacityCard === true ? 0.5 : null }}
                >
                  {itinerary?.HotelInfo?.hotel_name}

                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    // height: "15px",
                    color: colors.grey[10],
                    fontSize: "11px",
                    lineHeight: "13px",
                    mb: "2px",
                    // opacity: opacityCard === true ? 0.5 : null,
                  }}
                >
                  {findDisplayNameById(Number(itinerary?.location_id))
                    ? findDisplayNameById(Number(itinerary?.location_id))
                    : ""}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "13px",
                    lineHeight: "15px",
                    fontWeight: 300,
                    color: colors.grey[10],
                    mb: "2px",
                    // opacity: opacityCard === true ? 0.5 : null,
                  }}
                >
                  {dayjs(itinerary.start_date_time).format("DD MMM, YYYY")} -{" "}
                  {dayjs(itinerary.end_date_time).format("DD MMM, YYYY")}
                </Typography>
                {/* <Typography
    variant='subtitle2'
    sx={{
      color: colors.grey[10],
      mb: '8px',
      // opacity: opacityCard === true ? 0.5 : null
    }}
  >
    Tellus pharetra magna tincidunt viverra in turpis. Odio pulvinar neque, donec a placerat tellus
    adipiscing elementum. Viverra montes, integer nibh commodo pulvinar accumsan.
  </Typography> */}
                <Typography
                  variant="body1"
                  mt={"5px"}
                  sx={{
                    fontWeight: "500",
                    lineHeight: "20px",
                    color: colors.grey[10],
                    mb: "7px",
                    // opacity: opacityCard === true ? 0.5 : null,
                  }}
                >
                  Total Price
                </Typography>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "26px",
                    lineHeight: "20px",
                    mb: 2,
                    // opacity: opacityCard === true ? 0.5 : null,
                  }}
                >
                  {itinerary?.Payable ? addCommasToNumber(signForPricing + parseInt(itinerary.Payable)) : ""}
                </Typography>
                <CardActions sx={{ padding: "0px", mt: 'auto' }}>
                  {/* <Button
      variant='outlined'
      sx={{ width: '48.5%', whiteSpace: 'pre', mr: 1 }}
      onClick={() => dispatch(setDialogState({ ...dialogState, priceChangeDialog: true }))}
    >
      Update Pricing
    </Button> */}
                  <Button
                    variant="contained"
                    sx={{ width: "48.5%" }}
                    onClick={() => handleEdit(itinerary)}
                  >
                    Edit
                  </Button>
                </CardActions>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{ "aria-labelledby": "basic-button" }}
                  PaperProps={{
                    style: {
                      transform: "translateX(-90px) translateY(-155px)",
                      padding: 0,
                      borderRadius: 0,
                      width: "100%",
                      maxWidth: "140px",
                    },
                  }}
                  sx={{
                    "& ul": {
                      padding: "0 !important",
                      border: `1px solid ${colors.grey[40]}`,
                      borderRadius: "0px !important",

                      "& li": {
                        display: "flex",
                        borderBottom: `1px solid ${colors.grey[40]}`,
                        padding: "0 !important",
                        height: "32px ",
                      },
                    },
                  }}
                >
                  <MenuItem onClick={handleClose}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      padding="4px"
                      width="100%"
                    >
                      <Box flex="0 0 25%">
                        <Image
                          src={MailIcon}
                          alt=""
                          style={{
                            height: "100%",
                            display: "block",
                            margin: "auto",
                          }}
                        />
                      </Box>
                      <Box flex="0 0 75%">
                        <Typography
                          display="flex"
                          alignItems="center"
                          mt="2px"
                          variant="subtitle2"
                        >
                          Email
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      padding="4px"
                      width="100%"
                    >
                      <Box flex="0 0 25%">
                        <Image
                          src={FbIcon}
                          alt=""
                          style={{
                            height: "100%",
                            display: "block",
                            margin: "auto",
                          }}
                        />
                      </Box>
                      <Box flex="0 0 75%">
                        <Typography
                          display="flex"
                          alignItems="center"
                          mt="2px"
                          variant="subtitle2"
                        >
                          Facebook
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      padding="4px"
                      width="100%"
                    >
                      <Box flex="0 0 25%">
                        <Image
                          src={LinkIcon}
                          alt=""
                          style={{
                            height: "100%",
                            display: "block",
                            margin: "auto",
                          }}
                        />
                      </Box>
                      <Box flex="0 0 75%">
                        <Typography
                          display="flex"
                          alignItems="center"
                          mt="2px"
                          variant="subtitle2"
                        >
                          Copy Link
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                </Menu>
              </Card>
            </Grid>
          ))
        ) : (
          !loading && <Box ml={{ md: "40px", sm: "20px", xs: "10px" }} mt="20px">
            <Typography
              width={"100%"}
              color={colors.grey[10]}
              fontWeight={300}
              textAlign={"center"}
              lineHeight="130%"
              variant="body2"
            >
              No Data Found.
            </Typography>
          </Box>
        )}
      </Grid >
    </>
  );
};
