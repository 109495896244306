export const linkBaseUrl = process.env.REACT_APP_REDIRECT_URL
export const countries = {
  // Alaska
  alaska: [
    [
      {
        name: "Alaska",
        link: `${linkBaseUrl}alaska-holidays`,
        subData: []
      }]
  ],

  //* American Rockies and Prairies
  american: [
    [
      {
        name: "Colorado",
        link: `${linkBaseUrl}colorado-holidays`,
        subData: [
          {
            name: "Colorado Springs",
            link: `${linkBaseUrl}colorado-holidays/colorado-springs`
          }, {
            name: "Grand Junction",
            link: `${linkBaseUrl}colorado-holidays/grand-junction`
          }, {
            name: "Mesa Verde National Park",
            link: `${linkBaseUrl}colorado-holidays/mesa-verde-national-park`
          }, {
            name: "Denver",
            link: `${linkBaseUrl}colorado-holidays/denver`
          },
        ]
      },
      {
        name: "Idaho",
        link: `${linkBaseUrl}idaho-holidays`,
        subData: []
      }, {
        name: "South Dakota",
        link: `${linkBaseUrl}south-dakota-holidays`,
        subData: [
          {
            name: "Mount Rushmore",
            link: `${linkBaseUrl}south-dakota-holidays/mount-rushmore`
          },
        ]
      }, {
        name: "Montana",
        link: `${linkBaseUrl}montana-holidays`,
        subData: []
      },
    ], [
      {
        name: "North Dakota",
        link: `${linkBaseUrl}north-dakota-holidays`,
        subData: []
      }, {
        name: "Utah",
        link: `${linkBaseUrl}utah-holidays`,
        subData: [
          {
            name: "Salt Lake City",
            link: `${linkBaseUrl}utah-holidays/salt-lake-city`
          }, {
            name: "Bryce Canyon National Park",
            link: `${linkBaseUrl}utah-holidays/bryce-canyon-national-park`
          }, {
            name: "Zion National Park",
            link: `${linkBaseUrl}utah-holidays/zion-national-park`
          }
        ]
      }, {
        name: "Wyoming",
        link: `${linkBaseUrl}wyoming-holidays`,
        subData: [
          {
            name: "Jackson",
            link: `${linkBaseUrl}wyoming-holidays/jackson`
          }, {
            name: "Yellowstone National Park",
            link: `${linkBaseUrl}uwyoming-holidays/yellowstone-national-park`
          },
        ]
      },
    ]
  ],

  // Boston and New England

  bostonAndNewEngland: [
    [
      {
        name: "Massachusetts",
        link: `${linkBaseUrl}massachusetts-holidays`,
        subData: [
          {
            name: "Boston",
            link: `${linkBaseUrl}massachusetts-holidays/boston`
          }, {
            name: "Cape Cod",
            link: `${linkBaseUrl}massachusetts-holidays/cape-cod`
          }
        ]
      }, {
        name: "Maine",
        link: `${linkBaseUrl}maine-holidays`,
        subData: [
          {
            name: "Acadia National Park",
            link: `${linkBaseUrl}maine-holidays/acadia-national-park`
          }, {
            name: "Kennebunkport",
            link: `${linkBaseUrl}maine-holidays/kennebunkport`
          },
          {
            name: "Portland Maine",
            link: `${linkBaseUrl}maine-holidays/portland-maine`
          }
        ]
      }
    ], [
      {
        name: "New Hampshire",
        link: `${linkBaseUrl}new-hampshire-holidays`,
        subData: [
          {
            name: "North Conway",
            link: `${linkBaseUrl}maine-holidays/acadia-national-park`
          }, {
            name: "White Mountains",
            link: `${linkBaseUrl}new-hampshire-holidays/white-mountains`
          }
        ]
      }, {
        name: "Rhode Island",
        link: `${linkBaseUrl}rhode-island-holidays`,
        subData: [
          {
            name: "Newport",
            link: `${linkBaseUrl}rhode-island-holidays/newport`
          }
        ]
      }, {
        name: "Vermont",
        link: `${linkBaseUrl}vermont-holidays`,
        subData: [
          {
            name: "Stowe",
            link: `${linkBaseUrl}vermont-holidays/stowe`
          }
        ]
      }]
  ],

  // California & The West Destinations

  california: [
    [
      {
        name: "California",
        link: `${linkBaseUrl}california-holidays`,
        subData: [
          {
            name: "Anaheim",
            link: `${linkBaseUrl}california-holidays/anaheim`
          }, {
            name: "Carmel",
            link: `${linkBaseUrl}california-holidays/carmel`
          }, {
            name: "Coronado",
            link: `${linkBaseUrl}california-holidays/coronado`
          }, {
            name: "Lake Tahoe",
            link: `${linkBaseUrl}california-holidays/lake-tahoe`
          }, {
            name: "Los Angeles",
            link: `${linkBaseUrl}california-holidays/los-angeles`
          }, {
            name: "Monterey",
            link: `${linkBaseUrl}california-holidays/monterey`
          }, {
            name: "Napa Valley",
            link: `${linkBaseUrl}california-holidays/napa-valley`
          }, {
            name: "Palm Springs",
            link: `${linkBaseUrl}california-holidays/palm-springs`
          }, {
            name: "Sacramento",
            link: `${linkBaseUrl}california-holidays/sacramento`
          }, {
            name: "San Diego",
            link: `${linkBaseUrl}california-holidays/san-diego`
          }, {
            name: "San Francisco",
            link: `${linkBaseUrl}california-holidays/san-francisco`
          }, {
            name: "San Luis Obispo",
            link: `${linkBaseUrl}california-holidays/san-luis-obispo`
          }, {
            name: "Santa Barbara",
            link: `${linkBaseUrl}california-holidays/santa-barbara`
          }, {
            name: "Santa Monica",
            link: `${linkBaseUrl}california-holidays/santa-monica`
          }, {
            name: "Sequoia National Park",
            link: `${linkBaseUrl}california-holidays/sequoia-national-park`
          }, {
            name: "Sonoma Valley",
            link: `${linkBaseUrl}california-holidays/sonoma-valley`
          }
        ]
      }
    ], [
      {
        name: "Arizona",
        link: `${linkBaseUrl}arizona-holidays`,
        subData: [
          {
            name: "Grand Canyon",
            link: `${linkBaseUrl}arizona-holidays/grand-canyon`
          }, {
            name: "Lake Powell",
            link: `${linkBaseUrl}arizona-holidays/lake-powell`
          },
          {
            name: "Phoenix",
            link: `${linkBaseUrl}arizona-holidays/phoenix`
          }, {
            name: "Scottsdale",
            link: `${linkBaseUrl}arizona-holidays/scottsdale`
          }
        ]
      }, {
        name: "Nevada",
        link: `${linkBaseUrl}nevada-holidays`,
        subData: [
          {
            name: "Las Vegas",
            link: `${linkBaseUrl}nevada-holidays/las-vegas`
          }
        ]
      }, {
        name: "Oregon",
        link: `${linkBaseUrl}oregon-holidays`,
        subData: [
          {
            name: "Portland",
            link: `${linkBaseUrl}oregon-holidays/portland`
          }
        ]
      }, {
        name: "Washington State",
        link: `${linkBaseUrl}washington-state-holidays`,
        subData: [
          {
            name: "Seattle",
            link: `${linkBaseUrl}washington-state-holidays/seattle`
          }
        ]
      }]
  ],

  // Deep South

  deepSouth: [
    [
      {
        name: "Alabama",
        link: `${linkBaseUrl}alabama`,
        subData: []
      }, {
        name: "Georgia",
        link: `${linkBaseUrl}georgia-holidays`,
        subData: [
          {
            name: "Atlanta",
            link: `${linkBaseUrl}georgia-holidays/atlanta`
          }, {
            name: "Savannah",
            link: `${linkBaseUrl}georgia-holidays/savannah`
          }
        ]
      }, {
        name: "Louisiana",
        link: `${linkBaseUrl}louisiana-holidays`,
        subData: [
          {
            name: "Baton Rouge",
            link: `${linkBaseUrl}louisiana-holidays/baton-rouge`
          }, {
            name: "New Orleans",
            link: `${linkBaseUrl}louisiana-holidays/new-orleans`
          }
        ]
      }, {
        name: "Kentucky",
        link: `${linkBaseUrl}kentucky-holidays`,
        subData: []
      },
    ], [
      {
        name: "Mississippi",
        link: `${linkBaseUrl}mississippi-holidays`,
        subData: []
      }, {
        name: "North Carolina",
        link: `${linkBaseUrl}north-carolina-holidays`,
        subData: [
          {
            name: "Charlotte",
            link: `${linkBaseUrl}north-carolina-holidays/charlotte`
          }
        ]
      }, {
        name: "South Carolina",
        link: `${linkBaseUrl}south-carolina-holidays`,
        subData: [
          {
            name: "Charleston",
            link: `${linkBaseUrl}south-carolina-holidays/charleston`
          }, {
            name: "Hilton Head",
            link: `${linkBaseUrl}south-carolina-holidays/hilton-head`
          }, {
            name: "Myrtle Beach",
            link: `${linkBaseUrl}south-carolina-holidays/myrtle-beach`
          }
        ]
      }
    ], [
      {
        name: "Tennessee",
        link: `${linkBaseUrl}tennessee-holidays`,
        subData: [
          {
            name: "Chattanooga",
            link: `${linkBaseUrl}tennessee-holidays/chattanooga`
          }, {
            name: "Great Smoky Mountains",
            link: `${linkBaseUrl}tennessee-holidays/great-smoky-mountains`
          }, {
            name: "Memphis",
            link: `${linkBaseUrl}tennessee-holidays/memphis`
          }, {
            name: "Nashville",
            link: `${linkBaseUrl}tennessee-holidays/nashville`
          }
        ]
      }, {
        name: "Texas",
        link: `${linkBaseUrl}texas-holidays`,
        subData: []
      }]
  ],

  // Florida

  florida: [[
    {
      name: "Florida",
      link: `${linkBaseUrl}florida-holidays`,
      subData: [
        {
          name: "Orlando",
          link: `${linkBaseUrl}florida-holidays/orlando`
        }, {
          name: "Miami",
          link: `${linkBaseUrl}florida-holidays/miami`
        }, {
          name: "Florida Keys",
          link: `${linkBaseUrl}florida-holidays/florida-keys`
        }, {
          name: "Florida Panhandle",
          link: `${linkBaseUrl}florida-holidays/florida-panhandle`
        }, {
          name: "Gulf Coast",
          link: `${linkBaseUrl}florida-holidays/gulf-coast`
        }, {
          name: "Gold Coast",
          link: `${linkBaseUrl}florida-holidays/gold-coast`
        }
      ]
    }
  ]],

  // Hawai

  hawai: [[
    {
      name: "Hawaii",
      link: `${linkBaseUrl}hawaii-holidays`,
      subData: [
        {
          name: "Hawaii Island",
          link: `${linkBaseUrl}hawaii-holidays/hawaii-island`
        }, {
          name: "Honolulu",
          link: `${linkBaseUrl}hawaii-holidays/honolulu`
        }, {
          name: "Kauai",
          link: `${linkBaseUrl}hawaii-holidays/kauai`
        }, {
          name: "Maui",
          link: `${linkBaseUrl}/hawaii-holidays/maui`
        }
      ]
    }]
  ],

  // New york & the east cost destination

  newYork: [
    [
      {
        name: "New York State",
        link: `${linkBaseUrl}new-york-state-holidays`,
        subData: [
          {
            name: "New York",
            link: `${linkBaseUrl}new-york-state-holidays/new-york`
          }, {
            name: "Albany",
            link: `${linkBaseUrl}new-york-state-holidays/albany`
          }
        ]
      },
      {
        name: "Illinois",
        link: `${linkBaseUrl}/illinois-holidays`,
        subData: [
          {
            name: "Chicago",
            link: `${linkBaseUrl}illinois-holidays/chicago`
          }
        ]
      },
    ], [
      {
        name: "Pennsylvania",
        link: `${linkBaseUrl}/pennsylvania-holidays`,
        subData: [
          {
            name: "Gettysburg",
            link: `${linkBaseUrl}pennsylvania-holidays/gettysburg`
          }, {
            name: "Philadelphia",
            link: `${linkBaseUrl}pennsylvania-holidays/philadelphia`
          }
        ]
      }, {
        name: "District of Columbia",
        link: `${linkBaseUrl}district-of-columbia-holidays`,
        subData: [
          {
            name: "Washington D.C.",
            link: `${linkBaseUrl}district-of-columbia-holidays/washington-d-c`
          }
        ]
      }, {
        name: "Virginia",
        link: `${linkBaseUrl}virginia-holidays`,
        subData: [
          {
            name: "Williamsburg",
            link: `${linkBaseUrl}virginia-holidays/williamsburg`
          }
        ]
      }]
  ],

  // Canada

  canada: [
    {
      name: "With Canadian Sky",
      img: `${linkBaseUrl}FrontEndGlobalAssets/dist/img/brands/canadiansky.svg`,
      link: `https://www.canadiansky.co.uk/`,
      text: "To view our Canada destinations visit our specialist Canadian Sky site here"
    }
  ]
}
