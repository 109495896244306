import React from 'react';
import { Dialog, Box, Typography, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setDialogState } from '../../features/dialog/dialogSlice';
import { colors } from '../../styles/colors';

export default function PriceChangedDialog() {
  //! States
  const dialogState = useSelector((state: any) => state.dialog.dialogState);

  //! Variables
  const dispatch = useDispatch();

  return (
    <Dialog
      open={dialogState.priceChangeDialog}
      onClose={() => dispatch(setDialogState({ ...dialogState, priceChangeDialog: false }))}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      hideBackdrop={true}
      sx={{
        '& .MuiPaper-root': {
          borderColor: colors.basics.primary,
          width: '100%',
          m: { lg: '0px', md: '10px', xs: '30px' },
        },
      }}
      // className={className}
    >
      <Box>
        <Box mb='15px'>
          <Typography variant='h1'>Itinerary Change</Typography>
        </Box>
        <Box mb='15px'>
          <Typography variant='subtitle2'>
            Price Change for Itinerary: AB 234 5678 12 - Sugar Bay Barbados
          </Typography>
        </Box>
        <Box mb='15px'>
          <Typography variant='subtitle2'>
            The price for your itinerary has changed. The new price is £6550.00
          </Typography>
        </Box>
        <Box textAlign={{ sm: 'right', xs: 'center' }}>
          <Button
            variant='contained'
            sx={{ width: '150px' }}
            onClick={() => dispatch(setDialogState({ ...dialogState, priceChangeDialog: false }))}
          >
            Okay
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
