import axios from 'axios';

// Function to make a GET request to the API using Axios
const getApi = async (url: string) => {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('There was a problem with the operation:', error);
        return "error"
    }
}

const postApi = async (url: string, data: any) => {
    try {
        const response = await axios.post(url, data);
        return response.data; // Return response data
    } catch (error: any) {
        console.error('Error making POST request:', error.message);
        return "error"
    }
}

export { getApi, postApi };