import React from 'react';
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { ReactComponent as ExpandIcon } from '../assets/icons/ExpandIcon.svg';
import { colors } from '../styles/colors';

interface ItemAccordionProps {
  Label?: string;
  children?: JSX.Element;
  setHide?: (hide: boolean) => void;
  onChange?: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  expanded?: boolean;
  fullWidth?: boolean;
  className?: string;
}

export const ItemAccordion = ({
  Label,
  children,
  setHide,
  onChange,
  expanded,
  fullWidth,
  className,
}: ItemAccordionProps) => {
  return (
    <>
      <Box
        sx={{
          '& .MuiPaper-root': { boxShadow: 'none' },
          '& .MuiAccordionSummary-content': {
            justifyContent: 'flex-start !important',
          },
          '& .Mui-expanded': {
            transform: 'none !important',
            justifyContent: { sm: 'start !important', xs: "center !important" },
            '&>svg': {
              transform: 'rotate(90deg) !important',
            },
          },

          '& .MuiButtonBase-root': {
            border: `1px solid ${colors.grey[30]}`,
            borderRadius: '5px',
            background: colors.basics.secondary,
            boxShadow: 'none',
            width: '100%',
            ml: 'auto',
          },
          '& .MuiButtonBase-root.Mui-expanded': {
            borderTop: `1px solid ${colors.grey[30]}`,
            borderLeft: `1px solid ${colors.grey[30]}`,
            borderRight: `1px solid ${colors.grey[30]}`,
            borderBottom: '0',
            borderBottomLeftRadius: '0',
            borderBottomRightRadius: '0',
            position: 'relative',
            zIndex: 99,
            top: 0,
          },
          '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded.isChecked': {
            maxWidth: { lg: '35%', sm: '50%', xs: '100%' },
          },
          '& .MuiCollapse-root': {
            border: `1px solid ${colors.grey[30]}`,
            borderRadius: '5px',
            background: colors.basics.secondary,
            boxShadow: 'none',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderTop: 0,
            transitionDuration: '0',
            position: 'relative',
            zIndex: 1,
          },
          '& .MuiCollapse-wrapper:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            backgroundColor: colors.grey[30],
            top: '-1px',
            zIndex: 1,
            height: '1px',
          },
          '& .MuiCollapse-wrapper': {
            position: 'relative',
            zIndex: 11,
          },
        }}
      >
        <Accordion onChange={onChange} expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            className={expanded && fullWidth === true ? 'isChecked title-ac' : 'title-ac'}
          // onClick={() => {
          //   window.innerWidth > 1023 && fullWidth === false ? setHide?.(false) : setHide?.(true);
          // }}
          >
            {/* <Typography sx={{
              fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: '16px !important',
              lineHeight: "21px",
              color: colors.basics.black,
            }}>
              {Label}
            </Typography> */}
            <Typography variant="body1" fontWeight={500}
              lineHeight="20.8px" sx={{
                fontFamily: "El Messiri", fontSize: { sm: "26px", xs: "24px" },
                color: colors.basics.black,
                // textAlign: 'center',
                textAlign: { sm: 'start !important', xs: "center !important" },
              }}>
              {Label}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      </Box >
    </>
  );
};
