import { createSlice } from '@reduxjs/toolkit';

interface Hotels {
  hotels: string[];
}

const initialState: Hotels = {
  hotels: [],
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setHotelId: (state, { payload }) => {
      state.hotels = payload;
    },
  },
});
export const { setHotelId } = mapSlice.actions;
export default mapSlice.reducer;
