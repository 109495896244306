import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';

export const fetchAsyncHolidayPackageDetails: any = createAsyncThunk(
  'packageDetails/fetchAsyncHolidayPackageDetails',
  async (term) => {
    const response = await API.post(API_METHOD.Package.HOLIDAY_DETAIL, term);
    return response.data;
  }
);

const initialState: { packageDetails: any; status: string, taxText: string, countryRemark: any, country: any, vendorRemark: any } = {
  packageDetails: {},
  taxText: '',
  status: '',
  countryRemark: [],
  country: [],
  vendorRemark: {}
};

const holidayPackageDetailSlice = createSlice({
  name: 'packageDetails',
  initialState,
  reducers: {
    clearPackageDetails: (state) => {
      state.packageDetails = {};
    },
    setHolidayPackageDetails: (state, { payload }) => {
      state.packageDetails = payload;
    }
  },
  extraReducers: {
    [fetchAsyncHolidayPackageDetails.pending]: (state): void => {
      state.packageDetails = {};
      state.status = 'PENDING';
      state.taxText = '';
      state.countryRemark = {};
      state.vendorRemark = {};
      state.country = {};
    },
    [fetchAsyncHolidayPackageDetails.fulfilled]: (state, { payload }): void => {
      if (payload) {
        state.packageDetails = payload;
        state.taxText = payload?.find((p: any) => p?.success && p?.rooms)?.rooms?.[0]?.SearchResultRoomDetail?.quote_remarks || "";
        state.country = payload?.find((p: any) => p?.success && p?.rooms)?.rooms?.[0]?.SearchResultRoomDetail?.countryRemarks || [];
        state.vendorRemark = payload?.find((p: any) => p?.success && p?.rooms)?.rooms?.[0]?.SearchResultRoomDetail?.vendorRemarks || [];
        state.countryRemark = [
          ...Object.values(state.country),
          ...Object.values(state.vendorRemark)
        ];
        // state.taxText = payload[0].success ? payload[0]?.rooms ? payload[0]?.rooms[0]?.SearchResultRoomDetail?.quote_remarks : "" : "";
        // state.countryRemark = payload[0].success ? payload[0]?.rooms ? payload[0]?.rooms[0]?.SearchResultRoomDetail?.countryRemarks || [] : [] : [];
      }
      state.status = 'SUCCESS';
    },
    [fetchAsyncHolidayPackageDetails.rejected]: (state) => {
      state.packageDetails = {};
      state.taxText = '';
      state.status = 'REJECTED';
      state.countryRemark = {};
      state.vendorRemark = {};
      state.country = {};
    },
  },
});

export const { clearPackageDetails, setHolidayPackageDetails } = holidayPackageDetailSlice.actions;
export default holidayPackageDetailSlice.reducer;
export const getPackageDetails = (state: any) => state.packageDetails;
