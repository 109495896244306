import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';

export const fetchAsyncPaymentConfiguration: any = createAsyncThunk(
    'payment/fetchAsyncPaymentConfiguration',
    async () => {
        const res = await API.post(API_METHOD.Misc.GET_PAYMENT_CONFIGURATION)
        return res.data;
    }
);

interface state {
    depositeConfiguration: {
        "MinimumDepositAmountPP": string
        "DaysBeforeDepartureBeforeFullPaymentDue": string
        "DepositPercentDefault": string
    } | null
}

const initialState: state = {
    depositeConfiguration: null
};

const paymentConfigurationSlicer = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setDepositeConfiguration: (state, { payload }): void => {
            state.depositeConfiguration = payload;
        },

    }
});

export const { setDepositeConfiguration } = paymentConfigurationSlicer.actions;
export default paymentConfigurationSlicer.reducer;