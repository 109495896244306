import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import {
  Alaska,
  American,
  BostonAndNewEngland,
  California,
  Canada,
  DeepSouth,
  Florida,
  Hawai,
  NewYork,
} from './Menus';
import { colors } from '../styles/colors';
import { MenuOverlay } from './MenuOverlay';
import { useDispatch } from 'react-redux';
import { setDestinations } from '../features/destination/destinationSlice';
import { countries } from '../constants/menuItems';

const data = [
  {
    name: 'Alaska',
    component: <Alaska />,
    isName: true,
    index: 1,
    menus: countries.alaska,
  },
  {
    name: 'American Rockies & Prairies',
    component: <American />,
    isName: true,
    index: 2,
    menus: countries.american,
  },
  {
    name: 'Boston & New England',
    component: <BostonAndNewEngland />,
    isName: true,
    index: 3,
    menus: countries.bostonAndNewEngland,
  }, {
    name: 'California & The West',
    component: <California />,
    isName: true,
    index: 4,
    menus: countries.california,
  }, {
    name: 'Deep South',
    component: <DeepSouth />,
    isName: true,
    index: 5,
    menus: countries.deepSouth,
  }, {
    name: 'Florida',
    component: <Florida />,
    isName: true,
    index: 6,
    menus: countries.florida,
  }, {
    name: 'Hawaii',
    component: <Hawai />,
    isName: true,
    index: 7,
    menus: countries.hawai,
  }, {
    name: 'New York & The East Coast',
    component: <NewYork />,
    isName: true,
    index: 8,
    menus: countries.newYork,
  }, {
    name: 'Canada',
    component: <Canada />,
    isName: true,
    index: 9,
    menus: countries.canada,
  }
];

export const Destinations = () => {
  //! Hooks
  const [currentIndex, setCurrentIndex] = useState<number>(null || 6);

  //! variables
  const dispatch = useDispatch();

  //! Functions
  const handleContinentClick = (item: any) => {
    setCurrentIndex(item.index);
    dispatch(setDestinations(item.menus));
  };

  return (
    <>
      <MenuOverlay>
        <Box
          sx={{
            display: 'flex',
            '& button': {
              p: 0,
              height: '40px',
              justifyContent: 'start',
              width: '100%',
              pl: 1,
              maxHeight: '625px',
              borderTopLeftRadius: '2px',
              borderBottomLeftRadius: '2px',
              borderTopRightRadius: '0',
              borderBottomRightRadius: '0',
            },
          }}
        >
          <Box flex='0 0 25%'>
            <Box>
              <Box>
                <Typography variant='h1' fontFamily='Raleway,sans-serif' mb='0.625rem'>
                  Choose a destination
                </Typography>
              </Box>

              {data?.map((item) => {
                return (
                  item.isName && (
                    <Box key={item.index}>
                      <Button
                        disableRipple={true}
                        variant='text'
                        onClick={() => handleContinentClick(item)}
                        sx={{
                          bgcolor: item.index === currentIndex ? colors.basics.white : null,
                          color: item.index === currentIndex ? colors.basics.primary : '#303030',
                          '&:hover': {
                            color: colors.basics.primary,
                            bgcolor: item.index === currentIndex ? colors.basics.white : null,
                          },
                        }}
                      >
                        {item.name}
                      </Button>
                    </Box>
                  )
                );
              })}
            </Box>
          </Box>

          {data?.map((item: any) => (
            <Box key={item.index} flex='0 0 75%' display={item.index === currentIndex ? 'block' : 'none'}>
              {item.index === currentIndex && <Box height='100%'>{item.component}</Box>}
            </Box>
          ))}
        </Box>
      </MenuOverlay>
    </>
  );
};
