import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stepper: 0,
};

const stepperSlice = createSlice({
  name: 'stepper',
  initialState,
  reducers: {
    setStepperState: (state, { payload }) => {
      state.stepper = payload;
    },
  },
});

export const { setStepperState } = stepperSlice.actions;
export default stepperSlice.reducer;
