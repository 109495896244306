import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Rating,
  Slider,
  Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { resetFilters, setFilterState } from '../features/filter/filterSlice';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { SearchInput } from './SearchInput';
import { colors } from '../styles/colors';
import { roundPrice } from '../helpers/RoundPrice';
import { useAppSelector } from '../hooks/useAppReducer';
import { setFindButtonClick } from '../features/findButtonClicked/findButtonClicked';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { sortRoom } from '../helpers/sortRoom';
interface ModalProp {
  getPosition: (p: any, b: boolean, f: boolean) => void;
  setIsClickedFilters: any;
  isClickedFilters: any;
}

export const FilterComponent = ({ getPosition, isClickedFilters, setIsClickedFilters }: ModalProp) => {
  //! States
  const { filters } = useAppSelector((state) => state.filters);
  const { holidayPackage, filteredHolidayPackage, holidayTypes, filterCounts, regionCounts, regionTypes } = useAppSelector(
    (state) => state.holidayPackage
  );
  const { findButtonClick } = useAppSelector((state: any) => state.findButtonClicked)
  const [showAll, setShowAll] = useState<any>({
    region: false,
    hotels: false
  })
  const { flightRoom } = useAppSelector((state) => state.flightRoom);

  //! Hooks

  //! Hooks
  const [hotelNameSearchText, setHotelNameSearchText] = useState(filters.hotelName);
  const [resultAvailableHotelRatings, setResultAvailableHotelRatings] = useState([])
  // const [pricePerPerson, setPricePerPerson] = useState(filters.price);
  const [pricePerPerson, setPricePerPerson] = useState<any>(Array.isArray(filters.price) ? filters.price : [0, 10500]);
  const [price, setPrice] = useState({ low: 0, high: 0 });
  //! Variables
  const dispatch = useDispatch();
  const packageResults = filteredHolidayPackage?.Packages?.results?.length;
  const totalGuests = Number(flightRoom.searchCriteria.adults + flightRoom.searchCriteria.childrens);
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL

  const minPackage = useMemo(() => {
    return holidayPackage?.Packages?.results?.map((data: any, index: number) => {
      const getRooms = sortRoom(data.SearchResult, filters)

      let price: any = 0
      if (getRooms.length > 0) {
        getRooms.map((f: any) => {
          price = price + Number(f.total_package)
          return <></>
        })
      }

      const totalPrice = price / totalGuests

      return Number(
        roundPrice(
          String(
            totalPrice
          )
        )
      );
    });
  }, [holidayPackage?.Packages?.results, totalGuests]);

  useEffect(() => {
    let ratings: any = []
    holidayPackage?.Packages?.results
      ?.map((item: any) => item.HotelInfo)
      .filter((item: any) => {
        if (item?.hotel_rating) {
          let value = 0
          if (item.hotel_classification === "0") {
            value = Number(item.hotel_rating)
          } else {
            value = item?.hotel_classification === "2" ? Number(item.hotel_rating) === 5 ? 5 : Number(item.hotel_rating) + 0.5 : Number(item.hotel_rating)
          }

          if (!ratings.includes(value)) {
            ratings.push(value)
          }
        }
        return item
      })
    setResultAvailableHotelRatings(ratings.sort((a: number, b: number) => b - a))
  }, [holidayPackage?.Packages?.results])

  useEffect(() => {
    const price = {
      low:
        minPackage?.length !== 0
          ? minPackage?.reduce((min: number, current: number) => {
            return min > current ? current : min;
          })
          : 0,
      high:
        minPackage?.length !== 0
          ? minPackage?.reduce((max: number, current: number) => {
            return max > current ? max : current;
          })
          : 0,
    };
    setPrice(price);
  }, [minPackage]);

  useEffect(() => {
    if (isClickedFilters) {
      getPosition(filteredHolidayPackage, false, true)
    }
  }, [filteredHolidayPackage, isClickedFilters])

  //! Functions
  const handleReset = () => {
    dispatch(setIsClickedFilters(true))
    dispatch(resetFilters());
    setHotelNameSearchText('');
    dispatch(setFilterState({
      holidayType: [],
      starRating: [],
      hotelName: '',
      sorting: 'Popularity',
      regionType: [],
      price: [price?.low, price?.high]
    }))
    setPricePerPerson([price?.low, price?.high]);
  };

  useEffect(() => {
    if (!findButtonClick) {
      setPricePerPerson(filters.price);
      dispatch(setFilterState({ ...filters, price: filters.price }))
    }
  }, [filters.price, findButtonClick])

  useEffect(() => {
    if (findButtonClick) {
      setShowAll({
        region: false,
        hotels: false
      })
      setPricePerPerson([price.low ? price.low : 0, price?.high || 10500])
      dispatch(setFilterState({ ...filters, price: [price.low ? price.low : 0, price?.high || 10500] }))
    }
  }, [findButtonClick, price.low, price?.high])

  const handleChangeHolidayTypeFilter = (e: any) => {
    dispatch(setIsClickedFilters(true))
    const holidayTypes: any = [...filters.holidayType];

    if (e.target.checked) {
      holidayTypes.push(e.target.value);
    } else {
      holidayTypes.splice(holidayTypes.indexOf(e.target.value), 1);
    }

    dispatch(setFilterState({ ...filters, holidayType: holidayTypes }));
  };

  const handleChangeRegionTypeFilter = (e: any) => {
    dispatch(setIsClickedFilters(true))
    const regionTypes: any = [...filters.regionType];
    if (e.target.checked) {
      regionTypes.push(e.target.value);
    } else {
      regionTypes.splice(regionTypes.indexOf(e.target.value), 1);
    }
    dispatch(setFilterState({ ...filters, regionType: regionTypes }));
  };

  const handleChangeRating = (e: any) => {
    dispatch(setIsClickedFilters(true))
    let ratings = [...filters?.starRating];
    
    if (e?.target?.checked) {
      ratings.push(Number(e?.target?.value));
    } else {
      ratings = ratings?.filter((item) => item !== Number(e?.target?.value));
    }

    dispatch(setFilterState({ ...filters, starRating: ratings }));
  };

  const handleHotelNameSearchChange = (value: any) => {
    dispatch(setIsClickedFilters(true))
    if (value) {
      setHotelNameSearchText(value);
    } else {
      setHotelNameSearchText(value);
      dispatch(setFilterState({ ...filters, hotelName: '' }));
    }
  };

  const handleChangeShowAllFilters = (name: any) => {
    setShowAll({
      ...showAll,
      [name]: !showAll[name]
    })
  }

  return (
    <Box border={{ lg: `1px solid ${colors.grey[30]}`, md: 'none' }} className='main_filterComponent'>
      <Box
        display='flex'
        justifyContent='space-between'
        height={{ lg: '56px', sm: '40px', xs: '30px' }}
        alignItems='center'
        p='0 20px'
        bgcolor={{ lg: colors.basics.lightmain, md: 'none' }}
      >
        <Typography variant='body1' fontWeight={600} mt={{ lg: '0px', md: '20px' }}>
          Filter
        </Typography>
        <Button
          disableRipple={true}
          variant='text'
          sx={{
            fontSize: '13px',
            fontWeight: 500,
            display: { lg: 'flex', xs: 'none' },
            p: 0,
          }}
          onClick={handleReset}
        >
          Reset all
        </Button>
      </Box>
      <Box bgcolor={colors.basics.secondary} p='0 20px 36px'>
        <Box display={{ lg: 'flex', xs: 'none' }}>
          <Typography color={colors.grey[30]} p='22px 0 20px'>
            {packageResults || 0} Results
          </Typography>
        </Box>{' '}
        <div hidden={window.innerWidth > 576 ? regionTypes?.length <= 1 : true}>
          <Divider />
          <Box m='24px 0 19px'>
            <FormControl component='fieldset' variant='standard'>
              <FormLabel sx={{ mb: '10px' }}>
                <Typography variant='subtitle1'>Region</Typography>
              </FormLabel>
              <FormGroup
                sx={{
                  display: 'flex',
                  flexDirection: { md: 'col', xs: 'row' },
                  '& label': {
                    flex: { lg: '0 0 100%', sm: '0 0 48%', xs: '0 0 100%' },
                    alignItems: 'flex-start',
                  },
                  ml: '10px',
                  '& .MuiButtonBase-root': {
                    padding: '0',
                    mr: '15px',
                    mb: '5px',
                  },
                }}
              >
                {regionTypes?.slice(0, !showAll.region ? 5 : regionTypes.length)?.map((place, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={<Checkbox name={place} />}
                      label={place.replace(/&amp;/g, '&') + " (" + (regionCounts[place] || 0) + ")"}
                      value={place}
                      checked={filters.regionType.includes(place)}
                      onChange={handleChangeRegionTypeFilter}
                    />
                  );
                })}
              </FormGroup>
              {regionTypes.length > 5 && (!showAll.region ?
                <Box sx={{
                  '& .MuiButton-endIcon>*:nth-of-type(1)': {
                    fontSize: '25px',
                  },
                  '& .MuiButton-endIcon': {
                    marginLeft: 0,
                  }

                }}> <Button
                  sx={{ justifyContent: "left", fontSize: "15px", p: 0, whiteSpace: 'nowrap' }}
                  onClick={() => {
                    handleChangeShowAllFilters("region")
                  }}
                  variant='text'
                  endIcon={<KeyboardArrowDownIcon />}
                >
                    Show all
                  </Button> </Box> : <Box sx={{
                    '& .MuiButton-endIcon>*:nth-of-type(1)': {
                      fontSize: '25px',
                    },
                    '& .MuiButton-endIcon': {
                      marginLeft: 0,
                    }
                  }}> <Button
                    onClick={() => {
                      handleChangeShowAllFilters("region")
                    }}
                    variant='text'
                    endIcon={<KeyboardArrowUpIcon />}
                    sx={{ justifyContent: "left", fontSize: "15px", p: 0, whiteSpace: 'nowrap' }}
                  >
                    Show less
                  </Button></Box>)}


              {/* {regionTypes.length > 5 && (!showAll.region ? <span style={{ display: "flex !important", alignItems: "center" }} onClick={() => {
                handleChangeShowAllFilters("region")
              }}>Show all {regionTypes.length - 5}  <ArrowDownwardIcon /> </span>
                : <span onClick={() => {
                  handleChangeShowAllFilters("region")
                }}> Show less <ArrowUpwardIcon /></span>)} */}
            </FormControl>
          </Box>
        </div>
        <div hidden={!holidayTypes?.length}>
          <Divider />
          <Box m='24px 0 19px'>
            <FormControl component='fieldset' variant='standard'>
              <FormLabel sx={{ mb: '10px' }}>
                <Typography variant='subtitle1'>Holiday Type</Typography>
              </FormLabel>
              <FormGroup
                sx={{
                  display: 'flex',
                  flexDirection: { md: 'col', xs: 'row' },
                  '& label': {
                    flex: { lg: '0 0 100%', sm: '0 0 48%', xs: '0 0 100%' },
                    alignItems: 'flex-start',
                  },
                  ml: '10px',
                  '& .MuiButtonBase-root': {
                    padding: '0',
                    mr: '15px',
                    mb: '5px',
                  },
                }}
              >
                {holidayTypes?.slice(0, !showAll.hotels ? 5 : holidayTypes.length).map((place, index) => {
                  return <FormControlLabel
                    key={index}
                    control={<Checkbox name={place} />}
                    label={place.split('~')[1] + " (" + (filterCounts[place.split('~')[1]] || 0) + ")"}
                    value={place}
                    checked={filters.holidayType.includes(place)}
                    onChange={handleChangeHolidayTypeFilter}
                  />
                })}
              </FormGroup>

              {holidayTypes.length > 5 && (!showAll.hotels ? <Box sx={{
                '& .MuiButton-endIcon>*:nth-of-type(1)': {
                  fontSize: '25px',
                },
                '& .MuiButton-endIcon': {
                  marginLeft: 0,
                }

              }}> <Button
                sx={{ justifyContent: "left", fontSize: "15px", p: 0, whiteSpace: 'nowrap' }}
                onClick={() => {
                  handleChangeShowAllFilters("hotels")
                }}
                variant='text'
                endIcon={<KeyboardArrowDownIcon />}
              >
                  Show all
                </Button> </Box> : <Box sx={{
                  '& .MuiButton-endIcon>*:nth-of-type(1)': {
                    fontSize: '25px',
                  },
                  '& .MuiButton-endIcon': {
                    marginLeft: 0,
                  }
                }}> <Button
                  onClick={() => {
                    handleChangeShowAllFilters("hotels")
                  }}
                  variant='text'
                  endIcon={<KeyboardArrowUpIcon />}
                  sx={{ justifyContent: "left", fontSize: "15px", p: 0, whiteSpace: 'nowrap' }}
                >
                  Show less
                </Button></Box>)}

              {/* {holidayTypes.length > 5 && (!showAll.hotels ? <span onClick={() => {
                handleChangeShowAllFilters("hotels")
              }}>Show all {holidayTypes.length - 5}  <ArrowDownwardIcon /> </span> : <span onClick={() => {
                handleChangeShowAllFilters("hotels")
              }}> Show less <ArrowUpwardIcon /></span>)} */}

            </FormControl>
          </Box>
        </div>
        <Divider />
        {resultAvailableHotelRatings.length > 0 && (
          <>
            <Box m='18px 0 15px'>
              <FormControl component='fieldset' variant='standard'>
                <FormLabel sx={{ mb: '13px' }}>
                  <Typography variant='subtitle1'>Star Rating</Typography>
                </FormLabel>
                <FormGroup
                  sx={{
                    ml: '10px',
                    '& .MuiButtonBase-root': {
                      p: 0,
                      mr: '15px',
                      mb: '5px',
                    },
                  }}
                >
                  {resultAvailableHotelRatings?.map((rating: any, i: number) => {
                    return rating <= 5 ? (
                      <FormControlLabel
                        key={i}
                        control={<Checkbox />}
                        value={rating}
                        label={
                          <Rating sx={{ fontSize: "18px" }} emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} defaultValue={rating} precision={rating % 1 === 0 ? 1 : 0.5} readOnly={true} />
                        }
                        checked={filters.starRating.includes(rating)}
                        onChange={(e) => {
                          // resultAvailableHotelRatings?.includes(String(rating)) && 
                          handleChangeRating(e);
                        }}
                      />
                    ) : null;
                  })}
                </FormGroup>
              </FormControl>
            </Box>
            <Divider />
          </>
        )}
        <Box m='28px 0 23px' display={{ lg: 'block', xs: 'none' }}>
          <Typography variant='subtitle1' mb='10px'>
            Hotel Name
          </Typography>
          <SearchInput
            onSearchButtonClick={() =>
              dispatch(setFilterState({ ...filters, hotelName: hotelNameSearchText }))
            }
            onChange={handleHotelNameSearchChange}
            placeholder='Enter hotel name'
            // defaultValue={filters.hotelName}
            value={hotelNameSearchText}
          />
        </Box>
        <Divider />
        <Box m='30px 0 0'>
          <Typography variant='subtitle1' mb='10px'>
            Price(per person)
          </Typography>
          <Typography variant='body1' fontWeight={500} mb='9px'>
            {signForPricing + pricePerPerson[0]} - {signForPricing + pricePerPerson[1]}
          </Typography>
          <Box>
            <Slider
              onChangeCommitted={(event: React.SyntheticEvent | Event, val: any) => {
                // if (holidayPackage.Packages.results > 0) {
                dispatch(setFilterState({ ...filters, price: val }))
                dispatch(setFindButtonClick(false))
                // }
              }}
              min={price?.low || 0}
              max={price?.high || 10500}
              valueLabelDisplay="auto"
              value={pricePerPerson || 0}
              onChange={(e, val: number | any) => {
                // if (holidayPackage.Packages.results > 0) {
                setPricePerPerson(val)
                dispatch(setIsClickedFilters(true))
                // }
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
