import './index.css';

import { AppBar, Box, Button, Dialog, Toolbar, Typography } from '@mui/material';
import { GoogleMap, InfoWindowF, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomContainer } from '../../components/CustomContainer';
import { PackageCard } from '../../components/PackageCardComponent';
import { colors } from '../../styles/colors';
import gmapMarkerBlack from '../../assets/images/gmap-marker-black.png';
import { roundPrice } from '../../helpers/RoundPrice';
import { setDialogState } from '../../features/dialog/dialogSlice';
import { setHotelId } from '../../features/map/mapSlice';
import { useAppSelector } from '../../hooks/useAppReducer';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setFilterCounts, setFilteredHolidayPackage, setRegionCounts } from '../../features/holidayPackage/holidayPackageSlice';
import { setStepperState } from '../../features/stepper/stepperSlice';
import { getSessionStorage, setSessionStorage } from '../../helpers/storage';
import { sortRoom } from '../../helpers/sortRoom';
import parseHTML from '../../helpers/parseHtml';

interface mapDialogProps { }

interface CABIN_CLASS_ENUM_TYPE {
  Any: string;
  Economy: string;
  Premium: string;
  'Business Class': string;
  'First Class': string;
}

const CABIN_CLASS_ENUM: CABIN_CLASS_ENUM_TYPE = {
  Any: '',
  Economy: 'M',
  Premium: 'W',
  'Business Class': 'C',
  'First Class': 'F',
};

type CabinClass = keyof typeof CABIN_CLASS_ENUM;

var store = require('store');

const MapDialog = (props: mapDialogProps) => {
  //! States
  const { stepper } = useAppSelector((state) => state.stepper);
  const dialogState = useAppSelector((state: any) => state.dialog.dialogState);
  const { hotels } = useAppSelector((state) => state.map);
  const { holidayPackage, filteredHolidayPackage, status } = useAppSelector((state) => state.holidayPackage);
  const [basketId, setBasketId] = useState(getSessionStorage("basketId"));
  const { session_id }: any = useParams();
  const { filters } = useAppSelector((state: any) => state.filters);
  const { flightRoom } = useAppSelector((state) => state.flightRoom);
  const [clickOnPoints, setOnClickPoints] = useState(false)
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  const [hotelIds, setHotelIds] = useState("")
  const [initialHotelId, setInitialHotelId] = useState("")
  const [hotelsToShow, setHotelsToShow] = useState<Record<string, any>>();
  const { hotelDetails } = useAppSelector((state) => state.hotelDetails);
  const isPlataniumHotel = Number(hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel) !== 0;
  //! Variables
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [center, setCenter] = useState<any>();

  //* Guests count excluding infants
  const totalGuests = flightRoom.searchCriteria.adults + flightRoom.searchCriteria.childrens;
  const containerStyle = {
    width: '100%',
    height: 'calc(100vh - 200px)',
  };
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_MAP_API_KEY),
  });

  useEffect(() => {
    // display counts of filters hotel
    const counts: any = {};
    const facilities: string[] = [];
    const regionCounts: any = {}
    const regions: string[] = [];

    filteredHolidayPackage?.Packages?.results?.forEach((item: any, i: number) => {
      if (item?.HotelInfo?.resort_name) {
        regions.push(item.HotelInfo.resort_name)
      }

      item.facilities.forEach((ele: string) => {
        const parseString: string | null = parseHTML(ele).body.textContent || null;
        return parseString !== null && facilities.push(parseString)
      });
    })

    const remove17AllHoliday = facilities.filter((f: any) => f !== "17~All Holidays")

    remove17AllHoliday.forEach(item => {
      const name = item.split('~')[1];
      counts[name] = (counts[name] || 0) + 1;
    });

    regions.forEach(item => {
      regionCounts[item] = (regionCounts[item] || 0) + 1;
    })

    dispatch(setRegionCounts(regionCounts))
    dispatch(setFilterCounts(counts))
  }, [filteredHolidayPackage?.Packages?.results])

  useEffect(() => {
    if (getSessionStorage("basketId")) {
      setBasketId(getSessionStorage("basketId"))
    } else if (window.location.search.includes("?email")) {
      setBasketId(window.location.search.replace("?email=", ""))
    } else {
      setBasketId(null)
    }
  }, [getSessionStorage("basketId"), window.location.search])

  useEffect(() => {
    if (Object.keys(holidayPackage).length === 0) {
      if (window.location.pathname.includes("holiday-summary")) {
        navigate(`/holiday-summary/${session_id}${window.location.search}`)
      } else {
        navigate(`/${window.location.search}`)
      }
    }

  }, [holidayPackage, getSessionStorage("apiCall")])

  useEffect(() => {
    if (window.location.pathname.includes("holiday-summary")) {
      dispatch(setStepperState(1))
    } else {
      dispatch(setStepperState(0))
    }
  }, [])

  useEffect(() => {
    if (stepper === 1) {
      const stepper1Hotels = filteredHolidayPackage?.Packages?.results.filter((f: any) => f.SearchResult.hotel_id === flightRoom.hotel_id)
      if (filteredHolidayPackage?.Packages?.results?.length) {
        const firstResult = stepper === 1 ? stepper1Hotels.length > 0 ? stepper1Hotels[0] : filteredHolidayPackage.Packages.results[0] : filteredHolidayPackage.Packages.results[0];
        setInitialHotelId(firstResult.SearchResult.hotel_id)
        setHotelIds(firstResult.SearchResult.hotel_id)
        dispatch(
          setHotelId([
            ...hotels.filter((hotel: string) => hotel !== firstResult.SearchResult.hotel_id),
            firstResult.SearchResult.hotel_id,
          ])
        );
      }
    }

  }, [filteredHolidayPackage, flightRoom.hotel_id, stepper])

  useEffect(() => {
    if (holidayPackage?.Packages && status === 'SUCCESS') {
      let data = JSON.parse(JSON.stringify(holidayPackage));
      let filteredData = [];
      setTimeout(() => {
        //* Rating wise filters
        filteredData = holidayPackage.Packages.results.filter((item: any) => {
          const value = item.HotelInfo.hotel_classification === "0" ? Number(item.HotelInfo.hotel_rating) :
            item.HotelInfo.hotel_classification === "2" ? Number(item.HotelInfo.hotel_rating) === 5 ? 5 : Number(item.HotelInfo.hotel_rating) + 0.5 : Number(item.HotelInfo.hotel_rating)

          return filters.starRating.length === 0
            ? item
            : filters.starRating.includes(value);
        });

        //* Region wise filter
        if (filters.regionType.length) {
          filteredData = filteredData?.filter((item: any) => {
            if (filters.regionType.includes(item.HotelInfo?.resort_name)) {
              return item;
            }
          });
        }

        //* Hotel Name wise filter
        if (filters.hotelName) {
          filteredData =
            filteredData.length > 0 &&
            filteredData?.filter((item: any) => {
              return item.SearchResult.hotel_name.toLowerCase().includes(filters.hotelName.toLowerCase());
            });
        }

        if (filters.price) {
          filteredData = filteredData.filter((item: any) => {
            const data = stepper === 1 ? (item.SearchResult.hotel_id !== flightRoom.hotel_id) ? sortRoom(item.SearchResult, filters) :
              flightRoom.multiRooms : sortRoom(item.SearchResult, filters);

            let price1: any = 0
            if (data.length > 0) {
              data.map((f: any) => {
                price1 = price1 + Number(f.total_package)
              })
            }

            const totalPrice = price1 / totalGuests

            const price = Number(
              Number(
                roundPrice(
                  String(
                    totalPrice
                  )
                )
              ).toFixed()
            )

            return price >= filters.price[0] && price <= filters.price[1]
          });
        }

        //* Holiday types wise filters
        if (filters.holidayType.length) {
          filteredData = filteredData.filter((item: any) => {
            // if (filters.holidayType.includes('18~All Inclusive Holidays')) {
            //* All Inclusive meal type filter
            const parseString: string | null = parseHTML(item.facilities).body.textContent || null;

            return (
              // item.SearchResult.Rooms[1].map((room: any) => room.meal_name).includes('All Inclusive') ||
              filters.holidayType.every((facility: string) => parseString !== null ? parseString.includes(facility) : false)
            );
            // } else {
            //   return filters.holidayType.some((facility: string) => parseString !== null ? parseString.includes(facility) : false);
            // }
          });
        }

        // * Sorting data
        if (filters.sorting === 'Popularity') {
          filteredData = filteredData
            .slice()
            .sort(
              (a: any, b: any) =>
                parseInt(a.HotelInfo.preferredOnSites) - parseInt(b.HotelInfo.preferredOnSites)
            );
        }

        if (filters.sorting === 'Price (low to high)') {
          filteredData = filteredData.slice().sort((a: any, b: any) => {
            const aData = sortRoom(a.SearchResult, filters);
            const bData = sortRoom(b.SearchResult, filters);

            let aPrice: any = 0
            if (aData.length > 0) {
              aData.map((f: any) => {
                aPrice = aPrice + Number(f.total_package)
              })
            }
            const aTotalPrice = aPrice / totalGuests

            let bPrice: any = 0
            if (bData.length > 0) {
              bData.map((f: any) => {
                bPrice = bPrice + Number(f.total_package)
              })
            }

            const bTotalPrice = bPrice / totalGuests

            return (
              aTotalPrice -
              bTotalPrice
            );
          });
        }

        if (filters.sorting === 'Price (high to low)') {
          filteredData = filteredData.slice().sort((a: any, b: any) => {
            const aData = sortRoom(a.SearchResult, filters);
            const bData = sortRoom(b.SearchResult, filters);

            let aPrice: any = 0
            if (aData.length > 0) {
              aData.map((f: any) => {
                aPrice = aPrice + Number(f.total_package)
              })
            }

            let bPrice: any = 0
            if (bData.length > 0) {
              bData.map((f: any) => {
                bPrice = bPrice + Number(f.total_package)
              })
            }

            const aTotalPrice = aPrice / totalGuests
            const bTotalPrice = bPrice / totalGuests

            return (
              bTotalPrice - aTotalPrice
            );
          });
        }

        data.Packages.results = filteredData;

        dispatch(setFilteredHolidayPackage(data));
        // dispatch(setLoading(false));
      }, 500);
    }
  }, [filters]);

  useEffect(() => {
    if (!clickOnPoints) {
      (async () => {
        getCenterPosition().then((center) => {
          setTimeout(() => {
            setCenter(center);
          }, 250);
        });
      })();
    }
  }, [hotelsToShow, filteredHolidayPackage, hotels, clickOnPoints]);

  useEffect(() => {
    if (hotels?.length && Object.keys(filteredHolidayPackage).length) {
      const allHotels = filteredHolidayPackage?.Packages?.results?.map((hotel: any) => hotel);
      if (allHotels.length > 0) {
        let hotel = hotels.map((hotelId: string) => {
          return allHotels.find((hotel: any) => hotel.SearchResult.hotel_id === hotelId);
        });
        if (hotel.length > 0) {
          const removeUndefinedValues = hotel.filter((h) => h !== undefined)
          setHotelsToShow(removeUndefinedValues);
        } else {
          setHotelsToShow([]);
        }
      } else {
        setHotelsToShow([]);
      }
    } else {
      setHotelsToShow([]);
    }

  }, [filteredHolidayPackage, hotels]);

  useEffect(() => {
    let data: any = {}

    if (hotelsToShow?.length) {
      data = hotelsToShow[hotelsToShow?.length - 1]
    } else {
      data = filteredHolidayPackage?.Packages?.results[0]
    }

    const filterArr = filteredHolidayPackage && filteredHolidayPackage?.Packages && filteredHolidayPackage?.Packages?.results ? Object.values(filteredHolidayPackage?.Packages?.results) : []
    if (filterArr.length > 0) {
      let index = 0
      filterArr.filter((f: any, i: any) => {
        if (f.SearchResult.hotel_id === data.SearchResult.hotel_id) {
          index = i
        }
        return f
      })

      addClassToSelf(index)
    }
  }, [hotelsToShow, filteredHolidayPackage])

  useEffect(() => {
    if (basketId && flightRoom.psw_result_id === null && getSessionStorage("more-details-click") === "false") {
      if (Object.keys(holidayPackage).length === 0) {
        if (window.location.pathname.includes("holiday-summary")) {
          navigate(`/holiday-summary/${session_id}${window.location.search}`)
        } else {
          navigate(`/${window.location.search}`)
        }
      }
    }
  }, [basketId, getSessionStorage("more-details-click"), flightRoom])

  useEffect(() => {
    const search = window.location.search
    if (((search.includes("departure") || search.includes("destination"))) && getSessionStorage("mapApiCall") === "false") {
      setSessionStorage("mapApiCall", "true")
      if (Object.keys(holidayPackage).length === 0) {
        if (window.location.pathname.includes("holiday-summary")) {
          navigate(`/holiday-summary/${session_id}${window.location.search}`)
        } else {
          navigate(`/${window.location.search}`)
        }
      }
    }
  }, [])

  const getCenterPosition = () => {
    return new Promise((resolve) => {
      let center = { lat: 0, lng: 0 };

      const stepper1Hotels = filteredHolidayPackage?.Packages?.results.filter((f: any) => f.SearchResult.hotel_id === flightRoom.hotel_id)

      if (filteredHolidayPackage?.Packages?.results?.length) {
        const firstResult = stepper === 1 ? stepper1Hotels.length > 0 ? stepper1Hotels[0] : filteredHolidayPackage.Packages.results[0] : filteredHolidayPackage.Packages.results[0];
        setInitialHotelId(firstResult.SearchResult.hotel_id)
        setHotelIds(firstResult.SearchResult.hotel_id)
        center = getCoordinatesFromHotel(firstResult);
      }
      resolve(center);
    });
  }

  function getCoordinatesFromHotel(hotel: any) {
    const latitude = Number(hotel?.SearchResult?.address?.latitude);
    const longitude = Number(hotel?.SearchResult?.address?.longitude);
    return { lat: latitude, lng: longitude };
  }

  const addClassToSelf = useCallback((id: any) => {
    setTimeout(() => {
      const parentElement = document.getElementsByClassName(`gm-style-iw`)
      const element: HTMLElement | null = document.querySelector(`.gm-style-iw.gm-style-iw-c #hotelPrice${id}`) || null
      if (element !== null) {
        const nodeElement: Element | null = element.parentNode !== null ? element.parentNode.parentNode !== null ? element.parentNode.parentNode.parentNode as Element : null : null

        if (nodeElement !== null) {
          for (let i = 0; i < parentElement.length; i++) {
            if (stepper === 0) {
              parentElement[i].classList?.remove(isPlataniumHotel ? 'platinumStyle' : 'myStyle');
            } else {
              if (i !== 0) {
                parentElement[i].classList?.remove(isPlataniumHotel ? 'platinumStyle' : 'myStyle');
              }
              nodeElement?.classList?.remove(isPlataniumHotel ? 'outlined-light-platinum' : 'outlined-light-red');
            }
          }
          nodeElement?.classList?.add(isPlataniumHotel ? 'platinumStyle' : 'myStyle');
          if (stepper === 1) {
            if (hotelIds !== initialHotelId) {
              nodeElement?.classList?.add(isPlataniumHotel ? 'outlined-light-platinum' : 'outlined-light-red');
            } else {
              nodeElement?.classList?.remove(isPlataniumHotel ? 'outlined-light-platinum' : 'outlined-light-red');
            }
          }
        }
      }

    }, 200);
  }, [hotelIds, initialHotelId])

  const handleCloseMapDialog = () => {
    dispatch(setDialogState({ ...dialogState, mapDialog: false }));
    dispatch(setHotelId([]));
    setHotelsToShow([]);
  };

  const flightPrice: any = useMemo(() => {
    let price: any = 0
    const flightPrice = getSessionStorage("stepper") === "0" ? "0" : flightRoom.diffPrice || "0"
    // console.log(flightRoom?.selectedFlights?.outbound?.[0]?.FlightPswFlight?.FlightPswResult)
    if (getSessionStorage("nextAndSelectedFlights") === "false") {
      price = Math.ceil(flightPrice)
    } else {
      if (flightPrice.includes("-")) {
        price = Math.floor(flightPrice);
      } else {
        price = Math.ceil(flightPrice)
      }
    }
    return stepper === 0 ? 0 : price
  }, [flightRoom.diffPrice, getSessionStorage("stepper"), getSessionStorage("nextAndSelectedFlights")])

  return (
    <Dialog
      fullScreen
      // open={dialogState.mapDialog}
      open={true}
      // onClose={handleCloseMapDialog}
      sx={{
        p: 0,
        // top: { md: '50px', xs: '20px' },
        // left: { md: '70px', xs: '15px' },
        // right: { md: '70px', xs: '15px' },
        // bottom: { md: '50px', xs: '20px' },
        '& .MuiDialog-paper': {
          p: 0,
          border: 'none',
        },
        '& .MuiContainer-root': {
          px: 0,
        },
      }}
    >
      <AppBar
        sx={{
          position: 'sticky',
          top: '0',
          backgroundColor: colors.basics.white,
          boxShadow: 'none',
          borderBottom: `1px solid ${colors.grey[30]}`,
          p: 0,
          mb: '15px',
        }}
      >
        <Toolbar
          sx={{
            bgcolor: colors.basics.white,
            display: 'flex',
            justifyContent: 'space-between',
            p: 0,
            pr: '20px',
            pl: { sm: '20px', xs: '30px' },
            '& .MuiIconButton-root': {
              pl: { sm: '20px', xs: '30px' },
              padding: "0"
            },
            '& .MuiButton-root ': {
              padding: { sm: '14px 15px', xs: '9px 4px' },
            }
          }}
        >
        <Box
            sx={{
              '& .MuiButtonBase-root': {
                border: isPlataniumHotel
                  ? `1px solid ${colors.basics.plataniumGreenColor}`
                  : `1px solid ${colors.basics.primary}`,
                color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                // background: colors.grey[30],
                '&:hover': {
                  border: isPlataniumHotel
                    ? `1px solid ${colors.basics.plataniumGreenColor}`
                    : `1px solid ${colors.basics.primary}`,
                },
              },
            }}
          >
            <Button
              onClick={() => {
                dispatch(setDialogState({ ...dialogState, mapDialog: false }));
                const id = window.location.pathname.replace('/holiday-summary/', '').substring(0, 10);
                if (window.location.pathname.includes('holiday-summary')) {
                  navigate(`/holiday-summary/${id}${window.location.search}`);
                } else {
                  navigate(`/${window.location.search}`);
                }
              }}
              variant="outlined"
              sx={{ fontSize: '14px' }}
            >
              Close
            </Button>
          </Box>
          <Box
            sx={{
              '& .MuiButtonBase-root': {
                border: isPlataniumHotel
                  ? `1px solid ${colors.basics.plataniumGreenColor}`
                  : `1px solid ${colors.basics.primary}`,
                color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                // background: colors.grey[30],
                '&:hover': {
                  border: isPlataniumHotel
                    ? `1px solid ${colors.basics.plataniumGreenColor}`
                    : `1px solid ${colors.basics.primary}`,
                },
              },
            }}
          >
            <Button
              variant="outlined"
              onClick={() => dispatch(setDialogState({ ...dialogState, filterDialog: true }))}
            >
              <Typography
                variant="body2"
                color={isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary}
              >
                Filter by
              </Typography>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <>
        <Box
          sx={{
            border: `1px solid ${colors.grey[30]}`,
            mt: '1px',
            ml: '20px',
            mr: '20px',
            "& .gm-style-iw": {
              paddingTop: 0 + " !important"
            },
            ...(isPlataniumHotel
              ? {
                '& .gm-style-iw.gm-style-iw-c.myStyle.outlined-light-platinum': {
                  outline: '0 !important',
                },
                '& .outlined-light-platinum::before': {
                  border: '0 !important',
                },
              }
              : {
                '& .gm-style-iw.gm-style-iw-c.myStyle.outlined-light-platinum': {
                  outline: '0 !important',
                },
                '& .outlined-light-platinum::before': {
                  border: '0 !important',
                },
              }),
          }}
        >
          {isLoaded && filteredHolidayPackage && Object.keys(filteredHolidayPackage)?.length > 0 ? (
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={stepper === 0 ? 10 : 9}>
              {filteredHolidayPackage?.Packages?.results &&
                Object.values(filteredHolidayPackage?.Packages?.results)?.length !== 0 &&
                Object.values(filteredHolidayPackage?.Packages?.results).map((item: any, i: number) => {
                  const roomData = stepper === 1 ? (item.SearchResult.hotel_id === flightRoom.hotel_id ? flightRoom.multiRooms : sortRoom(item?.SearchResult, filters)) :
                    sortRoom(item?.SearchResult, filters)
                  let roomPrice = 0

                  roomData.map((f: any) => {
                    roomPrice = roomPrice + Number(f?.total_package)
                  })

                  const totalPrice = (stepper === 0 || (item.SearchResult.hotel_id !== flightRoom.hotel_id)) ? (roomPrice / totalGuests) : (roomPrice / totalGuests) + flightPrice

                  return (
                    <Box key={i}>
                      {stepper === 0 && (
                        <InfoWindowF
                          position={{
                            lat: Number(item?.SearchResult?.address?.latitude),
                            lng: Number(item?.SearchResult?.address?.longitude),
                          }}
                          key={i}
                          onLoad={() => addClassToSelf(0)}
                        >
                          <Box
                            id={`hotelPrice${i}`}
                          >
                            <Typography variant='h4' fontFamily='Raleway' fontWeight='bold'
                              sx={{
                                padding: "4px 14px",
                                '& .gm-style-iw.gm-style-iw-c::after': {
                                  contain: " '' !important",
                                }
                              }}
                              onClick={() => {
                                setHotelIds(item.SearchResult.hotel_id)
                                setOnClickPoints(true)
                                addClassToSelf(i)
                                dispatch(
                                  setHotelId([
                                    ...hotels.filter((hotel: string) => hotel !== item.SearchResult.hotel_id),
                                    item.SearchResult.hotel_id,
                                  ])
                                );
                              }}>
                              {signForPricing + Number(
                                roundPrice(
                                  String(
                                    totalPrice
                                  )
                                )
                              ).toFixed(0)}
                            </Typography>
                            <Typography variant='caption'
                              className='down-arrow'
                              onClick={() => {
                                setHotelIds(item.SearchResult.hotel_id)
                                setOnClickPoints(true)
                                addClassToSelf(i)
                                dispatch(
                                  setHotelId([
                                    ...hotels.filter((hotel: string) => hotel !== item.SearchResult.hotel_id),
                                    item.SearchResult.hotel_id,
                                  ])
                                );
                              }}
                              sx={{
                                height: '15px !important',
                                cursor: 'pointer',
                                width: "15px !important",
                                position: 'absolute !important',
                                backgroundColor: 'white',
                                bottom: '-15px !important',
                                left: ' 50% !important',
                                transform: 'rotate(45deg) translateX(-50%) !important',
                                border: ' 0 !important',
                                borderBottom: '#000000 solid 3px !important',
                                borderRight: '#000000 solid 3px !important',
                                zIndex: '5'
                              }}></Typography>
                          </Box>
                        </InfoWindowF>
                      )}
                      {stepper === 1 &&
                        (hotels.includes(item.SearchResult.hotel_id) ? (
                          <InfoWindowF
                            position={{
                              lat: Number(item?.SearchResult?.address?.latitude),
                              lng: Number(item?.SearchResult?.address?.longitude),
                            }}
                            key={i}
                            onLoad={() => addClassToSelf(i)}
                          >
                            <Box
                              id={`hotelPrice${i}`}
                            >
                              <Typography variant='h4' fontFamily='Raleway' fontWeight='bold'
                                onClick={() => {
                                  setHotelIds(item.SearchResult.hotel_id)
                                  setOnClickPoints(true)
                                  addClassToSelf(i)
                                  const hotelId = item.SearchResult.hotel_id;
                                  let newArr = [...hotels]

                                  if (initialHotelId !== hotelId) {
                                    if (newArr.includes(hotelId)) {
                                      const index = newArr.indexOf(hotelId);
                                      if (index > -1) {
                                        newArr.splice(index, 1);
                                      }
                                      if (newArr.length === 1) {
                                        setHotelIds(initialHotelId)
                                      }
                                    } else {
                                      newArr = []
                                      newArr.push(initialHotelId)
                                      newArr.push(hotelId)
                                    }
                                    dispatch(setHotelId(newArr));
                                  } else {
                                    setHotelIds(initialHotelId)
                                    dispatch(setHotelId([initialHotelId]));
                                  }
                                }
                                } sx={{
                                  padding: "4px 14px",
                                  '& .gm-style-iw.gm-style-iw-c::after': {
                                    contain: " '' !important",
                                  }
                                }}>
                                {signForPricing + Number(
                                  roundPrice(
                                    String(
                                      totalPrice
                                    )
                                  )
                                ).toFixed(0)}
                              </Typography>
                              <Typography variant='caption'
                                className='down-arrow'
                                onClick={() => {
                                  setHotelIds(item.SearchResult.hotel_id)
                                  setOnClickPoints(true)
                                  addClassToSelf(i)
                                  const hotelId = item.SearchResult.hotel_id;
                                  let newArr = [...hotels]

                                  if (initialHotelId !== hotelId) {
                                    if (newArr.includes(hotelId)) {
                                      const index = newArr.indexOf(hotelId);
                                      if (index > -1) {
                                        newArr.splice(index, 1);
                                      }
                                      if (newArr.length === 1) {
                                        setHotelIds(initialHotelId)
                                      }
                                    } else {
                                      newArr = []
                                      newArr.push(initialHotelId)
                                      newArr.push(hotelId)
                                    }
                                    dispatch(setHotelId(newArr));
                                  } else {
                                    setHotelIds(initialHotelId)
                                    dispatch(setHotelId([initialHotelId]));
                                  }
                                }
                                }
                                sx={{
                                  height: '15px !important',
                                  cursor: 'pointer',
                                  width: "15px !important",
                                  position: 'absolute !important',
                                  backgroundColor: 'white',
                                  bottom: '-15px !important',
                                  left: ' 50% !important',
                                  transform: 'rotate(45deg) translateX(-50%) !important',
                                  border: ' 0 !important',
                                  borderBottom: '#000000 solid 3px !important',
                                  borderRight: '#000000 solid 3px !important',
                                  zIndex: '5'
                                }}></Typography>
                            </Box>
                          </InfoWindowF>
                        ) : (
                          <MarkerF
                            position={{
                              lat: Number(item?.SearchResult?.address?.latitude),
                              lng: Number(item?.SearchResult?.address?.longitude),
                            }}
                            clickable
                            onClick={() => {
                              setHotelIds(item.SearchResult.hotel_id)
                              setOnClickPoints(true)

                              const hotelId = item.SearchResult.hotel_id;
                              let newArr = [...hotels]
                              if (initialHotelId !== hotelId) {
                                if (newArr.includes(hotelId)) {
                                  const index = newArr.indexOf(hotelId);
                                  if (index > -1) {
                                    newArr.splice(index, 1);
                                  }
                                } else {
                                  newArr = []
                                  newArr.push(initialHotelId)
                                  newArr.push(hotelId)
                                }
                                dispatch(setHotelId(newArr));
                              } else {
                                setHotelIds(initialHotelId)
                                dispatch(setHotelId([initialHotelId]));
                              }
                            }
                            }
                            icon={gmapMarkerBlack}
                          />
                        ))}
                    </Box>
                  );
                })}
            </GoogleMap>
          ) : null}
        </Box>
        {filteredHolidayPackage?.Packages?.results?.length > 0 ? (
          <CustomContainer>
            <Box display='flex' justifyContent='center' m={3} bgcolor={colors.basics.secondary}>
              <Box
                sx={{
                  border: `1px solid ${colors.grey[30]}`,
                  // backgroundColor: `colors.basics.secondary !important`,
                  borderRadius: '5px',
                  '& .MuiCard-root': {
                    backgroundColor: colors.basics.secondary,
                  },
                  '& .bottom-border': {
                    display: 'none',
                  },
                  '& .item-star': {
                    display: 'none',
                  },
                }}
                p={3}
              >
                <PackageCard
                  psw_result_id={filteredHolidayPackage?.FlightDetails?.outbound?.flights[0].psw_result_id}
                  item={
                    hotelsToShow?.length
                      ? hotelsToShow[hotelsToShow?.length - 1]
                      : filteredHolidayPackage?.Packages?.results[0]
                  }
                />
              </Box>
            </Box>
          </CustomContainer>
        ) : (<>
          <Typography variant="h1" textAlign="center" color={colors.basics.primary}>
            Unfortunately, no results were returned for the package you have searched.
          </Typography>
          <Typography variant="h1" textAlign="center" color={colors.basics.primary}>
            Please search again for alternatives.
          </Typography></>
        )}
      </>
    </Dialog >
  );
};

export default React.memo(MapDialog);
