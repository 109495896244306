import { formatTime } from "./formatTime";

export const getFlightTimesObject = (data: any) => {
  return data
    .map((item: any, index: number) => {
      const timeArr: string[] = [];
      timeArr.push(singleFlightTravelTime(item?.FlightPswFlight?.travel_time));
      if (index !== data?.length - 1) {
        const layoverTime = flightTime(
          new Date(
            `${item?.FlightPswFlight?.arrival_date} ${formatTime(
              item?.FlightPswFlight?.arrival_time
            )}`
          ),
          new Date(
            `${data[index + 1]?.FlightPswFlight?.departure_date} ${formatTime(
              data[index + 1]?.FlightPswFlight?.departure_time
            )}`
          )
        );
        timeArr.push(layoverTime);
      }
      return timeArr;
    })
    ?.flat();
};

export const getFlightTimesObjectHolidayPackage = (data: any) => {
  return data
    ?.map((item: any, index: number) => {
      const timeArr: string[] = [];
      timeArr.push(singleFlightTravelTime(item?.travel_time));
      if (index !== data?.length - 1) {
        const layoverTime = flightTime(
          new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
          new Date(
            `${data[index + 1].departure_date} ${formatTime(
              data[index + 1].departure_time
            )}`
          )
        );
        timeArr.push(layoverTime);
      }
      return timeArr;
    })
    ?.flat();
};
export const flightTime = (fromDate: Date, toDate: Date, forDisplay?: boolean) => {
  const diffInMs = toDate.getTime() - fromDate.getTime();
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  const diffInMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

  return forDisplay ? diffInHours === 0 ? `${diffInMinutes}m` : `${diffInHours}hr ${diffInMinutes}m` : `${diffInHours} hr ${diffInMinutes} m`;
};

export const singleFlightTravelTime = (travel_time: number, forDisplay?: boolean) => {
  const totalMinutes = Number(travel_time);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return forDisplay ? hours === 0 ? `${minutes}m` : `${hours}hr ${minutes}m` : `${hours} hr ${minutes} m`;
};

export function sumTime(getFlightTimesObject: string[]) {
  let totalHours = 0;
  let totalMinutes = 0;

  for (let i = 0; i < getFlightTimesObject?.length; i++) {
    const time = getFlightTimesObject[i].split(" ");

    const hours = parseInt(time[0]);
    const minutes = parseInt(time[2]);

    totalHours += hours;
    totalMinutes += minutes;

    if (totalMinutes >= 60) {
      totalHours += Math.floor(totalMinutes / 60);
      totalMinutes = totalMinutes % 60;
    }
  }

  return totalHours + "hr " + totalMinutes + "m";
}
