import React from 'react';
import { Box, Typography } from '@mui/material';
// import { Link } from 'react-router-dom';
import BackButton from './BackButton';
import LeftDrawer from './LeftDrawer';
import { colors } from '../styles/colors';
import { LiComponent, holidayContent } from './HolidayType';
import { linkBaseUrl } from '../constants/menuItems';
const MobileHolidayTypes = () => {
  const [showResults, setShowResults] = React.useState(false);
  return (
    <>
      {showResults ? (
        <LeftDrawer />
      ) : (
        <Box
          p={"20px"}
          sx={{
            '& ul': { listStyle: 'none' },
            '& .heading': { fontSize: '20px' },
            '& a': {
              textDecoration: 'none',
            },
            '& .side_ul': {
              padding: '0 0 30px 30px',
              borderLeft: '1px solid #939292',
            },

            '& .bottom_title': {
              color: colors.basics.primary,
              pt: '15px',
            },
          }}
        >
          <Box width={"fit-content"} ml='10px' mt={3} mb={"15px"} onClick={() => setShowResults(true)}>
            <BackButton />
          </Box>
          <Box p='20px 10px'>
            <Typography
              variant='subtitle2'
              className='heading'
              fontSize='20px'
              color='#303030'
              fontWeight={500}
            >
              Choose a holiday type
            </Typography>
            <Box
              p='10px 0px'
              justifyContent=' space-between'
              sx={{
                '& ul': {
                  '& li ': { padding: '0 0 1.25rem', '& a': { fontSize: '16px', color: '#303030' } },
                },
                'ul li a': {
                  '&:hover': {
                    color: colors.basics.primary,
                  },
                },
              }}
            >
              {/* <ul className='first_ul'>
                <LiComponent name='Adult Only Holidays' href={`${linkBaseUrl}adult-only-holidays`} />
                <LiComponent name='All Inclusive Holidays' href={`${linkBaseUrl}all-inclusive-holidays`} />
                <LiComponent name='Cruise Holidays' href={`${linkBaseUrl}cruise-holidays/cruises`} />
                <LiComponent name='Family Holidays' href={`${linkBaseUrl}family-holidays`} />
                <LiComponent name='Honeymoons' href={`${linkBaseUrl}honeymoons`} />
                <LiComponent name='Luxury Holidays' href={`${linkBaseUrl}luxury-holidays`} />
                <LiComponent name='Tours' href={`${linkBaseUrl}tours`} />
                <LiComponent
                  name='Twin & Multi Centre Holidays'
                  href={`${linkBaseUrl}twin-multi-centre-holidays`}
                />
                <LiComponent name='Villa Holidays' href={`${linkBaseUrl}villa-holidays`} />

                <LiComponent name='Weddings' href={`${linkBaseUrl}weddings`} />
              </ul> */}
              {holidayContent.map((h, i) => {
                return (
                  <Box key={i} py={"20px"}>
                    <Typography fontSize={"20px !important"} mb={"10px"} variant='h3' fontWeight={400}>
                      {h.title}
                    </Typography>
                    <ul className='first_ul'>
                      {h.data.map((d, dIndex) => {
                        return (
                          <Box sx={{
                            '& li': {
                              paddingBottom: "8px !important"
                            }
                          }} className="inner-data">
                            <LiComponent key={dIndex} name={d.name} href={`${linkBaseUrl}${d.link}`} />
                          </Box>
                        )
                      })}

                    </ul>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MobileHolidayTypes;
