import { isEmpty } from "./isEmpty";

export const capitalizeFirstLetterEachWords = (mySentence: string) => {
    const words = mySentence.split(" ");
    for (let i = 0; i < words.length; i++) {
        if (isEmpty(words[i]) !== "") {
            let word = words[i];

            // Handle words with parentheses
            if (word.includes("(") && word.includes(")")) {
                let part1 = word.substring(0, word.indexOf("("));
                let part2 = word.substring(word.indexOf("(") + 1, word.indexOf(")"));
                let part3 = word.substring(word.indexOf(")") + 1);

                part1 = part1 ? part1[0].toUpperCase() + part1.substr(1).toLowerCase() : "";
                part2 = part2 ? part2[0].toUpperCase() + part2.substr(1).toLowerCase() : "";
                part3 = part3 ? part3[0].toUpperCase() + part3.substr(1).toLowerCase() : "";

                word = part1 + "(" + part2 + ")" + part3;
            } else {
                // Handle hyphenated words
                if (word.includes('-')) {
                    let parts = word.split('-');
                    for (let j = 0; j < parts.length; j++) {
                        if (parts[j]) {  // Check if part is not empty
                            parts[j] = parts[j][0]?.toUpperCase() + parts[j].substr(1).toLowerCase();
                        }
                    }
                    word = parts.join('-');
                } else {
                    word = word[0]?.toUpperCase() + word.substr(1).toLowerCase();
                }
            }

            words[i] = word;
        }
    }
    return words.join(" ");
}