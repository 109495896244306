import React from 'react';
import { Container, SxProps } from '@mui/material';

interface customContainerProps {
  children: React.ReactNode;
  style?: SxProps;
  className?: string;
}

export const CustomContainer = ({ children, style, className }: customContainerProps) => {
  return (
    <Container
      maxWidth={'lg'}
      sx={{
        '&.MuiContainer-maxWidthLg': {
          maxWidth: '1340px',
          pl: { sm: '20px', xs: '10px' },
          pr: { sm: '20px', xs: '10px' },
        },
        ...style,
      }}
      className={className}
    >
      {children}
    </Container>
  );
};
