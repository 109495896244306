import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setStepperState } from '../../../features/stepper/stepperSlice';

const BillingDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleBack = () => {
    dispatch(setStepperState(1));
    navigate('/passenger-details');
  };
  return (
    <Box width={{ lg: '100%', md: '100%', sm: '100%', xs: '100%' }}>
      <Box
        display={{ lg: 'flex', md: 'flex', sm: 'flex', xs: 'block' }}
        justifyContent={{
          lg: 'space-between',
          md: 'space-between',
          sm: 'space-between',
          xs: 'center',
        }}
        mt='70px'
      >
        <Button
          sx={{
            height: '50px',
            width: { lg: 'auto', md: 'auto', sm: 'auto', xs: '100%' },
            mb: '30px',
          }}
          variant='outlined'
          onClick={handleBack}
        >
          Back
        </Button>
        <Box
          mb={{ md: 0, xs: '20px' }}
          sx={{
            '& button': { width: { md: 'auto', xs: '100%' } },
          }}
        >
          <Button
            variant='outlined'
            sx={{ bgcolor: 'transparent' }}
            onClick={() => navigate('/profile/dashboard')}
          >
            Save Itinerary
          </Button>
        </Box>
        <Button
          sx={{
            height: '50px',
            width: { lg: 'auto', md: 'auto', sm: 'auto', xs: '100%' },
            mb: '30px',
          }}
          onClick={() => navigate('/booking-summary')}
        >
          Pay
        </Button>
      </Box>
    </Box>
  );
};

export default BillingDetails;
