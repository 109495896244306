import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentCountry: process.env.REACT_APP_PROJECT_COUNTRY,
};

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    setCurrentCountry: (state, { payload }) => {
      state.currentCountry = payload;
    },
  },
});

export const { setCurrentCountry } = countrySlice.actions;
export default countrySlice.reducer;
