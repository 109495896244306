import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../api/services';
import { API_METHOD } from '../api/services/apiMethods';

export const fetchAsyncCreateBasicFolder: any = createAsyncThunk('createBasicFolder', async (Ì) => {
  const response = await API.post(API_METHOD.Misc.CREATE_BASIC_FOLDER, [
    {
      foldcur: 'GBP',
    },
  ]);
  return response.data;
});

export const checkUserEmailIsExist: any = createAsyncThunk('checkUserEmailIsExist', async (email) => {
  const response = await API.post(API_METHOD.Misc.USER_EMAIL_CHECK, [`${email}`]);
  return response.data;
});

export const retrieveBasket: any = createAsyncThunk('retrieveBasket', async (session_id) => {
  // const body = [{ siteId: process.env.REACT_APP_SITE_ID, basketId: session_id }];
  const body = [{ siteId: "1", basketId: session_id }];
  const response = await API.post(API_METHOD.Basket.RETRIEVE_BASKET_FROM_DB, body);
  return response.data;
});
