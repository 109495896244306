import { Box, Button, Typography, IconButton } from '@mui/material';
import React, { useMemo, useState } from 'react';
import TextInput from '../../components/TextInput';
import { useNavigate, useParams } from 'react-router-dom';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';
import { CustomContainer } from '../../components/CustomContainer';
import * as yup from 'yup';
import { colors } from '../../styles/colors';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../features/loading/loadingSlice';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(7, 'Password length should be minimum 7')
    .matches(/(?=.*\d)/, '1 Number Required')
    .matches(/(?=.*[@$!%*#?&])/, '1 Special character Required'),
  repassword: yup
    .string()
    .required("Confirm password is Required")
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

export default function ResetPassword() {
  /**
   * @Hooks
   */
  const [formData, setFormData] = useState<{ password: string; repassword: string }>({
    password: '',
    repassword: '',
  });
  const [formError, setFormErrors] = useState<any>({});
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState<boolean | 'error'>(false);
  const [passwordData, setPasswordData] = useState({
    showPassword: false,
    confirmPassword: false,
  });
  const [buttonClick, setButtonClick] = useState(false)
  /**
   * @Variables
   */
  const { key, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /**
   * @Functions
   */
  const isKeyValid = useMemo(async () => {
    /**
     * @API call for validate token
     */

    const body = [2, { key, id }];

    const response = await API.post(API_METHOD.Misc.RESET_PASSWORD, body);
    return response?.data || false;
  }, [key, id]);

  const handleRegisterFormData = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    let obj = { ...formData, [name]: value }

    setFormData(obj);
    if (buttonClick) {
      validationSchema
        .validate(obj, { abortEarly: false })
        .then(async () => {
          setFormErrors({});
        })
        .catch((err) => {
          const errors: any = {};
          err.inner.forEach((e: any) => {
            errors[e.path] = e.message;
          });
          setFormErrors(errors);
          dispatch(setLoading(false));
        });
    }
  };

  const handleSubmit = async (e: any) => {
    setButtonClick(true)
    e.preventDefault();
    dispatch(setLoading(true));
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(async () => {
        setFormErrors({});
        if (await isKeyValid) {
          const body = [null, { password: formData.password, id }];
          const res = await API.post(API_METHOD.Misc.RESET_PASSWORD, body);

          if (res?.data === true) {
            setIsPasswordResetSuccess(true);
          } else if (res?.data?.error) {
            setIsPasswordResetSuccess('error');
          }
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setFormErrors(errors);
        dispatch(setLoading(false));
      });
  };

  /**
   * @Returns
   */

  if (isPasswordResetSuccess === true) {
    return (
      <Box bgcolor={colors.basics.secondary} >
        <CustomContainer style={{ marginTop: window.innerWidth > 1200 ? '71px' : '31px', marginBottom: window.innerWidth > 1200 ? '71px' : '31px' }}>
          <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
            <Box m={{ md: '51px', xs: "20px 10px" }} mr={{ xl: '0px' }} width={{ xs: '100%' }} maxWidth='700px'>
              <Typography variant='h1' fontSize={'32px'} mb={5} color={colors.basics.primary}>
                Password Reset Successful!
              </Typography>
              <Box display={'flex'} width={'100%'} justifyContent={{ sm: 'flex-start', xs: "space-between" }}>
                <Button onClick={() => navigate('/')} variant='contained' sx={{ mr: 2 }}>
                  Home
                </Button>
                <Button onClick={() => navigate('/signin')} variant='outlined'>
                  Login
                </Button>
              </Box>
            </Box>
          </Box>
        </CustomContainer >
      </Box>
    );
  }

  if (isPasswordResetSuccess === 'error') {
    return (
      <CustomContainer style={{ my: 12, mx: 5 }}>
        <Typography variant='h1' fontSize={'32px'} mb={5} color={colors.basics.primary}>
          Something Went Wrong, Please Try Again
        </Typography>

      </CustomContainer>
    );
  }

  if (!isKeyValid) {
    return (
      <Typography variant='h1' fontSize={'32px'} mb={5} color={colors.basics.primary} m={15}>
        Session Expired
      </Typography>
    );
  }
  const handleClickShowPassword = (field: any) => {
    setPasswordData((prevData: any) => ({
      ...prevData,
      [field]: !prevData[field],
    }));
  };

  return (
    <Box bgcolor={colors.basics.secondary}>
      <CustomContainer style={{ marginTop: window.innerWidth > 1200 ? '71px' : '31px', marginBottom: window.innerWidth > 1200 ? '71px' : '31px' }}>
        <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
          <Box m={{ md: '51px', xs: '20px 0' }} mr={{ xl: '0px' }} width={{ xs: '100%' }} maxWidth='700px' sx={{
            '& .MuiSvgIcon-root': {
              width: '20px',
              height: 'auto'
            },
            '.Mui-error': {
              margin: "0",
            }
          }}>
            <Typography variant='h1' fontSize={'32px'} fontWeight={700} >
              Reset Password
            </Typography>
            <Box sx={{
              '& .MuiOutlinedInput-root': {
                width: { xs: "100%", sm: "60%" }
                // windowWidth >= 600 ? "60%" : "100%"
              }
            }} my={"17px"} >
              <Box mb={3}>
                <TextInput
                  // EndIcon={showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                  EndIcon={
                    <IconButton onClick={() => handleClickShowPassword('showPassword')}>
                      {passwordData.showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                    </IconButton>
                  }
                  type={passwordData.showPassword ? 'text' : 'password'}

                  title='Password *'
                  required
                  onChange={(evt) => handleRegisterFormData(evt)}
                  // textFieldStyle={{ width: '80%' }}
                  name={`password`}
                  error={formError && formError['password'] ? true : false}
                  helperText={formError && formError['password'] ? formError['password'] : ''}
                />
                {/* <VisibilityOffOutlinedIcon /> */}
              </Box>
              <Box mb={3}>
                <TextInput
                  EndIcon={
                    <IconButton onClick={() => handleClickShowPassword('confirmPassword')}>
                      {passwordData.confirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                    </IconButton>
                  }
                  type={passwordData.confirmPassword ? 'text' : 'password'}
                  title='Confirm Password *'
                  required
                  onChange={(evt) => handleRegisterFormData(evt)}
                  // textFieldStyle={{ width: '80%' }}
                  name='repassword'
                  error={formError && formError['repassword'] ? true : false}
                  helperText={formError && formError['repassword'] ? formError['repassword'] : ''}
                />
              </Box>
              <Box mt={formError.repassword ? 4 : 3}>
                <Button variant='contained' onClick={handleSubmit}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </CustomContainer >
    </Box >

  );
}
