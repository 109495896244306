export const generatePassword = () => {
  const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const specialCharacters = '@#$%^&';

  let password = '';

  // Generate one uppercase letter
  password += uppercaseLetters.charAt(Math.floor(Math.random() * uppercaseLetters.length));

  // Generate one lowercase letter
  password += lowercaseLetters.charAt(Math.floor(Math.random() * lowercaseLetters.length));

  // Generate one special character
  password += specialCharacters.charAt(Math.floor(Math.random() * specialCharacters.length));

  // Generate remaining characters
  for (let i = 0; i < 7; i++) {
    const allCharacters = uppercaseLetters + lowercaseLetters + specialCharacters;
    password += allCharacters.charAt(Math.floor(Math.random() * allCharacters.length));
  }

  // Shuffle the password to make it more random
  password = password
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('');

  return password;
};
