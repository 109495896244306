import { isEmpty } from "../helpers/isEmpty"
import React from "react"
import Image from "./Image"
import { capitalizeFirstLetterEachWords } from "../helpers/capitalizeFirstLetterEachWords"
import dayjs from "dayjs";
import { dateSplit } from "../helpers/dateSplit";
import { RoomMealWrapper } from "../helpers/roomMealWrapper";

import { capitalizeFirstLetter } from "../helpers/capitalizeFirstLetter";
import { formatStringTime } from "../helpers/formatStringTime";
type OutboundData = {
    layover1?: {
        time: string,
        code: string | undefined,
        layover_airport_name: string | undefined,
        layover_airport_city: string | undefined,
    }
};

type Room = {
    room_name: string;
    passangers_info: string;
    meal_name: string;
};

export const BookingConfirmationEmail = (data: Record<string, any>, email: string) => {
    const { flight, hotel, transfer, deposit,destination_name } = data
    const outbound = flight?.outbound || {}
    const inbound = flight?.inbound
    const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL

    return (
        <div id="booking-table" style={{ width: "100%" }}>
            <html>
                <body>
                    <table style={{ fontFamily: "Raleway" }} width={"100%"}>
                        <tbody>
                            <tr>
                                <td style={{ padding: "0 20px 0" }}>
                                    <table width={"100%"} style={{ margin: "30px auto 0px auto" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ paddingTop: "30px" }}>
                                                    <table style={{ color: "black", maxWidth: "556px", width: '100%', margin: "auto", border: "1px solid #999999", background: "#f9f9f9", borderRadius: "4px", boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)", }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ verticalAlign: "top", padding: "23px 5px 20px 15px", }}>
                                                                    <Image alt="a" style={{ width: "32px", height: "32px" }}
                                                                        src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/check.png`}
                                                                    />
                                                                </td>
                                                                <td style={{ padding: "22px 15px 20px 0px", }}>
                                                                    <table style={{ lineHeight: "19px", fontSize: "16px" }}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ paddingBottom: "8px" }}>
                                                                                    <h4 style={{ fontWeight: 600, margin: 0 }}>Thank you for choosing to book with American Sky</h4>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ paddingBottom: "5px" }}>
                                                                                    <span>our Booking reference is<span style={{ paddingLeft: "5px", margin: 0, fontWeight: 600 }}>TSR{data.folder_no}</span> </span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ paddingBottom: "7px" }}>
                                                                                    <span>A payment receipt will be emailed to <b>{email}</b> shortly.</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <span>All elements of your holiday have been requested, and we are working on confirming them with our suppliers. We aim to have your invoice issued within 48 hours.</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <span>If you already have an online account, booking details can found in your dashboard. Alternatively, if you have elected to have an online account, we have sent an email containing a temporary password to your account. This link is valid for 24 hours.</span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: "30px 20px" }}>
                                    <table style={{ color: "black", maxWidth: "556px", width: '100%', margin: "auto", border: "1px solid #999999", background: "#f9f9f9", borderRadius: "4px", boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)", }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: '20px' }}>
                                                    <table width={'100%'}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ padding: "0 0 10px" }}>
                                                                    <span style={{
                                                                        fontSize: "16px",
                                                                        color: "black",
                                                                        fontWeight: 500,
                                                                        lineHeight: "21px"
                                                                    }}>{`${destination_name} Holiday Summary`}</span>
                                                                </td>
                                                            </tr>
                                                            {/* Flight Section */}
                                                            <tr>
                                                                <td>
                                                                    <div style={{ backgroundColor: "#f2f2f2", padding: "10px 20px", marginBottom: '20px' }}>
                                                                        <table style={{ fontSize: "16px", width: "100%", color: "black", lineHeight: "1.2", fontWeight: 500 }}>
                                                                            <tbody>
                                                                                {/* departure section */}
                                                                                <tr>
                                                                                    <td style={{
                                                                                        verticalAlign: "top",
                                                                                    }}>
                                                                                        <Image alt="a" style={{ objectFit: 'contain', width: 19, height: 19 }}
                                                                                            src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/rightSidePlane.png`}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ marginBottom: "10px" }}>
                                                                                    <div style={{ marginBottom: 5, color: "#f2f2f2 !important" }}><span>Outbound Flight</span></div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{
                                                                                        verticalAlign: "top",
                                                                                    }}>
                                                                                        {/* <Image alt="a" style={{ objectFit: 'contain', width: 19, height: 19 }}
                                                                                            src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/rightSidePlane.png`}
                                                                                        /> */}
                                                                                    </td>
                                                                                    <td style={{ marginBottom: "10px" }}>
                                                                                        <div style={{ marginBottom: 5 }}>
                                                                                            <div style={{ marginBottom: 5 }}><span>{outbound?.arrival_date} - {outbound?.airline_name} - {outbound?.airline_code}</span></div>
                                                                                            {/* <div><span>{outbound?.airline_name} - {outbound?.airline_code}</span></div> */}
                                                                                        </div>
                                                                                        <div style={{ marginBottom: 5 }}>
                                                                                            <span>{"(" + outbound?.arrival_time + ")"} - </span>
                                                                                            <span>{outbound?.arrival_airport_name}</span>
                                                                                            <span style={{ display: 'inline-block', verticalAlign: "text-top" }}>
                                                                                                <Image style={{ objectFit: 'contain', width: 19, height: 19 }} src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/doubleArrow.png`} alt="double-right" />
                                                                                            </span>

                                                                                        </div>
                                                                                        <div style={{ marginBottom: 5 }}>
                                                                                            <span>{"(" + outbound?.departure_time + ")"} - </span>
                                                                                            <span>{outbound?.departure_airport_name}</span>
                                                                                        </div>
                                                                                        <div style={{ marginBottom: 5 }}>
                                                                                            {isEmpty(outbound?.outbound_time) !== "" && <span>{formatStringTime(outbound?.outbound_time) + " - "}  </span>}
                                                                                            <span>{"(" + outbound?.stops + ")"}</span>
                                                                                        </div>
                                                                                        {outbound?.layover_stops?.length > 0 && outbound?.layover_stops.map((l: OutboundData, i: number) => {
                                                                                            return (
                                                                                                isEmpty(l.layover1?.time) !== "" ? <div key={i} style={{ marginBottom: 5 }}>
                                                                                                    <span>Layover {i + 1} </span>
                                                                                                    <span>{"(" + l.layover1?.time.replace(/0\s*hr\s*/g, '')                    // Remove "0 hr"
                                                                                                        .replace(/\s*m/g, 'm')                         // Remove space before "m"
                                                                                                        .replace(/(\d+)\s*hr/g, '$1hr')                // Ensure "1 hr" becomes "1hr"
                                                                                                        .trim() + ")"}</span>
                                                                                                    {" - " + l?.layover1?.layover_airport_city}  {(isEmpty(l?.layover1?.code) !== "") && ("(" + l?.layover1?.code + ")")}
                                                                                                </div> : <span key={i}></span>
                                                                                            )
                                                                                        })}
                                                                                    </td>
                                                                                </tr>
                                                                                {/* arrival section */}
                                                                                <tr>
                                                                                    <td style={{
                                                                                        verticalAlign: "top",
                                                                                        // paddingTop: "8px"
                                                                                    }}>
                                                                                        <Image alt="a" style={{ objectFit: 'contain', width: 19, height: 19 }}
                                                                                            src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/leftSidePlane.png`}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ marginBottom: "10px" }}>
                                                                                        <div style={{ marginBottom: 5, color: "#f2f2f2 !important" }}><span>Inbound Flight</span></div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{
                                                                                        verticalAlign: "top",
                                                                                        // paddingTop: "8px"
                                                                                    }}>
                                                                                        {/* <Image alt="a" style={{ objectFit: 'contain', width: 19, height: 19 }}
                                                                                            src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/leftSidePlane.png`}
                                                                                        /> */}
                                                                                    </td>
                                                                                    <td style={{ paddingTop: "8px" }}>
                                                                                        <div style={{ marginBottom: 5 }}>
                                                                                            <div style={{ marginBottom: 5 }}><span>{inbound?.departure_date} - {inbound?.airline_name} - {inbound?.airline_code}</span></div>
                                                                                            {/* <div><span>{inbound?.airline_name} - {inbound?.airline_code}</span></div> */}
                                                                                        </div>
                                                                                        <div style={{ marginBottom: 5 }}>
                                                                                            <span>{"(" + inbound?.departure_time + ")"} - </span>
                                                                                            <span>{inbound?.departure_airport_name} </span>
                                                                                            <span style={{ display: 'inline-block', verticalAlign: "text-top" }}>
                                                                                                {/* <img src={DoubleArrow} style={{ objectFit: 'contain' }} width={19} height={19} alt="a" /> */}
                                                                                                <Image style={{ objectFit: 'contain', width: 19, height: 19 }} src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/doubleArrow.png`} alt="double-right" />
                                                                                            </span>
                                                                                            <span>

                                                                                            </span>
                                                                                        </div>
                                                                                        <div style={{ marginBottom: 5 }}>
                                                                                            <span>{"(" + inbound?.arrival_time + ")"} - </span>
                                                                                            <span>{inbound?.arrival_airport_name}</span>
                                                                                        </div>
                                                                                        <div style={{ marginBottom: 5 }}>
                                                                                            {isEmpty(inbound?.inboundTime) !== "" && <span>{formatStringTime(inbound?.inboundTime)} - </span>}
                                                                                            <span>{"(" + inbound?.stops + ")"}</span>
                                                                                        </div>
                                                                                        {inbound?.layover_stops.length > 0 && inbound?.layover_stops.map((l: OutboundData, i: number) => {
                                                                                            return (
                                                                                                isEmpty(l.layover1?.time) !== "" ? <div key={i} style={{ marginBottom: 5 }}>
                                                                                                    <span>Layover {i + 1} - </span>
                                                                                                    {isEmpty(l.layover1?.time) !== "" && <span>{"(" + l.layover1?.time.replace(/0\s*hr\s*/g, '')                    // Remove "0 hr"
                                                                                                        .replace(/\s*m/g, 'm')                         // Remove space before "m"
                                                                                                        .replace(/(\d+)\s*hr/g, '$1hr')                // Ensure "1 hr" becomes "1hr"
                                                                                                        .trim()                                        // Remove any leading/trailing spaces
                                                                                                        + ")"}</span>}
                                                                                                    {" - " + l?.layover1?.layover_airport_city}  {(isEmpty(l?.layover1?.code) !== "") && ("(" + l?.layover1?.code + ")")}
                                                                                                </div> : <div key={i}></div>
                                                                                            )
                                                                                        })}
                                                                                    </td>
                                                                                </tr>
                                                                                {/* bag section */}
                                                                                <tr>
                                                                                    <td style={{ paddingRight: "8px" }}>
                                                                                        <Image alt="a" style={{ objectFit: 'contain', width: 19, height: 19 }}
                                                                                            src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/bag.png`}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <span>{flight.baggage.includes("kg") ? flight.baggage : flight.baggage.replace("K", "kg")}</span>

                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {/* Room Section */}
                                                            <tr>
                                                                <td>
                                                                    <div style={{ backgroundColor: "#f2f2f2", padding: "10px 20px", marginBottom: '20px' }}>
                                                                        <table style={{ fontSize: "16px", width: "100%", color: "black", lineHeight: "1.2", fontWeight: 500 }}>
                                                                            <tbody>
                                                                                <tr>
                                                                                <td style={{
                                                                                        verticalAlign: "top",
                                                                                    }}>
                                                                                        <Image alt="a" style={{ objectFit: 'contain', width: 16, height: 16 }}
                                                                                            src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/building.png`}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ marginBottom: "10px" }}>
                                                                                        <div style={{ marginBottom: 5, color: "#f2f2f2 !important" }}><span>Hotel Info</span></div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ paddingRight: "8px", paddingBottom: "8px" }}>
                                                                                        {/* <Image alt="a" style={{ objectFit: 'contain', width: 16, height: 16 }}
                                                                                            src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/building.png`}
                                                                                        /> */}
                                                                                    </td>
                                                                                    {/* <td style={{ paddingRight: "8px", paddingBottom: "8px" }}>
                                                                                        <Image alt="a" style={{ objectFit: 'contain', width: 16, height: 16 }}
                                                                                            src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/building.png`}
                                                                                        />
                                                                                    </td> */}
                                                                                    <td style={{ paddingBottom: "8px" }}>
                                                                                        <span >{hotel.hotel_description}</span>
                                                                                    </td>

                                                                                </tr>
                                                                                <tr>

                                                                                    <td style={{
                                                                                        verticalAlign: "top",
                                                                                        paddingRight: "8px"
                                                                                    }}>
                                                                                        <Image alt="a" style={{ objectFit: 'contain', width: 16, height: 16 }}
                                                                                            src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/calendar.png`}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <div style={{ marginBottom: "5px" }}>
                                                                                        <span>Check In: {hotel.check_in} (1500 hrs)</span>
                                                                                        </div>
                                                                                        <div style={{ marginBottom: "5px" }}>
                                                                                        <span>Check Out: {hotel.check_out} (1000 hrs)</span>
                                                                                        </div>
                                                                                    </td>

                                                                                </tr>

                                                                                {hotel.room.length > 0 && hotel.room.map((h: Room, i: number) => {
                                                                                    return (
                                                                                        <React.Fragment key={i}>
                                                                                            <tr>
                                                                                                <td style={{ paddingRight: "8px", verticalAlign: "top", paddingBottom: "5px" }}>
                                                                                                    <Image style={{ objectFit: 'contain', width: 16, height: 16 }} alt="a"
                                                                                                        src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/bed.png`}
                                                                                                    />
                                                                                                </td>

                                                                                                <td style={{ paddingBottom: "5px" }}>
                                                                                                    <span>Room {i + 1} - {capitalizeFirstLetter(h.room_name)}</span>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td style={{ paddingRight: "8px", verticalAlign: "top", paddingBottom: "5px" }}>
                                                                                                    <Image style={{ objectFit: 'contain', width: 16, height: 16 }} alt="a"
                                                                                                        src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/person.png`}
                                                                                                    />
                                                                                                </td>
                                                                                                <td style={{ paddingBottom: "5px" }}>
                                                                                                    <span>{h.passangers_info}</span>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td style={{ paddingRight: "8px", verticalAlign: "top", paddingBottom: "5px" }}>
                                                                                                    <Image style={{ objectFit: 'contain', width: 16, height: 16 }} alt="a"
                                                                                                        src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/spoon.png`}
                                                                                                    />
                                                                                                </td>
                                                                                                <td style={{ paddingBottom: "5px" }}>
                                                                                                    <span dangerouslySetInnerHTML={{ __html: RoomMealWrapper(capitalizeFirstLetter(h.meal_name)) }}></span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {/* Transfer  Section */}
                                                            {isEmpty(transfer.airport_to_hotel_vehicle_name) !== "" && isEmpty(transfer.hotel_to_airport_vehicle_name) !== "" && <tr>
                                                                <td>
                                                                    <div style={{ backgroundColor: "#f2f2f2", padding: "10px 20px", marginBottom: '20px' }}>
                                                                        <table style={{ fontSize: "16px", width: "100%", color: "black", lineHeight: "1.2", fontWeight: 500 }}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td style={{ verticalAlign: "top", paddingRight: "8px" }}>
                                                                                        <Image alt="a" style={{ objectFit: 'contain', width: 16, height: 16 }}
                                                                                            src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/car.png`}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{
                                                                                        marginRight: "auto",
                                                                                        width: "100%"
                                                                                    }}>
                                                                                        <div style={{ marginBottom: "10px" }}>
                                                                                            <span>Airport to Hotel - {capitalizeFirstLetterEachWords(transfer.airport_to_hotel_vehicle_name)} </span>
                                                                                        </div>
                                                                                        <div style={{ marginBottom: 5 }}>
                                                                                            <span>Hotel to Airport - {capitalizeFirstLetterEachWords(transfer.hotel_to_airport_vehicle_name)}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            }
                                                            {/* Full Payment  Section */}
                                                            <tr>
                                                                <td>
                                                                    <div style={{ backgroundColor: "#f2f2f2", padding: "10px 20px" }}>
                                                                        <table style={{ width: "100%", color: "black", lineHeight: "1.2", fontWeight: 500 }}>
                                                                            <tbody>
                                                                                {isEmpty(deposit?.due_date) !== "" && <tr>
                                                                                    <td style={{
                                                                                        verticalAlign: "top",
                                                                                        paddingRight: "8px",
                                                                                        alignItems: "center"
                                                                                    }}>
                                                                                        <Image alt="a" style={{ objectFit: 'contain', width: 16, height: 16 }}
                                                                                            src={`${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/calendar.png`}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{
                                                                                        marginRight: "auto",
                                                                                        width: "100%",
                                                                                        alignItems: "center"
                                                                                    }}>
                                                                                        <div style={{ marginBottom: 5, fontSize: "16px" }}>
                                                                                            <span>Balance Due Date: </span>
                                                                                            <span> {dayjs(dateSplit(deposit?.due_date, "/")).format("DD MMM, YYYY")}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>}

                                                                                <tr>
                                                                                    <td style={{ paddingRight: "8px", paddingTop: "4px", verticalAlign: "top" }}>
                                                                                        <Image style={{ objectFit: 'contain', width: 16, height: 16 }} alt="a"
                                                                                            src={process.env.REACT_APP_PROJECT_COUNTRY === "ie" ? `${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/euro.png`
                                                                                                : process.env.REACT_APP_PROJECT_COUNTRY === "us" ? `${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/dollar.png`
                                                                                                    : `${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/pound.png`}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{
                                                                                        marginRight: "auto",
                                                                                        width: "100%",
                                                                                    }}>
                                                                                        <div style={{ marginBottom: 5, fontSize: "18px" }}>
                                                                                            <span>Paid: </span>
                                                                                            <span> {signForPricing + deposit.deposit_payment + ` (${deposit.isDeposit === "true" ? "Deposit" : "Full Payment"})`}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td style={{ paddingRight: "8px", verticalAlign: "top", paddingTop: "6px", }}>
                                                                                        <Image style={{ objectFit: 'contain', width: 16, height: 16 }} alt="a"
                                                                                            src={process.env.REACT_APP_PROJECT_COUNTRY === "ie" ? `${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/euro.png`
                                                                                                : process.env.REACT_APP_PROJECT_COUNTRY === "us" ? `${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/dollar.png`
                                                                                                    : `${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/email/pound.png`}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{
                                                                                        marginRight: "auto",
                                                                                        width: "100%",
                                                                                        paddingTop: "4px",
                                                                                    }}>
                                                                                        <div style={{ marginBottom: 5, fontSize: "18px" }}>
                                                                                            <span>Total Price: </span>
                                                                                            <span> {signForPricing + deposit.total_price}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </body>
            </html>
        </div >
    )
}