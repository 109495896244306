import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    findButtonClick: true
};

const findClickReducer = createSlice({
    name: 'findClickSlice',
    initialState,
    reducers: {
        setFindButtonClick: (state, { payload }) => {
            state.findButtonClick = payload;
        },
    },
});

export const { setFindButtonClick } = findClickReducer.actions;
export default findClickReducer.reducer;