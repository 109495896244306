import { Box, Button, Dialog, Divider, Grid, Rating, Typography } from "@mui/material";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Slider, { Settings } from "react-slick";
import platinumLogo from "../../assets/images/platinum-logo.png";
import { ReactComponent as DoubleArrowIcon } from '../../assets/icons/double-arrow-icon.svg';
import {
  fetchAsyncChangeFlight,
  setFlightOptions,
} from "../../features/holidayPackage/changeFlight";
import {
  fetchAsyncHotelDetails,
  setHotelDetailsState,
} from "../../features/holidayPackage/hotelDetails";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StarIcon from "@mui/icons-material/Star";
import { API } from "../../api/services";
import { API_METHOD } from "../../api/services/apiMethods";
import { countriesWithCountryCode } from "../../constants/countries";
import { CustomContainer } from "../../components/CustomContainer";
import { HotelDetails } from "./Components/HotelDetails";
import { ItemAccordion } from "../../components/Accordion";
import { ReactComponent as LeftChervon } from "../../assets/icons/LeftChervon.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/LocationIcon.svg";
import { PricingCard } from "../../components/PricingCard";
import { ReactComponent as RightChervon } from "../../assets/icons/RightChervon.svg";
import { RoomAvailable } from "./Components/RoomAvailable";
import { StepperCard } from "../../components/StepperComponent";
import { colors } from "../../styles/colors";
import dayjs from "dayjs";
import {
  fetchAsyncHolidayPackageDetails,
} from "../../features/holidayPackage/packageDetail";
import { formatTime } from "../../helpers/formatTime";
import { retrieveBasket } from "../../features/actions";
import { roundPrice } from "../../helpers/RoundPrice";
import { setDialogState } from "../../features/dialog/dialogSlice";
import { setFlightRoomState } from "../../features/flightRoom/flightRoomSlice";
import { setHolidayDetailsState } from "../../features/holidaySummary/holidaySummarySlice";
import { setHotelId } from "../../features/map/mapSlice";
import { setLoading } from "../../features/loading/loadingSlice";
import { setStepperState } from "../../features/stepper/stepperSlice";
import { useAppSelector } from "../../hooks/useAppReducer";
import { addCommasToNumber } from "../../helpers/commaSeparatedPrice";
import { isEmpty } from "../../helpers/isEmpty";
import { SliderComponent } from "../../components/SliderComponent";
import {
  flightTime,
  getFlightTimesObject,
  sumTime,
} from "../../helpers/TotalFlightTime";
import { fetchAsyncHolidayPackages, setFilteredHolidayPackage, setHolidayPackage } from "../../features/holidayPackage/holidayPackageSlice";
import CustomizeHolidaySummary from "../../components/CustomizeHolidaySummary";
import OfferDetails from "../../components/OfferDetails";
import { StaticAccordion } from "./Components/StaticAccordion";
import { DestinationList } from "../../constants/Destinations";
import { directFlights, directFlightsIE } from "../../constants/directFlights";
import { IE_DEPARTURE, UK_DEPARTURE, US_DEPARTURE } from "../../constants/departure";
import { setFilterState } from "../../features/filter/filterSlice";
import { capitalizeFirstLetterEachWords } from "../../helpers/capitalizeFirstLetterEachWords";
import Image from "../../components/Image";
import { clearSessionValues, getSessionStorage, removeSessionStorage, setSessionStorage } from "../../helpers/storage";
import { removeSessionElements } from "../../common/removeSessionElements";
import { getApi } from "../../utils/api";
import { getSiteID } from "../../helpers/getSiteId";
import { DateObject } from "react-multi-date-picker";
import useWindowWidth from "../../hooks/useWindowWidth";
import { sortRoom } from "../../helpers/sortRoom";
import { setLottieLoading } from "../../features/lottieLoader/lottieLoader";
import { dateSplit } from "../../helpers/dateSplit";
import { RoomMealWrapper } from "../../helpers/roomMealWrapper";
import { ApplicationStateType } from "../../features/store";
import { setSliderContent } from "../../features/sliderApi/sliderApi";
import Swal from "sweetalert2";
import ScrollToTop from "../../helpers/ScrollToTop";
import { setHolidayDetials } from "../../features/holidayDetailsState";
import { branchCode } from "../../constants/branchCode";

// const HotelTransfer = React.lazy(() => import("./Components/HotelTransfer"));
const FlightDetails = React.lazy(() => import("./Components/FlightDetails"));
var store = require('store');

interface CABIN_CLASS_ENUM_TYPE {
  Any: string;
  Economy: string;
  Premium: string;
  'Business Class': string;
  'First Class': string;
}

const CABIN_CLASS_ENUM: CABIN_CLASS_ENUM_TYPE = {
  Any: '',
  Economy: 'M',
  Premium: 'W',
  'Business Class': 'C',
  'First Class': 'F',
};

type CabinClass = keyof typeof CABIN_CLASS_ENUM;

const HolidaySummary = () => {
  //! States
  const stepperState = useSelector(
    (state: { stepper: { stepper: number } }) => state.stepper.stepper
  );
  const { flightRoom } = useAppSelector((state: ApplicationStateType) => state.flightRoom);
  const { hotelDetails } = useAppSelector((state) => state.hotelDetails);
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  const { lottieLoading } = useAppSelector((state) => state.lottieLoader)
  const { sliderContent } = useAppSelector((state) => state.sliderContent)
  const {
    flightRoom: {
      searchCriteria: { guest, adults, childrens },
    },
  } = useAppSelector((state) => state.flightRoom);
  const { status } = useAppSelector(
    (state) => state.changeFlight
  );
  const dialogState = useAppSelector((state) => state.dialog.dialogState);
  const hotel_id = useAppSelector(
    (state) => state.flightRoom?.flightRoom?.hotel_id || ""
  );
  const { filters } = useAppSelector((state: any) => state.filters);

  const [isDirect, setIsDirect] = useState(true)
  const { holidayPackage } = useAppSelector(
    (state) => state.holidayPackage
  );
  let [loadOnce, setLoadOnce] = useState(true)
  const [roomPrice, setRoomPrice] = useState([]);
  const [onChangeMultiRoom, setOnChangeMultiRoom] = useState(
    flightRoom?.multiRooms || []
  );
  const [isRetrieveBasketApiCall, setIsRetrieveBasketApiCall] = useState(false)
  const [offerDetails, setOfferDetails] = useState([])
  const [basketId, setBasketId] = useState(getSessionStorage("basketId"));
  const [isCustomizeButtonClick, setIsCustomizeButtonClick] = useState(false)
  const [hide, setHide] = useState(false);
  const [expanded, setExpanded] = useState<number | null>(1);
  const [currentTab, setCurrentTab] = useState<string | any>('Outbound');
  const [apiCallAccordianData, setApicallAccordianData] = useState(true)
  const [activeIndex, setActiveIndex] = useState<any>(null);
  const [showButtons, setShowButtons] = useState<any>(false);
  const [disableButton, setDisableButton] = useState<any>(false);
  const [roomDisableButton, setRoomDisableButton] = useState(false);
  const [isFlightsChanged, setIsFlightChanged] = useState(false)
  const [saveRoom, setSaveRooms] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(true)
  const [totalOverviewParagraph, setTotalOverviewParagraph] = useState(0)
  const [isVisibleOverviewData, setIsVisibleOverviewData] = useState(false)
  const [scrollToFirstPara, setScrollToFirstPara] = useState(0)
  const [disabledFlightTabsInbound, setDisabledFlightTabsInbound] = useState(true)
  const [isTimeExpired, setIsTimeExpired] = useState(false);
  const [timeLeft, setTimeLeft] = useState(15 * 60);
  const [uatRedirect, setUatRedirect] = useState(false)
  const [apiCallPageLaod,setApiCallPageLoad] = useState(false)
  const getCustomizeHeight = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const { session_id }: any = useParams();

  const hotelImages = hotelDetails?.VendorImages?.filter(
    (item: any) => item?.VendorImage?.image_width === "1800.00"
  );
  const components = ["flightDetails", "roomAvailable"];
  const totalGuest =
    flightRoom.searchCriteria.adults + flightRoom.searchCriteria.childrens;
  const isPlataniumHotel = hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel
  ? Number(hotelDetails.HotelInfo.HotelInfo.platinumHotel) !== 0
  : false;
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL

  const hotelInfo =
    isEmpty(hotelDetails) !== ""
      ? hotelDetails?.HotelInfo?.HotelInfo
      : undefined;
  const rating =
    isEmpty(hotelInfo) !== ""
      ? hotelInfo.classification === "2"
        ? Number(hotelInfo.stars) + 0.5
        : Number(hotelInfo.stars)
      : 0;

  const isMorePackageClick = getSessionStorage("more-details-click")

  const steps = [
    "Flight Details",
    "Room and Board Details",
  ];

  const stepsMobile = [
    "Flight",
    "Room and board",
  ];

  const SampleNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <RightChervon />
      </div>
    );
  }

  const SamplePrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <LeftChervon />
      </div>
    );
  }

  const HorizontalLinearStepper = () => {
    return (
      <Box
        sx={{
          width: "100%",
          "& .MuiStepLabel-alternativeLabel": {
            fontWeight: "500",
          },
          "& .MuiStepLabel-label.Mui-active": {
            fontWeight: "600",
          },
        }}
      >
        <Stepper activeStep={activeIndex} alternativeLabel>
          {steps.map((label) => (
                <Step key={label}  sx={{
                  '& .MuiStepLabel-label.Mui-active, .Mui-completed, .Mui-active': {
                    fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary
                  }
                }}>
                  <StepLabel key={label}>{label}</StepLabel>
                </Step>
          ))}
        </Stepper>
      </Box>
    );
  }

  const HorizontalLinearStepperMobile = () => {
    return (
      <Box
        sx={{
          width: "100%",
          "& .MuiStepLabel-alternativeLabel": {
            fontWeight: "500",
          },
          "& .MuiStepLabel-label.Mui-active": {
            fontWeight: "600",
          },
          '& .MuiStep-root': {
            pl: '0',
            pr: "0",
          }
        }}
      >
        <Stepper activeStep={activeIndex} alternativeLabel>
          {stepsMobile.map((label) => (
             <Step sx={{
              '& .MuiStepLabel-label.Mui-active, .Mui-completed, .Mui-active': {
                fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary
              }
            }} key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }

  const settings: Settings = {
    nextArrow: <SampleNextArrow />,
    fade: true,
    prevArrow: <SamplePrevArrow />,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",
    lazyLoad: "progressive",
  };

  const nights = useMemo(() => {
    return flightRoom.multiRooms[0]?.days_spent || ""
  }, [flightRoom.multiRooms]);

  const hotelName: any = useMemo(() => {
    return holiday?.hotel_name || ""
  }, [holiday?.hotel_name]);

  const checkOutDate = useMemo(() => {
    const date = holiday?.packageDate?.checkout;
    if (date) {
      return dayjs(date).format('DD MMM, YYYY');
    } else return false;
  }, [holiday?.packageDate?.checkout]);

  const roomData = useMemo(() => {
    const res = holidayPackage?.Packages?.results?.filter(
      ({ SearchResult }: any) => SearchResult.hotel_id === flightRoom.hotel_id
    );

    if (res) {
      const payload = dispatch(
        setFlightRoomState({ ...flightRoom, roomData: res })
      ).payload;
      return payload.roomData;
    } else {
      return flightRoom?.roomData;
    }
  }, [flightRoom?.hotel_id, holidayPackage]);

  const minPackage = useMemo(() => {
    return holidayPackage?.Packages?.results?.map((data: any, index: number) => {
      const getRooms = sortRoom(data.SearchResult, filters)
      let price: any = 0
      if (getRooms.length > 0) {
        getRooms.map((f: any) => {
          price = price + Number(f.total_package)
          return <></>
        })
      }

      const totalPrice = (price) / totalGuest

      return Number(
        roundPrice(
          String(
            totalPrice
          )
        )
      );
    });
  }, [holidayPackage?.Packages?.results, totalGuest]);

  const flightPriceDiff = useMemo(() => {
    let price: any = 0
    const flightPrice = getSessionStorage("stepper") === "0" ? "0" : flightRoom.diffPrice || "0"

    if (getSessionStorage("nextAndSelectedFlights") === "false") {
      price = Math.ceil(flightPrice)
    } else {
      if (flightPrice?.includes("-")) {
        price = Math.floor(flightPrice);
      } else {
        price = Math.ceil(flightPrice)
      }
    }
    return price
  }, [flightRoom.diffPrice, getSessionStorage("nextAndSelectedFlights")])

  const packagePricePerPerson: any = useMemo(() => {
    let price = 0;

    if (flightRoom.multiRooms.length > 0) {
      flightRoom.multiRooms.map((f: any, i: number) => {
        price = price + Number(f?.total_package);
        return <></>
      });
    }

    

    let newPrice = (price) / totalGuest;

    return roundPrice(String(Number(newPrice + flightPriceDiff)));
  }, [flightRoom.multiRooms, holiday, totalGuest, flightPriceDiff]);

  const queryData = useMemo(() => {
    const search = window.location.search
    const urlSearchParams = new URLSearchParams(search);
    const queryData: any = {};
    urlSearchParams.forEach((value, key) => {
      queryData[key] = value;
    });
    return queryData
  }, [])

  const renderCarasoulComponent = useMemo(() => {
    return <SliderComponent data={sliderContent !== null ? sliderContent : []}
    />
  }, [sliderContent])

  const getHolidayPackageRetrieveData = useCallback(async (flightRoom: any) => {
    dispatch(setLoading(true))

    let isDirectFlight: boolean = false

    if (process.env.REACT_APP_PROJECT_COUNTRY === "ie") {
      directFlightsIE.map((d: any) => {
        if (d.dep_code === holidayPackage?.SearchCriteria?.[0]?.DestinationFrom && d.des_code === isEmpty(holidayPackage?.SearchCriteria?.[0]?.Destination.toUpperCase())) {
          isDirectFlight = true
          return d
        } else {
          return <></>
        }
      })
    } else if (process.env.REACT_APP_PROJECT_COUNTRY === "uk") {
      directFlights.map((d: any) => {
        if (d.dep_code === holidayPackage?.SearchCriteria?.[0]?.DestinationFrom && d.des_code === isEmpty(holidayPackage?.SearchCriteria?.[0]?.Destination.toUpperCase())) {
          isDirectFlight = true
          return d
        } else {
          return <></>
        }
      })
    }

    let holidayPackageJson: any = [
      {
        "DestinationFrom": holidayPackage.SearchCriteria[0].DestinationFrom,
        "Destination": holidayPackage.SearchCriteria[0].Destination,
        "departure_date": holidayPackage.SearchCriteria[0].departure_date,
        "rooms": holidayPackage.SearchCriteria[0].rooms,
        "nights": holidayPackage.SearchCriteria[0].nights,
        "cabin_class": holidayPackage.SearchCriteria[0].cabin_class,
        "adults": holidayPackage?.SearchCriteria[0]?.adults,
        "children": holidayPackage.SearchCriteria[0].children,
        "infants": holidayPackage.SearchCriteria[0].infants,
        "siteId": holidayPackage.SearchCriteria[0].siteId,
        "cheapestFareCat": holidayPackage.SearchCriteria[0]?.cheapestFareCat,
        'direct_flight_only': isDirectFlight ? 1 : 0,
        basketId: "0",
        packageSearchId: flightRoom.packageSearchId,
        only_it_fares: true,
        only_pu_fares: false,
        hotel_id: Number(flightRoom?.hotel_id),
        // hotel_name: flightRoom.roomData[0]?.SearchResult?.hotel_name
      },
      {
        ids: {
          "flightSearchCriteriaId": Number(holidayPackage.SearchCriteria[1].ids.flightSearchCriteriaId),
          "hotelSearchCriteriaIds": Number(holidayPackage.SearchCriteria[1].ids.hotelSearchCriteriaIds),
          "selectedFlight": Number(flightRoom?.psw_result_id),
          "hotelSearchCriteriaId_nextDay": Number(holidayPackage?.SearchCriteria[1]?.ids.hotelSearchCriteriaId_nextDay),
        },
        filter: {
          // "selected_flight_id": Number(flightRoom?.psw_result_id),
          "selected_flight_id": Number(flightRoom.selectedFlights.outbound[0].FlightPswFlight.psw_result_id),
          "selected_hotel_id": Number(flightRoom?.hotel_id)
        }
      }
    ]

    if (flightRoom?.searchCriteria?.childrens > 0) {
      holidayPackageJson[0].child_age = flightRoom?.searchCriteria?.child_age
    }
    if (flightRoom?.searchCriteria?.infants > 0) {
      holidayPackageJson[0].infant_ages = flightRoom?.searchCriteria?.infant_age
    }

    const { payload } = await dispatch(fetchAsyncHolidayPackages(holidayPackageJson));
    return payload
  }, [holidayPackage, flightRoom])

  const retrieveDB = useCallback(async () => {
    let res = await dispatch(retrieveBasket(basketId));
    res = res.payload;

    let data: any = {}
    if (res?.data?.length > 0) {
      data = res.data[0]
      const flightData: any = JSON.parse(
        Object.values(data?.basket?.cart?.flights)[0] as string
      );
      removeSessionElements()
      dispatch(setLottieLoading(true))
      setSessionStorage("stepper", "1")
      setSessionStorage("OutboundFlights", JSON.stringify(flightData.selectedFlights.outbound))
      setSessionStorage("InboundFlights", JSON.stringify(flightData.selectedFlights.inbound))
      const resHoliday = await fetchHolidayDetail(flightData);
      dispatch(setHolidayDetials(resHoliday))
      if (resHoliday?.length > 0) {
        flightData.holidayDetailLogId = resHoliday[0].holidayDetailLogId || 0
      }
      setIsRetrieveBasketApiCall(true)
      await fetchHotelDetails(flightData.hotel_id);
      await getChangeFlight(flightData, true);
      // await getOffers(flightData.hotel_id)
      const abc = await retrieveholidayBasketRetrieve(flightData, false)
      const holidayData: any = JSON.parse(data.basket.cart.holiday);
      dispatch(setFlightRoomState(flightData));
      setOnChangeMultiRoom(flightRoom.multiRooms)
      dispatch(setHolidayPackage(abc))
      setSessionStorage("more-details-click", "true")
      dispatch(setHolidayDetailsState(holidayData));
      dispatch(setLoading(false));
      setIsDataLoaded(true)
      dispatch(setLottieLoading(false))
      // dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
      dispatch(setLottieLoading(false))
    }
  }, [flightRoom, basketId, holiday]);

  const saveBasket = useCallback(
    async (state?: any, notToCallLoader?: boolean) => {

      let flightObj: any = {}
      if (state) {
        flightObj = {
          ...state?.updatedFlightRoomState,
          roomData: null
        }
      } else {
        flightObj = {
          ...flightRoom,
          roomData: null
        }
      }

      const body = [
        {
          sessionId: session_id,
          agent_id: "1",
          agency_user_id: "396379",
          basket: JSON.stringify({
            branch: process.env.REACT_APP_PROJECT_COUNTRY?.toUpperCase(),
            cart: {
              flights: {
                [flightObj?.selectedFlights?.outbound[0]?.FlightPswFlight
                  ?.psw_result_id]: state?.updatedFlightRoomState
                    ? JSON.stringify(state?.updatedFlightRoomState)
                    : JSON.stringify(flightObj),
              },
              holiday: state?.yourHolidaySummary
                ? JSON.stringify(state?.yourHolidaySummary)
                : JSON.stringify(holiday),
            },
            sessionId: session_id,
            current_stage: 0,
            last_stage: null,
          }),
          folder_no: -1,
        },
      ];

      try {
        // eslint-disable-next-line
        const response = await API.post(
          API_METHOD.Basket.SAVE_BASKET_TO_DB,
          body
        );
        setSessionStorage("basketId", response.data.data.id)
        setBasketId(response.data.data.id)

        if (notToCallLoader !== true) {
          // dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
      }
    },
    [flightRoom, holiday, session_id, dispatch]
  );

  // useEffect(() => {
  //   let times = timeLeft
  //   const intervalId = setInterval(() => {
  //     if (times <= 1) {
  //       clearInterval(intervalId);
  //       setIsTimeExpired(true);
  //       return Swal.fire({
  //         title: "Please refresh your search for the latest prices",
  //         text: 'Flight price changes frequently due to availabilty and demand. We want to make sure  you always see the best prices, guaranteed',
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
  //         cancelButtonColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
  //         confirmButtonText: 'New Search',
  //         cancelButtonText: "Reload",
  //         allowOutsideClick: false,
  //         allowEscapeKey: false,
  //         customClass: {
  //           confirmButton: 'confirmButton',
  //           actions: 'actions',
  //           icon: isPlataniumHotel ? "platinum-icon-class" : "primary-icon-class",
  //         },
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           newSearch()
  //         } else {
  //           window.location.reload()
  //         }
  //       });
  //     }
  //     times = times - 1
  //     setTimeLeft(times)
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, [isPlataniumHotel]);

  useEffect(() => {
    if (sliderContent === null && flightRoom.callHolidaySummaryApi) {
      fetchCarasoulData()
    }
  }, [])

  useEffect(() => {
    if (Object.keys(holidayPackage).length === 0) {
      setIsDataLoaded(false)
    }
  }, []);

  useEffect(() => {
    const search = window.location.search
    if (((search.includes("departure") || search.includes("destination") || search.includes("s=b"))) && getSessionStorage("holidayRetrieveApiCall") === "false" && (queryData.hotelId || queryData.hotel_id)) {
      setSessionStorage("holidayRetrieveApiCall", "true")
      if (Object.keys(holidayPackage).length === 0) {
        setApiCallPageLoad(true)
        setIsDataLoaded(false)
        removeSessionElements()
        renderOnLoadHolidayPackageApi(queryData)
      }
    }
  }, [])
  useEffect(() => {
    if (getSessionStorage("basketId")) {
      setBasketId(getSessionStorage("basketId"))
    } else if (window.location.search.includes("?email")) {
      if (getSessionStorage("more-details-click") === "false") {
        setBasketId(window.location.search.replace("?email=", ""))
      } else {
        setBasketId(null)
      }
    } else {
      setBasketId(null)
    }
  }, [getSessionStorage("basketId"), window.location.search])

  useEffect(() => {
    const hotel_id = queryData.hotelId || queryData.hotel_id || null
    if (hotel_id !== null) {
      setBasketId(null)
      removeSessionStorage("basketId")
    }

  }, [queryData.hotelId, queryData.hotel_id])

  useEffect(() => {
    const hotel_id = queryData.hotelId || queryData.hotel_id || null
    if (!basketId && loadOnce && flightRoom?.selectedFlights.outbound.length > 0) {
      setTimeout(() => {
        saveBasket();
        setLoadOnce(false);
      }, 100)
    // } else if (basketId && loadOnce && getSessionStorage("more-details-click") === "false" && !window.location.search.includes("departure")) {
    } else if (basketId && loadOnce && getSessionStorage("more-details-click") === "false" && !hotel_id) {
      setIsCustomizeButtonClick(true)
      dispatch(setLottieLoading(true))
      setIsDataLoaded(false)
      retrieveDB();
      setSessionStorage("nextAndSelectedFlights", "true")
      setLoadOnce(false);
    }
  }, [loadOnce, basketId, getSessionStorage("more-details-click"), window.location.search, flightRoom.selectedFlights.outbound.length]);

  useEffect(() => {
    stepperState !== 1 && dispatch(setStepperState(1));
    // getHotelDetails();
    if (hotel_id && isMorePackageClick === "true" && flightRoom.callHolidaySummaryApi) {
      fetchHotelDetails(hotel_id);
    }
  }, []);

  useEffect(() => {
    setSessionStorage("holidayPageLoad", "true")
    setSaveRooms(roomData);
  }, []);

  useEffect(() => {
    (async () => {
      if (flightRoom.callHolidaySummaryApi && flightRoom?.psw_result_id !== null && flightRoom?.roomids !== null && isMorePackageClick === "true") {
        const newFlightRoom = { ...flightRoom }
        const resHoliday = await fetchHolidayDetail(newFlightRoom, true);
        dispatch(setHolidayDetials(resHoliday))
        if (resHoliday?.length > 0) {
          newFlightRoom.holidayDetailLogId = resHoliday[0].holidayDetailLogId || 0
        }
        await getChangeFlight(newFlightRoom, true);
      }
    })();
  }, []);

  useEffect(() => {
    if (hotelDetails?.HotelInfo?.HotelContent?.accomIntro && windowWidth < 600) {
      const paragraphs = document.querySelectorAll('#overview-para p');
      const numberOfParagraphs = paragraphs.length;
      setTotalOverviewParagraph(numberOfParagraphs)
      const getFirstParaTop: any = document.querySelector("#overview-para p:first-of-type")
      setScrollToFirstPara((getFirstParaTop?.offsetTop + 200) || 0)
    }
  }, [hotelDetails?.HotelInfo?.HotelContent?.accomIntro, windowWidth])

  useEffect(() => {
    const outBoundFlights = holidayPackage?.FlightDetails?.outbound ? holidayPackage?.FlightDetails?.outbound.flights : []
    const inboundFlights = holidayPackage?.FlightDetails?.inbound ? holidayPackage?.FlightDetails?.inbound.flights : []

    let obj = {
      ...holiday,
      hotel_name: hotelDetails?.HotelInfo?.Vendor?.vendor_name || hotelName,
      hotel_id: hotelDetails?.HotelInfo?.Vendor?.id,
      hotel: isEmpty(nights) !== "" ? `${flightRoom?.multiRooms[0]?.days_spent} nights at ${hotelDetails?.HotelInfo?.Vendor?.vendor_name}` : "",
      packageDate: {
        checkin: `${dayjs(flightRoom?.multiRooms[0]?.checkInDate).format(
          "DD MMM, YYYY"
        )}`,
        checkout: `${dayjs(flightRoom?.multiRooms[0]?.checkOutDate).format(
          "DD MMM, YYYY"
        )}`,
      },
      selectedFlight: flightRoom?.selectedFlights,
      guest: flightRoom.searchCriteria.guest,
      room: flightRoom?.multiRooms,
    }
    if (isCustomizeButtonClick || flightRoom.selectedFlights.outbound.length > 0) {
      const outboundLayover: any = []
      const inboundLayover: any = []

      flightRoom?.selectedFlights?.outbound?.forEach((item: any, index: number) => {
        item = item?.FlightPswFlight;
        return index !== (flightRoom.selectedFlights.outbound.length - 1) ?
          outboundLayover.push({
            time: flightTime(
              new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
              new Date(
                `${flightRoom.selectedFlights.outbound[index + 1]?.FlightPswFlight?.departure_date} ${formatTime(
                  flightRoom.selectedFlights.outbound[index + 1]?.FlightPswFlight?.departure_time
                )}`
              )
            ),
            code: item.arrival_airport,
            layover_airport_name: item?.Airport?.arrive_airport_name,
            layover_airport_city: item?.Airport?.arrive_airport_city,
          }
          ) : outboundLayover.push(null);
      })

      flightRoom.selectedFlights.inbound?.map((item: any, index: number) => {
        item = item?.FlightPswFlight;
        return index !== (flightRoom.selectedFlights.inbound.length - 1) ?
          inboundLayover.push(
            {
              time: flightTime(
                new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
                new Date(
                  `${flightRoom?.selectedFlights?.inbound[index + 1]?.FlightPswFlight?.departure_date} ${formatTime(
                    flightRoom?.selectedFlights?.inbound[index + 1]?.FlightPswFlight?.departure_time
                  )}`
                )
              ),
              code: item.arrival_airport,
              layover_airport_name: item?.Airport?.arrive_airport_name,
              layover_airport_city: item?.Airport?.arrive_airport_city,

            }
          ) : inboundLayover.push(null);
      })

      obj.baggage = flightRoom?.selectedFlights?.inbound.length > 0 ?
        flightRoom?.selectedFlights?.inbound[0].FlightPswFlight?.Baggage.includes("kg") ? flightRoom?.selectedFlights?.inbound[0].FlightPswFlight?.Baggage : flightRoom?.selectedFlights?.inbound[0].FlightPswFlight?.Baggage.replace("K", "kg") : ""

      obj.flight = {
        inbound: [
          {
            airline_code: isEmpty(flightRoom?.selectedFlights?.inbound[0]?.FlightPswFlight?.airline_code),
            airline_name: isEmpty(flightRoom?.selectedFlights.inbound[0]?.FlightPswFlight?.airline_name),
            inbound_date: `${dayjs(
              flightRoom?.selectedFlights.inbound[0]?.FlightPswFlight
                ?.departure_date
            ).format("DD MMM, YYYY")}`,
            dep_airport: `${isEmpty(flightRoom?.selectedFlights.inbound[0]?.FlightPswFlight.Airport
              .depart_airport_city)
              } (${isEmpty(flightRoom?.selectedFlights.inbound[0]?.FlightPswFlight?.departure_airport?.toUpperCase())})`,
            des_airport: `${isEmpty(flightRoom?.selectedFlights.inbound[
              flightRoom?.selectedFlights.inbound?.length - 1
            ]?.FlightPswFlight.Airport.arrive_airport_city)
              } (${isEmpty(flightRoom?.selectedFlights.inbound[
                flightRoom?.selectedFlights.inbound?.length - 1
              ]?.FlightPswFlight?.arrival_airport?.toUpperCase())})`,
            layover: inboundLayover,
            arrival_time:
              flightRoom?.selectedFlights.inbound.length > 0
                ? formatTime(
                  flightRoom?.selectedFlights.inbound[
                    flightRoom?.selectedFlights.inbound.length - 1
                  ]?.FlightPswFlight?.arrival_time
                )
                : "",
            stop: `${flightRoom?.selectedFlights.inbound?.length - 1 !== 0
              ? flightRoom?.selectedFlights.inbound?.length - 1 + " stops"
              : "Direct"
              }`,
            flightDeparture:
              isEmpty(
                flightRoom?.selectedFlights.inbound[0]?.FlightPswFlight
                  ?.departure_time
              ) !== ""
                ? formatTime(
                  flightRoom?.selectedFlights.inbound[0]?.FlightPswFlight
                    ?.departure_time
                )
                : "",
          },
        ],
        outbound: [
          {
            airline_code: isEmpty(flightRoom?.selectedFlights?.outbound[0]?.FlightPswFlight?.airline_code),
            airline_name: isEmpty(flightRoom?.selectedFlights.outbound[0]?.FlightPswFlight?.airline_name),
            outbound_date: `${dayjs(
              flightRoom?.selectedFlights.outbound[0]?.FlightPswFlight
                ?.departure_date
            ).format("DD MMM, YYYY")}`,
            dep_airport: `${isEmpty(flightRoom?.selectedFlights.outbound[0]?.FlightPswFlight.Airport
              .depart_airport_city)
              } (${isEmpty(flightRoom?.selectedFlights.outbound[0]?.FlightPswFlight?.departure_airport?.toUpperCase())})`,
            des_airport:
              flightRoom?.selectedFlights.outbound.length > 0
                ? `${isEmpty(flightRoom?.selectedFlights.outbound[
                  flightRoom?.selectedFlights.outbound?.length - 1
                ]?.FlightPswFlight.Airport.arrive_airport_city)
                } (${isEmpty(flightRoom?.searchCriteria?.destination?.airport_code?.toUpperCase())})`
                : "",
            layover: outboundLayover,
            arrival_time:
              flightRoom?.selectedFlights.outbound.length > 0
                ? formatTime(
                  flightRoom?.selectedFlights.outbound[
                    flightRoom?.selectedFlights.outbound.length - 1
                  ]?.FlightPswFlight?.arrival_time
                )
                : "",
            stop: `${flightRoom?.selectedFlights.outbound?.length - 1 !== 0
              ? flightRoom?.selectedFlights.outbound?.length - 1 + " stops"
              : "Direct"
              }`,
            flightDeparture:
              isEmpty(
                flightRoom?.selectedFlights.outbound[0]?.FlightPswFlight
                  ?.departure_time
              ) !== ""
                ? formatTime(
                  flightRoom?.selectedFlights.outbound[0]?.FlightPswFlight
                    ?.departure_time
                )
                : "",
          },
        ],
      }
    } else {
      const outboundLayover: any = []
      const inboundLayover: any = []

      if (isEmpty(outBoundFlights) !== "" && isEmpty(inboundFlights) !== "") {

        outBoundFlights.length > 0 && outBoundFlights.map((item: any, index: number) => {
          return index !== (outBoundFlights.length - 1) ?
            outboundLayover.push(
              {
                time: flightTime(
                  new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
                  new Date(
                    `${outBoundFlights[index + 1]?.departure_date} ${formatTime(
                      outBoundFlights[index + 1]?.departure_time
                    )}`
                  )
                ),
                code: outBoundFlights[index + 1]?.arrival_airport,
                layover_airport_name: item?.arrive_airport_name,
                layover_airport_city: item?.arrive_airport_city,
              }
            ) : outboundLayover.push(null);
        })

        inboundFlights.length > 0 && inboundFlights.map((item: any, index: number) => {
          obj.baggage = inboundFlights[0].Baggage.includes("kg") ? inboundFlights[0].Baggage : inboundFlights[0].Baggage.replace("K", "kg")
          return index !== (inboundFlights.length - 1) ?
            inboundLayover.push({
              time: flightTime(
                new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
                new Date(
                  `${inboundFlights[index + 1]?.departure_date} ${formatTime(
                    inboundFlights[index + 1]?.departure_time
                  )}`
                )
              ),
              code: inboundFlights[index + 1]?.arrival_airport,
              layover_airport_name: item?.arrive_airport_name,
              layover_airport_city: item?.arrive_airport_city,
            }
            ) : inboundLayover.push(null);
        })
        obj.flight = {
          inbound: [
            {
              airline_code: inboundFlights[0]?.airline_code,
              airline_name: inboundFlights[0]?.airline_name,
              inbound_date: `${dayjs(
                inboundFlights[0]
                  ?.departure_date
              ).format("DD MMM, YYYY")}`,
              dep_airport: `${inboundFlights[0]?.depart_airport_city
                } (${inboundFlights[0]?.departure_airport?.toUpperCase()})`,
              des_airport: `${inboundFlights[
                inboundFlights?.length - 1
              ]?.arrive_airport_city
                } (${inboundFlights[
                  inboundFlights?.length - 1
                ]?.arrival_airport?.toUpperCase()})`,
              layover: inboundLayover,
              arrival_time:
                inboundFlights.length > 0
                  ? formatTime(
                    inboundFlights[
                      inboundFlights.length - 1
                    ]?.arrival_time
                  )
                  : "",
              stop: `${inboundFlights?.length - 1 !== 0
                ? inboundFlights?.length - 1 + " stops"
                : "Direct"
                }`,
              flightDeparture:
                isEmpty(
                  inboundFlights[0]
                    ?.departure_time
                ) !== ""

                  ? formatTime(
                    inboundFlights[0]
                      ?.departure_time
                  )
                  : "",
            },
          ],
          outbound: [
            {
              airline_code: outBoundFlights[0]?.airline_code,
              airline_name: outBoundFlights[0]?.airline_name,
              outbound_date: `${dayjs(
                outBoundFlights[0]
                  ?.departure_date
              ).format("DD MMM, YYYY")}`,
              dep_airport: `${outBoundFlights[0]?.depart_airport_city
                } (${outBoundFlights[0]?.departure_airport?.toUpperCase()})`,
              des_airport:
                outBoundFlights.length > 0
                  ? `${outBoundFlights[
                    outBoundFlights?.length - 1
                  ]?.arrive_airport_city
                  } (${flightRoom?.searchCriteria?.destination?.airport_code?.toUpperCase()})`
                  : "",
              layover: outboundLayover,
              arrival_time:
                outBoundFlights.length > 0
                  ? formatTime(
                    outBoundFlights[
                      outBoundFlights.length - 1
                    ]?.arrival_time
                  )
                  : "",
              stop: `${outBoundFlights?.length - 1 !== 0
                ? outBoundFlights?.length - 1 + " stops"
                : "Direct"
                }`,
              flightDeparture:
                isEmpty(
                  outBoundFlights[0]
                    ?.departure_time
                ) !== ""
                  ? formatTime(
                    outBoundFlights[0]
                      ?.departure_time
                  )
                  : "",
            },
          ]
        }
      }
    }

    dispatch(
      setHolidayDetailsState(obj)
    );
  }, [flightRoom, hotelDetails, isCustomizeButtonClick]);

  useEffect(() => {
    (async () => {
      if (holidayPackage?.Packages) {
        const newPackageObj : any = holidayPackage
        const result = Object.keys(newPackageObj).length > 0 ? [...newPackageObj?.Packages?.results].filter((e: any) => e.SearchResult.hotel_id === flightRoom?.hotel_id) : []
        const isOfferDetailsApiCall = result.length > 0 ? result[0].SearchResult?.offerDetails ? false : true : true
        if (isOfferDetailsApiCall && flightRoom.callHolidaySummaryApi && flightRoom?.hotel_id && isMorePackageClick === "true") {
          const offerDetailsData = await getOffers([flightRoom?.hotel_id], flightRoom)
          const offerDetailsHotelWise = offerDetailsData?.filter((itemm: any) => itemm.hotelId == flightRoom?.hotel_id)
          if (result?.length > 0 ) {
            if(newPackageObj.Packages.results[0].length) {
            newPackageObj.Packages.results[0] = {
              ...newPackageObj.Packages.results[0],
              SearchResult: {
                ...result[0].SearchResult,
                offerDetails: offerDetailsHotelWise
              }
            }
            const newObj = {
              ...newPackageObj,
              Packages: {
                ...newPackageObj.Packages,
                results: newPackageObj.Packages.results
              }

            };

            dispatch(setHolidayPackage(newObj))
          }
          }
        } else {
          if (!isOfferDetailsApiCall) {
            setOfferDetails(result.length > 0 ? result[0]?.SearchResult?.offerDetails : [])
          }
        }
      }
    })()
  }, [holidayPackage?.Packages?.results, flightRoom.searchCriteria, flightRoom?.hotel_id, flightRoom.callHolidaySummaryApi, stepperState]);

  useEffect(() => {
    const price = {
      low:
        minPackage?.length !== 0
          ? minPackage?.reduce((min: number, current: number) => {
            return min > current ? current : min;
          })
          : 0,
      high:
        minPackage?.length !== 0
          ? minPackage?.reduce((max: number, current: number) => {
            return max > current ? max : current;
          })
          : 0,
    };

    dispatch(setFilterState({ ...filters, price: [price.low, price.high] }))
  }, [minPackage]);

  useEffect(() => {
    if (isTimeExpired) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isTimeExpired]);
  
  useLayoutEffect(() => {
    /**
     * @Hook
       set holidayRetrieveApiCall to false when DOM is loaded
     */
    setSessionStorage("holidayRetrieveApiCall", "false");
  }, [dispatch]);

  const renderOnLoadHolidayPackageApi = async (queryData: any) => {
    // html page and payment microsite when comes on refresh
    removeSessionElements()
    dispatch(setLottieLoading(true))
    const adult = queryData.adult.split(",")
    const cabinClass: CabinClass = capitalizeFirstLetterEachWords(queryData.cabin_class.split("_").join(" ")) as CabinClass
    const child_age = queryData?.child_age && queryData?.child_age?.length > 0 ? JSON.parse(queryData.child_age) : []
    const children = queryData.children_count.split(",")
    const startDate = queryData.daterange.split(",")[0]
    const endDate = queryData.daterange.split(",")[1]
    let destination = queryData.destination
    const infantAge = (queryData.infant_age && queryData.infant_age !== 'undefined' && queryData.infant_age.length > 0) ? JSON.parse(queryData.infant_age) : []
    const infantCount = queryData.infant_count.split(",")
    const roomCount = queryData.room_count
    const DestinationFrom = queryData.departure.toUpperCase()
    const des = queryData.destinationId || null
    const hotelId = queryData.hotelId || queryData.hotel_id || null

    if (des) {
      const destinationData = DestinationList.filter((f) => Number(des) === f["Searc ID"])
      if (destinationData.length > 0) {
        destination = destinationData[0].airport_code + "_;" + destinationData[0]["Searc ID"]
      }
    }

    const destinationCode = destination?.split("_").join("").toUpperCase()

    const desParts = destinationCode?.split(";");
    const desCodeName = desParts?.[0] + " ;" + desParts?.[1];


    let isDirectFlight: boolean = false

    if (process.env.REACT_APP_PROJECT_COUNTRY === "ie") {
      directFlightsIE.map((d: any) => {
        if (d.dep_code === DestinationFrom && d.des_code === isEmpty(desCodeName?.toUpperCase())) {
          isDirectFlight = true
          return d
        } else {
          return <></>
        }
      })
    } else if (process.env.REACT_APP_PROJECT_COUNTRY === "uk") {
      directFlights.map((d: any) => {
        if (d.dep_code === DestinationFrom && d.des_code === isEmpty(desCodeName?.toUpperCase())) {
          isDirectFlight = true
          return d
        } else {
          return <></>
        }
      })
    }

    // Set Rooms 
    let roomArr: any = []
    for (let i = 0; i < Number(roomCount); i++) {
      let childArr: any = []
      let infantArr: any = []

      for (let j = 0; j < Number(children[i]); j++) {
        let child = {
          child: j + 1,
          age: Number(child_age[i][j + 1])
        }

        childArr.push(child)
      }

      for (let j = 0; j < Number(infantCount[i]); j++) {
        let child = {
          child: j + 1,
          age: Number(infantAge[i][j + 1])
        }

        infantArr.push(child)
      }

      let obj = {
        room: i + 1,
        adults: Number(adult[i]),
        children: childArr,
        infants: infantArr
      }
      roomArr.push(obj)
    }

    // Set Departure 
    const dep = setInitialDeparture(DestinationFrom, true)

    // Set Dates 
    const date = [
      new DateObject(dayjs(reverseString(startDate)).format("YYYY/MM/DD")),
      new DateObject(dayjs(reverseString(endDate)).format("YYYY/MM/DD"))
    ]

    // Set Destination
    const selectedDestination: DestinationListType[] = DestinationList.filter((f) => f.airport_code.toUpperCase() + ";" + f["Searc ID"] === destinationCode)

    // set individual adults , infants and childrens
    const adults = roomArr?.reduce((a: number, b: any) => a + b?.adults, 0);
    const childrens = roomArr?.reduce(
      (a: number, b: any) => a + b?.children?.filter((item: { age: string }) => Number(item?.age) >= 2)?.length || 0,
      0
    );
    const infants = roomArr?.reduce((a: number, b: any) => a + b?.infants.length, 0);

    const date1: any = new Date(reverseString(startDate));
    const date2: any = new Date(reverseString(endDate));

    const timeDifference = date2 - date1;

    let nights = timeDifference / (1000 * 60 * 60 * 24);
    nights = Math?.floor(nights);
    const isDubai = selectedDestination.length > 0 ? selectedDestination[0]['Display Name #1'].includes('Dubai') : false;

    const totalChildren = roomArr.flatMap((el: any) => el.children);
    const hasAgeSelected = totalChildren?.every((el: any) => el.age);
    const totalInfantsChildren = roomArr.flatMap((el: any) => el.infants);
    const hasInfantAgeSelected = totalInfantsChildren?.every((el: any) => el.age);
    const totalGuestWithInfants = adults + childrens + infants

    let isError = false
    if (DestinationFrom === "") {
      isError = true
    } else if (selectedDestination.length === 0) {
      isError = true
    } else if (isDubai === false && !(nights >= 3)) {
      isError = true
    }  else if (!hasAgeSelected) {
      isError = true
    } else if (!hasInfantAgeSelected) {
      isError = true
    } else if (totalGuestWithInfants === 0) {
      isError = true
    } else if (isEmpty(cabinClass) === "") {
      isError = true
    } else if (roomCount === "") {
      isError = true
    } else if (queryData.adult === "") {
      isError = true
    } else {
      isError = false
    }
    if (hotelId === null) {
      window.location.href = "/" + window.location.search
    }

    if (hasAgeSelected && hasInfantAgeSelected && selectedDestination.length > 0 && !isError) {
      let body: any = [
        {
          DestinationFrom,
          Destination: selectedDestination.length > 0 ? selectedDestination[0].airport_code?.toUpperCase() + " ;" + selectedDestination[0]['Searc ID'] : "",
          departure_date: startDate,
          rooms: roomCount,
          nights: String(nights),
          cabin_class: CABIN_CLASS_ENUM[cabinClass],
          adults: adult,
          children: children,
          infants: infantCount,
          siteId: process.env.REACT_APP_SITE_ID,
          only_it_fares: true,
          cheapestFareCat: 'IT',
          hotel_id: hotelId,
          direct_flight_only: isDirectFlight ? 1 : 0,
          basketId: "0",
          only_pu_fares: false
        },
      ];
      const holidayDetailsFails = queryData.holiday_details_fails ? queryData.holiday_details_fails?.split(',')?.map((val: any) => val?.trim() === 'true') : '';
      if (queryData?.confirmitinearary_fails) {
        body[1] = {
          ids: {
            "flightSearchCriteriaId": Number(queryData.flight_search_criteria_id),
            "hotelSearchCriteriaIds": Number(queryData.hotel_search_criteria_ids),
            "selectedFlight": Number(queryData.selected_flight),
            "hotelSearchCriteriaId_nextDay": Number(queryData.hotel_search_criteria_id_nextDay),
          },
          filter: {
            // "selected_flight_id": Number(flightRoom?.psw_result_id),
            ...(holidayDetailsFails[0] === false ? { "selected_hotel_id": Number(queryData.hotel_id) } : {}),
            ...(holidayDetailsFails[1] === false ? { "selected_flight_id": Number(queryData.selected_flight) } : {})
          }
        }
      }

      // if (queryData?.flight_search_criteria_id) {
      //   body[1] = {
      //     ids: {
      //       "flightSearchCriteriaId": Number(queryData.flight_search_criteria_id),
      //       "hotelSearchCriteriaIds": Number(queryData.hotel_search_criteria_ids),
      //       "selectedFlight": Number(queryData.selected_flight),
      //       "hotelSearchCriteriaId_nextDay": Number(queryData.hotel_search_criteria_id_nextDay),    
      //     },
      //     filter: {
      //       // "selected_flight_id": Number(flightRoom?.psw_result_id),
      //       "selected_flight_id": Number(queryData.selected_flight),
      //       "selected_hotel_id": Number(queryData.hotel_id)
      //     }
      //   }
      // }

      if (childrens !== 0) {
        body[0].child_age = child_age
      }
      if (infants > 0) {
        body[0].infant_ages = infantAge
      }

      setSessionStorage('search', JSON.stringify(body));
      setSessionStorage('room', JSON.stringify({ room: roomArr, date }));
      const { payload } = await dispatch(fetchAsyncHolidayPackages(body));
      const res = payload?.Packages?.results
      if (res?.length === 0 || payload == undefined) {
        setUatRedirect(true)
      }
      const selectedDeparture = dep.filter((f) => f.selected)
      const { depart_airport_name, departure_airport, full_name } = selectedDeparture[0];
      if (payload?.Packages) {
        const res = payload?.Packages?.results
        if (res.length > 0) {
          const step = window.location.pathname.includes("holiday-summary") ? 1 : 0
          let filterHotel = hotelId !== null ? payload?.Packages?.results.filter((f: any) => f.SearchResult.hotel_id === hotelId) : payload.Packages.results
          filterHotel = filterHotel.length > 0 ? filterHotel : payload.Packages.results

          const multiRoomIds = getRoomIds(sortRoom(hotelId !== null && step === 1 ? filterHotel[0].SearchResult : payload.Packages.results[0].SearchResult, filters), roomArr)

          let obj = {
            ...flightRoom,
            packageSearchId: payload?.packageSearchId || "",
            flightSearchCriteriaId: payload.SearchCriteria[1].ids.flightSearchCriteriaId,
            hotelSearchCriteriaIds: payload.SearchCriteria[1].ids.hotelSearchCriteriaIds,
            hotelSearchCriteriaId_nextDay: payload.SearchCriteria[1].ids.hotelSearchCriteriaId_nextDay,
            psc_request_id: payload.flightSearchCriteriaId,
            psw_result_id: payload.FlightDetails.outbound.flights[0].psw_result_id,
            store_psw_result_id: payload.FlightDetails.outbound.flights[0].psw_result_id,
            roomids: multiRoomIds,
            hotel_name: hotelId !== null && step === 1 ? filterHotel[0].SearchResult.hotel_name : res[0].SearchResult.hotel_name,
            hotel_id: hotelId !== null && step === 1 ? hotelId : res[0].SearchResult.hotel_id,
            selectHotelId: hotelId !== null && step === 1 ? filterHotel[0].SearchResult.id : res[0].SearchResult.id,
            searchCriteriaId: payload.hotelSearchCriteriaIds,
            search_result_id: hotelId !== null && step === 1 ? filterHotel[0].SearchResult : res[0].SearchResult.id,
            multiRooms: hotelId !== null && step === 1 ? sortRoom(filterHotel[0].SearchResult, filters) : sortRoom(res[0].SearchResult, filters),
            storeMultiRoom: hotelId !== null && step === 1 ? sortRoom(filterHotel[0].SearchResult, filters) : sortRoom(res[0].SearchResult, filters),
            roomData: hotelId !== null && step === 1 ? filterHotel : res,
            searchCriteria: {
              child_age: child_age,
              departure: {
                depart_airport_name,
                departure_airport,
                full_name,
                selected: true,
              },
              // isAdditionalTransferAmountAdd: selectedDestination.length > 0 ? selectedDestination[0]?.transfer_add : false,
              infant_age: infantAge,
              destination: selectedDestination[0],
              date: [startDate, endDate],
              guest: roomArr,
              cabin_class: cabinClass,
              class: cabinClass,
              night: nights,
              adults,
              childrens,
              infants,
            }
          }
          let obj2 = { ...obj }
          setSaveRooms(hotelId !== null && step === 1 ? filterHotel : res)
          await fetchHotelDetails(hotelId !== null && step === 1 ? hotelId : res[0].SearchResult.hotel_id);
          const resHoliday = await fetchHolidayDetail(obj);
          dispatch(setHolidayDetials(resHoliday))
          if (resHoliday?.length > 0) {
            obj2.holidayDetailLogId = await resHoliday[0].holidayDetailLogId || 0
          }
          await  setSessionStorage("more-details-click", "true")
          // setSessionStorage("apiCall", "true")
          await dispatch(setFlightRoomState(obj2))
          await dispatch(setHolidayPackage(payload))
          await getChangeFlight(obj2, true);
          await setIsDataLoaded(true)
          await dispatch(setLottieLoading(false))
          // await getOffers(hotelId !== null && step === 1 ? [Number(hotelId)] : [Number(res[0].SearchResult.hotel_id)])
          // await handleSetTransfer(payload, obj2)
        } else {
          dispatch(setHolidayPackage({}));
          setIsDataLoaded(false)
          dispatch(setLoading(false))
          dispatch(setLottieLoading(false))
        }
      } else {
        setIsDataLoaded(false)
        dispatch(setHolidayPackage({}));
        // console.log(payload)
        dispatch(setLoading(false))
        dispatch(setLottieLoading(false))
      }
    } else {
      setIsDataLoaded(false)
      dispatch(setLottieLoading(false))
    }
  }

  const holidayPackageStoreCriteria1 = async () => {
    let holidayPackageJson: any = await [
      {
        "DestinationFrom": holidayPackage?.SearchCriteria?.[0]?.DestinationFrom,
        "Destination": holidayPackage?.SearchCriteria?.[0]?.Destination,
        "departure_date": holidayPackage?.SearchCriteria?.[0]?.departure_date,
        "rooms": holidayPackage?.SearchCriteria?.[0]?.rooms,
        "nights": holidayPackage?.SearchCriteria?.[0]?.nights,
        "cabin_class": holidayPackage?.SearchCriteria?.[0]?.cabin_class,
        "adults": holidayPackage?.SearchCriteria?.[0]?.adults,
        "children": holidayPackage?.SearchCriteria?.[0]?.children,
        "infants": holidayPackage?.SearchCriteria?.[0]?.infants,
        "siteId": holidayPackage?.SearchCriteria?.[0]?.siteId,
        "cheapestFareCat": holidayPackage?.SearchCriteria?.[0]?.cheapestFareCat,
        hotel_id: Number(flightRoom?.hotel_id),
        // hotel_name: flightRoom.roomData[0]?.SearchResult?.hotel_name
      },
      {
        ids: {
          "flightSearchCriteriaId": Number(flightRoom.flightSearchCriteriaId),
          "hotelSearchCriteriaIds": Number(flightRoom.hotelSearchCriteriaIds),
          "selectedFlight": Number(flightRoom?.psw_result_id),
          "hotelSearchCriteriaId_nextDay": Number(flightRoom?.hotelSearchCriteriaId_nextDay),
        },
        filter: {
          // "selected_flight_id": Number(flightRoom?.psw_result_id),
          "selected_flight_id": Number(flightRoom.psw_result_id),
          "selected_hotel_id": Number(flightRoom?.hotel_id)
        }
      }
    ]

    if (flightRoom?.searchCriteria?.childrens > 0) {
      holidayPackageJson[0].child_age = await flightRoom?.searchCriteria?.child_age
    }
    return holidayPackageJson;
  }

  const handleSubmit = async (flight: any) => {
    setSessionStorage("previous", "true");
    setSessionStorage(
      "OutboundFlights",
      JSON.stringify(flightRoom?.selectedFlights?.outbound)
    );
    setSessionStorage(
      "InboundFlights",
      JSON.stringify(flightRoom?.selectedFlights?.inbound)
    );
    const body = [
      {
        psw_result_id: flightRoom?.psw_result_id,
        roomids: flight.roomids,
        "packageSearchId": flight?.packageSearchId || "",
        holidayDetailLogId: flight.holidayDetailLogId
      },
    ];

    const containsTrue = Object.values(flight.confirmSelection).some(value => value === true);
    if (containsTrue) {
      dispatch(setLoading(true))
      await dispatch(fetchAsyncHolidayPackageDetails(body));
    }
    const holidayPackageStoreCriteria = await holidayPackageStoreCriteria1();
    const payload = await dispatch(
      setFlightRoomState({
        ...flight,
        storeMultiRoom: flight.multiRooms,
        store_psw_result_id: flight.psw_result_id,
        storeSelectedFlights: flight.selectedFlights,
        searchCriteria2: holidayPackageStoreCriteria
      })
    ).payload;

    await setRoomDisableButton(false);
    await setDisableButton(false);
    await window.scrollTo(0, 700);
    await setActiveIndex(null);
    await setExpanded(1);
    navigate(`/passenger-details/${session_id}${window.location.search}`);
    await setShowButtons(false);
    await dispatch(setDialogState({ ...dialogState, customiseModal: false }))
    const newData = { ...payload };
    if (newData.hasOwnProperty("roomData")) {
      delete newData["roomData"];
    }

    setSessionStorage("nextAndSelectedFlights", "true");
    await saveBasket({ updatedFlightRoomState: newData });
    await dispatch(setLoading(false))
  };

  const retrieveholidayBasketRetrieve = async (flightRoom: any, isConfirmFlight: boolean) => {
    // setApiCallPageLoad(true)
    let isDirectFlight: boolean = false
    const adultPerRoom: any = []
    const childPerRoom: any = []
    const childAge: any = [];
    const cabinClass: CabinClass = flightRoom.searchCriteria.class
    const room = flightRoom.searchCriteria.guest
    const infantPerRoom: any = [];
    if (room.length > 0) {
      room?.map((r: any) => {
        adultPerRoom.push(r.adults.toString())
        childPerRoom.push(r.children.length.toString())
        infantPerRoom.push(r.infants.length.toString())
        const childrenAges: any = {};

        if (r?.children?.length > 0) {
          r?.children.forEach((child: any) => {
            childrenAges[child.child.toString()] = child.age;
          });
          childAge.push(childrenAges);
        } else {
          childAge.push({});
        }
        return r
      })
    }

    if (process.env.REACT_APP_PROJECT_COUNTRY === "ie") {
      directFlightsIE.map((d: any) => {
        if (d.dep_code === flightRoom.searchCriteria.departure.departure_airport && d.des_code.toUpperCase() === isEmpty(flightRoom.searchCriteria.destination.airport_code.toUpperCase() + " ;" + flightRoom.searchCriteria.destination["Searc ID"])) {
          isDirectFlight = true
          return d
        } else {
          return <></>
        }
      })
    } else if (process.env.REACT_APP_PROJECT_COUNTRY === "uk") {
      directFlights.map((d: any) => {
        if (d.dep_code === flightRoom.searchCriteria.departure.departure_airport && d.des_code.toUpperCase() === isEmpty(flightRoom.searchCriteria.destination.airport_code.toUpperCase() + " ;" + flightRoom.searchCriteria.destination["Searc ID"])) {
          isDirectFlight = true
          return d
        } else {
          return <></>
        }
      })
    }

    const fetchClass: any = CABIN_CLASS_ENUM[cabinClass]

    let holidayPackageJson: any = [
      {
        "DestinationFrom": flightRoom.searchCriteria.departure.departure_airport,
        "Destination": flightRoom.searchCriteria.destination.airport_code?.toUpperCase() + " ;" + flightRoom.searchCriteria.destination["Searc ID"],
        "departure_date": flightRoom.searchCriteria.date[0],
        "rooms": flightRoom.searchCriteria.guest.length.toString(),
        "nights": flightRoom.searchCriteria.night,
        "cabin_class": fetchClass,
        adults: adultPerRoom,
        children: childPerRoom,
        infants: infantPerRoom,
        "siteId": process.env.REACT_APP_SITE_ID,
        "cheapestFareCat": 'IT',
        'direct_flight_only': isDirectFlight ? 1 : 0,
        only_it_fares: true,
        basketId: isEmpty(basketId) !== "" ? basketId : "0",
        hotel_id: Number(flightRoom?.hotel_id),
        only_pu_fares: false
        // hotel_name: flightRoom.roomData[0]?.SearchResult?.hotel_name
      }
    ]

    if (isEmpty(basketId) !== "") {
      holidayPackageJson[0].packageSearchId = flightRoom.packageSearchId
    }

    if (isConfirmFlight) {
      holidayPackageJson[1] = {
        ids: {
          "flightSearchCriteriaId": Number(flightRoom.flightSearchCriteriaId),
          "hotelSearchCriteriaIds": Number(flightRoom.hotelSearchCriteriaIds),
          "selectedFlight": Number(flightRoom?.psw_result_id),
          "hotelSearchCriteriaId_nextDay": Number(flightRoom.hotelSearchCriteriaId_nextDay),
        },
        filter: {
          // "selected_flight_id": Number(flightRoom?.psw_result_id),
          "selected_flight_id": Number(flightRoom.selectedFlights.outbound[0].FlightPswFlight.psw_result_id),
          "selected_hotel_id": Number(flightRoom?.hotel_id)
        }
      }
    }

    if (flightRoom?.searchCriteria?.childrens > 0) {
      holidayPackageJson[0].child_age = flightRoom?.searchCriteria?.child_age
    }
    if (flightRoom?.searchCriteria?.infants > 0) {
      holidayPackageJson[0].infant_ages = flightRoom?.searchCriteria?.infant_age
    }

    const { payload } = await dispatch(fetchAsyncHolidayPackages(holidayPackageJson));
    if (payload && payload.Packages.results.length > 0 && !isConfirmFlight) {
      setSaveRooms(payload.Packages.results)
      // setIsDataLoaded(true)
      // dispatch(setHolidayPackage(payload));
      // dispatch(setLottieLoading(false))
      // handleSetTransfer(payload, flightRoom)
      dispatch(setLottieLoading(false))
    } else {
      dispatch(setLottieLoading(false))
      // dispatch(setLoading(false))
    }
    // await dispatch(setLoading(false))
    return payload
  }

  const setInitialDeparture = (e: string, code?: boolean) => {
    let data = process.env.REACT_APP_PROJECT_COUNTRY === "ie" ? [...IE_DEPARTURE] : process.env.REACT_APP_PROJECT_COUNTRY === "us" ? [...US_DEPARTURE] : [...UK_DEPARTURE];
    data = data.map((item: any, index: number) => {
      return {
        ...item,
        selected: index === 0 ? true : false,
      };
    });

    let d = data?.map((item) => {
      code === true ? item.departure_airport === e ? (item.selected = true) : (item.selected = false) : item.full_name === e ? (item.selected = true) : (item.selected = false);
      return item;
    });
    return d
  }

  const handleConfirmFlight = () => {
    //* Outbound to Redux

    const outboundFlightJSON = getSessionStorage("OutboundFlights");
    const outboundFlight = outboundFlightJSON && JSON.parse(outboundFlightJSON)
    const inboundFlightJSON = getSessionStorage("InboundFlights")
    const inboundFlight = inboundFlightJSON && JSON.parse(inboundFlightJSON)
    const updatedFlightRoomState = dispatch(
      setFlightRoomState({
        ...flightRoom,
        selectedFlights: { inbound: inboundFlight, outbound: outboundFlight },
        // storeSelectedFlights: { inbound: inboundFlight, outbound: outboundFlight },
      })
    ).payload;
    const yourHolidaySummary = dispatch(
      setHolidayDetailsState({
        ...holiday,
        outboundTime: sumTime(getFlightTimesObject(outboundFlight)),
        inboundTime: sumTime(getFlightTimesObject(inboundFlight)),
      })
    ).payload;

    return { updatedFlightRoomState, yourHolidaySummary };
  }

  const reverseString = (str: any) => {
    var splitString = str.split("/");
    var reverseArray = splitString.reverse();
    var joinArray = reverseArray.join("/");
    return joinArray;
  }

  const handleConfirmSelectedFlight = async () => {
    setSessionStorage("stepper", "1")
    dispatch(setLoading(true))
    const updatedState: any = handleConfirmFlight();
    const updatedflightRoom: any = updatedState?.updatedFlightRoomState
    setSessionStorage("previous", "false")
    const flightRes: any = await getChangeFlight(updatedflightRoom, true)
    if (flightRes) {
      setIsFlightChanged(true)
      let price: any = '0'

      if (updatedflightRoom?.selectedFlights?.outbound[0]?.FlightPswFlight?.FlightPswResult?.holiday_diff_per_person.includes("-")) {
        price = Math.floor(Number(updatedflightRoom?.selectedFlights?.outbound[0]?.FlightPswFlight?.FlightPswResult?.holiday_diff_per_person) + Number(flightRoom.diffPrice))
      } else {
        price = Math.ceil(Number(updatedflightRoom?.selectedFlights?.outbound[0].FlightPswFlight?.FlightPswResult?.holiday_diff_per_person) + Number(flightRoom.diffPrice))
      }
      setSessionStorage("OutboundFlights", JSON.stringify(updatedflightRoom.selectedFlights.outbound))
      setSessionStorage("InboundFlights", JSON.stringify(updatedflightRoom.selectedFlights.inbound))

      const flightObj = {
        ...updatedState?.updatedFlightRoomState,
        diffPrice: price.toString(),
        psc_request_id: updatedflightRoom.psc_request_id,
        psw_result_id: updatedflightRoom.psw_result_id,
        confirmSelection: {
          ...updatedState?.updatedFlightRoomState.confirmSelection,
          flights: updatedflightRoom.psw_result_id === flightRoom.store_psw_result_id ? false : true,
        },
      }

      const response = isRetrieveBasketApiCall ? await retrieveholidayBasketRetrieve(updatedflightRoom, true) : await getHolidayPackageRetrieveData(updatedflightRoom)

      if (response) {
        setSaveRooms(response.Packages.results)

        dispatch(setFilteredHolidayPackage(holidayPackage))

        const selectedRooms = response.Packages.results.length > 0 ? sortRoom(response.Packages.results[0].SearchResult, filters) : []
        let roomArr = []
        let index = 0
        if (response.Packages.results.length > 0) {
          for (const [key, value] of Object.entries(response.Packages.results[0].SearchResult.Rooms)) {
            const isRoomAvaialble = Array.isArray(value) ? value.filter((f: any) => f.id === flightRoom.multiRooms[index].id) : []
            if (isRoomAvaialble.length > 0) {
              roomArr.push(isRoomAvaialble[0])
            } else {
              roomArr.push(selectedRooms[index])
            }
            index = index + 1
          }
        }
        setOnChangeMultiRoom(roomArr)
        const updated = await dispatch(
          setFlightRoomState({
            ...flightObj,
            packageSearchId: response?.packageSearchId || "",
            flightSearchCriteriaId: response.SearchCriteria[1].ids.flightSearchCriteriaId,
            hotelSearchCriteriaIds: response.SearchCriteria[1].ids.hotelSearchCriteriaIds,
            hotelSearchCriteriaId_nextDay: response.SearchCriteria[1].ids.hotelSearchCriteriaId_nextDay,
            multiRooms: roomArr,
            roomData: response.Packages.results,
          })
        ).payload;

        setDisableButton(false)
        const newData = { ...updated };
        if (newData.hasOwnProperty("roomData")) {
          delete newData["roomData"]
        }

        await saveBasket({
          updatedFlightRoomState: newData,
          yourHolidaySummary: updatedState?.yourHolidaySummary,
        }, true);

        setTimeout(() => {
          scrollToText()
        }, 1)

        await setActiveIndex((prevIndex: any) => (prevIndex + 1) % components.length);
        await dispatch(setLoading(false))
      } else {
        setSaveRooms([])
        setOnChangeMultiRoom([])

        setDisableButton(false)
        const newData = { ...flightObj };
        if (newData.hasOwnProperty("roomData")) {
          delete newData["roomData"]
        }
        saveBasket({
          updatedFlightRoomState: newData,
          yourHolidaySummary: updatedState?.yourHolidaySummary,
        });
        setSessionStorage("previous", "true")
        setSessionStorage("nextAndSelectedFlights", "true")
        setTimeout(() => {
          scrollToText()
        }, 1)
        await setActiveIndex((prevIndex: any) => (prevIndex + 1) % components.length);
        dispatch(setLoading(false))
      }
    }
  }

  const getRoomIds = (arr: any, guest: any) => {
    const roomsArr: any = arr.map((e: any) => e.id)
    let passengerDetails: any = {}

    let totalPassenger = [...Array(adults + childrens)]
      .map((value, index) => (index + 1).toString())

    roomsArr.map((r: any, i: any) => {
      const totalLength = guest[i].adults + guest[i].children.length

      const totalPassengerPerRoom = []

      for (let i = 0; i < totalLength; i++) {
        totalPassengerPerRoom.push(totalPassenger[i])
      }

      totalPassenger.splice(0, totalLength)
      return passengerDetails[r] = totalPassengerPerRoom
        .join(',')
    })
    return roomsArr.toString()
  }

  const hasSimilarId = (array1: any, array2: any) => {
    const ids1 = array1.map((item: any) => item.id);
    const ids2 = array2.map((item: any) => item.id);

    const commonIds = ids1.filter((id: any) => ids2.includes(id));

    return commonIds.length > 0;
  }

  const handleChangeRoom = () => {
    setDisableButton(false)
    const result = hasSimilarId(flightRoom.storeMultiRoom, onChangeMultiRoom);

    let flight = {
      ...flightRoom,
      multiRooms: onChangeMultiRoom,
      roomids: getRoomIds(onChangeMultiRoom, guest),
      confirmSelection: {
        ...flightRoom.confirmSelection,
        room: !result,
      },
    }
    const updated = dispatch(
      setFlightRoomState(flight)
    ).payload;

    const newData = { ...updated };
    if (newData.hasOwnProperty("roomData")) {
      delete newData["roomData"]
    }

    saveBasket({
      updatedFlightRoomState: newData,
      yourHolidaySummary: null,
    });
    handleSubmit(flight)
    // setTimeout(() => {
    //   scrollToText()
    // }, 1)
    // // setExpanded(null);
    // setActiveIndex(2);
  };

  const scrollToText = () => {
    if (getCustomizeHeight.current) {
      getCustomizeHeight.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const showNextComponent = () => {
    if (activeIndex === 0) {
      // setIsRender(true)
      setSessionStorage("nextButtonClicked", "true")
      if (flightRoom.isNewFlightSelected.flights) {
        setIsDirect(false)
        setSessionStorage("nextButtonClicked", "true")
        handleConfirmSelectedFlight()
      } else {
        setIsDirect(true)
        setSessionStorage(
          "OutboundFlights",
          JSON.stringify(flightRoom?.selectedFlights?.outbound)
        );
        setSessionStorage(
          "InboundFlights",
          JSON.stringify(flightRoom?.selectedFlights?.inbound)
        );
        setOnChangeMultiRoom(flightRoom.multiRooms);
        if (windowWidth < 600) {
          setTimeout(() => {
            scrollToText()
          }, 1)
        }
        setActiveIndex((prevIndex: any) => (prevIndex + 1) % components.length);
      }
    } else {
      handleChangeRoom()

      if (windowWidth < 600) {
        setTimeout(() => {
          scrollToText()
        }, 1)
      }
    }
    // dispatch(
    //   setFlightRoomState({
    //     ...flightRoom,
    //     // multiRooms: flightRoom.multiRooms,
    //     //   flightRoom.multiRooms.length > 0 ? flightRoom.multiRooms[0] : {},
    //     roomData: saveRoom,
    //   })
    // );

  };

  const showPreviousComponent = async (activeIndex: any) => {
    if (activeIndex === 0) {
      if (getSessionStorage("nextButtonClicked") === "true") {
        setSessionStorage("nextAndSelectedFlights", "true")
      } else {
        setSessionStorage("nextAndSelectedFlights", "false")
      }

      setSessionStorage("OutboundFlights", JSON.stringify(flightRoom.storeSelectedFlights.outbound))
      setSessionStorage("InboundFlights", JSON.stringify(flightRoom.storeSelectedFlights.inbound))

      const flightObj = {
        ...flightRoom,
        psw_result_id: flightRoom.store_psw_result_id,
        selectedFlights: flightRoom.storeSelectedFlights,
        multiRooms: flightRoom.storeMultiRoom,
      }

      if (flightRoom.store_psw_result_id !== null) {
        dispatch(setFlightRoomState(flightObj))
      }

      if (isFlightsChanged) {
        dispatch(setLoading(true))
        await getChangeFlight(flightObj);
        await dispatch(setLoading(false))
      }

    }

    activeIndex === null && setShowButtons(false);
    activeIndex === null && dispatch(setDialogState({ ...dialogState, customiseModal: false }))
    activeIndex === 0 && window.scrollTo(0, 600);
    setDisableButton(false);
    setRoomDisableButton(false);

    if (showButtons === true && activeIndex === 0) {
      setShowButtons(false);
      dispatch(setDialogState({ ...dialogState, customiseModal: false }))
      setActiveIndex(null);
      setExpanded(1);
    } else {
      setActiveIndex((prevIndex: any) =>
        prevIndex === 0 ? components.length - 1 : prevIndex - 1
      );

      if (showButtons === false) {
        setExpanded(1);
      } else if (showButtons === true && activeIndex === 1) {
        setExpanded(2);
        dispatch(setFlightRoomState({
          ...flightRoom,
          isNewFlightSelected: {
            ...flightRoom.isNewFlightSelected,
            flights: false
          },
        }))

        if (getSessionStorage("inboundMaxDuration") !== null) {
          removeSessionStorage("inboundMaxDuration")
        }
        if (getSessionStorage("inboundDuration") !== null) {
          removeSessionStorage("inboundDuration")
        }
        let price: any = []
        flightRoom.multiRooms.map((f: any) => {
          price.push(f.total_package)
          return <></>
        })

        setRoomPrice(price)

        setSessionStorage("nextButtonClicked", "true")
        setSessionStorage("previous", "true");
        setSessionStorage("nextAndSelectedFlights", "true")
      } else if (showButtons === true && activeIndex === 0) {
        setExpanded(1);
      } else if (showButtons === true) {
        setExpanded(2);
      }
    }
  };

  const handleOpenAccordion = (id: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setOnChangeMultiRoom(flightRoom.multiRooms);
    setExpanded(
      activeIndex === 0 || activeIndex === 1 || activeIndex === 2 ? 2 : 1
    );
  };

  // const getOffers = async (hotelId: any) => {
  //   console.log('hotelId :', hotelId);
  //   let arr: any = [];
  //   const body = [{ 'hotel_ids': hotelId }];
  //   await API.post(API_METHOD.Misc.GET_CMS_OFFERS, body)
  //     .then((result: any) => {
  //       const res: any = result?.data;

  //       if (Object.keys(res).length > 0) {
  //         if (res?.SpecialOffers.length > 0) {

  //           const currentDate = new Date();
  //           const departureStartDate: any = new Date(dateSplit(flightRoom.searchCriteria.date[0], "/"));
  //           const departureEndDate: any = new Date(dateSplit(flightRoom.searchCriteria.date[1], "/"));

  //           // Filter with branchCode
  //           const filterBranchCode = res?.SpecialOffers.filter((r: any) => r.branchCode === process.env.REACT_APP_PROJECT_COUNTRY?.toUpperCase());

  //           // Filter with offerEndDate
  //           const filterOfferEndDate = filterBranchCode.filter((offer: any) => new Date(offer?.offerStartDate) <= currentDate && new Date(offer?.offerEndDate) >= currentDate);

  //           // Filter with the dept start and end date
  //           const filterInBetweenDepDatesOffer = filterOfferEndDate.filter((d: any) => {
  //             const start = new Date(d.deptStartDate)
  //             start.setHours(0, 0, 0, 0);

  //             const end = new Date(d.deptEndDate)
  //             end.setHours(0, 0, 0, 0);

  //             departureStartDate.setHours(0, 0, 0, 0)
  //             departureEndDate.setHours(0, 0, 0, 0)
  //             return new Date(start) <= departureStartDate && new Date(end) >= departureEndDate
  //           })

  //           // Filter with the top offers
  //           const filterShowInTop = filterInBetweenDepDatesOffer.filter((r: any) => r.showInTopOffersBoxOnProductPage === "1");

  //           filterShowInTop.map((offer: any) => {
  //             arr.push(offer)
  //           })

  //           // Filter without the top offers
  //           const withoutOne = filterInBetweenDepDatesOffer?.filter((r: any) => r?.showInTopOffersBoxOnProductPage !== "1");
  //           withoutOne?.forEach((offer: any) => {
  //             arr?.push(offer);
  //           });

  //           setOfferDetails(arr)
  //           return arr
  //         } else {
  //           return arr
  //         }
  //       }
  //     })
  //     .catch((error: any) => {
  //       return arr
  //     });
  //   return await arr
  // }
  const getOffers = async (hotelId: any, flightRoom: any, packageResult?: any) => {
    const body = [{ 'hotel_ids': hotelId }];
    let arr: any = [];
    try {
      const result = await API.post(API_METHOD.Misc.GET_CMS_OFFERS, body);
      const res = result?.data;

      if (Object.keys(res).length > 0) {
        const currentDate = new Date();
        const formattedStartDate = flightRoom.searchCriteria.date[0];
        const formattedEndDate = flightRoom.searchCriteria.date[1];

        const departureStartDate = new Date(dateSplit(formattedStartDate, "/"));
        const departureEndDate = new Date(dateSplit(formattedEndDate, "/"));

        Object.keys(res).forEach((id) => {

          const offers = res[id];

          // Filter with branchCode
          const filterBranchCode = offers?.filter((r: any) => r.key === process.env.REACT_APP_PROJECT_COUNTRY?.toUpperCase());
          // Filter with offerEndDate
          const filterOfferEndDate = filterBranchCode?.filter((offer: any) =>
            new Date(offer?.bookFrom) <= currentDate && new Date(offer.bookTo) >= currentDate
          );

          // Filter with the dept start and end date
          const filterInBetweenDepDatesOffer = filterOfferEndDate?.filter((d: any) => {
            const start = new Date(d?.validFrom);
            start.setHours(0, 0, 0, 0);
            const end = new Date(d?.validTo);
            end.setHours(0, 0, 0, 0);

            departureStartDate.setHours(0, 0, 0, 0);
            departureEndDate.setHours(0, 0, 0, 0);

            return start <= departureStartDate && end >= departureEndDate;
          });

          // Filter with the top offers
          const filterShowInTop = filterInBetweenDepDatesOffer?.filter((r: any) => r.showInTopOffersBoxOnProductPage === "1");

          filterShowInTop?.forEach((offer: any) => {
            arr.push(offer);
          });

          // Filter without the top offers
          const withoutOne = filterInBetweenDepDatesOffer?.filter((r: any) => r.showInTopOffersBoxOnProductPage !== "1");

          withoutOne?.forEach((offer: any) => {
            arr.push(offer);
          });

          setOfferDetails(arr)
        })
      }
    } catch (error) {
      console.error('Error fetching offers:', error);
    }

    return arr;
  };

  const fetchHotelDetails = async (hotel_id: any) => {
    if (hotel_id) {
      const resp = await dispatch(fetchAsyncHotelDetails(hotel_id));
      dispatch(setHotelDetailsState(resp?.payload));
    }
  };

  const getChangeFlight = async (flightRoom: any, noLoaderCall?: boolean) => {
    const res = await dispatch(
      fetchAsyncChangeFlight([
        {
          psc_request_id: flightRoom.psc_request_id,
          psw_result_id: flightRoom.psw_result_id,
          grouped_results: true,
        },
      ])
    );
    dispatch(
      setFlightRoomState({
        ...flightRoom,
        psc_request_id: flightRoom.psc_request_id,
        psw_result_id: flightRoom.psw_result_id,
      })
    );
    if (res) {
      dispatch(setFlightOptions(res));
      if (!noLoaderCall) {
        dispatch(setLoading(false));
      }

    }
    return res
  };

  const holidayPackageStoreCriteria = async () => {
    let holidayPackageJson: any = await [
      {
        "DestinationFrom": holidayPackage?.SearchCriteria?.[0]?.DestinationFrom,
        "Destination": holidayPackage?.SearchCriteria?.[0]?.Destination,
        "departure_date": holidayPackage?.SearchCriteria?.[0]?.departure_date,
        "rooms": holidayPackage?.SearchCriteria?.[0]?.rooms,
        "nights": holidayPackage?.SearchCriteria?.[0]?.nights,
        "cabin_class": holidayPackage?.SearchCriteria?.[0]?.cabin_class,
        "adults": holidayPackage?.SearchCriteria?.[0]?.adults,
        "children": holidayPackage?.SearchCriteria?.[0]?.children,
        "infants": holidayPackage?.SearchCriteria?.[0]?.infants,
        "siteId": holidayPackage?.SearchCriteria?.[0]?.siteId,
        "cheapestFareCat": holidayPackage?.SearchCriteria?.[0]?.cheapestFareCat,
        hotel_id: Number(flightRoom?.hotel_id),
        // hotel_name: flightRoom.roomData[0]?.SearchResult?.hotel_name
      },
      {
        ids: {
          "flightSearchCriteriaId": Number(flightRoom.flightSearchCriteriaId),
          "hotelSearchCriteriaIds": Number(flightRoom.hotelSearchCriteriaIds),
          "selectedFlight": Number(flightRoom?.psw_result_id),
          "hotelSearchCriteriaId_nextDay": Number(flightRoom?.hotelSearchCriteriaId_nextDay),
        },
        filter: {
          // "selected_flight_id": Number(flightRoom?.psw_result_id),
          "selected_flight_id": Number(flightRoom.psw_result_id),
          "selected_hotel_id": Number(flightRoom?.hotel_id)
        }
      }
    ]

    if (flightRoom?.searchCriteria?.childrens > 0) {
      holidayPackageJson[0].child_age = await flightRoom?.searchCriteria?.child_age
    }
    await dispatch(setFlightRoomState({
      ...flightRoom,
      searchCriteria2: holidayPackageJson
    }))

    return await true
  }
  const handleBook = async () => {
    if (getSessionStorage("nextButtonClicked") === "true") {
      setSessionStorage("nextAndSelectedFlights", "true")
      setSessionStorage("previous", "true")
    } else {
      setSessionStorage("nextAndSelectedFlights", "false")
    }
    // setSessionStorage("nextAndSelectedFlights", "true");
    // setSessionStorage("nextButtonClicked", "true");
    // dispatch(setLoading(true))
    // const body = [
    //   {
    //     psw_result_id: flightRoom?.psw_result_id,
    //     roomids: flightRoom.roomids,
    //     "packageSearchId": flightRoom?.packageSearchId || ""
    //   },
    // ];
    const res = await holidayPackageStoreCriteria()
    console.log(res)
    // eslint-disable-next-line
    // let res = await dispatch(fetchAsyncHolidayPackageDetails(body));
    // dispatch(setLoading(false))
    dispatch(setStepperState(2));
    res && navigate(`/passenger-details/${session_id}${window.location.search}`);
  };

  const showButtonsAndFirstComponent = () => {
    setDisabledFlightTabsInbound(true)
    setIsDirect(true)
    setIsFlightChanged(false)
    removeSessionStorage("inboundMaxDuration")
    removeSessionStorage("inboundDuration")
    setIsCustomizeButtonClick(true)
    setSessionStorage("OutboundFlights", JSON.stringify(flightRoom.storeSelectedFlights.outbound))
    setSessionStorage("InboundFlights", JSON.stringify(flightRoom.storeSelectedFlights.inbound))
    // setIsRender(false)
    if (document.body.clientWidth < 1025 && document.body.clientWidth > 769) {
      dispatch(setLoading(true))
      window.scrollTo(0, 700);
      dispatch(setLoading(false))
    } else if (document.body.clientWidth < 769 && document.body.clientWidth > 426) {
      dispatch(setLoading(true))
      setTimeout(() => {
        window.scrollTo({
          top: 250,
          // behavior: "smooth",
        });
        dispatch(setLoading(false))
      }, 1000);
    } else if (document.body.clientWidth < 426 && document.body.clientWidth > 1) {
      dispatch(setLoading(true))
      setTimeout(() => {
        window.scrollTo({
          top: 350,
        });
        dispatch(setLoading(false))
      }, 1000);
    } else {
      setTimeout(() => {
        window.scrollTo(0, 750);
      }, 1000);
    }

    dispatch(setFlightRoomState({
      ...flightRoom,
      confirmSelection: {
        flights: false,
        room: false,
      },
      selectedFlights: !isCustomizeButtonClick ? { inbound: [], outbound: [] } : flightRoom.selectedFlights,
      storeSelectedFlights: flightRoom.selectedFlights.outbound.length > 0 ? flightRoom.selectedFlights : holiday.selectedFlight,
      isNewFlightSelected: {
        ...flightRoom.isNewFlightSelected,
        flights: false,
        room: false
      },
    }))
    setOnChangeMultiRoom(flightRoom.multiRooms);
    setShowButtons(true);
    dispatch(
      setDialogState({
        ...dialogState,
        customiseModal: true,
      })
    );
    setActiveIndex(0);
    setExpanded(2);
    // window.scrollTo(0, 750);
  };

  const handleOpenHotelOnMap = () => {
    dispatch(
      setFlightRoomState({
        ...flightRoom,
        callHolidaySummaryApi: false,
      }))
    setSessionStorage("OutboundFlights", JSON.stringify(flightRoom.storeSelectedFlights.outbound))
    setSessionStorage("InboundFlights", JSON.stringify(flightRoom.storeSelectedFlights.inbound))
    setSessionStorage("previous", "true")

    if (getSessionStorage("nextButtonClicked") === "true") {
      setSessionStorage("nextAndSelectedFlights", "true")
    } else {
      setSessionStorage("nextAndSelectedFlights", "false")
    }

    setSessionStorage("OutboundFlights", JSON.stringify(flightRoom.storeSelectedFlights.outbound))
    setSessionStorage("InboundFlights", JSON.stringify(flightRoom.storeSelectedFlights.inbound))

    navigate(`/holiday-summary/${session_id}/${[flightRoom?.hotel_id]}${window.location.search}`);
    dispatch(setHotelId([flightRoom?.hotel_id]));
    dispatch(setDialogState({ ...dialogState, mapDialog: true }));
  };

  const fetchHolidayDetail = async (flightRoom: any, loader?: boolean) => {
    if (loader) {
      dispatch(setLoading(true));
    }

    const body = [
      {
        psw_result_id: flightRoom?.psw_result_id,
        roomids: flightRoom?.roomids,
        "packageSearchId": flightRoom?.packageSearchId || "",
        holidayDetailLogId: flightRoom.holidayDetailLogId
      },
    ];
    let res = await dispatch(fetchAsyncHolidayPackageDetails(body));
    res = res.payload;
    dispatch(setLoading(false))
    return res;
  };

  const fetchCarasoulData = async () => {
    const siteID = getSiteID();
    const apiUrl = `${process.env.REACT_APP_PUBLIC_STATIC_URL}why?siteId=${siteID}`;
    const response = await getApi(apiUrl)

    if (response?.length > 0) {
      dispatch(setSliderContent(response))
    } else {
      dispatch(setSliderContent([]))
    }
  }
  const ArrivalLocation =
  flightRoom?.searchCriteria?.destination &&
  flightRoom?.searchCriteria?.destination['Display Name #1']?.split(', ')[0];

  useEffect(() => {
    if (queryData.hotelId && uatRedirect && Object.keys(holidayPackage)?.length === 0) {
      dispatch(setLottieLoading(true))
      navigate(`/holiday-search${window.location.search}&UAT=0`)
      setTimeout(() => {
        dispatch(setLottieLoading(false))
      }, 2000);
    }
    else if (queryData.destinationId == 0) {
      dispatch(setLottieLoading(true))
      navigate('/holiday-search')
      dispatch(setLottieLoading(false))
    }
  }, [uatRedirect, queryData])

  const hotelDetailsComponent = () => {
    return (
      <Box
        order={{ xs: 2, sm: 5 }}
        id="tHeight"
        className={hide === true ? "active" : ""}
        sx={{
          filter: showButtons ? 'blur(15px)' : 'blur(0)',
          width: { md: "35%", xs: "100%" },
          right: { md: "0" },
          display: { md: 'block', xs: showButtons ? 'none' : '' },
          ml: !showButtons ? "auto" : '',
          // mb: "20px"
        }}
        mt={{ md: "0", xs: "16px" }}
      >
        <Divider
          sx={{
            mt: "40px",
            mb: "32px",
            display: 'none',
            width: "100%",
          }}
        />
        <Box
          // ref={sourceDivRef}
          ml={{ lg: "auto", xs: "0px" }}
          className="right-accordion"
          sx={{
            position: "sticky",
            top: 0,
            ".title-ac": {
              background: "#f9f9f9 !important",
            },
          }}
        >
          {/* {stepperState === 0 && <HolidayCard />} */}
          {stepperState === 1 && (
            <>
            <Box m="0 0 22px">
                <PricingCard session_id={session_id} showButtons={showButtons} showButtonsAndFirstComponent={showButtonsAndFirstComponent} />
              </Box>
              <Box
                sx={{
                  "& .price-accordian": {
                    width: "100% !important",
                    maxWidth: "100% !important",
                    position: "relative",
                    zIndex: "1",
                  },
                }}
              >
                <Box
                  m="0 0 22px"
                  sx={{
                    "& .MuiAccordionSummary-expandIconWrapper": {
                      // display: expanded == 1 ? 'none' : 'flex'
                      display: "none",
                    },
                    '& .MuiAccordionDetails-root': {
                      paddingTop: '0',
                      padding: { sm: '0px 16px 16px', xs: "0px 8px 8px" },
                    },
                    '& .MuiAccordionSummary-root': {
                      cursor: 'text !important'
                    },
                    '& .MuiAccordionSummary-content ': {
                      // margin: '16px 0',
                      display: "flex",

                      // fontSize: { sm: "26px", xs: "24px" }
                      width: "100%"
                    },


                  }}
                >
                  <ItemAccordion
                    Label={`${ArrivalLocation} Holiday Summary`}
                    setHide={setHide}
                    onChange={handleOpenAccordion(1)}
                    expanded={[0, 1, null].includes(expanded)}
                    fullWidth={false}
                  >
                    <HotelDetails showButtons={showButtons} showButtonsAndFirstComponent={showButtonsAndFirstComponent} />
                  </ItemAccordion>
                </Box>

              </Box>
              <Box sx={{ display: "none" }}>
                {!isCustomizeButtonClick &&
                  <Suspense fallback={<div>Loading...</div>}>
                    <FlightDetails
                      isDirect={isDirect}
                      setCurrentTab={setCurrentTab}
                      currentTab={currentTab}
                      setDisabledFlightTabsInbound={setDisabledFlightTabsInbound}
                      disabledFlightTabsInbound={disabledFlightTabsInbound}
                      setDisableButton={setDisableButton}
                      expanded={activeIndex}
                    />
                  </Suspense>
                }
              </Box>
              <Box
                mt='10px'
                // display={{ sm: "none", xs: "flex" }}
                width={{ sm: '100%', xs: "100%" }}
                sx={{
                  alignSelf: "flex-start",
                  '& .MuiButtonBase-root': {
                    background: 'transparent',
                    border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor}` : `1px solid ${colors.basics.primary}`,
                    color: isPlataniumHotel ? `${colors.basics.plataniumGreenColor}` : `${colors.basics.primary}`,
                    '&:hover': {
                      background: 'transparent',
                      border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor}` : `1px solid ${colors.basics.primary}`,
                      color: isPlataniumHotel ? `${colors.basics.plataniumGreenColor}` : `${colors.basics.primary}`,
                    }
                  }
                }}>
                <Button sx={{
                  width: { sm: '100%', xs: "100%" },
                  // marginTop: { sm: '0', xs: "10px" },
                }} className='book-button btn-olb-passenger-details' onClick={handleBook}>
                  Passenger Details
                </Button>
              </Box>
              <Box
                sx={{
                  '& .MuiButtonBase-root': {
                    background: colors.grey[30],
                    '&:hover': {
                      background: colors.grey[30]
                    }
                  }
                }} className="email-itinearary">
                <Button
                className="btn-olb-email-itinerary"
                  id="email-itinerary-btn"
                  sx={{
                    width: "100%",
                    mt: "20px",
                    display: "flex",
                  }}
                  onClick={() => {
                    dispatch(
                      setDialogState({
                        ...dialogState,
                        emailItinerary: true,
                      })
                    );
                  }}
                // variant="outlined"
                >
                  Email Itinerary
                </Button>
              </Box>
              {offerDetails.length > 0 && <Box sx={{ background: "#DDDDDD", padding: { sm: "20px ", xs: '15px' }, mt: "20px", mb: { md: 0, xs: "20px" }, filter: showButtons ? 'blur(15px) !important' : 'blur(0) !important' }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "& .MuiSvgIcon-root": {
                      color: colors.basics.primary,
                    },
                    borderBottom: '1px solid #212529',
                    width: 'fit-content',

                  }}

                >
                  <Box sx={{
                    marginRight: "5px", '& i': {
                      fontSize: "26px",
                      color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,

                    }
                  }}>
                    {/* <SellIcon /> */}
                    <i className="fa fa-tag" />
                  </Box>
                  <Box sx={{}} >
                    {" "}
                    <Typography
                      variant="h1"
                      lineHeight="43.76px"
                      fontSize="26px"
                      fontWeight='500'
                    >
                      Offers & Deals
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{
                  padding: "10px 20px 0px 30px",
                  "& .package-card-chip": {
                    background: isPlataniumHotel ? colors.basics.plataniumGreenColor + "!important" : colors.basics.primary
                  },
                  "& .MuiChip-root": {
                    background: isPlataniumHotel ? colors.basics.plataniumGreenColor + "!important" : colors.basics.primary
                  }
                }}>
                  <OfferDetails data={offerDetails} />
                </Box>
              </Box>}

            </>
          )}
        </Box>
      </Box >
    )
  }

  const newSearch = async () => {
    await setBasketId(null)
    await clearSessionValues()
    await removeSessionStorage("basketId")
    await removeSessionStorage("search")
    await dispatch(setHolidayPackage({}))
    await dispatch(setFilteredHolidayPackage({}))
    await dispatch(setFilterState({
      holidayType: [],
      starRating: [],
      hotelName: '',
      price: [],
      sorting: 'Popularity',
      regionType: []
    }))
    await dispatch(setFlightRoomState({
      ...flightRoom,
      psw_result_id: null,
      searchCriteria: {
        departure: {
          depart_airport_name: "London All Airports",
          departure_airport: "LON",
          full_name: "London All Airports LON",
        },
        destination: null,
        date: [
          dayjs().add(7, "days").toISOString(),
          dayjs().add(14, "days").toISOString(),
        ],
        child_age: {},
        guest: [
          {
            room: 1,
            adults: 2,
            children: [],
            infants: [],
          },
        ],
        class: "Economy",
        night: dayjs().add(14, "days").diff(dayjs().add(7, "days"), "day"),
        adults: 2,
        childrens: 0,
        infants: 0,
      },
    }
    ))
    setTimeout(() => {
      navigate("/")
    }, 10)
  }

  const stickyCustomizeButtons = (isSticky: boolean) => {
    let stickyCss: React.CSSProperties = isSticky ? {
      position: "sticky",
      bottom: 0,
      backgroundColor: "#F9F9F9",
      // height: "60px",
      width: "100%",
      padding: "10px",
      zIndex: 999,
      boxShadow: windowWidth < 600 ? "rgba(0, 0, 0, 0.24) 0px 3px 8px" : "none",
    } : {
      display: windowWidth > 838 ? "flex" : "block",
      width: "100%",
    }
    return (
      <Box sx={{ ...stickyCss }}>
        <Box sx={{
            display: { md: 'none', xs: "flex" },
            marginBottom: windowWidth <= 840 ? "10px" : "",
            textAlign: windowWidth <= 840 ? "center" : "center",
            marginRight: windowWidth <= 840 ? "0" : "auto",
            width: '100%',
            alignItems: "center"
        }} >
          <Typography variant="h1" fontWeight={500} width={'100%'} fontSize={{ sm: "26px", xs: '18px' }}>Customise Your Holiday</Typography>
        </Box>
        <Box
          sx={{
            display: { sm: "flex", xs: "grid" },
            gridTemplateColumns: windowWidth >= 374 ? activeIndex === 0 ? "repeat(2, 1fr)" : "repeat(3, 1fr)" : "repeat(1, 1fr)",
            gap: "10px",
            justifyContent: "center",
            marginBottom: windowWidth <= 840 ? "10px" : "",
            textAlign: windowWidth <= 840 ? "center" : "left",
            marginRight: windowWidth <= 840 ? "0" : "auto",
            order: 2, width: '100%',
            '& .MuiButton-root': {
              padding: { sm: "14px 23px", xs: "10px 5px" },
              fontSize: { sm: '16px', xs: "14px" },

            },
            "& #confirm-button": {
              "&:hover": {
                backgroundColor:
                  isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                color: colors.basics.white,
                border: "none"
              },
            },
            "& #cancel-btn": {
              color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
              border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor}` : `1px solid ${colors.basics.primary}`,
              "&:hover": {
                backgroundColor: "transparent",
                color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
              },
            },
            "& #prev-btn": {
              "&:hover": {
                backgroundColor: colors.grey["30"],
                color: colors.basics.white,
                border: "none"
              },
            }
          }}
        >
          {(activeIndex === 1 || activeIndex === 2) && <Box sx={{
            alignItems: 'center',
            // flex: 1,
            display: 'flex',
            order: '1',
            '& .MuiButton-root': {
              padding: { sm: "14px 23px", xs: "5px 23px" },
              fontSize: { sm: '16px', xs: "14px" },
            },

          }}>
            <Button
              id="cancel-btn"
              onClick={() => showPreviousComponent(0)}
              variant="outlined"
              sx={{ width: { xs: "100%", sm: "138px" } }}
            >
              Cancel
            </Button>
          </Box>}
          <Box sx={{
            // flex: 1,
            alignItems: 'center',
            display: 'flex',
            order: '2',
            '& .MuiButton-root': {
              padding: { sm: "14px 23px", xs: "5px 23px" },
              fontSize: { sm: '16px', xs: "14px" },
            },
          }}>
            <Button
              id={activeIndex === 0 ? "cancel-btn" : "prev-btn"}
              onClick={() => showPreviousComponent(activeIndex)}
              variant="outlined"
              sx={{
                background: activeIndex === 0 ? "" : colors.grey["30"],
                color: activeIndex === 0 ? "" : colors.basics.white,
                border: activeIndex === 0 ? "" : "none",
                width: { xs: "100%", sm: "180px" },
                alignItems: 'center',
                whiteSpace: "nowrap"
              }}
            >
              {activeIndex === 0 ? "Cancel" : <>  <DoubleArrowIcon width={12} height={12} style={{ marginRight: "5px", rotate: "180deg" }} /> Flights</> }
            </Button>
          </Box>
          <Box sx={{
            display: { md: 'flex', xs: "none" },
            marginBottom: windowWidth <= 840 ? "10px" : "",
            textAlign: windowWidth <= 840 ? "center" : "center",
            marginRight: windowWidth <= 840 ? "0" : "auto",
            order: 2,
            width: '100%',
            alignItems: "center"

          }} >
            <Typography variant="h1" fontWeight={500} width={'100%'} fontSize={{ sm: "26px", xs: '18px' }}> Customise Your Holiday</Typography>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: { sm: "center" },
              order: 3,

            }}
          >

            <Box sx={{
              // flex: 1,
              order: 3,
              width: "100%",
            }}>
              <Button
                id="confirm-button"
                onClick={() => showNextComponent()}
                variant="outlined"
                sx={{
                  width: { xs: "100%", sm: "180px" },
                  backgroundColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                  border: "none",
                  color: colors.basics.white,
                  display: 'flex',
                  alignItems: 'center'
                }}
                className={activeIndex === 1 ? "btn-olb-confirm-holiday" : "btn-olb-room-board"}
              >
                {activeIndex === 1? "Confirm" :  "Room & Board" }<DoubleArrowIcon width={16} height={16} style={{ marginLeft: "5px", paddingTop: "3px" }} />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box >
    )
  }

  useEffect(() => {
    // This will trigger when isDataLoaded is false and lottieLoading is falsy (i.e., the "else" case)
    if (!isDataLoaded && !lottieLoading && apiCallPageLaod) {
      callYourAPI();
    }
  }, [isDataLoaded, lottieLoading]);

  const callYourAPI = async () => {
    const now = new Date();

    const padToTwoDigits = (num: any) => String(num).padStart(2, '0');
    const padToThreeDigits = (num: any) => String(num).padStart(3, '0');

    const currentDateTime = `${now.getFullYear()}-${padToTwoDigits(now.getMonth() + 1)}-${padToTwoDigits(now.getDate())} ` +
      `${padToTwoDigits(now.getHours())}:${padToTwoDigits(now.getMinutes())}:${padToTwoDigits(now.getSeconds())}.` +
      `${padToThreeDigits(now.getMilliseconds())}`;

    const search = window.location.href
    try {
      const body = {
        // notFoundLogId:,
        url: String(search),
        response: "Unfortunately, no results were returned for the package you have searched.",
        source: String(branchCode),
        logDate: currentDateTime,
        method: "POST"
      }
      const res = await API.post(API_METHOD.Others.SAVE_NOT_FOUND_LOGS, body)

    } catch (error) {
      console.error('API error:', error);
    }
  };

  return (
    isDataLoaded ? <Box sx={{
      "& ::selection": {
        background: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary
      }
    }} position="relative" >
      <Box position="relative" mb={"10px"}>
        <Box
          sx={{
            position: "relative",
            "& .slick-prev": {
              left: "28px",
              width: "40px",
              height: "40px",
              margin: "auto",
              borderRadius: "50%",
              backgroundColor: "rgba(33,37,41,.9)",
              zIndex: 1,
            },
            "& .slick-arrow": {
              width: { md: "40px", sm: "35px", xs: "30px" },
              height: { md: "40px", sm: "35px", xs: "30px" },
              padding: 0,
              display: "flex!important",
              alignItems: "center",
              justifyContent: "center",
              "&:before": {
                display: "none",
              },
              "& svg": {
                width: "6px",
                "& path": {
                  fill: "#fff",
                },
              },
            },
            "& .slick-next": {
              right: "28px",
              borderRadius: "50%",
              backgroundColor: "rgba(33,37,41,.9)",
              "&:before": {
                display: "none",
              },
            },
          }}
        >
          <Slider {...settings}>
            {hotelImages?.map((item: any, index: number) => {
              return (
                item?.VendorImage?.id?.length > 4 && (
                  <Box key={index}>
                    <Box
                      sx={{
                        width: "100%",
                        height: {
                          xl: "600px",
                          lg: "500px",
                          md: "400px",
                          sm: "300px",
                          xs: "200px",
                        },
                        overflow: "hidden",
                        "& img": {
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          minHeight: "145px",
                          objectPosition: "top",
                        },
                      }}
                    >
                      <Image
                        src={item?.VendorImage?.printed_image_url}
                        alt="img"
                      />
                    </Box>
                    <Typography
                      variant="h1"
                      position="absolute"
                      color={colors.basics.secondary}
                      width="100%"
                      display="flex"
                      alignItems="center"
                      bottom="5px"
                      py={1}
                      lineHeight={1.2}
                      pl={{ xs: "10px", sm: "30px" }}
                      fontSize={{ xs: "12px", sm: "20px" }}
                      sx={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
                    >
                      {item?.VendorImage?.image_label}
                    </Typography>
                  </Box>
                )
              );
            })}
          </Slider>
        </Box>
      </Box>
      <CustomContainer>
        <Box
          sx={{
            "& .active .right-accordion .MuiBox-root .MuiAccordionSummary-root":
            {
              marginLeft: { lg: "auto", xs: "0px" },
              width: { lg: "35%", xs: "100%" },
              transition: "all smooth 0.5s",
            },
            ".MuiPaper-rounded": {
              bgcolor: "transparent",
            },
            "& .active.MuiBox-root": {
              width: "100%",
              "& .right-accordion": {
                width: "100%",
                "& .price-summary": {
                  marginLeft: { lg: "auto", xs: "0px" },
                  width: { lg: "35%", xs: "100%" },
                  marginTop: "22px",
                },
                "& .MuiAccordionSummary-root": {
                  position: "relative",
                  zIndex: 999,
                  top: 0,
                  backgroundColor: "transparent",
                },
                "& .email-itinearary": {
                  marginLeft: { lg: "auto", xs: "0px" },
                  width: { lg: "35%", xs: "100%" },
                  marginTop: "22px",
                },
              },
              "& .MuiAccordionDetails-root": {
                width: "100%",
                // p: '8px 0px 16px',
              },
              "& .Mui-expanded": {
                marginLeft: "auto",
                background: "transparent",
              },

            },
            // "& button:hover": {
            //   color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary
            // },
            "& svg": {
              '& circle:first-of-type': {
                fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : ""
              },
              '& path': {
                fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : ""
              },
              fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : ""
            },
          }}
        >
          <Button
            disableRipple
            sx={{
              fontSize: { sm: "16px", xs: "14px" },
              p: 0,
              "&:hover": {
                color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                background: "transparent"

              },
              height: "fit-content",
              margin: "8px 0 ",
              color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
              background: "transparent"
            }}
            onClick={() => {
              navigate(`/holiday-search${window.location.search}`);
            }}
          >
            Back to results
          </Button>
          <StepperCard />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              position: "relative",
              minHeight: "800px",
              margin: { xs: "20px 0", lg: "40px 0 " }
            }}
          >
            <Box sx={{ width: { md: '65%', xs: "100%" }, transition: 'all 0.3s ease' }} >
              {!showButtons && <Box display={"flex"} flexDirection={"column"} sx={{
                width: { md: "calc(100% - 50px)", xs: '100%' }, position: { md: 'relative', xs: showButtons ? 'absolute' : 'relative' },
                left: "0", zIndex: '0'
              }}>
                <Box order={1}>
                  <Grid container >
                    <Grid mt={{ xs: 1, lg: 0 }} xs={12} order={{ lg: 1, xs: 2 }} item lg={isPlataniumHotel ? 9 : 10}>
                      <Box sx={{ mr: "10px " }}>
                        <Typography
                          variant="h1"
                          lineHeight="1.2"
                          textTransform={"capitalize"}
                          fontSize={{ sm: "26px", xs: '24px' }}
                        >
                          {capitalizeFirstLetterEachWords(isEmpty(hotelDetails?.HotelInfo?.Vendor?.vendor_name))}
                        </Typography>

                        <Box mt="5px" mb="10px" display={"block"}>
                          <Typography
                            variant="body1"
                            fontWeight={500}
                            sx={{ color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary }}
                          >
                            {hotelDetails?.HotelInfo?.Country?.country_name}
                          </Typography>
                        </Box>


                        <Box mt="10px">
                          <Typography
                            variant="body1"
                            fontWeight={500}
                            lineHeight="1.2"
                            fontSize={{ xs: "16px !important", sm: "20px !important" }}
                            sx={{ fontFamily: "El Messiri" }}
                          >
                            <span
                              style={{ textTransform: "capitalize" }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  RoomMealWrapper(flightRoom?.multiRooms[0]?.meal_name.toLowerCase()),
                              }}
                            />
                            {` ${nights
                              } nights from ${signForPricing + addCommasToNumber(
                                Number(packagePricePerPerson)
                              )} per person including flights
                              `}
                            {/* ${flightRoom?.selectedTransfer ? "and transfers" : ""} */}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid display={"flex"} flexDirection={{ xs: "row", lg: "column" }} alignItems={{ xs: "center", lg: "unset" }} xs={12} order={{ lg: 2, xs: 1 }} item lg={isPlataniumHotel ? 3 : 2}>
                      <Box
                        sx={{
                          "& .MuiRating-root .MuiRating-iconFilled": {
                            color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary
                          },
                          "& .MuiRating-iconHover": {
                            color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary
                          },
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        <Rating
                          sx={{ fontSize: "18px", marginLeft: { xs: 0, lg: "auto" } }}
                          value={rating}
                          precision={0.5}
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 0.55 }}
                              fontSize="inherit"
                            />
                          }
                          readOnly={true}
                        />
                      </Box>

                      {hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel && <Box mt={{ xs: "unset", lg: "auto" }} hidden={Number(hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel) === 0}>
                        <Image
                          src={platinumLogo}
                          alt="logo"
                          // height="40px"
                          // width="100%"
                          style={{
                            width: windowWidth > 1200 ? "200px" : windowWidth < 600 ? "150px" : "186px",
                            height: windowWidth > 1200 ? "62px" : windowWidth < 600 ? "auto" : "58px",
                            // marginBottom: "10px",
                            display: "flex", marginLeft: "auto"
                          }}
                        />
                      </Box>}
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      mt: "10px",
                      display: "flex",
                      justifyContent: { xs: "space-between", md: "flex-start" },
                      flex: { xs: "0 0 100%" },

                      '& .MuiButton-root': {
                        // marginTop: '6px',
                        background: colors.basics.white,
                        height: '37px',
                        borderColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary

                      },
                    }}
                  >
                    <Button
                      onClick={handleOpenHotelOnMap}
                      variant="outlined"
                      disableRipple
                      endIcon={<LocationIcon fill={isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary} />}
                      sx={{
                        "&:hover": {
                          color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                          background: colors.basics.white,
                          borderColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary

                        },
                        p: "7px 11px",
                        fontSize: { sm: "16px", xs: "14px" },
                        whiteSpace: "nowrap", color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary
                      }}
                    >
                      Show on map
                    </Button>
                  </Box>
                </Box>
                {isEmpty(hotelDetails?.HotelInfo?.HotelContent?.accomIntro) !== "" && <Box
                  order={2}
                  pt={{ xs: "16px", sm: "20px" }}
                  ml={{ sm: "0", xs: "0px" }}
                  display="flex"
                  flexDirection="column"
                >
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "El Messiri", fontSize: { sm: "26px", xs: "24px" } }}
                    fontWeight={500}
                    lineHeight="20.8px"
                  >
                    Overview
                  </Typography>
                  <Typography
                    id="overview-para"
                    variant="body2"
                    fontSize={{ xl: '16px !important', xs: "14px !important" }}
                    fontWeight={400}
                    lineHeight="1.5"
                    m="10px 0 0"
                    sx={{
                      '& p:first-of-type': {
                        display: "block"
                      },
                      "& p": {
                        mb: 1.2,
                        display: { xs: isVisibleOverviewData ? "block" : "none", sm: "block" }
                      },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `${hotelDetails?.HotelInfo?.HotelContent?.accomIntro || ""
                        }
                  `,
                    }}
                  />
                  {totalOverviewParagraph > 1 && windowWidth < 600 && <span onClick={() => {
                    setTimeout(() => {
                      window.scrollTo({
                        top: scrollToFirstPara,
                        behavior: 'smooth',
                      })
                    }, 1)
                    setIsVisibleOverviewData(!isVisibleOverviewData)
                  }} style={{ fontSize: "12px", color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.red, cursor: "pointer" }}>{isVisibleOverviewData ? "Show less.." : "Show more.."}</span>}
                </Box>}
                <Box mt={{ xs: "5px", sm: "auto" }} order={{ xs: 5, sm: 4 }} display={showButtons ? 'none' : 'flex'}>
                  {renderCarasoulComponent}
                </Box>
                {flightRoom.hotel_id && <Box order={{ xs: 3, sm: 3 }} my={{ xs: "5px", sm: "auto" }} display={showButtons ? 'none' : 'flex'}>
                  <StaticAccordion apiCallAccordianData={apiCallAccordianData}
                    setApicallAccordianData={setApicallAccordianData} />
                </Box>}
                {windowWidth <= 991 && hotelDetailsComponent()}
              </Box>
              }
            </Box>
            <Dialog
              open={dialogState.customiseModal}
              // onClose={() => dispatch(setDialogState({ ...dialogState, customiseModal: true }))}
              fullWidth
              hideBackdrop={false}
              sx={{
                '& .MuiDialog-paper': {
                  border: 'none',
                  borderRadius: 2,
                  maxWidth: "1340px",
                  margin: { xs: "0", md: "32px" },
                  width: { xs: `calc(100% - 30px)`, md: `calc(100% - 64px)` },
                  padding: { xs: "10px", md: "20px" },
                },
                '& .MuiAccordion-root ': {
                  background: "transparent"
                }
              }}
            >
            {showButtons && (
              <CustomizeHolidaySummary
                disableButton={disableButton}
                guest={guest}
                nights={nights}
                holiday={holiday}
                flightRoom={flightRoom}
                checkOutDate={checkOutDate}
                hotelName={hotelName}
                activeIndex={activeIndex}
              />
            )}
            {showButtons && <Box position={"relative"} width={"100%"}>
              <Box sx={{ width: '100%' }}>
                {showButtons && (
                  <Box
                    ref={getCustomizeHeight}
                    sx={{
                      border: "1px solid #999999",
                      borderRadius: "5px",
                      background: "#F9F9F9",
                      padding: { sm: "20px", xs: "0" },
                      position: "relative",
                      zIndex: "999",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      // mb: "20px"
                      // "& button": {
                      //   backgroundColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                      //   border: isPlataniumHotel ? "none" : "auto",
                      //   color: isPlataniumHotel ? colors.basics.white : "auto"
                      // }

                    }}
                  >
                    <Box >
                      <Box
                        sx={{
                          display:
                            showButtons && activeIndex == null
                              ? "none"
                              : "flex",
                          flexDirection: windowWidth >= 840 ? "row" : "column",
                          padding: { xs: 0, sm: "0 10px 0 10px" },
                          justifyContent: windowWidth <= 840 ? "center" : "space-between",
                          // padding: { md: "10px 10px 0 10px", xs: "0" },
                          flexWrap: { xs: "wrap", md: "nowrap" },
                          alignItems: windowWidth <= 840 ? "center" : "",
                          gap: 1,
                          width: "100%"
                        }}
                      >
                        {windowWidth > 599 && stickyCustomizeButtons(false)}

                      </Box>
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                          // order: { xs: "3", sm: "2 !important" },
                          width: { sm: "100%", xs: "100%" },
                          padding: { sm: '30px 0 10px 0', xs: "15px 0px 0 0px" },
                        }}
                      >
                        <Box sx={{ display: { xs: "none", sm: "flex" }, width: "100%", }}>
                          <HorizontalLinearStepper />
                        </Box>
                        <Box sx={{ display: { xs: "flex", sm: "none" }, }}>
                          <HorizontalLinearStepperMobile />
                        </Box>
                      </Box>
                    </Box>

                    <Suspense fallback={<div>Loading...</div>}>
                      {components.map((component, index) => (
                        <div
                          key={index}
                          style={{
                            display: index === activeIndex ? "block" : "none",
                          }}
                        >
                          {component === "flightDetails" && (
                            <Suspense fallback={<div>Loading...</div>}>
                              <FlightDetails
                                isDirect={isDirect}
                                setCurrentTab={setCurrentTab}
                                currentTab={currentTab}
                                setDisabledFlightTabsInbound={setDisabledFlightTabsInbound}
                                disabledFlightTabsInbound={disabledFlightTabsInbound}
                                setDisableButton={setDisableButton}
                                expanded={activeIndex}
                              />
                            </Suspense>
                          )}
                          {component === "roomAvailable" && (
                            <RoomAvailable
                              roomPrice={roomPrice}
                              setRoomPrice={setRoomPrice}
                              saveRoom={saveRoom}
                              showPreviousComponent={showPreviousComponent}
                              setRoomDisableButton={setRoomDisableButton}
                              setDisableButton={setDisableButton}
                              roomDisableButton={roomDisableButton}
                              accordionOpen={setExpanded}
                              onChangeMultiRoom={onChangeMultiRoom}
                              setOnChangeMultiRooms={setOnChangeMultiRoom}
                              setShowButtons={setShowButtons}
                              setExpanded={setActiveIndex}
                              saveBasket={saveBasket}
                            />
                          )}
                        </div>
                      ))}
                    </Suspense>
                    {windowWidth < 600 && stickyCustomizeButtons(true)}
                     <Box
                      sx={{
                        display: { sm: "flex", xs: "none" },
                        gridTemplateColumns: windowWidth >= 374 ? activeIndex === 0 ? "repeat(2, 1fr)" : "repeat(3, 1fr)" : "repeat(1, 1fr)",
                        gap: "10px",
                        justifyContent: "center",
                        marginBottom: windowWidth <= 840 ? "10px" : "",
                        textAlign: windowWidth <= 840 ? "center" : "left",
                        marginRight: windowWidth <= 840 ? "0" : "auto",
                        order: 2, width: '100%',
                        '& .MuiButton-root': {
                          padding: { sm: "14px 23px", xs: "10px 5px" },
                          fontSize: { sm: '16px', xs: "14px" },

                        },
                        "& #confirm-button": {
                          "&:hover": {
                            backgroundColor:
                              isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                            color: colors.basics.white,
                            border: "none"
                          },
                        },
                        "& #cancel-btn": {
                          color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                          border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor}` : `1px solid ${colors.basics.primary}`,
                          "&:hover": {
                            backgroundColor: "transparent",
                            color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                          },
                        },
                        "& #prev-btn": {
                          "&:hover": {
                            backgroundColor: colors.grey["30"],
                            color: colors.basics.white,
                            border: "none"
                          },
                        }
                      }}
                    >
                      <Box sx={{ display: 'flex', width: "100%", justifyContent:'space-between', gap: '10px' }}>
                        <Box sx={{display:'flex', gap:'10px'}}>
                      {(activeIndex === 1 || activeIndex === 2) && <Box sx={{
                        alignItems: 'center',
                        // flex: 1,
                        display: 'flex',
                        order: '1',
                        '& .MuiButton-root': {
                          padding: { sm: "14px 23px", xs: "5px 23px" },
                          fontSize: { sm: '16px', xs: "14px" },
                        },

                      }}>
                        <Button
                          id="cancel-btn"
                          onClick={() => showPreviousComponent(0)}
                          variant="outlined"
                          sx={{ width: { xs: "100%", sm: "138px" }, alignItems: "center" }}
                        >
                          Cancel
                        </Button>
                      </Box>}
                      <Box sx={{
                        // flex: 1,
                        alignItems: 'center',
                        display: 'flex',
                        order: '2',
                        '& .MuiButton-root': {
                          padding: { sm: "14px 23px", xs: "5px 23px" },
                          fontSize: { sm: '16px', xs: "14px" },
                        },
                      }}>
                        <Button
                          id={activeIndex === 0 ? "cancel-btn" : "prev-btn"}
                          onClick={() => showPreviousComponent(activeIndex)}
                          variant="outlined"
                          sx={{
                            background: activeIndex === 0 ? "" : colors.grey["30"],
                            color: activeIndex === 0 ? "" : colors.basics.white,
                            border: activeIndex === 0 ? "" : "none",
                            width: { xs: "100%", sm: "180px" },
                            alignItems: 'baseline'
                          }}
                        >
                          {activeIndex === 0 ? "Cancel" : <>  <DoubleArrowIcon width={12} height={12} style={{ marginRight: "5px", rotate: "180deg" }} /> Flights</> }
                        </Button>
                      </Box>
                      </Box>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: { sm: "center" },
                          order: 3,

                        }}
                      >

                        <Box sx={{
                          // flex: 1,
                          order: 3,
                          display: 'flex',
                          justifyContent: 'end',
                          width: "100%",
                        }}>
                          <Button
                            id="confirm-button"
                            onClick={() => showNextComponent()}
                            variant="outlined"
                            sx={{
                              width: { xs: "100%", sm: "180px" },
                              backgroundColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                              border: "none",
                              color: colors.basics.white,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                            className={activeIndex === 1 ? "btn-olb-confirm-holiday" : "btn-olb-room-board"}
                          >
                            {activeIndex === 1? "Confirm" :  "Room & Board" }<DoubleArrowIcon width={16} height={16} style={{ marginLeft: "5px", paddingTop: "3px" }} />
                          </Button>
                        </Box>
                      </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>}
</Dialog>
            {!showButtons && windowWidth > 991 && hotelDetailsComponent()}
          </Box >
        </Box >
      </CustomContainer >
    </Box > : !lottieLoading ?
      <Box
        my={"120px"}
        sx={{
          width: "100%",
          // mt: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px"
        }}
      >
       <Typography
          variant='h1' textAlign='center' color={colors.basics.primary} >
          Unfortunately, no results were returned for the package you have searched.
        </Typography>
        <Typography
          variant='h1' textAlign='center' color={colors.basics.primary} >
          Please search again for alternatives.
        </Typography>
        <Button
          sx={{
            width: "fit-content"
          }}
          onClick={() => {
            newSearch()
          }}
        >
          New Search
        </Button>
      </Box >
      : <Box minHeight={500}></Box>
  );

}

export default HolidaySummary;
