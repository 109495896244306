export const DestinationList: DestinationListType[] = [
  {
    "Searc ID": 10982,
    airport_code: "BOS",
    "Display Name #1": "Boston",
  }, 
  {
    "Searc ID": 11366,
    airport_code: "ORD",
    "Display Name #1": "Chicago",
  },
  {
    "Searc ID": 31248,
    airport_code: "LIH",
    "Display Name #1": "Hawaii - Kauai, HI",
  },
  {
    "Searc ID": 31251,
    airport_code: "OGG",
    "Display Name #1": "Hawaii - Maui, HI",
  },
  {
    "Searc ID": 31238,
    airport_code: "KOA",
    "Display Name #1": "Hawaii - The Big Island, HI",
  },
  {
    "Searc ID": 31237,
    airport_code: "HNL",
    "Display Name #1": "Honolulu, HI",
  },
  {
    "Searc ID": 13863,
    airport_code: "LAS",
    "Display Name #1": "Las Vegas",
  }, 
  {
    "Searc ID": 13868,
    airport_code: "LAX",
    "Display Name #1": "Los Angeles, CA",
  }, 
  {
    "Searc ID": 19403,
    airport_code: "MIA",
    "Display Name #1": "Miami",
  },
  {
    "Searc ID": 10941,
    airport_code: "BNA",
    "Display Name #1": "Nashville, TN",
  }, 
  {
    "Searc ID": 15134,
    airport_code: "NYC",
    "Display Name #1": "New York",
  }, 
  // {
  //   "Searc ID": 15309,
  //   airport_code: "MCO",
  //   "Display Name #1": "Orlando",
  // },
  {
    "Searc ID": 15539,
    airport_code: "PHL",
    "Display Name #1": "Philadelphia",
  },
  {
    "Searc ID": 16836,
    airport_code: "SFO",
    "Display Name #1": "San Francisco, CA",
  },
  {
    "Searc ID": 16800,
    airport_code: "SEA",
    "Display Name #1": "Seattle",
  },
  {
    "Searc ID": 19373,
    airport_code: "IAD",
    "Display Name #1": "Washington, DC",
  }
];

// export const DestinationList: DestinationListType[] = [
//   {
//     "Searc ID": 10559,
//     airport_code: "AUH",
//     "Display Name #1": "Abu Dhabi, Middle East",
//   },
//   {
//     "Searc ID": 31376,
//     airport_code: "DXB",
//     "Display Name #1": "Ajman, Middle East",
//   },
//   {
//     "Searc ID": 10598,
//     airport_code: "ANU",
//     "Display Name #1": "Anguilla, Caribbean",
//   },
//   {
//     "Searc ID": 10430,
//     airport_code: "ANU",
//     "Display Name #1": "Antigua, Caribbean",
//   },
//   {
//     "Searc ID": 10553,
//     airport_code: "AUA",
//     "Display Name #1": "Aruba, Caribbean",
//   },
//   {
//     "Searc ID": 31302,
//     airport_code: "NAS",
//     "Display Name #1": "Bahamas, Caribbean",
//   },
//   {
//     "Searc ID": 11884,
//     airport_code: "DPS",
//     "Display Name #1": "Bali, Indonesia",
//   },
//   {
//     "Searc ID": 10877,
//     airport_code: "BKK",
//     "Display Name #1": "Bangkok, Thailand",
//   },
//   {
//     "Searc ID": 10785,
//     airport_code: "bgi",
//     "Display Name #1": "Barbados, Caribbean",
//   },
//   {
//     "Searc ID": 10714,
//     airport_code: "BDA",
//     "Display Name #1": "Bermuda, Caribbean",
//   },
//   {
//     "Searc ID": 10875,
//     airport_code: "BKI",
//     "Display Name #1": "Borneo, Malaysia",
//   },
//   // {
//   //   "Searc ID": 31631,
//   //   airport_code: "EIS",
//   //   "Display Name #1": "British Virgin Islands, Caribbean",
//   // },
//   {
//     "Searc ID": 11623,
//     airport_code: "CUN",
//     "Display Name #1": "Cancun & Caribbean Coast, Mexico",
//   },
//   {
//     "Searc ID": 11537,
//     airport_code: "CPT",
//     "Display Name #1": "Cape Town, South Africa",
//   },
//   {
//     "Searc ID": 11498,
//     airport_code: "CNX",
//     "Display Name #1": "Chiang Mai, Thailand",
//   },
//   // {
//   //   "Searc ID": 31540,
//   //   airport_code: "LIR",
//   //   "Display Name #1": "Costa Rica, Central America",
//   // },
//   {
//     "Searc ID": 11712,
//     airport_code: "DAD",
//     "Display Name #1": "Da Nang, Vietnam",
//   },
//   {
//     "Searc ID": 15805,
//     airport_code: "PUJ",
//     "Display Name #1": "Dominican Republic, Caribbean",
//   },
//   {
//     "Searc ID": 11945,
//     airport_code: "DXB",
//     "Display Name #1": "Dubai, Middle East",
//   },
//   {
//     "Searc ID": 17425,
//     airport_code: "KUL",
//     "Display Name #1": "East Coast and Kuantan, Malaysia",
//   },
//   {
//     "Searc ID": 31535,
//     airport_code: "DXB",
//     "Display Name #1": "Fujairah, Middle East",
//   },
//   {
//     "Searc ID": 12549,
//     airport_code: "GND",
//     "Display Name #1": "Grenada, Caribbean",
//   },
//   // {
//   //   "Searc ID": 12717,
//   //   airport_code: "HAV",
//   //   "Display Name #1": "Havana, Cuba",
//   // },
//   {
//     "Searc ID": 31296,
//     airport_code: "DAD",
//     "Display Name #1": "Hoi An, Vietnam",
//   },
//   {
//     "Searc ID": 12808,
//     airport_code: "HKG",
//     "Display Name #1": "Hong Kong, Asia",
//   },
//   {
//     "Searc ID": 12785,
//     airport_code: "BKK",
//     "Display Name #1": "Hua Hin and Cha Am, Thailand",
//   },
//   {
//     "Searc ID": 31636,
//     airport_code: "MBJ",
//     "Display Name #1": "Jamaica, Caribbean",
//   },
//   {
//     "Searc ID": 13298,
//     airport_code: "JNB",
//     "Display Name #1": "Johannesburg, South Africa",
//   },
//   {
//     "Searc ID": 31358,
//     airport_code: "USM",
//     "Display Name #1": "Khanom, Thailand",
//   },
//   {
//     "Searc ID": 31293,
//     airport_code: "HKT",
//     "Display Name #1": "Khao Lak, Thailand",
//   },
//   // {
//   //   "Searc ID": 31289,
//   //   airport_code: "BKK",
//   //   "Display Name #1": "Koh Chang, Thailand",
//   // },
//   {
//     "Searc ID": 31443,
//     airport_code: "usm",
//     "Display Name #1": "Koh Phangan, Thailand",
//   },
//   {
//     "Searc ID": 17902,
//     airport_code: "USM",
//     "Display Name #1": "Koh Samui, Thailand",
//   },
//   {
//     "Searc ID": 31445,
//     airport_code: "HKT",
//     "Display Name #1": "Koh Yao, Thailand",
//   },
//   {
//     "Searc ID": 13405,
//     airport_code: "KBV",
//     "Display Name #1": "Krabi, Thailand",
//   },
//   {
//     "Searc ID": 13769,
//     airport_code: "KUL",
//     "Display Name #1": "Kuala Lumpur, Malaysia",
//   },
//   {
//     "Searc ID": 13961,
//     airport_code: "LGK",
//     "Display Name #1": "Langkawi, Malaysia",
//   },
//   {
//     "Searc ID": 19381,
//     airport_code: "MLE",
//     "Display Name #1": "Maldives, Indian Ocean",
//   },
//   {
//     "Searc ID": 14672,
//     airport_code: "MRU",
//     "Display Name #1": "Mauritius, Indian Ocean",
//   },
//   {
//     "Searc ID": 14286,
//     airport_code: "MBA",
//     "Display Name #1": "Mombasa, Kenya",
//   },
//   {
//     "Searc ID": 30202,
//     airport_code: "CXR",
//     "Display Name #1": "Nha Trang, Vietnam",
//   },
//   {
//     "Searc ID": 14327,
//     airport_code: "MCT",
//     "Display Name #1": "Oman, Middle East",
//   },
//   {
//     "Searc ID": 32681,
//     airport_code: "KUL",
//     "Display Name #1": "Pangkor, Malaysia",
//   },
//   // {
//   //   "Searc ID": 15491,
//   //   airport_code: "PEN",
//   //   "Display Name #1": "Penang, Malaysia",
//   // },
//   {
//     "Searc ID": 31478,
//     airport_code: "SGN",
//     "Display Name #1": "Phan Thiet, Vietnam",
//   },
//   {
//     "Searc ID": 15550,
//     airport_code: "HKT",
//     "Display Name #1": "Phi Phi Islands, Thailand",
//   },
//   // {
//   //   "Searc ID": 15719,
//   //   airport_code: "SGN",
//   //   "Display Name #1": "Phu Quoc, Vietnam",
//   // },
//   {
//     "Searc ID": 19391,
//     airport_code: "HKT",
//     "Display Name #1": "Phuket, Thailand",
//   },
//   {
//     "Searc ID": 11866,
//     airport_code: "DOH",
//     "Display Name #1": "Qatar, Middle East",
//   },
//   {
//     "Searc ID": 16567,
//     airport_code: "DXB",
//     "Display Name #1": "Ras Al Khaimah, Middle East",
//   },
//   {
//     "Searc ID": 31356,
//     airport_code: "RUN",
//     "Display Name #1": "Reunion, Indian Ocean",
//   },
//   {
//     "Searc ID": 31542,
//     airport_code: "SEZ",
//     "Display Name #1": "Seychelles, Indian Ocean",
//   },
//   {
//     "Searc ID": 16907,
//     airport_code: "SIN",
//     "Display Name #1": "Singapore, Asia",
//   },
//   {
//     "Searc ID": 31543,
//     airport_code: "CMB",
//     "Display Name #1": "Sri Lanka, Indian Ocean",
//   },
//   {
//     "Searc ID": 16943,
//     airport_code: "SKB",
//     "Display Name #1": "St Kitts and Nevis, Caribbean",
//   },
//   {
//     "Searc ID": 16984,
//     airport_code: "UVF",
//     "Display Name #1": "St Lucia, Caribbean",
//   },
//   {
//     "Searc ID": 15102,
//     airport_code: "DUR",
//     "Display Name #1": "Sun City, South Africa",
//   },
//   {
//     "Searc ID": 17311,
//     airport_code: "TAB",
//     "Display Name #1": "Tobago, Caribbean",
//   },
//   // {
//   //   "Searc ID": 18068,
//   //   airport_code: "HAV",
//   //   "Display Name #1": "Varadero, Cuba",
//   // },
//   {
//     "Searc ID": 19235,
//     airport_code: "ZNZ",
//     "Display Name #1": "Zanzibar, Africa",
//   },
// ];
