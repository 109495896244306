import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';

export const resetPassword: any = createAsyncThunk(
    'resetPassword/resetPassword',
    async (res) => {
        const response = await API.post(API_METHOD.Misc.RESET_PASSWORD, res);
        return response.data;
    }
);