import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';

export const getFolderDetails: any = createAsyncThunk('getFolder/getFolderDetails', async (res) => {
  const response = await API.post(API_METHOD.Misc.GET_FOLDER_DETAILS_FULL_RQ, res);
  return response.data;
});
interface folderDetail {
  folderDetails: any;
}
const initialState: folderDetail = {
  folderDetails: {},
};
const getFolderDetailsToFolderSlice = createSlice({
  name: 'getFolder',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getFolderDetails.fulfilled]: (state, { payload }): void => {
      state.folderDetails = payload;
    },
  },
});

export default getFolderDetailsToFolderSlice.reducer;
