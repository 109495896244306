// export const generateAndMultiply = () => {
//   // Generate 8-digit number
//   const number = Math.floor(Math.random() * 100000000);

//   // Generate random number to multiply with
//   const randomMultiplier = Math.floor(Math.random() * 10) + 1;

//   // Multiply the two numbers together
//   const result = number * randomMultiplier;

//   // Return the result
//   return String(result);
// };

export const generateUniqueSessionId = () => {
  let number;
  let differentNumber;

  do {
    // Generate a 10-digit random number
    number = Math.floor(Math.random() * 9000000000) + 1000000000;

    // Generate a different 10-digit random number
    differentNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
  } while (differentNumber === number);

  // Generate a random percentage between 0 and 99
  const percentage = Math.floor(Math.random() * 100);

  // If the generated percentage is less than or equal to 1, return the original number
  if (percentage <= 1) {
    return number;
  } else {
    return differentNumber;
  }
};
