import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lottieLoading: false,
  lottieLoadingConfirm: false
};

const lottieLoaderSlice = createSlice({
  name: 'lottieLoaderSlice',
  initialState,
  reducers: {
    setLottieLoading(state, { payload }) {
      state.lottieLoading = payload;
    },
    setLottieLoadingConfirm(state, { payload }) {
      state.lottieLoadingConfirm = payload;
    },
  },
});

export const { setLottieLoading, setLottieLoadingConfirm } = lottieLoaderSlice.actions;
export default lottieLoaderSlice.reducer;
