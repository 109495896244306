import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { CustomContainer } from '../../components/CustomContainer';
import { StepperCard } from '../../components/StepperComponent';
import { colors } from '../../styles/colors';
import BillingDetails from './components/BillingDetails';
// import BookingDetails from './components/BookingDetails';
import GuestAccountDetails from './components/GuestAccountDetails';
// import PaymentDetails from './components/PaymentDetails';
// import { ReactComponent as Check } from '../../assets/icons/Check.svg';
// import { ReactComponent as ArrowDownBlack } from '../../assets/icons/ArrowDownBlack.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setStepperState } from '../../features/stepper/stepperSlice';
const AccountAndSummary = () => {
  const dispatch = useDispatch();
  const stepperState: number = useSelector((state: any) => state.stepper.stepper);

  useEffect(() => {
    if (stepperState !== 2) {
      dispatch(setStepperState(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box>
        <CustomContainer>
          <Box>
            <Box
              mt={5}
              mb={{ lg: 7, md: 7, sm: 7, xs: 2 }}
              sx={{
                '& .stepper-all': {
                  display: 'none',
                },
                '& .MuiBox-root': {
                  '& .MuiButtonBase-root': {
                    display: 'none',
                  },
                },
              }}
            >
              <StepperCard />
            </Box>
          </Box>
        </CustomContainer>
        <Box bgcolor={colors.grey[20]} height='54px' display='flex' alignItems='center' mb='32px'>
          <CustomContainer>
            <Typography variant='h1' fontSize='20px' fontWeight={700}>
              Guest/Account
            </Typography>
          </CustomContainer>
        </Box>
        <Box>
          <CustomContainer>
            <GuestAccountDetails />
          </CustomContainer>
        </Box>
        {/* <Box bgcolor={colors.grey[20]} height='54px' display='flex' alignItems='center' mb='20px' mt='60px'>
          <CustomContainer>
            <Typography variant='h1' fontSize='20px' fontWeight={700}>
              Booking Summary
            </Typography>
          </CustomContainer>
        </Box>
        <Box>
          <CustomContainer>
            <BookingDetails />
          </CustomContainer>
        </Box>
        <Box mb='30px'>
          <CustomContainer>
            <Typography variant='h1' fontSize='20px' fontWeight={700}>
              Payment details
            </Typography>
          </CustomContainer>
        </Box>
        <Box>
          <CustomContainer>
            <PaymentDetails />
          </CustomContainer>
        </Box>
        <Box bgcolor={colors.grey[20]} height='54px' display='flex' alignItems='center' mb='32px'>
          <CustomContainer>
            <Box
              display='flex'
              justifyContent='space-between'
              width={{ lg: '60%', md: '60%', sm: '100%', xs: '100%' }}
            >
              <Box display='flex'>
                <Typography variant='h1' fontSize='20px' fontWeight={700}>
                  Billing address
                </Typography>
                <Typography ml='20px' mt='5px'>
                  <Check />
                </Typography>
              </Box>
              <Box display='flex'>
                <Typography variant='body1' fontWeight={500} lineHeight='130%' mr='10px'>
                  Edit
                </Typography>
                <Typography>
                  <ArrowDownBlack />
                </Typography>
              </Box>
            </Box>
          </CustomContainer>
        </Box> */}
      </Box>
      <Box>
        <CustomContainer>
          <BillingDetails />
        </CustomContainer>
      </Box>
    </>
  );
};

export default AccountAndSummary;
