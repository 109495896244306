interface Room {
    meal_name: string;
    total_package: string;
    days_spent?: number;
}

interface Data {
    Rooms: Record<string, Room[]>;
}

interface Filters {
    holidayType?: string[];
}

export const sortRoom = (data: Data, filters: Filters) => {
    const arr: Room[] = []
    if (filters?.holidayType?.includes('18~All Inclusive Holidays')) {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(data?.Rooms)) {
            if (Array.isArray(value)) {
                if (value?.some((obj: any) => obj.meal_name === 'All Inclusive')) {
                    const minPackage = value?.reduce((min: any, obj: any) => {
                        if (obj.meal_name === 'All Inclusive') {
                            const packageValue = parseFloat(obj.total_package);
                            return packageValue < min ? packageValue : min;
                        }
                        return min
                    }, Infinity);
                    const minPackageObject = value?.find(
                        (obj: any) => obj.meal_name === 'All Inclusive' && parseFloat(obj.total_package) === minPackage
                    );
                    arr.push(minPackageObject as Room)
                } else if (value?.every((obj: any) => obj.meal_name !== 'All Inclusive')) {
                    arr.push(value?.[0])
                } else {
                    arr.push({} as Room)
                    // return null;
                }
            }
        }
    } else {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(data?.Rooms)) {
            if (Array.isArray(value)) {
                arr.push(value[0])
            }
        }
        // return data?.Rooms?.[1]?.[0];
    }
    return arr
};