export const Occasiondata = [
  {
    name: 'Holiday',
  },
  {
    name: 'Honeymoon',
  },
  {
    name: 'Birthday',
  },
  {
    name: 'Wedding Anniversary',
  },
  {
    name: 'Renewal of Vows',
  },
];
export const mealPreferences = ['Asian vegetarian meal', 'Infrant/baby food', 'Bland meal', 'Child meal', 'Diabetic meal', 'Fruit platter',
  'Gluten-free-meal', 'Hindu (non-vegetarian) meal', 'Japanese meal (Ih specific)', 'Kosher meal', 'Low calorie meal', 'Low cholesterol/low fat meal',
  'Low sodium,no salt added', 'Moslem meal', 'No fish meal (Ih specific)', 'Non lactose meal', 'Japanese obento meal(ua specific)', 'Oriental meal',
  'Raw vegetarian meal', 'Sea food meal', 'Special meal,specify food', 'Vegetarian meal (non-dairy)', 'Vegetarian meal (lacto-ovo)', 'Vegetarian oriental meal'];
export const titlePreferences = ['Mr', 'Mrs', 'Miss'];
export const childPreferences = ['Mr', 'Miss'];
export const airportAssistanceData = ['Yes', 'No'];
