import { Box, Button, Tooltip, Typography } from '@mui/material';
import { addCommasToNumber } from '../helpers/commaSeparatedPrice';
import { colors } from '../styles/colors';
import { roundPrice } from '../helpers/RoundPrice';
import { setStepperState } from '../features/stepper/stepperSlice';
import { useAppSelector } from '../hooks/useAppReducer';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAsyncHolidayPackageDetails } from '../features/holidayPackage/packageDetail';
import { setLoading } from '../features/loading/loadingSlice';
import { setFlightRoomState } from '../features/flightRoom/flightRoomSlice';
import dayjs from 'dayjs';
import { formatTime } from '../helpers/formatTime';
import { getSessionStorage, setSessionStorage } from '../helpers/storage';
import useWindowWidth from '../hooks/useWindowWidth';
import { setHolidayDetailsState } from '../features/holidaySummary/holidaySummarySlice';
import { fetchAsyncPaymentConfiguration, setDepositeConfiguration } from '../features/paymentConfiguration/paymentConfiguration';
import { calculateDeposit } from '../helpers/deposit';
import { ReactComponent as Euro } from '../assets/icons/Euro.svg';
import { ReactComponent as Dollar } from '../assets/icons/Dollar.svg';
import { ReactComponent as Pound } from '../assets/icons/Pound.svg';
interface pricingCardProps {
  totalPrice?: string | number;
  totalGuests?: number;
  session_id?: number;
  showButtons?: boolean,
  showButtonsAndFirstComponent?: () => void
}

export const PricingCard = ({ session_id, showButtons, showButtonsAndFirstComponent }: pricingCardProps) => {
  /**
   * @states
   */
  const windowWidth = useWindowWidth();
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  const { flightRoom } = useAppSelector((state) => state.flightRoom);
  const { holidayPackage } = useAppSelector(
    (state) => state.holidayPackage
  );
  const { taxText } = useAppSelector(
    (state) => state.packageDetails
  );
  const hotelDetails = holidayPackage?.Packages?.results?.filter(
    (f: any) => f?.SearchResult?.hotel_id === flightRoom?.hotel_id
  );
  const isPlataniumHotel = hotelDetails?.length > 0 ? Number(hotelDetails[0]?.HotelInfo?.platinumHotel) !== 0 : false;
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL
  const totalGuestsWithInfants: any = flightRoom.searchCriteria.adults + flightRoom.searchCriteria.childrens + flightRoom.searchCriteria.infants
  const [deposit, setDeposit] = useState(0)
  const [day, month, year] = flightRoom.searchCriteria.date[0].split("/");
  const formattedDate = `${year}-${month}-${day}`
  const { depositeConfiguration } = useAppSelector((state) => state.depositeConfiguration);
  const [showMore, setShowMore] = useState(false);

  const maxLines = 5;
  const lineHeight = 1.4;
  const flightPriceDiff = useMemo(() => {
    let price: any = 0
    const flightPrice = getSessionStorage("stepper") === "0" ? "0" : flightRoom.diffPrice || "0"
    if (getSessionStorage("nextAndSelectedFlights") === "false") {
      price = Math.ceil(flightPrice)
    } else {
      if (flightPrice.includes("-")) {
        price = Math.floor(flightPrice);
      } else {
        price = Math.ceil(flightPrice)
      }
    }
    return price
  }, [flightRoom.diffPrice, getSessionStorage("stepper"), getSessionStorage("nextAndSelectedFlights")])

  /**
   * @var
   */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**
   * @function
   */

  const holidayPackageStoreCriteria = async () => {
    let holidayPackageJson: any = await [
      {
        "DestinationFrom": holidayPackage?.SearchCriteria?.[0]?.DestinationFrom,
        "Destination": holidayPackage?.SearchCriteria?.[0]?.Destination,
        "departure_date": holidayPackage?.SearchCriteria?.[0]?.departure_date,
        "rooms": holidayPackage?.SearchCriteria?.[0]?.rooms,
        "nights": holidayPackage?.SearchCriteria?.[0]?.nights,
        "cabin_class": holidayPackage?.SearchCriteria?.[0]?.cabin_class,
        "adults": holidayPackage?.SearchCriteria?.[0]?.adults,
        "children": holidayPackage?.SearchCriteria?.[0]?.children,
        "infants": holidayPackage?.SearchCriteria?.[0]?.infants,
        "siteId": holidayPackage?.SearchCriteria?.[0]?.siteId,
        "cheapestFareCat": holidayPackage?.SearchCriteria?.[0]?.cheapestFareCat,
        hotel_id: Number(flightRoom?.hotel_id),
        // hotel_name: flightRoom.roomData[0]?.SearchResult?.hotel_name
      },
      {
        ids: {
          "flightSearchCriteriaId": Number(flightRoom.flightSearchCriteriaId),
          "hotelSearchCriteriaIds": Number(flightRoom.hotelSearchCriteriaIds),
          "selectedFlight": Number(flightRoom?.psw_result_id),
          "hotelSearchCriteriaId_nextDay": Number(flightRoom?.hotelSearchCriteriaId_nextDay),
        },
        filter: {
          // "selected_flight_id": Number(flightRoom?.psw_result_id),
          "selected_flight_id": Number(flightRoom.psw_result_id),
          "selected_hotel_id": Number(flightRoom?.hotel_id)
        }
      }
    ]
    if (flightRoom?.searchCriteria?.childrens > 0) {
      holidayPackageJson[0].child_age = await flightRoom?.searchCriteria?.child_age
    }
    await dispatch(setFlightRoomState({
      ...flightRoom,
      searchCriteria2: holidayPackageJson
    }))

    return await true
  }

   

  const handleBook = async () => {
    if (getSessionStorage("nextButtonClicked") === "true") {
      setSessionStorage("nextAndSelectedFlights", "true")
      setSessionStorage("previous", "true")
    } else {
      setSessionStorage("nextAndSelectedFlights", "false")
    }
    // setSessionStorage("nextAndSelectedFlights", "true");
    // setSessionStorage("nextButtonClicked", "true");
    // dispatch(setLoading(true))
    // const body = [
    //   {
    //     psw_result_id: flightRoom?.psw_result_id,
    //     roomids: flightRoom.roomids,
    //     "packageSearchId": flightRoom?.packageSearchId || ""
    //   },
    // ];
    const res = await holidayPackageStoreCriteria()
    // eslint-disable-next-line
    // let res = await dispatch(fetchAsyncHolidayPackageDetails(body));
    // dispatch(setLoading(false))
    dispatch(setStepperState(2));
    res && navigate(`/passenger-details/${session_id}${window.location.search}`);
  };

  //* Guests count excluding infants
  const totalGuests = flightRoom.searchCriteria.adults + flightRoom.searchCriteria.childrens


  const flightPrice: any = useMemo(() => {
    let price: any = 0
    const flightPrice = getSessionStorage("stepper") === "0" ? "0" : flightRoom.diffPrice || "0"

    if (getSessionStorage("nextAndSelectedFlights") === "false") {
      price = Math.ceil(flightPrice)
    } else {
      if (flightPrice.includes("-")) {
        price = Math.floor(flightPrice);
      } else {
        price = Math.ceil(flightPrice)
      }
    }
    return price
  }, [flightRoom.diffPrice, getSessionStorage("stepper"), getSessionStorage("nextAndSelectedFlights")])

  const packagePricePerPerson: any = useMemo(() => {
    let price = 0
    if (flightRoom.multiRooms.length > 0) {
      flightRoom.multiRooms.map((f: any) => {
        price = price + Number(f?.total_package)
        return <></>
      })
    }
    const totalPrice = price / totalGuests
    return (
      String(totalPrice + flightPriceDiff)
    );
  }, [flightRoom.multiRooms, totalGuests, flightPrice]);


  const additionalFlightPrice = useMemo(() => {
    let price = 0
    if (flightRoom.multiRooms.length > 0) {
      flightRoom.multiRooms.map((f: any) => {
        price = price + Number(f?.package_breakdown?.flight_tot_net_amt)
        return <></>
      })
    }

    return (
      price
    );
  }, [flightRoom.multiRooms])

  const totalPrice = useMemo(() => {
    return Number(roundPrice(String(packagePricePerPerson))) * totalGuests;
  }, [packagePricePerPerson, totalGuests, flightPrice]);

  useEffect(() => {
    const depositValue = calculateDeposit({
      tripDate: formattedDate || '',
      ticketingDate: Object.keys(holidayPackage).length > 0 && holidayPackage?.FlightDetails
        ? (holidayPackage.FlightDetails.outbound?.flights?.length > 0
          ? holidayPackage.FlightDetails.outbound?.flights[0]?.FlightPswResult.last_ticket_date
          : ""
        )
        : "",
      totalCost: totalPrice,
      totalGuests,
      totalGuestsWithInfants,
      flightPrice: additionalFlightPrice,
      depositeConfiguration
    });
    setDeposit(depositValue)
  }, [formattedDate, additionalFlightPrice, holidayPackage?.FlightDetails?.outbound?.flights, totalPrice, totalGuests, totalGuestsWithInfants, flightPrice, depositeConfiguration])

  const getBalanceDueDate = useCallback(() => {
    const beforeFullPaymentDueDays = Number(depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue) ? Number(depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue) : 0
    const todayDate = new Date()
    const tripDateDiff = dayjs(new Date(formattedDate)).diff(dayjs(), 'day'); // two date difference

    if (tripDateDiff > beforeFullPaymentDueDays) {
      const dateFormat = new Date(formattedDate)
      const subtractedDate = new Date(dateFormat.getTime() - (beforeFullPaymentDueDays * 24 * 60 * 60 * 1000))
      const returnDate = `${subtractedDate.getDate()}/${subtractedDate.getMonth() + 1}/${subtractedDate.getFullYear()}`
      return returnDate
    } else {
      const returnDate = `${todayDate.getDate()}/${todayDate.getMonth() + 1}/${todayDate.getFullYear()}`
      return returnDate
    }
  }, [formattedDate, depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue])

  useEffect(() => {
    const balaneDue = getBalanceDueDate()
    if (depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue) {
      // setTimeout(() => {
        dispatch(setHolidayDetailsState({
          ...holiday,
          deposit: deposit !== 0 ? `${signForPricing + addCommasToNumber(deposit)}` : signForPricing + addCommasToNumber(totalPrice),
          isDepositPayment: deposit !== 0 ? true : false,
          balance_due_date: balaneDue
        }))
      // }, 200)
    }
    // eslint-disable-next-line
  }, [getBalanceDueDate(), deposit]);

  useEffect(() => {
    if (depositeConfiguration === null) {
      (async () => {
        const res = await dispatch(fetchAsyncPaymentConfiguration())
        if (res?.payload) {
          dispatch(setDepositeConfiguration(res?.payload))
        }
      })();
    }
  }, [])

  const calculateLineCount = (text: any) => {
    const div = document.createElement("div");
    div.style.position = "absolute";
    div.style.visibility = "hidden";
    div.style.width = "100%";
    div.style.lineHeight = `${lineHeight}em`;
    div.innerHTML = text;
    document.body.appendChild(div);
    const lines = Math.ceil(div.clientHeight / (lineHeight * 16)); // Assuming 16px font size
    document.body.removeChild(div);
    return lines;
  };
  // if (!taxText || typeof taxText !== 'string') return null;
  const lineCount = calculateLineCount(taxText);
  const fixedTwoDigitPrice: any = parseFloat(packagePricePerPerson).toFixed(2)
  /**
   * @returns
   */

  return (
    <Box
    display={{ sm: 'flex', xs: windowWidth <= 375 ? "block" : "flex" }}
      alignItems='center'
      justifyContent='space-between'
      borderRadius='5px'
      sx={{ background: colors.basics.secondary, border: `1px solid ${colors.grey[30]}` }}
      padding={{ sm: '20px 16px', xs: "20px 10px" }}
      className='price-summary'
    >
       <Box width={'100%'} gap={"10px"}>
        <Box justifyContent='space-between' display={{ sm: 'flex', xs: windowWidth <= 375 ? "block" : "flex" }}>
          <Box>
            <Typography mx={0} sx={{
              fontFamily: "El Messiri",
              fontStyle: "normal", lineHeight: "31px",
              fontWeight: 400,
            }} fontSize={{ sm: "26px !important", xs: "24px !important" }}>
              <Tooltip
                title={fixedTwoDigitPrice}
              >
                <span>
                  {signForPricing + addCommasToNumber(
                    roundPrice(packagePricePerPerson))}{' '}
                  pp
                </span>
              </Tooltip>
            </Typography>
            <Typography sx={{
              fontFamily: "El Messiri",
              fontStyle: "normal", lineHeight: "31px",
              fontWeight: 400,
            }} fontSize={{ sm: "16px !important", xs: "16px !important" }}>Total Price:
              <Tooltip
                title={(fixedTwoDigitPrice * totalGuests)}
              >
                <span style={{ marginLeft: '3px' }}>
                  {signForPricing + addCommasToNumber(roundPrice(packagePricePerPerson) * totalGuests)}
                </span>
              </Tooltip>
            </Typography>
          </Box>
          <Box
            display={{ sm: "flex", xs: "none" }}
            width={{ sm: 'auto', xs: windowWidth <= 375 ? "100%" : "auto" }}
            sx={{
              alignSelf: "flex-start",
              '& .MuiButtonBase-root': {
                background: 'transparent',
                border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor}` : `1px solid ${colors.basics.primary}`,
                color: isPlataniumHotel ? `${colors.basics.plataniumGreenColor}` : `${colors.basics.primary}`,
                '&:hover': {
                  background: 'transparent',
                  border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor}` : `1px solid ${colors.basics.primary}`,
                  color: isPlataniumHotel ? `${colors.basics.plataniumGreenColor}` : `${colors.basics.primary}`,
                }
              }
            }}>
            <Button sx={{
              width: { sm: 'auto', xs: windowWidth <= 375 ? "100%" : "auto" },
              marginTop: { sm: '0', xs: windowWidth <= 375 ? "10px" : "0" },
            }} className='book-button btn-olb-passenger-details'  onClick={handleBook}>
              Passenger Details
            </Button>
          </Box>
        </Box>

        <Box marginTop={"10px"} display='flex' width={'100%'} alignItems={'center'} sx={{ background: colors.grey[20] }} padding={{ sm: '10px 16px', xs: "10px 10px" }}>
          <Box className='Icon' sx={{
            mr: "20px",
            "& svg": {
              '& path': {
                fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary + " !important"
              },
                fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary
            },
            '& g path:nth-of-type(2)': {
              fill: colors.basics.white + " !important"
            }
          }} maxWidth={{ sm: '40px', xs: "36px" }}>
            {process.env.REACT_APP_PROJECT_COUNTRY === "uk" && <Pound height={24} width={24} fill={colors.basics.primary} />}
            {process.env.REACT_APP_PROJECT_COUNTRY === "ie" && <Euro />}
            {process.env.REACT_APP_PROJECT_COUNTRY === "us" && <Dollar />}
          </Box>

          <Box className='content' >
            <Typography variant='body1' fontWeight={500} lineHeight='130%'>
              {deposit !== 0 ? `Due today: Deposit ${signForPricing + addCommasToNumber(deposit)}` : `Due today: Full payment`}
            </Typography>
          </Box>
        </Box>
        {taxText && typeof taxText === 'string' ? <Typography variant='subtitle2' marginTop={2} fontSize='12px' color='#212529' dangerouslySetInnerHTML={{ __html: taxText }} sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          WebkitLineClamp: showMore ? 'none' : maxLines, // Clamp lines if "showMore" is false
          maxHeight: showMore ? 'none' : `${maxLines * 1.4}em`, // Adjust based on font size
          transition: 'max-height 0.3s ease',
        }} /> : null}
        {taxText && lineCount > maxLines && (
          <span onClick={() => setShowMore(!showMore)} style={{ fontSize: "12px", color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary, cursor: "pointer" }}>
            {showMore ? 'Show less..' : 'Show more..'}</span>
        )}
        <Box
          mt={'20px'}
          sx={{
            '& .MuiButtonBase-root': {
              background: isPlataniumHotel ? `${colors.basics.plataniumGreenColor}` : `${colors.basics.primary}`,
              color: colors.basics.white,
              border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor}` : `1px solid ${colors.basics.primary}`,
              // background: colors.grey[30],
              '&:hover': {
                background: isPlataniumHotel ? `${colors.basics.plataniumGreenColor}` : `${colors.basics.primary}`,
                color: colors.basics.white,
                border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor}` : `1px solid ${colors.basics.primary}`,
              }
            },
            display: showButtons === true ? "none" : "flex",
            width: "100%",
          }}
          className='book-button'
        >
          <Button
            id="customize-holiday-btn"
            onClick={showButtonsAndFirstComponent}
            sx={{ width: "100%", }}
            variant="outlined"
            className='btn-olb-customise-holiday'
          >
            Customise Your Holiday
          </Button>
        </Box>
        <Box
          mt='10px'
          display={{ sm: "none", xs: "flex" }}
          width={{ sm: 'auto', xs: "100%" }}
          sx={{
            alignSelf: "flex-start",
            '& .MuiButtonBase-root': {
              background: 'transparent',
              border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor}` : `1px solid ${colors.basics.primary}`,
              color: isPlataniumHotel ? `${colors.basics.plataniumGreenColor}` : `${colors.basics.primary}`,
              '&:hover': {
                background: 'transparent',
                border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor}` : `1px solid ${colors.basics.primary}`,
                color: isPlataniumHotel ? `${colors.basics.plataniumGreenColor}` : `${colors.basics.primary}`,
              }
            }
          }}>
          <Button sx={{
            width: { sm: 'auto', xs: "100%" },
            // marginTop: { sm: '0', xs: "10px" },
          }} className='book-button btn-olb-passenger-details' onClick={handleBook}>
            Passenger Details
          </Button>
        </Box>
      </Box>
    </Box >
  );
};
