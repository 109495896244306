import { isEmpty } from "./isEmpty"
import parseHTML from "./parseHtml"

export const mergeMealNames = (meal: string) => {
    const mealName = isEmpty(meal) !== "" ? meal.toLowerCase().split(" ").join("") : ""

    if (mealName.includes("breakfast") || mealName.includes("bed")) {
        return "Bed & Breakfast"
    } else if (mealName.includes("halfboard")) {
        return "Half Board"
    } else if (mealName.includes("allinclusive")) {
        return "All Inclusive"
    } else if (mealName.includes("fullboard")) {
        return "Full Board"
    } else {
        return meal
    }
}

export const RoomMealWrapper = (string: any) => {
    const htmlString: string | null = string = isEmpty(string) !== "" ? parseHTML(string).body.textContent : "";
    return htmlString !== "" && htmlString !== null ? mergeMealNames(htmlString.replaceAll(/ and /gi, " & ")) : ""
}