import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';
import { countriesWithCountryCode } from '../../constants/countries';
import { DestinationList } from '../../constants/Destinations';

export const fetchAsyncDestinations: any = createAsyncThunk(
  'holiday_package/fetchAsyncDestinations',
  async (term) => {
    const response = await API.post(API_METHOD.Misc.GET_ALL_DESTINATIONS, term);
    response?.data?.forEach((item: any) => {
      item.Destination.country = countriesWithCountryCode[item.Destination.country_code];
      item.Destination.fullName =
        item.Destination.name + ', ' + countriesWithCountryCode[item.Destination.country_code];
    });
    return response.data;
  }
);

const initialState = {
  destinations: DestinationList,
  status: '',
};

const destinationSlice = createSlice({
  name: 'destinations',
  initialState,
  reducers: {
    setDestinations: (state, { payload }) => {
      state.destinations = payload;
    },
  },
  extraReducers: {
    [fetchAsyncDestinations.pending]: (state) => {
      state.status = 'PENDING';
    },
    [fetchAsyncDestinations.fulfilled]: (state, { payload }) => {
      if (state.status !== 'SUCCESS') {
        state.destinations = payload;
      }
      state.destinations = payload;
      state.status = 'SUCCESS';
    },
    [fetchAsyncDestinations.rejected]: (state) => {
      state.status = 'REJECTED';
    },
  },
});

export const { setDestinations } = destinationSlice.actions;
export default destinationSlice.reducer;
