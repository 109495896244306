import { createSlice } from '@reduxjs/toolkit';
import { SearchCriteria } from '../flightRoom/flightRoomSlice';

interface sessionProps {
  sessionData: [
    {
      sessionId: string | null;
      agent_id: string | null;
      basket: {
        branch: string | null;
        cart: {
          localStates: SearchCriteria | null;
        };
        sessionId: string | null;
        current_stage: number | null;
        last_stage: any;
      } | null;
      folder_no: number | null;
      dep_date: null | any;
      destination_id: null | any;
    }
  ];
}

const initialState: sessionProps = {
  sessionData: [
    {
      sessionId: null,
      agent_id: null,
      basket: null,
      folder_no: null,
      dep_date: null,
      destination_id: null,
    },
  ],
};

const summarySessionSlice = createSlice({
  name: 'summarySession',
  initialState,
  reducers: {
    setSummaryDataToSession: (state, { payload }) => {
      state.sessionData = payload;
    },
  },
});

export default summarySessionSlice.reducer;
export const { setSummaryDataToSession } = summarySessionSlice.actions;
