import { createSlice } from "@reduxjs/toolkit";

interface HolidayDetail {
  holiday: {
    hotel: string;
    hotel_id?: string;
    packageDate: Record<any, any>;
    hotel_name: any;
    baggage: any;
    isDepositPayment: boolean;
    flight: {
      inbound: [
        {
          airline_code: string;
          airline_name: string;
          inbound_date: string;
          dep_airport: string;
          des_airport: string;
          layover: any;
          stop: string;
          flightDeparture: string;
          arrival_time: any;
        }
      ];
      outbound: [
        {
          airline_code: string;
          airline_name: string;
          outbound_date: string;
          dep_airport: string;
          des_airport: string;
          layover: any;
          stop: string;
          flightDeparture: string;
          arrival_time: any;
          arrival_date?: any;
          departure_date?: any;
        }
      ];
    };
    room: null | Record<any, any>;
    guest: any;
    deposit: number;
    balance_due_date: string;
    outboundTime: string;
    inboundTime: string;
    selectedFlight: null | Record<any, any>;
  };
}

const initialState: HolidayDetail = {
  holiday: {
    hotel: "",
    hotel_id: "",
    packageDate: {},
    hotel_name: "",
    baggage: "",
    isDepositPayment: false,
    flight: {
      inbound: [
        {
          airline_code: "",
          airline_name: "",
          inbound_date: "",
          dep_airport: "",
          des_airport: "",
          layover: [],
          stop: "",
          flightDeparture: "",
          arrival_time: "",
        },
      ],
      outbound: [
        {
          airline_code: "",
          airline_name: "",
          outbound_date: "",
          dep_airport: "",
          des_airport: "",
          layover: [],
          stop: "",
          flightDeparture: "",
          arrival_time: "",
          arrival_date: "",
          departure_date: ""
        },
      ],
    },
    room: null,
    guest: [],
    deposit: 0,
    balance_due_date: "",
    outboundTime: "",
    inboundTime: "",
    selectedFlight: null,
  },
};

const hoidaySummarySlice = createSlice({
  name: "holidayDetail",
  initialState,
  reducers: {
    setHolidayDetailsState: (state, { payload }) => {
      state.holiday = payload || state.holiday;
    },
  },
});

export default hoidaySummarySlice.reducer;

export const { setHolidayDetailsState } = hoidaySummarySlice.actions;
