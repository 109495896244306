export const roundPrice: any = (price: string, displayOnlyNumber?: boolean) => {
  let amount: number = Number(price);
  let [dollars, cent]: string[] = String(price).split(".");
  const cents = Number(cent);

  if (dollars !== "undefined") {
    const lastDigit = Number(dollars?.charAt(dollars.length - 1));

    if (lastDigit < 5) {
      for (let i = lastDigit; i < 5; i++) {
        amount++;
      }
    } else if (lastDigit === 5 && !Boolean(cents)) {
      amount = Number(dollars);
    } else if (lastDigit === 9 && Boolean(cents)) {
      for (let i = lastDigit; i < lastDigit + 6; i++) {
        amount++;
      }
    } else if ((lastDigit === 5 && cents !== 0) || lastDigit > 5) {
      for (let i = lastDigit; i < 9; i++) {
        amount++;
      }
    } else {
      // console.log({ lastDigit }, { cents }, { price }, 'invalid');
    }
    let [newAmount] = String(amount).split(".");
    return displayOnlyNumber
      ? Number(newAmount)
      : String(Number(newAmount).toFixed(0));
  }
};
