import { Box, Typography } from "@mui/material"
import { SelectInput } from "../../../components/SelectInput"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { branchCode } from "../../../constants/branchCode";
import { setLoading } from "../../../features/loading/loadingSlice";
import { getQueryStringValue } from "../../../helpers/getQueryStringValues";
import { getFolderDetails } from "../../../features/profileDetails/getFolderSlice";
import CustomSelectInput from "../../../components/CustomSelectInput";
import { CustomContainer } from "../../../components/CustomContainer";

const PaymentTab = (props: any) => {
    const dispatch = useDispatch();
    const [options, setOptions] = useState([])
    const [valuesPaymentTab, setValuesPaymentTab] = useState({
        id: "",
        name: ""
    })
    const [data, setData] = useState([])
    const [urlForPayment, setUrlForPayment] = useState("")
    const { upcomingBookings, loading } = props

    const getFolderApiCall = async (folderNo: any, itineararyNumber: any) => {
        const { payload } = await dispatch(
            getFolderDetails([{
                fold_no: folderNo, branch_code: branchCode
            }])
        );
        return await payload
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await upcomingBookings || [];
                if (result.length > 0) {
                    let ref: any = []

                    await result.map((f: any) => {
                        ref.push({
                            id: f.fold_no,
                            name: `TSR${f.fold_no}`
                        })
                        return <></>
                    })

                    setData(result)
                    await setOptions(ref)
                    await dispatch(setLoading(false));
                } else {
                    dispatch(setLoading(false));
                }
            } catch (error) {
                console.error("Error:", error);
                dispatch(setLoading(false));
            }
        };
        setData([])
        fetchData();
    }, [dispatch, upcomingBookings])

    const handlePaymentTab = async (e: any, name: any, value: any) => {
        dispatch(setLoading(true))
        const filterData = upcomingBookings.filter((f: any) => f.fold_no.toString() === value.toString())
        const itineraryNumber = 1
        const payload = await getFolderApiCall(value, itineraryNumber)
        if (payload) {
            const token = payload.foldertoken
            const branch = payload.folderDetails.Folder.branch_code
            setUrlForPayment(
                `${process.env.REACT_APP_LIVE_PAYMENT_URL}?folder=${value}&token=${token}&branch=${branch}&s=d`
            )
        }
        setValuesPaymentTab({
            id: value,
            name: `TSR${filterData[0].fold_no}`
        })
        await dispatch(setLoading(false))
    }
    return (
        <CustomContainer>
            {data.length > 0 ? <Box>
                <Box sx={{ display: "flex", justifyContent: "left", flexDirection: "column", width: "100%" }}>
                    <Typography variant="subtitle2" pb={1}>
                        To make payment for an upcoming booking please select the booking number from the dropdown and follow the onscreen instructions:
                    </Typography>
                    <Box sx={{'& .dropdown-menu.show ': {
                            maxHeight: "300px ",
                        }, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", maxWidth: { sm: "200px", xs: '100%' }
                    }}>
                        <CustomSelectInput
                            data={options}
                            idIncluded={true}
                            ids="payment"
                            dropdownWidth={"100%"}
                            itemId={valuesPaymentTab?.id || ""}
                            value={valuesPaymentTab?.name || ""}
                            onChange={(e: any, n: any, v: any) => { handlePaymentTab(e, n, v) }}
                        />
                    </Box>

                </Box>
                <Box mt={'10px'} sx={{ height: urlForPayment !== "" ? '100vh' : "auto" }}>
                    {urlForPayment !== "" && <iframe
                        title='payment'
                        src={urlForPayment}
                        style={{ width: '100%', height: '100%' }}
                    ></iframe>}
                </Box>
            </Box> : !loading ? <Box textAlign={"center"}>No Booking Found</Box> : <></>}
        </CustomContainer>
    )
}

export default PaymentTab