import MapDialog from './dialogs/MapDialog';
import FilterSearchDialog from './dialogs/FilterSearch';
import PriceChangedDialog from './dialogs/PriceChangedDialog';
import EmailEnquiryForm from './dialogs/EmailEnquiryForm';
import { useAppSelector } from '../hooks/useAppReducer';
import EmailItinerary from './dialogs/EmailItinerary';

export default function Dialog() {
  //! States
  const { filterDialog, priceChangeDialog, emailEnquiryDialog, emailItinerary } = useAppSelector(
    (state: any) => state.dialog.dialogState
  );

  return (
    <div>
      {/* Load MapDialog initially */}
      {/* <MapDialog /> */}

      {filterDialog && <FilterSearchDialog />}
      {priceChangeDialog && <PriceChangedDialog />}
      {emailEnquiryDialog && <EmailEnquiryForm />}
      {emailItinerary && <EmailItinerary />}
    </div>
  );
}
