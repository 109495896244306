import { Box, Typography } from '@mui/material';
import '../assets/css/fontawesome.css'
import '../assets/css/elmessiri-medium.css'
import Slider, { Settings } from 'react-slick';
import { ReactComponent as RightChervon } from '../assets/icons/RightChervon.svg';
import { ReactComponent as LeftChervon } from '../assets/icons/LeftChervon.svg';
import Image from './Image';
import useWindowWidth from '../hooks/useWindowWidth';

export const SliderComponent = ({ data }: any) => {
    const windowWidth = useWindowWidth()

    const SampleNextArrow = (props: any) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                <RightChervon />
            </div>
        );
    }

    const SamplePrevArrow = (props: any) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                <LeftChervon />
            </div>
        );
    }

    const settings: Settings = {
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        cssEase: 'linear',
        lazyLoad: 'progressive',
    };

    return (
        <Box
            sx={{
                height: "auto",
                width: "100%",
                padding: { sm: '0 50px', xs: '0 15px ' },
                '& .slick-prev': {
                    left: { sm: '-50px', xs: '-10px' },
                    width: '30px',
                    height: '40px',
                    margin: 'auto',
                    borderRadius: { sm: '50%', xs: '0 20% 20% 0' },
                    backgroundColor: { sm: 'rgba(33,37,41,.9)', xs: 'rgba(0,0,0,.6)' },
                    zIndex: 1,
                },
                '& .slick-next': {
                    right: { sm: '-50px', xs: '-10px' },
                    borderRadius: { sm: '50%', xs: '20% 0 0 20%' },
                    backgroundColor: { sm: 'rgba(33,37,41,.9)', xs: 'rgba(0,0,0,.6)' },
                    '&:before': {
                        display: 'none',
                    },
                },
                '& .slick-list': {
                },
                '& .slick-arrow': {
                    width: { sm: '40px', xs: "30px" },
                    height: '40px',
                    padding: 0,
                    display: 'flex!important',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:before': {
                        display: 'none',
                    },
                    p: { sm: '', xs: '6px 0' },
                    '& svg': {
                        width: '6px',
                        '& path': {
                            fill: '#fff',
                        },
                    },
                },

            }}
        >
            <Slider {...settings}>
                {data?.map((item: any, index: number) => {
                    return (
                        <Box key={index} >
                            <Box
                                className='heading-slider'
                                sx={{
                                    p: '20px',
                                    display: "block",
                                    width: '100%',
                                    height: 'auto',
                                    '& img': {
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        minHeight: '145px',
                                        objectPosition: 'top',
                                    },
                                }}
                            >
                                <Typography
                                    textAlign={'center'}
                                    variant='h1'
                                    fontWeight={500}
                                    color='#212529'
                                    margin={"0 0 15px"}
                                    lineHeight={1.2}
                                    alignItems='center'
                                    fontSize={{ xs: '1.25rem', sm: '1.625rem' }}
                                >
                                    {item?.title}
                                </Typography>
                                <Box>
                                    <Box sx={{
                                    }}>
                                        <Typography>
                                            <Image style={{
                                                margin: 'auto',
                                                minHeight: 'auto',
                                                padding: "10px 10px 20px",
                                                maxWidth: windowWidth < 600 ? "90%" : "320px"
                                            }} src={item?.imgURL} alt='images' />
                                        </Typography>

                                    </Box>
                                </Box>
                                <Box sx={{
                                    // mt: '10px',
                                    textAlign: 'center',
                                    '& a': {
                                        fontFamily: 'El Messiri',
                                        // fontFamily: 'El Messiri,georgia,serif !important',
                                        textAlign: 'center',
                                        lineHeight: '1.25rem',
                                        fontWeight: '500',
                                        color: '#303030 !important',
                                        marginBottom: "10px",
                                        marginTop: "10px",
                                        alignItems: 'center',
                                        fontSize: { xs: '16px', sm: '1.25rem' },
                                        textDecoration: "none",
                                        '&:hover': {
                                            textDecoration: "underline",
                                        }
                                    }
                                }}
                                >
                                    <Typography dangerouslySetInnerHTML={{ __html: item.bodyHtml }} sx={{
                                        textAlign: 'center',
                                        variant: 'h3',
                                        fontFamily: 'ElMessiri-SemiBold',
                                        lineHeight: '1.25rem',
                                        fontWeight: 500,
                                        color: '#303030 !important',
                                        marginTop: "10px",
                                        alignItems: 'center',
                                        fontSize: { xs: '16px', sm: '20px' },
                                        textDecoration: "none",
                                    }}
                                    >
                                    </Typography>

                                    <Box mt={"5px"}>
                                        <Typography
                                            dangerouslySetInnerHTML={{ __html: item.footerHtml }}
                                            textAlign={'center'}
                                            variant='body1'
                                            fontWeight={500}
                                            color='#212529'
                                            marginBottom={"10px"}
                                            alignItems='center'
                                            lineHeight={'1.25rem'}
                                            fontSize={{ xs: '14px', sm: '16px' }}
                                        >
                                            {item?.footer}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box >
                    );
                })}
            </Slider >
        </Box >
    )
}