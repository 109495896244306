import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { colors } from "../styles/colors";
import RemoveIcon from '@mui/icons-material/Remove';
import Delete from '../assets/icons/Delete';
import AddIcon from '@mui/icons-material/Add';
import CustomSelectInput from "./CustomSelectInput";

const GuestDropdown = ({ setRoom, room, totalChildren, totalInfantsChildren, guestRoomSubmit, selectedDestination, handleFind }: any) => {
    const handleRemoveRoom = (index: number, event: any) => {
        setTimeout(() => {
            event.stopPropagation();
            const updatedRoom = [...room.slice(0, index), ...room.slice(index + 1)];
            setRoom(updatedRoom);
        }, 2)

    };

    const handleAgeChange = (roomIndex: number, childIndex: number, newAge: string, type: string, event: any) => {
        event.stopPropagation();
        if (type === "infants") {
            setRoom((prevRoom: any) => {
                const updatedRoom = [...prevRoom];
                const updatedChildren = [...updatedRoom[roomIndex].infants];
                updatedChildren[childIndex] = {
                    ...updatedChildren[childIndex],
                    age: newAge,
                };
                updatedRoom[roomIndex] = {
                    ...updatedRoom[roomIndex],
                    infants: updatedChildren,
                };
                return updatedRoom;
            });
        } else {
            setRoom((prevRoom: any) => {
                const updatedRoom = [...prevRoom];
                const updatedChildren = [...updatedRoom[roomIndex].children];
                updatedChildren[childIndex] = {
                    ...updatedChildren[childIndex],
                    age: newAge,
                };
                updatedRoom[roomIndex] = {
                    ...updatedRoom[roomIndex],
                    children: updatedChildren,
                };
                return updatedRoom;
            });
        }
    };

    const handleIncDecAdultOrChildren = (index: number, type: string, action: string, event: any) => {
        event.stopPropagation();
        let arr: any = [...room];

        switch (type) {
            case 'adult':
                if (action === 'dec') {
                    arr[index] = {
                        ...arr[index],
                        adults: arr[index].adults > 1 ? arr[index].adults - 1 : 1,
                    };
                } else {
                    arr[index] = {
                        ...arr[index],
                        adults: arr[index].adults ? arr[index].adults + 1 : 1,
                    };
                }
                break;

            case 'child':
                if (action === 'dec') {
                    const updatedChildren = arr[index].children.slice(0, -1);
                    arr[index] = {
                        ...arr[index],
                        children: updatedChildren,
                    };
                } else {
                    if (totalChildren.length < 6) {
                        const newChild = {
                            child: arr[index].children.length + 1,
                            age: '',
                        };
                        const updatedChildren = [...arr[index].children, newChild];
                        arr[index] = {
                            ...arr[index],
                            children: updatedChildren,
                        };
                    }
                }
                break;

            case 'infant':
                if (action === 'dec') {
                    const updatedChildren = arr[index].infants.slice(0, -1);
                    arr[index] = {
                        ...arr[index],
                        infants: updatedChildren,
                    };
                } else {
                    if (totalInfantsChildren.length < 6) {
                        const newChild = {
                            child: arr[index].infants.length + 1,
                            age: '',
                        };
                        const updatedChildren = [...arr[index].infants, newChild];
                        arr[index] = {
                            ...arr[index],
                            infants: updatedChildren,
                        };
                    }
                }
                break;
            default:
                break;
        }
        setRoom(arr);
    };

    return (
        <Box borderRadius='3px' position='relative' padding={'0px 5px'}  >
            <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Typography variant='body2' fontSize={'18px'} color={colors.grey[10]}>
                    Rooms
                </Typography>
                <Box display='flex' alignItems='center'>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            if (room.length >= 2) {
                                setRoom(room?.filter((item: any, index: number) => index !== room.length - 1))
                            }
                        }}
                        disableRipple={true}
                        sx={{ pt: 0, pb: 0 }}
                    >
                        <RemoveIcon sx={{ fill: colors.grey[10] }} />
                    </IconButton>
                    <Typography variant='body2' fontSize='18px' color={colors.grey[10]}>
                        {room?.length}
                    </Typography>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            room.length < 5 &&
                                setRoom((item: any) => [
                                    ...item,
                                    {
                                        room: room.length + 1,
                                        adults: 2,
                                        children: [],
                                        infants: []
                                    },
                                ]);
                        }}
                        disableRipple={true}
                        sx={{ pt: 0, pb: 0 }}
                    >
                        <AddIcon sx={{ fill: colors.grey[10] }} />
                    </IconButton>
                </Box>
            </Box>
            {room?.map((item: object[] | any, index: number) => {
                return (
                    <Box key={index} mb={2.5}>
                        <hr style={{ marginTop: 10, marginBottom: 10 }} />
                        <Box mb={1} display='flex' alignItems='center' justifyContent={'space-between'}>
                            <Typography variant='body2' color={colors.grey[10]} mr={1} fontSize='18px'>
                                Room {index + 1}
                            </Typography>
                            {room.length !== 1 && (
                                <Box
                                    color={colors.basics.primary}
                                    sx={{
                                        cursor: 'pointer',
                                        '& svg': {
                                            '& path': { fill: colors.basics.primary },
                                            width: '18px',
                                            height: '18px',
                                        },
                                    }}
                                    onClick={(e) => handleRemoveRoom(index, e)}
                                >
                                    <Delete />
                                </Box>
                            )}
                        </Box>
                        <Box display='flex' justifyContent='space-between'>
                            <Box flex={1} mr={{ xs: 12, sm: 15 }}>
                                <Typography variant='body2' color={colors.grey[10]}>
                                    Adults
                                </Typography>
                                <Box display='flex' alignItems='center' mt={1} width='100%'>
                                    <IconButton
                                        onClick={(e) => handleIncDecAdultOrChildren(index, 'adult', 'dec', e)}
                                        disableRipple={true}
                                        sx={{
                                            p: 0,
                                            border: `1px solid ${colors.grey[10]}`,
                                            borderRadius: 0,
                                        }}
                                    >
                                        <RemoveIcon sx={{ fill: colors.grey[10] }} />
                                    </IconButton>
                                    <Typography variant='body2' fontSize='18px' color={colors.grey[10]} mx={1}>
                                        {room[index].adults}
                                    </Typography>
                                    <IconButton
                                        onClick={(e) => handleIncDecAdultOrChildren(index, 'adult', 'inc', e)}
                                        disableRipple={true}
                                        sx={{
                                            p: 0,
                                            border: `1px solid ${colors.grey[10]}`,
                                            borderRadius: 0,
                                        }}
                                    >
                                        <AddIcon sx={{ fill: colors.grey[10] }} />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box flex={1} >
                                <Typography variant='body2' color={colors.grey[10]}>
                                    Children
                                </Typography>
                                <Box display='flex' alignItems='center' mt={1} width='100%'>
                                    <IconButton
                                        onClick={(e) => handleIncDecAdultOrChildren(index, 'child', 'dec', e)}
                                        disableRipple={true}
                                        sx={{
                                            p: 0,
                                            border: `1px solid ${colors.grey[10]}`,
                                            borderRadius: 0,
                                        }}
                                    >
                                        <RemoveIcon sx={{ fill: colors.grey[10] }} />
                                    </IconButton>
                                    <Typography variant='body2' fontSize='18px' color={colors.grey[10]} mx={1}>
                                        {room[index].children.length}
                                    </Typography>
                                    <IconButton
                                        onClick={(e) => handleIncDecAdultOrChildren(index, 'child', 'inc', e)}
                                        disableRipple={true}
                                        sx={{
                                            p: 0,
                                            border: `1px solid ${totalChildren.length < 6 ? colors.grey[10] : colors.disable[2]}`,
                                            borderRadius: 0,
                                        }}
                                    >
                                        <AddIcon
                                            sx={{
                                                fill: totalChildren.length < 6 ? colors.grey[10] : colors.disable[2],
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                        <Box ml='auto' mt={2}>
                            <Typography variant='body2' color={colors.grey[10]}>
                                Infants
                            </Typography>
                            <Box display='flex' alignItems='center' mt={1} width='100%'>
                                <IconButton
                                    onClick={(e) => handleIncDecAdultOrChildren(index, 'infant', 'dec', e)}
                                    disableRipple={true}
                                    sx={{
                                        p: 0,
                                        border: `1px solid ${colors.grey[10]}`,
                                        borderRadius: 0,
                                    }}
                                >
                                    <RemoveIcon sx={{ fill: colors.grey[10] }} />
                                </IconButton>
                                <Typography variant='body2' fontSize='18px' color={colors.grey[10]} mx={1}>
                                    {room[index].infants.length}
                                </Typography>
                                <IconButton
                                    onClick={(e) => handleIncDecAdultOrChildren(index, 'infant', 'inc', e)}
                                    disableRipple={true}
                                    sx={{
                                        p: 0,
                                        border: `1px solid ${totalInfantsChildren.length < 6 ? colors.grey[10] : colors.disable[2]}`,
                                        borderRadius: 0,
                                    }}
                                >
                                    <AddIcon sx={{ fill: totalInfantsChildren.length < 6 ? colors.grey[10] : colors.disable[2] }} />
                                </IconButton>
                            </Box>
                        </Box>

                        {item?.children?.length > 0 && <Grid mt={3} container columnSpacing={1} >
                            <Box mb={"3px"} sx={{ color: colors.basics.primary, fontSize: "11px", marginLeft: "6px" }}>Please ensure that the child age is in line with the checkout date if they are booking in advance.</Box>
                            {item?.children?.map((element: any, i: number) => {
                                return (
                                    <Grid key={i} marginTop={'0'} xs={4} item >
                                        <Box key={i}>
                                            <Typography variant='body2' color={colors.grey[10]} my={1}>
                                                Child  {element.child} Age
                                            </Typography>
                                            <Box
                                                sx={{
                                                    flex: { sm: '0 0 30%', xs: '100%' },
                                                    // height: '40px',
                                                }}
                                            >
                                                <CustomSelectInput
                                                    ids={`child-age-${index}-${i}`}
                                                    dropdownWidth={"100%"}
                                                    data={[...Array(16)].map((el, i) => String(i + 2))}
                                                    // placeholder='Age'
                                                    value={String(room[index]?.children[i]?.age) || ''}
                                                    onChange={(e: any, n: any, v: any) => handleAgeChange(index, i, v, "child", e)}
                                                    menuItemStyle={{ width: '100px' }}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                );
                            })}
                        </Grid>}
                        {item?.infants?.length > 0 && <Grid mt={3} container columnSpacing={1} >
                            <Box mb={"3px"} sx={{ color: colors.basics.primary, fontSize: "11px", marginLeft: "6px" }}>Please ensure that the infant age is in line with the checkout date if they are booking in advance.</Box>

                            {item?.infants?.map((element: any, i: number) => {
                                return (
                                    <Grid key={i} marginTop={'0'} xs={4} item>
                                        <Box key={i}>
                                            <Typography variant='body2' color={colors.grey[10]} my={1}>
                                                Infant  {element.child} Months
                                            </Typography>
                                            <Box
                                                sx={{
                                                    flex: { sm: '0 0 30%', xs: '100%' },
                                                    // height: '40px',
                                                }}
                                            >
                                                <CustomSelectInput
                                                    ids={`infant-age-${index}-${i}`}
                                                    data={[...Array(24)].map((el, i) => String(i))}
                                                    // placeholder='Month'
                                                    dropdownWidth={"100%"}
                                                    value={String(room[index]?.infants[i]?.age) || ''}
                                                    onChange={(e: any, n: any, v: any) => handleAgeChange(index, i, v, "infants", e)}
                                                    menuItemStyle={{ width: '100px' }}
                                                />
                                                {/* <Box  sx={{ color: "red", fontSize: "11px", marginLeft: "1px",marginTop:"6px" }}>Please ensure the child age is in line with the checkout date if they are booking in advance.</Box> */}

                                            </Box>
                                        </Box>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        }
                    </Box>
                );
            })}
            <Button onClick={() => {
                guestRoomSubmit()
                // if (selectedDestination !== null) {
                    handleFind()
                // }
            }}>Search</Button>
        </Box >
    );
};

export default GuestDropdown