import PrivateRoute from "./PrivateRoute";
import Signin from "../pages/Signin/Signin";
import Home from "../pages/Home/Home";
import Profile from "../pages/Profile/Profile";
import CreateAccount from '../pages/CreateAccount/CreateAccount';
import PersonalDetails from '../pages/PersonalDetails/PersonalDetails';
import HolidaySummary from '../pages/HolidaySummary/HolidaySummary';
import MapDialog from '../dialog/dialogs/MapDialog';
import AccountAndSummary from '../pages/AccountAndSummary/AccountAndSummary';
import BookingSuccessfulSummary from "../pages/AccountAndSummary/BookingSuccessfulSummary";
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import PublicRoute from "./publicRoute";
import Logs from "../pages/Logs/Logs";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import ValidationFailureLogs from "../pages/Logs/ValidationFailureLogs";
import Search from "../pages/Search/Search";
import NotFound from "../pages/Logs/NotFound";

export const routes = [{
    path: '/',
    element: <Search />
}, {
    path: '/holiday-search',
    element: <Home />
}, {
    path: '/signin',
    element: <PublicRoute> <Signin /> </PublicRoute>
}, {
    path: '/profile/:tab',
    element: <PrivateRoute>  <Profile />  </PrivateRoute>
}, {
    path: '/signup',
    element: <PublicRoute><CreateAccount /></PublicRoute>
}, {
    path: '/holiday-summary/:session_id',
    element: <HolidaySummary />
}, {
    path: '/holiday-summary/:session_id/:hotelId',
    element: <MapDialog />
}, {
    path: '/:hotelId',
    element: <MapDialog />
}, {
    path: '/passenger-details/:session_id',
    element: <PersonalDetails />
}, {
    path: '/account-summary',
    element: <PrivateRoute> <AccountAndSummary /></PrivateRoute>
}, {
    path: '/booking-confirmation',
    element: <BookingSuccessfulSummary />
}, {
    path: '/reset-password/:key/:id',
    element: <PublicRoute>  <ResetPassword /> </PublicRoute>
}, {
    path: '/forgot-password',
    element: <PublicRoute> <ForgotPassword /> </PublicRoute>
}, {
    path: '/logs',
    element: <Logs />
},
{
    path: '/validation-failure-logs',
    element: <ValidationFailureLogs />
},
{
    path: '/not-found-logs',
    element: <NotFound />
},
{
    path: '*',
    element: <PageNotFound />
}]