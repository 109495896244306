import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';

export const addHotelFlightDetailsToFolder: any = createAsyncThunk(
  'detailsToFolder/addHotelFlightDetailsToFolder',
  async (res) => {
    const response = await API.post(API_METHOD.Misc.ADD_FLIGHT_AND_HOTEL_TO_FOLDER, res);
    return response.data;
  }
);