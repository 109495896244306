interface DepositProps {
  tripDate: string;
  ticketingDate: string;
  totalCost: number;
  totalGuests: number;
  totalGuestsWithInfants: number,
  flightPrice: any,
  depositeConfiguration: any
}

const roundedNearestZero = (number: number) => {
  return Math.ceil(number / 10) * 10
}

const convertDateString = (inputStr: string): string => {
  const day = inputStr.slice(0, 2);
  const monthStr = inputStr.slice(2, 5);
  const year = inputStr.slice(5, 7);

  const monthMap: { [key: string]: string } = {
    JAN: "01",
    FEB: "02",
    MAR: "03",
    APR: "04",
    MAY: "05",
    JUN: "06",
    JUL: "07",
    AUG: "08",
    SEP: "09",
    OCT: "10",
    NOV: "11",
    DEC: "12",
  };

  const month = monthMap[monthStr.toUpperCase()];
  const formattedDate = `20${year}-${month}-${day}`;

  return formattedDate;
}

const calculateDateDifference = (givenDateStr: string) => {
  const givenDate: any = new Date(givenDateStr);

  const today: any = new Date();

  givenDate.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  const timeDiff: number = givenDate - today;

  const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  return dayDiff
}

export const calculateDeposit = ({ tripDate, ticketingDate, totalCost, totalGuests, totalGuestsWithInfants, flightPrice, depositeConfiguration }: DepositProps): number => {

  const parsedTicketingDate: any = new Date(ticketingDate);
  const parsedTripDate: any = new Date(tripDate);
  parsedTicketingDate.setHours(0, 0, 0, 0);
  parsedTripDate.setHours(0, 0, 0, 0);
  const timeDifference = parsedTripDate - parsedTicketingDate;

  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); //ticketing date to trip date different
  const regexPattern = /(\d{2}[A-Z]{3}\d{2})/; // Matches "27JUN24" format
  const match = ticketingDate.match(regexPattern);
  const extractedDate = match ? convertDateString(match[0]) : ticketingDate;

  const tripDateDiff = calculateDateDifference(tripDate)
  const ticketingDateDiff = calculateDateDifference(extractedDate);

  // console.log("last ticketing date ", ticketingDate)
  // console.log("trip diff: ", tripDateDiff)
  // console.log("ticketing date diff", ticketingDateDiff)

  const BeforeFullPayment = Number(depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue) ? Number(depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue) : 0
  const DepositPercent = Number(depositeConfiguration?.DepositPercentDefault) ? Number(depositeConfiguration?.DepositPercentDefault) : 0
  const MinimumDeposit = Number(depositeConfiguration?.MinimumDepositAmountPP) ? Number(depositeConfiguration?.MinimumDepositAmountPP) : 0

  if (tripDateDiff <= BeforeFullPayment) {
    // depart date is less than or equal to 90 days so full payment - 16 Oct 2024(new logic)
    // this includes flight price
    return 0
  } else if (daysDifference > BeforeFullPayment) {
   // ticketing date to trip date different more than 90 days so flight + deposit - 16 Oct 2024(new logic)
    // take 10 % deposit of total price 
    // add flightprice with 10% deposit
    // If includedFlights is less than 200 return 200 else price
    // infants are added only when includedFlights is more than 200 

    const includedFlights = Math.round((DepositPercent / 100) * totalCost) + flightPrice;
    const perPersonPrice = includedFlights <= MinimumDeposit ? includedFlights / totalGuests : includedFlights / totalGuestsWithInfants

    if (perPersonPrice <= MinimumDeposit) {
      return roundedNearestZero(MinimumDeposit * totalGuests)
    } else {
      return roundedNearestZero(Math.round(perPersonPrice) * totalGuestsWithInfants)
    }
  } else if (tripDateDiff > BeforeFullPayment && daysDifference < BeforeFullPayment) {
    //  trip date more than 90 and ticketing date to trip date different less than 90 then deposit only - 16 Oct 2024(new logic)
    // take 10 % deposit of total price 
    // If discountPrice is less than 200 return 200 else price
    // infants are added only when discountPrice is more than 200 

    const discountPrice = Math.round((DepositPercent / 100) * totalCost);
    const perPersonPrice = discountPrice <= MinimumDeposit ? discountPrice / totalGuests : discountPrice / totalGuestsWithInfants

    if (perPersonPrice <= MinimumDeposit) {
      return roundedNearestZero(MinimumDeposit * totalGuests)
    } else {
      return roundedNearestZero(Math.round(perPersonPrice) * totalGuestsWithInfants)
    }
  } else {
    return 0
  }
};

// export const calculateDeposit = ({ tripDate, ticketingDate, totalCost, totalGuests, totalGuestsWithInfants, flightPrice, depositeConfiguration }: DepositProps): number => {
//   const tripDateDiff = dayjs(tripDate).diff(dayjs(), 'day');
//   const regexPattern = /(\d{2}[A-Z]{3}\d{2})/; // Matches "27JUN24" format
//   const match = ticketingDate.match(regexPattern);
//   const extractedDate = match ? match[0] : ticketingDate;
//   const ticketingDateDiff = dayjs(extractedDate).diff(dayjs(), 'day');

//   const BeforeFullPayment = Number(depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue) ? Number(depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue) : 0
//   const DepositPercent = Number(depositeConfiguration?.DepositPercentDefault) ? Number(depositeConfiguration?.DepositPercentDefault) : 0
//   const MinimumDeposit = Number(depositeConfiguration?.MinimumDepositAmountPP) ? Number(depositeConfiguration?.MinimumDepositAmountPP) : 0
//   if (tripDateDiff <= BeforeFullPayment || ticketingDateDiff <= BeforeFullPayment) {
//     // If tripdate is less than or equal to 90 days then take full payment
//     return 0;
//   } else if (tripDateDiff > BeforeFullPayment && ticketingDateDiff < BeforeFullPayment) {
//     // If tripdate is more than 90 days and ticketing date is less than 90 days
//     // take 10 % deposit of total price 
//     // add flightprice with 10 % discount price 
//     // If includedFlights is less than 200 return 200 else price
//     // infants are added only when includedFlights is more than 200 

//     const discountPrice = Math.round((DepositPercent / 100) * totalCost);
//     const includedFlights = discountPrice + flightPrice
//     const perPersonPrice = includedFlights <= MinimumDeposit ? includedFlights / totalGuests : includedFlights / totalGuestsWithInfants

//     if (perPersonPrice <= MinimumDeposit) {
//       return MinimumDeposit * totalGuests
//     } else {
//       return Math.round(perPersonPrice) * totalGuestsWithInfants
//     }
//   } else if (tripDateDiff > BeforeFullPayment && ticketingDateDiff >= BeforeFullPayment) {
//     // If tripdate is more than 90 days and ticketing date is more than 90 days
//     // take 10 % deposit of total price 
//     // If discountPrice is less than 200 return 200 else price
//     // infants are added only when discountPrice is more than 200 

//     const discountPrice = Math.round((DepositPercent / 100) * totalCost);
//     const perPersonPrice = discountPrice <= MinimumDeposit ? discountPrice / totalGuests : discountPrice / totalGuestsWithInfants

//     if (perPersonPrice <= MinimumDeposit) {
//       return MinimumDeposit * totalGuests
//     } else {
//       return Math.round(perPersonPrice) * totalGuestsWithInfants
//     }
//   } else {
//     return 0;
//   }
// };

// export const calculateDeposit = ({ tripDate, ticketingDate, totalCost, totalGuests, totalGuestsWithInfants, flightPrice }: DepositProps): number => {
//   const tripDateDiff = dayjs(tripDate).diff(dayjs(), 'day');
//   const ticketingDateDiff = dayjs(ticketingDate).diff(dayjs(), 'day');

//   if (tripDateDiff <= 90) {
//     return 0;
//   } else if (tripDateDiff > 90 && ticketingDateDiff < 90) {
//     const discountPrice = Math.round((10 / 100) * totalCost);
//     const flightPricePerPerson = discountPrice <= 200 ? (flightPrice / totalGuests) : (flightPrice / totalGuestsWithInfants)
//     const includedFlights = discountPrice + flightPricePerPerson
//     const perPersonPrice = includedFlights <= 200 ? includedFlights / totalGuests : includedFlights / totalGuestsWithInfants

//     if (perPersonPrice <= 200) {
//       return 200
//     } else {
//       return Math.round(perPersonPrice)
//     }

//   } else if (tripDateDiff >= 90 && ticketingDateDiff >= 90) {
//     const discountPrice = Math.round((10 / 100) * totalCost);
//     const perPersonPrice = discountPrice <= 200 ? discountPrice / totalGuests : discountPrice / totalGuestsWithInfants

//     if (perPersonPrice <= 200) {
//       return 200
//     } else {
//       return Math.round(perPersonPrice)
//     }
//   } else {
//     return 0;
//   }
// };