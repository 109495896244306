import { Box, Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

const PageNotFound = () => {
    const navigate = useNavigate()
    return (
        <Box display={"flex"} justifyContent={"center"} mt="40px" flexDirection={"column"} alignItems={"center"} width={"100%"}>
            <Typography pt={"40px"} variant="h1" fontSize={100}>
                404
            </Typography>
            <Box mt={2} fontWeight={600} fontSize={30} >
                Not Found
            </Box>
            <Typography my={2}>
                Try searching for something else
            </Typography>

            <Button onClick={() => { navigate(`/${window.location.search}`) }} style={{ width: "auto" }}>
                New Search
            </Button>
        </Box>
    )
}

export default PageNotFound