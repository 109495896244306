import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';

export const postUserFolderDetails: any = createAsyncThunk(
  'createFolder/postUserFolderDetails',
  async (res) => {
    const response = await API.post(API_METHOD.Misc.CREATE_FULL_FOLDER, res);
    return response.data;
  }
);
const initialState = {
  folderNumber: '',
};
const addHotelFlightDetailsToFolderSlice = createSlice({
  name: 'createFolder',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [postUserFolderDetails.fulfilled]: (state, { payload }): void => {
      state.folderNumber = payload;
    },
  },
});

export default addHotelFlightDetailsToFolderSlice.reducer;
