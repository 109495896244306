import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';

export const fetchUserInformationAPI: any = createAsyncThunk(
  'userInformation/fetchUserInformationAPI',
  async (resp) => {
    const response = await API.post(API_METHOD.Misc.USER_INFORMATION, resp);
    return response.data;
  }
);
interface initialStateProps {
  userDetails: Record<any, any>;
}

const initialState: initialStateProps = {
  userDetails: {},
};

const userInformationSlice = createSlice({
  name: 'userInformation',
  initialState,
  reducers: {
    setUserDetails: (state, { payload }) => {      
      if (payload) {
        state.userDetails = payload;
      }
    },
  },
});

export const { setUserDetails } = userInformationSlice.actions;
export default userInformationSlice.reducer;
