import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';

export const fetchAsyncHotelDetails: any = createAsyncThunk(
  'hotel_details/fetchAsyncHotelDetails',
  async (id) => {
    const response = await API.post(API_METHOD.Hotel.GET_HOTEL_DETAILS, [id]);
    return response.data;
  }
);

const initialState: any = {
  hotelDetails: {},
};

const hotelDetailSlice = createSlice({
  name: 'hoteldetails',
  initialState,
  reducers: {
    setHotelDetailsState: (state, { payload }) => {
      state.hotelDetails = payload;
    },
  },
});

export const { setHotelDetailsState } = hotelDetailSlice.actions;

export default hotelDetailSlice.reducer;
