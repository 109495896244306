import React from 'react';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { colors } from '../styles/colors';
export const ItineraryNotification = () => {
  const matches = useMediaQuery('(max-width:600px)');
  return (
    <Box
      p={{ xs: '25px 35px 17px', sm: '30px', lg: '25px 35px 17px' }}
      sx={{
        border: { xs: `1px solid ${colors.basics.primary}`, sm: '1px solid #FF4B33' },
        boxSizing: 'border-box',
      }}
    >
      <Box>
        <Box
          display='flex'
          width={{ sm: '50%' }}
          flex={{ sm: '0 0 100%' }}
          justifyContent='space-between'
          marginBottom='25px'
        >
          <Typography variant='subtitle2'>Itinerary AB 234 5678 12</Typography>
          <Typography variant='subtitle2'>Sugar Bay Barbados</Typography>
        </Box>
        <Box>
          <Box>
            <Typography variant='subtitle2' color={colors.basics.primary} display='inline'>
              Important Notfication:
            </Typography>
            <Typography variant='subtitle2' color={colors.basics.black} display='inline'>
              This itinerary has changed as follows.
            </Typography>
          </Box>
          <Box display={{ xs: 'block', sm: 'flex' }} justifyContent='space-between' flexWrap='wrap' mt='10px'>
            <Box width={{ sm: '719px' }} mb={3} ml={{ lg: '30px', md: '30px', sm: '30px', xs: '20px' }}>
              <ul>
                <li>
                  <Typography
                    variant='subtitle2'
                    color={colors.basics.black}
                    fontSize='16px'
                    fontWeight={300}
                    lineHeight='130%'
                  >
                    The price of the rooms has increased by £150
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant='subtitle2'
                    fontSize='16px'
                    fontWeight={300}
                    lineHeight='130%'
                    color={colors.basics.black}
                  >
                    The flight are no longer available. New flights have been selected, with increase in price
                    of £200
                  </Typography>
                </li>
              </ul>
            </Box>
            <Box
              display={{ md: 'flex', sm: 'flex', xs: 'block' }}
              justifyContent='space-between'
              width='100%'
            >
              <Button
                variant={matches ? 'outlined' : 'contained'}
                sx={{
                  color: matches ? '' : colors.grey[30],
                  background: matches ? '' : colors.grey[20],
                  border: matches ? '' : 'none',
                  height: '50px',
                  whiteSpace: 'nowrap',
                  width: { md: 'auto', sm: 'auto', xs: '100%' },
                  '&:hover': { background: matches ? '' : colors.grey[20] },
                }}
              >
                Edit Itinerary
              </Button>

              <Button
                variant='contained'
                sx={{
                  marginLeft: { md: '20px', sm: '20px', xs: 0 },
                  marginTop: { md: 0, sm: 0, xs: '10px' },
                  height: '50px',
                  whiteSpace: 'nowrap',
                  width: { md: 'auto', sm: 'auto', xs: '100%' },
                }}
              >
                Accept change
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
