import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/useAppReducer';

const PublicRoute = ({ children }: any) => {
    const { userDetails } = useAppSelector((state) => state.userInformation);
    const isUserLoggedIn = Object.keys(userDetails).length > 0;
    return isUserLoggedIn ? <Navigate to={'/'} /> : children;
};

export default PublicRoute;
