import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';

export const getBookingHistory: any = createAsyncThunk(
    'getBookingHistory/getBookingHistory',
    async (res) => {
        const response = await API.post(API_METHOD.Misc.GET_BOOKING_HISTORY, res);
        return response.data;
    }
);