export const setSessionStorage = (key: string, value: any) => {
    return sessionStorage.setItem(key, value)
}

export const getSessionStorage = (key: string) => {
    return sessionStorage.getItem(key)
}

export const removeSessionStorage = (key: string) => {
    return sessionStorage.removeItem(key)
}

export const clearSession = () => {
    sessionStorage.clear()
}

export const clearLocalStorage = () => {
    localStorage.clear()
}

export const setLocalStorage = (key: string, value: any) => {
    return localStorage.setItem(key, value)
}

export const getLocalStorage = (key: string) => {
    return localStorage.getItem(key)
}

export const removeLocalStorage = (key: string) => {
    return localStorage.removeItem(key)
}

export const clearSessionValues = () => {
    setSessionStorage("more-details-click", "false")
    setSessionStorage("apiCall", "false")
    setSessionStorage("folderApiCall", "false")
    setSessionStorage("click", "true")
    setSessionStorage("mapApiCall", "false")
    setSessionStorage("previous", "false")
    setSessionStorage("nextAndSelectedFlights", "false")
    setSessionStorage("stepper", "0")
    setSessionStorage("holidayRetrieveApiCall", "false")
    setSessionStorage("transferApiCall", "false")
    setSessionStorage("holidayPageLoad", "false")
}