import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';

export const fetchAsyncChangeFlight: any = createAsyncThunk(
  'changeFlight/fetchAsyncChangeFlight',
  async (term) => {
    const res = await API.post(API_METHOD.Package.CHANGE_FLIGHTS, term);
    return res.data;
  }
);

const initialState = {
  flightOptions: [],
  status: '',
};

const changeFlightSlice = createSlice({
  name: 'changeFlight',
  initialState,
  reducers: {
    setFlightOptions: (state, { payload }): void => {
      state.flightOptions = payload.payload;
      if (payload.payload) {
        state.status = 'OK';
      }
    },
    clearFlights: (state): any => {
      state.status = '';
      state.flightOptions = [];
    },
  },
  extraReducers: {
    [fetchAsyncChangeFlight.pending]: (state) => {
      state.status = '';
      state.flightOptions = [];
    },
    [fetchAsyncChangeFlight.fulfilled]: (state, { payload }) => {
      state.status = 'OK';
    },
    [fetchAsyncChangeFlight.rejected]: (state) => {
      state.status = '';
      state.flightOptions = [];
    },
  },
});

export default changeFlightSlice.reducer;
export const { setFlightOptions, clearFlights } = changeFlightSlice.actions;
