import React from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import DrawerRoot from './Drawer';
import { CustomIconButton } from '../components/CustomIconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as Search } from '../assets/icons/Search.svg';
import { colors } from '../styles/colors';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerState } from '../features/drawer/drawerSlice';

interface searchDrawerProps {
  onChange: (value: any) => void;
  onSearchButtonClick: () => void;
  placeholder?: string;
}

export const SearchDrawer = ({ onChange, onSearchButtonClick, placeholder }: searchDrawerProps) => {
  const dispatch = useDispatch();
  const drawerState = useSelector((state: any) => state.drawer.drawerState);
  return (
    <Box>
      <DrawerRoot direction='right' width='100%' DrawerRightRootClassName='drawer-main-search'>
        <>
          <Box
            display='flex'
            padding='20px 30px'
            sx={{ flexFlow: { xs: 'column nowrap', sm: 'row nowrap' } }}
          >
            <Box flexGrow={1} flex='0 0 80%' mb={{ xs: '1rem', sm: 0 }}>
              <Typography marginBottom='0.5rem' fontSize='20px' display='inline-block'>
                Search by keyword
              </Typography>
              <TextField
                onChange={(e) => onChange(e.target.value)}
                id='input-with-icon-textfield'
                InputProps={{
                  startAdornment: <Search />,
                  disableUnderline: true,
                }}
                fullWidth
                variant='standard'
                sx={{
                  height: '41px',
                  backgroundColor: colors.basics.lightmain,
                  justifyContent: 'center',
                  width: { sm: '100%', xs: '100%' },

                  '& .MuiInputBase-input': {
                    paddingLeft: '10px',
                  },
                  '& .MuiInput-root': {
                    paddingLeft: '10px',
                  },
                  '& svg': { width: '20px', height: '17px' },
                }}
                placeholder='Enter search'
              />
            </Box>
            <Box
              onClick={() => onSearchButtonClick()}
              justifyContent='flex-end'
              alignSelf='flex-end'
              marginLeft='1rem'
              flex='0 0 20%'
              sx={{
                '& .FooterButton': {
                  height: '50px',
                  padding: '18px',
                },
              }}
            >
              <CustomIconButton
                label='Search'
                onClick={() => { }}
                icon={<Search />}
                className='FooterButton'
              />
            </Box>
          </Box>
          <Box position='absolute' bottom='76px' right='12px'>
            <Button
              variant='text'
              endIcon={<CloseIcon />}
              sx={{ ml: '10px' }}
              onClick={() => dispatch(setDrawerState({ ...drawerState, right: false }))}
            >
              Close
            </Button>
          </Box>
        </>
      </DrawerRoot>
    </Box>
  );
};

export default SearchDrawer;
