import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Snackbar, Tooltip, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import React, { useCallback, useEffect, useState } from 'react';
import banner from '../../assets/images/banner.png';
import { CustomContainer } from '../../components/CustomContainer';
import { colors } from '../../styles/colors';
import { ReactComponent as ExpandIcon } from '../../assets/icons/ExpandIcon.svg';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
// import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { ReactComponent as KeyboardDoubleArrowRightIcon } from '../../assets/icons/DoubleArrow.svg';
import { ReactComponent as Bed } from '../../assets/icons/Bed.svg';
import { ReactComponent as Building } from '../../assets/icons/Building.svg';
import { ReactComponent as Calender } from '../../assets/icons/Calender.svg';
import { ReactComponent as Car } from '../../assets/icons/Car.svg';
import { ReactComponent as Person } from '../../assets/icons/Person.svg';
import { ReactComponent as Pound } from '../../assets/icons/Pound.svg';
import { ReactComponent as Spoon } from '../../assets/icons/Spoon.svg';
import { ReactComponent as Euro } from '../../assets/icons/Euro.svg';
import { ReactComponent as Dollar } from '../../assets/icons/Dollar.svg';
import LuggageIcon from '@mui/icons-material/Luggage';
import { API_METHOD } from '../../api/services/apiMethods';
import { isEmpty } from '../../helpers/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter';
import { BookingConfirmationEmail } from '../../components/BookingConfirmationEmail';
import { API } from '../../api/services';
import { capitalizeFirstLetterEachWords } from '../../helpers/capitalizeFirstLetterEachWords';
import { checkUserEmailIsExist } from '../../features/actions';
import dayjs from 'dayjs';
import { dateSplit } from '../../helpers/dateSplit';
import { RoomMealWrapper } from '../../helpers/roomMealWrapper';
import { setLottieLoadingConfirm } from '../../features/lottieLoader/lottieLoader';
import Swal from 'sweetalert2';
import { ApplicationStateType } from '../../features/store';
import { setLoading } from '../../features/loading/loadingSlice';
import { getFolderDetails } from '../../features/profileDetails/getFolderSlice';
import { generateUniqueSessionId } from '../../helpers/randomSessionId';
import { branchCode } from '../../constants/branchCode';
import { formatStringTime } from '../../helpers/formatStringTime';

interface status {
  email: boolean,
  itinearary: boolean
}
interface CABIN_CLASS_ENUM_TYPE {
  Any: string;
  Economy: string;
  Premium: string;
  'Business Class': string;
  'First Class': string;
}
interface emailStatus {
  is_email_sent: boolean,
  is_confirm_itinerary_call: boolean
}

interface VisibleItem {
  fold_no: any;
  location_id: string;
  HotelInfo: {
    cover_image: string;
    hotel_name: string;
    hotel_id?: string | number;
  };
  start_date_time: string;
  end_date_time: string;
  Payable: string;
  branch_code: string;
}
type SegmentType = {
  fi_type: string;
  start_date_time: string;
  end_date_time: string;
  linked_passengers: string;
  class_name: string;
};
type PageDataType = {
  Segment: SegmentType;
  HotelPreferred?: {
    hotel_id: string
  }
};
type PassengerData = {
  Passenger: {
    pax_type: string;
    fi_type: string;
    pax_no: string;
    age: number;
    birth_date: Date;
  };
};
const CABIN_CLASS_ENUM: CABIN_CLASS_ENUM_TYPE = {
  Any: '',
  Economy: 'M',
  Premium: 'W',
  'Business Class': 'C',
  'First Class': 'F',
};

const BookingSuccessfulSummary = () => {
  const [bookingData, setBookingData] = useState<any>({})
  const [open, setOpen] = useState(false)
  const [isUserRegister, setIsUserRegister] = useState(false)
  const { loading } = useSelector((state: ApplicationStateType) => state.loading);
  const dispatch = useDispatch();
  const url = window.location.search
  const urlSearchParams = new URLSearchParams(url);
  const queryData: any = {};
  urlSearchParams.forEach((value, key) => {
    queryData[key] = value;
  });

  const folderNo = queryData?.folder || ""
  const email = queryData?.email
  const [isFolderNoExists, setIsFolderNoExist] = useState(folderNo !== "" ? true : false)
  const itineraryNumber = bookingData?.itinerary_number ? bookingData?.itinerary_number : "1"
  const isView = queryData?.isView || false
  const vertical = "top"
  const horizontal = "right"
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL

  const getHolidaySummaryDetails = useCallback(async () => {
    try {
      const result = await API.post(API_METHOD.Others.GET_HOLIDAY_SUMMARY + `?folder_no=${folderNo}&email=${email}`);
      const data = [...result.data];
      if (data?.length > 0) {
        setBookingData(data[0]);
        if (!isView) {
          checkEmailSendStatus()
        }
      } else {
        setIsFolderNoExist(false)
        dispatch(setLottieLoadingConfirm(false));
        Swal.fire({
          title: "Something Went Wrong",
          text: 'Check you Email or Folder No.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: colors.basics.primary,
          cancelButtonColor: colors.basics.primary,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
          customClass: {
            confirmButton: 'confirmButton',
            actions: 'actions',
            icon: "primary-icon-class",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            setIsFolderNoExist(false)
          }
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Something Went Wrong",
        text: 'Check you Email or Folder No.',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: colors.basics.primary,
        cancelButtonColor: colors.basics.primary,
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          confirmButton: 'confirmButton',
          actions: 'actions',
          icon: "primary-icon-class",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setIsFolderNoExist(false)
        }
      });
      console.log('Error:', error);
      dispatch(setLottieLoadingConfirm(false));
    }
  }, []);

  const checkUserEmail = async () => {
    try {
      const result = isView && await dispatch(checkUserEmailIsExist(email));
      if (result?.payload === "1") {
        setIsUserRegister(true)
      }
    } catch (error) {
      console.log('Error:', error);
    }
  }

  const dateDiffInMonths = (a: Date, b: Date): number => {
    const monthsDiff = (b.getFullYear() - a.getFullYear()) * 12 + (b.getMonth() - a.getMonth());
    if (b.getDate() < a.getDate()) {
      return monthsDiff - 1;
    }
    return monthsDiff;
  }
  const calculateInfantAge = (date: Date): number => {
    const date1 = new Date(date)
    const date2 = new Date();
    const diffInMonths = dateDiffInMonths(date1, date2);
    return diffInMonths
  }

  const newSearch = async (folder_no: VisibleItem): Promise<void> => {
    dispatch(setLoading(true))
    const b_code = bookingData?.branch_code ? bookingData?.branch_code : branchCode
    const { payload } = await dispatch(
      getFolderDetails([{ fold_no: folder_no, branch_code: b_code }])
    );
    let hotelId: string | undefined = ""
    if (payload) {
      const pageData = payload?.pagedata
      const paxData = payload?.pax
      const folder = payload?.folderDetails?.Folder
      let destination: string = "";
      let departure: string = "";
      let dateRange: string = "";
      let cabin_class: string = ""
      let room_count: string = ""
      let linkedPassesnger: string[] = [];
      let itineraryNumber = ""

      if (pageData.length > 0) {
        let dateArr = []
        itineraryNumber = pageData[0]?.Segment.itinerary_id
        const filterHotel: PageDataType[] = pageData.filter((p: PageDataType) => p.Segment.fi_type === "HOT")
        hotelId = filterHotel.length > 0 ? filterHotel[0]?.HotelPreferred?.hotel_id.toString() : ""

        const filterAir: PageDataType[] = pageData.filter((p: PageDataType) => p.Segment.fi_type === "AIR")
        if (filterHotel.length > 0) {
          filterHotel.forEach((f) => {
            if (isEmpty(f.Segment.linked_passengers) !== "") {
              const dataArray = f.Segment.linked_passengers.split(',').map((item: any) => item.trim()).filter(Boolean);
              const uniqueSet = new Set(dataArray);
              const uniqueArray = Array.from(uniqueSet);
              linkedPassesnger.push(uniqueArray.join(','));
            }
          })
        }
        if (filterAir.length > 0) {
          dateArr.push(new Date(filterAir[0].Segment.start_date_time).toLocaleDateString())
          dateArr.push(new Date(filterAir[filterAir.length - 1].Segment.end_date_time).toLocaleDateString())

          let cabinClassObj = {
            Any: '',
            Economy: 'M',
            Premium: 'W',
            'Business': 'C',
            'First': 'F',
          }
          const foundClass: string | undefined = filterAir[0].Segment.class_name ? Object.keys(cabinClassObj).find(travelClass => {
            return filterAir[0].Segment.class_name.toLowerCase().includes(travelClass.toLowerCase())
          }) : "";

          let isAnyClassFound: string | undefined = ""
          if (foundClass !== undefined && foundClass !== "") {
            isAnyClassFound = Object.keys(CABIN_CLASS_ENUM).find(travelClass => {
              return travelClass.toLowerCase().includes(foundClass.toLowerCase())
            })
          }

          cabin_class = isAnyClassFound !== undefined && isAnyClassFound !== "" ? isAnyClassFound.split(" ").join("_") : ""

        }
        departure = pageData[0].Segment.start_point_code
        dateRange = dateArr.join(",")
        room_count = filterHotel.length.toString()
      }

      let childAge: { [key: number]: number }[] = [];
      let infantAge: { [key: number]: number }[] = [];
      let adult: number[] = [];
      let children_count: number[] = [];
      let infant_count: number[] = [];

      if (paxData.length > 0) {
        const childData: PassengerData[] = paxData.filter((p: PassengerData) => p.Passenger.pax_type === "CHD")
        const infantData: PassengerData[] = paxData.filter((p: PassengerData) => p.Passenger.pax_type === "INF")
        const adultData: PassengerData[] = paxData.filter((p: PassengerData) => p.Passenger.pax_type === "ADT")

        linkedPassesnger.forEach((p) => {
          let obj: { [key: number]: number } = {}
          let infantObj: { [key: number]: number } = {}
          let index = 0
          let infantIndex = 0

          let adultIndex = 0

          adultData.forEach((c) => {
            if (p.split(",").includes(c.Passenger.pax_no)) {
              adultIndex = adultIndex + 1
            }
          })

          adult.push(adultIndex)

          childData.forEach((c) => {
            if (p.split(",").includes(c.Passenger.pax_no)) {
              obj[index + 1] = c.Passenger.age
              index = index + 1
            }
          })

          children_count.push(index)

          infantData.forEach((c) => {
            if (p.split(",").includes(c.Passenger.pax_no)) {
              infantObj[infantIndex + 1] = calculateInfantAge(c.Passenger.birth_date)
              infantIndex = infantIndex + 1
            }
          })

          infant_count.push(infantIndex)
          infantAge.push(infantObj)
          childAge.push(obj)
        })
      }

      destination = folder.arrival_point_code + ";" + folder.destination_id
      dispatch(setLoading(false))
      const session_id = generateUniqueSessionId();
      const url = process.env.REACT_APP_LIVE_URL
      window.open(`${url}holiday-summary/${session_id}/?folder=${null}&hotel_id=${hotelId}&itinerary_number=${1}&departure=${departure}&destination=${destination}&daterange=${dateRange}&cabin_class=${cabin_class}&room_count=${room_count}&adult=${adult.join(",")}&children_count=${children_count.join(",")}&child_age=${JSON.stringify(childAge)}&infant_count=${infant_count.join(",")}&infant_age=${JSON.stringify(infantAge)}`, '_blank');
    } else {
      dispatch(setLoading(false))
    }
  };

  const confirmItinerary = useCallback(async (status: status, data: emailStatus[]) => {
    try {
      const body = [{
        "folderNumber": Number(folderNo),
        "itineraryNumber": itineraryNumber,
        "validateOnly": "0",
        "api": 1,
        branchCode: branchCode
      }];
      const res = await API.post(API_METHOD.Misc.CONFIRM_ITINERARY, body);
      // const validateFlightsStatus = res?.data?.validateflights?.status;
      // const validateHotelStatus = res?.data?.validatehotel?.status;;
      // let swalText = '';
      // if ((validateFlightsStatus === 'NO' || validateFlightsStatus === 'false') && (validateHotelStatus === 'NO' || validateHotelStatus === 'false')) {
      //   swalText = 'Your chosen flight and hotel room are not available anymore, please update to an alternative flight and room or alternatively you can do a new search.';
      // } else if (validateFlightsStatus === 'NO' || validateFlightsStatus === 'false') {
      //   swalText = 'Your chosen flight is not available anymore, please change the flight or alternatively you can do a new search.';
      // } else if (validateHotelStatus === 'NO' || validateHotelStatus === 'false') {
      //   swalText = 'Your chosen hotel room is not available anymore, please update to another room or alternatively you can do a new search.';
      // }
      if (res?.data) {
        // if (res?.data?.itineraryconfirmed?.status === "OK") {
          status.itinearary = true
          if (!data[0].is_email_sent) {
            await sendEmail(status)
          }
      //   }
      //    else {
      //     status.itinearary = false

      //     Swal.fire({
      //       title: "Something Went Wrong",
      //       text: 'One or more of your travel elements may not be available.',
      //       icon: 'warning',
      //       showCancelButton: false,
      //       confirmButtonColor: colors.basics.primary,
      //       cancelButtonColor: colors.basics.primary,
      //       confirmButtonText: 'New Search',
      //       allowOutsideClick: false,
      //       allowEscapeKey: false,
      //       customClass: {
      //         confirmButton: 'confirmButton',
      //         actions: 'actions',
      //         icon: "primary-icon-class",
      //       },
      //     }).then((result) => {
      //       if (result.isConfirmed) {
      //         newSearch(folderNo)
      //       }
      //     });
      //   }
      // } 
      // else {
      //   status.itinearary = false
      //   Swal.fire({
      //     title: "Something Went Wrong",
      //     text: 'One or more of your travel elements may not be available.',
      //     icon: 'warning',
      //     showCancelButton: false,
      //     confirmButtonColor: colors.basics.primary,
      //     cancelButtonColor: colors.basics.primary,
      //     confirmButtonText: 'New Search',
      //     allowOutsideClick: false,
      //     allowEscapeKey: false,
      //     customClass: {
      //       confirmButton: 'confirmButton',
      //       actions: 'actions',
      //       icon: "primary-icon-class",
      //     },
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       newSearch(folderNo)
      //     }
      //   });
      }

      dispatch(setLottieLoadingConfirm(false));
    } catch (error) {
      status.itinearary = false
      dispatch(setLottieLoadingConfirm(false));
    }
  }, [folderNo, dispatch]);

  const checkEmailSendStatus = useCallback(async () => {
    try {
      const res = await API.post(API_METHOD.Others.GET_EMAIL_ITINERARY_STATUS + `?folder_no=${folderNo}&email=${email}`);
      let data = res?.data || []
      // let data = [{
      //   is_email_sent: true,
      //   is_confirm_itinerary_call: false
      // }]

      if (data.length > 0) {
        let obj: status = {
          email: data[0].is_email_sent,
          itinearary: data[0].is_confirm_itinerary_call
        }

        if (!data[0].is_confirm_itinerary_call) {
          await confirmItinerary(obj, data);
        }

        if (!data[0].is_email_sent || !data[0].is_confirm_itinerary_call) {
          const res = await API.post(API_METHOD.Others.SAVE_EMAIL_ITINEARY_STATUS + `?folder_no=${folderNo}&email=${email}&is_email_sent=${obj.email}&is_confirm_itinerary_call=${obj.itinearary}`);
          res && await dispatch(setLottieLoadingConfirm(false));
        } else {
          dispatch(setLottieLoadingConfirm(false));
        }

      } else {
        dispatch(setLottieLoadingConfirm(false));
      }

    } catch (error) {
      console.log('Error:', error);
      dispatch(setLottieLoadingConfirm(false));
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (folderNo) {
        dispatch(setLottieLoadingConfirm(true));
        await checkUserEmail()
        await getHolidaySummaryDetails();
        if (isView) {
          dispatch(setLottieLoadingConfirm(false));
        }
      }
    };
    fetchData();
  }, [folderNo, dispatch, isView, getHolidaySummaryDetails, confirmItinerary]);

  const sendEmail = useCallback(async (status: status) => {
    try {
      const element = document.getElementById("booking-table") || null;
      let obj = {
        "email": email,
        "subject": `Your booking was successful and your reference number is ${folderNo}`,
        "htmlText": element?.innerHTML
      };
      const resp = await API.post(API_METHOD.Email.SEND_BOOKING_CONFIRMATION_EMAIL, obj);
      if (resp.data.item1) {
        await setOpen(true);
        status.email = true
      } else {
        status.email = false
      }
    } catch (error) {
      status.email = false
      console.log('Error:', error);
    }
  }, [email, folderNo]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  }, [open]);

  return (
    <>
      {isFolderNoExists ? (Object.keys(bookingData).length > 0 && folderNo !== "") ? <Box mb='20px'>
        <CustomContainer>
          <Box sx={{
            width: '100%', display: "flex", flexDirection: "column", alignItems: "center", '& svg': {
              fontSize: "1.7rem",
            },
          }}>
            <Box
              width={{ md: '50%', xs: '100%' }}
              display={{ sm: "flex", xs: "block" }}
              alignItems='flex-start'
              bgcolor={colors.basics.secondary}
              border={`1px solid ${colors.grey[30]}`}
              padding={{ sm: '30px 40px', xs: '30px 10px' }}
              mt='40px'
              mb='30px'
              sx={{ borderRadius: '5px' }}
              flexGrow={1}
              height={'fit-content'}
            >
              <Box sx={{
                textAlign: { xs: "center", sm: "" }
              }} mr={{ md: '40px', xs: '10px' }} mb={{ xs: "10px", sm: "0px" }}>
                <CheckCircleOutlineIcon sx={{ height: '40px', width: '40px', color: colors.basics.primary }} />
              </Box>
              <Box>
                <Box mb='15px'>
                  <Typography textAlign={{ xs: "center", sm: "left" }} variant='h2' fontSize='28px' fontWeight={600}>
                    Thank you for choosing to book with American Sky
                  </Typography>
                </Box>
                <Box mb='15px' sx={{ display: { sm: 'flex', xs: 'block' }, textAlign: { xs: "center", sm: "" } }}>
                  <Typography variant='body1' fontSize='18px' fontWeight={300}>
                    Your Booking reference is <b> TSR{folderNo}</b>
                  </Typography>
                </Box>

                <Box sx={{
                  textAlign: { xs: "center", sm: "left" }
                }} mb='15px'>
                  <Typography variant='body1' fontSize='18px' fontWeight={300}>
                    A payment receipt will be emailed to <b>{email}</b> shortly.
                  </Typography>
                </Box>

                {/* {isUserRegister && <Box sx={{
                  textAlign: { xs: "center", sm: "left" }
                }} mb='15px'>
                  <Typography variant='body1' fontSize='18px' fontWeight={300}>
                    All elements of your holiday have been requested, and we are working on confirming them with our suppliers. We aim to have your invoice issued within 48 hours.
                  </Typography>
                </Box>} */}
                <Box sx={{
                  textAlign: { xs: "center", sm: "left" }
                }} mb='15px'>
                  <Typography variant='body1' fontSize='18px' fontWeight={300}>
                    All elements of your holiday have been requested, and we are working on confirming them with our suppliers. We aim to have your invoice issued within 48 hours.
                  </Typography>
                </Box>
                <Box sx={{
                  textAlign: { xs: "center", sm: "left" }
                }}>
                  <Typography variant='body1' fontSize='18px' fontWeight={300}>
                    If you already have an online account, booking details can found in your dashboard. Alternatively, if you have elected to have an online account, we have sent an email containing a temporary password to your account. This link is valid for 24 hours.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              width={{ md: '50%', xs: '100%' }}
              sx={{
                maxWidth: { xs: '100%', md: '50%' },
                marginLeft: { xs: '0px' },
                // width: '100%',
                mt: '20px',
              }}
            >
              <Box
                bgcolor={colors.basics.secondary}
                sx={{
                  '& .MuiPaper-root': {
                    border: `1px solid ${colors.grey[30]}`,
                  },
                  '& .MuiAccordionDetails-root': {
                    width: '100%',
                    padding: { sm: '0 16px 16px', xs: "0 8px 8px" },
                  },
                  '& .MuiAccordionSummary-root': {
                    backgroundColor: colors.basics.secondary,
                    borderRadius: '5px',
                  },
                  '& .Mui-expanded': {
                    marginLeft: 'auto',
                    background: 'transparent',
                    transform: 'none !important',
                    justifyContent: 'flex-start !important',
                    pointerEvents: "none",
                    '&>svg': {
                      transform: 'rotate(90deg) !important',
                    },
                  },
                  '& .Mui-expanded>svg ': {
                    display: 'none',
                  },
                }}
              >
                <Accordion expanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <Typography variant='body1' fontSize={'16px !important'} fontWeight={'500'} lineHeight={'21px'}>{`${bookingData?.destination_name} Holiday Summary`}</Typography>
                  </AccordionSummary>
                  {bookingData && Object.keys(bookingData).length > 0 ? (
                    <AccordionDetails >
                      <Box
                        sx={{
                          '& ul': {
                            padding: '0',
                            listStyle: 'none',
                            '& li': {
                              padding: { sm: '10px 18px', xs: '5px' },
                              '& .content': {
                                alignSelf: 'center'
                              },
                              '&:nth-of-type(odd)': {
                                background: colors.grey[20],
                              },
                            },
                          },
                          '.Icon': {
                            width: '100%',
                            maxWidth: '40px',
                          },
                        }}
                      >
                        <ul>
                          <li>
                          <Box display='flex' mb='6px'>
                              <Box
                                className='Icon'
                                // mt={0.4}
                                sx={{
                                  '& svg': {
                                    fill: colors.basics.primary,
                                    transform: 'rotate(-270deg)',
                                  },
                                }}
                              >
                                <Tooltip title='Outbound'>
                                  <AirplanemodeActiveIcon />
                                </Tooltip>
                              </Box>
                              <Box display={'flex'} alignItems={'center'}>
                                <Typography variant='body1' fontSize={'18px'} color={colors.basics.primary} fontWeight={500} lineHeight={'1.2'}>
                                  Outbound Flight
                                </Typography>
                              </Box>
                            </Box>
                            <Box display='flex' flexDirection='column'>
                              <Box display='flex' mb='14px'>
                                <Box
                                  className='Icon'
                                  // mt={0.4}
                                  sx={{
                                    '& svg': {
                                      fill: colors.basics.primary,
                                      transform: 'rotate(-270deg)',
                                    },
                                  }}
                                >
                                  {/* <Tooltip title='Outbound'>
                                    <AirplanemodeActiveIcon />
                                  </Tooltip> */}
                                </Box>
                                <Box className='content'>
                                  <Typography variant='body1' fontWeight={500} lineHeight={'1.2'}>
                                    {bookingData?.flight?.outbound?.arrival_date} {isEmpty(bookingData?.flight?.outbound?.airline_name) !== "" && <>
                                      {" - " + `${bookingData?.flight?.outbound?.airline_name + " - "} (${bookingData?.flight?.outbound?.airline_code})`}
                                    </>}
                                  </Typography>
                                  <Typography variant='body1' fontWeight={500} lineHeight={'1.2'}>
                                    {/* {isEmpty(bookingData?.flight?.outbound?.airline_name) !== "" && <>
                                      {`${bookingData?.flight?.outbound?.airline_name + " - "} (${bookingData?.flight?.outbound?.airline_code})`}
                                    </>} */}
                                  </Typography>
                                  {isEmpty(bookingData?.flight?.outbound?.arrival_airport_name) !== "" && isEmpty(bookingData?.flight?.outbound?.departure_airport_name) !== "" &&
                                    <Typography
                                      variant='body1'
                                      fontWeight={500}
                                      lineHeight={'1.2'}
                                      sx={{
                                        '& svg': {
                                          display: "inline-block",
                                          verticalAlign: "center",
                                          height: { sm: "14px", xs: "12px" },
                                          width: { sm: "14px", xs: "12px" },
                                          ml: "4px"
                                        }
                                      }}
                                    >
                                      {isEmpty(bookingData?.flight?.outbound?.arrival_time) !== "" && " (" + isEmpty(bookingData?.flight?.outbound?.arrival_time) + ") -  "}
                                      <span >{isEmpty(bookingData?.flight?.outbound?.arrival_airport_name)} <KeyboardDoubleArrowRightIcon /></span>
                                      {' '}
                                    </Typography>
                                  }
                                  {isEmpty(bookingData?.flight?.outbound?.departure_airport_name) !== "" && <Typography variant='body1' lineHeight={'1.2'}
                                    fontWeight={500}
                                  >
                                    {isEmpty(bookingData?.flight?.outbound?.departure_time) !== "" && <span>{"(" + isEmpty(bookingData?.flight?.outbound?.departure_time) + ") - "}</span>}
                                    {isEmpty(bookingData?.flight?.outbound?.departure_airport_name) !== "" && <span>{isEmpty(bookingData?.flight?.outbound?.departure_airport_name)}</span>}
                                  </Typography>}
                                  {isEmpty(bookingData?.flight?.outbound?.stops) !== "" && !bookingData?.flight?.outbound?.stops.includes("-1") && <Typography lineHeight={'1.2'} variant='body1' fontWeight={500} >
                                    {formatStringTime(bookingData?.flight?.outbound?.outbound_time)}  ({isEmpty(bookingData?.flight?.outbound?.stops)})
                                  </Typography>}
                                  <Typography variant="body1" fontWeight={500} lineHeight={1.2}>
                                    {bookingData?.flight?.outbound?.layover_stops.map((lay: any, index: any) => {
                                      return isEmpty(lay?.layover1?.time) !== "" ? (
                                        <span style={{ fontWeight: '500', lineHeight: '1.2' }} key={index}>
                                          {index > 0 && <br />}
                                          {isEmpty(lay?.layover1?.time) !== "" && `Layover ${index + 1} (${lay?.layover1?.time
                                            ?.replace(/0\s*hr\s*/g, '')                    // Remove "0 hr"
                                            .replace(/\s*m/g, 'm')                         // Remove space before "m"
                                            .replace(/(\d+)\s*hr/g, '$1hr')                // Ensure "1 hr" becomes "1hr"
                                            .trim()                                        // Remove any leading/trailing spaces
                                            }) `}
                                          {"- " + lay?.layover1?.layover_airport_city} {(isEmpty(lay?.layover1?.code) !== "") && ("(" + lay?.layover1?.code + ")")}

                                          {/* {(isEmpty(lay?.layover1?.code) !== "") && (" - (" + lay?.layover1?.code + ")")} */}
                                        </span>) : <React.Fragment key={index} />
                                    })}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box display='flex' mb='6px' >
                                <Box
                                  className='Icon'
                                  // mt={0.4}
                                  sx={{
                                    '& svg': {
                                      fill: colors.basics.primary,
                                      transform: 'rotate(270deg)',
                                    },
                                  }}
                                >
                                  <Tooltip title='Outbound'>
                                    <AirplanemodeActiveIcon />
                                  </Tooltip>
                                </Box>
                                <Box display={'flex'} alignItems={'center'}>
                                  <Typography variant='body1' fontSize={'18px'} color={colors.basics.primary} fontWeight={500} lineHeight={'1.2'}>
                                    Inbound Flight
                                  </Typography>
                                </Box>
                              </Box>
                              <Box display='flex'>
                                <Box
                                  className='Icon'
                                  // mt={0.4}
                                  sx={{
                                    '& svg': {
                                      fill: colors.basics.primary,
                                      transform: 'rotate(270deg)',
                                    },
                                  }}
                                >
                                  {/* <Tooltip title='Inbound'>
                                    <AirplanemodeActiveIcon />
                                  </Tooltip> */}
                                </Box>
                                <Box className='content'>
                                  <Typography variant='body1' fontWeight={500} lineHeight={'1.2'}>
                                    {bookingData?.flight?.inbound.departure_date + " "}   {isEmpty(bookingData?.flight?.inbound.airline_name) !== "" && <>
                                      {" - " + `${bookingData?.flight?.inbound.airline_name + " - "}  (${bookingData?.flight?.inbound.airline_code})`}
                                    </>}
                                  </Typography>
                                  <Typography variant='body1' fontWeight={500} lineHeight='1.2'>
                                    {/* {isEmpty(bookingData?.flight?.inbound.airline_name) !== "" && <>
                                      {`${bookingData?.flight?.inbound.airline_name + " - "}  (${bookingData?.flight?.inbound.airline_code})`}
                                    </>} */}
                                  </Typography>
                                  <Typography variant='body1'
                                    fontWeight={500}
                                    lineHeight={'1.2'}
                                    sx={{
                                      '& svg': {
                                        display: "inline-block",
                                        verticalAlign: "center",
                                        height: { sm: "14px", xs: "12px" },
                                        width: { sm: "14px", xs: "12px" },
                                        ml: "4px"
                                      }
                                    }}>
                                    {`(${bookingData?.flight?.inbound.departure_time})`} - <span >{bookingData?.flight?.inbound.departure_airport_name}   <KeyboardDoubleArrowRightIcon /></span>
                                    {' '}
                                  </Typography>
                                  <Typography variant='body1'
                                    fontWeight={500}
                                    lineHeight='1.2'>
                                    <span>{`(${bookingData?.flight?.inbound.arrival_time}) - `}</span>
                                    <span >{bookingData?.flight?.inbound.arrival_airport_name}  </span>
                                  </Typography>
                                  {isEmpty(bookingData?.flight?.inbound.stops) !== "" && !bookingData?.flight?.inbound.stops.includes("-1") && <Typography lineHeight={'1.2'} variant='body1' fontWeight={500} >
                                    {formatStringTime(bookingData?.flight?.inbound.inboundTime)} ({bookingData?.flight?.inbound.stops})
                                  </Typography>}
                                  {/* <Typography lineHeight={'1.2'} variant='body1' fontWeight={500} >
                                    {bookingData?.flight?.inbound.inboundTime}{" (" + `${bookingData?.flight?.inbound.stops}` + ") "}
                                  </Typography> */}
                                  {/* <Typography variant="body1" fontWeight={500} lineHeight={1.2}>
                                  8h 5min (Direct)
                                </Typography> */}
                                  <Typography variant="body1" fontWeight={500} lineHeight={1.2}>
                                    {bookingData?.flight?.inbound?.layover_stops.map((lay: any, index: any) => (
                                      isEmpty(lay?.layover1?.time) !== "" ? (
                                        <span style={{ fontWeight: '500', lineHeight: '1.2' }} key={index}>
                                          {index > 0 && <br />}
                                          {`Layover ${index + 1} (${lay?.layover1?.time
                                            ?.replace(/0\s*hr\s*/g, '')                    // Remove "0 hr"
                                            .replace(/\s*m/g, 'm')                         // Remove space before "m"
                                            .replace(/(\d+)\s*hr/g, '$1hr')                // Ensure "1 hr" becomes "1hr"
                                            .trim()                                        // Remove any leading/trailing spaces
                                            }) `}

                                          {"- " + lay?.layover1?.layover_airport_city}  {(isEmpty(lay?.layover1?.code) !== "") && ("(" + lay?.layover1?.code + ")")}
                                        </span>) : <React.Fragment key={index} />
                                    ))}
                                  </Typography>
                                </Box>

                              </Box>
                              <Box display={'flex'}>

                                <Box
                                  className='Icon'
                                  mt={0.4}
                                  sx={{
                                    '& svg': {
                                      fill: colors.basics.primary,
                                      // transform: 'rotate(-270deg)',
                                    },
                                  }}>
                                  <Tooltip title='Baggage' >
                                    <LuggageIcon />
                                  </Tooltip>
                                </Box>

                                <Box className='content'>
                                  {isEmpty(bookingData?.flight?.baggage) !== '' && <Box className='content'>
                                    <Typography variant='body1' fontWeight={500} lineHeight='1.2'>
                                      {bookingData?.flight?.baggage.includes("kg") ? bookingData?.flight?.baggage : bookingData?.flight?.baggage.replace("K", "kg")}
                                    </Typography>
                                  </Box>}
                                </Box>
                              </Box>
                            </Box>

                          </li>
                          <li />
                          <li>
                            <Box display='flex' mb='10px'>
                              <Box className='Icon ' mt={0.5}>
                                <Tooltip title='Hotel'>
                                  <Building />
                                </Tooltip>
                              </Box>
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography variant='body1' fontSize={'18px'} color={colors.basics.primary} fontWeight={500} lineHeight={'1.2'}>Hotel Info</Typography>
                              </Box>
                            </Box>
                            <Box display='flex' mb='14px'>
                              <Box className='Icon ' mt={0.5}>
                                {/* <Tooltip title='Hotel'>
                                  <Building />
                                </Tooltip> */}
                              </Box>
                              <Box className='content'>
                                <Typography variant='body1' fontWeight={500} lineHeight='1.2'>
                                  <span>
                                    {bookingData?.hotel?.hotel_description}
                                  </span>
                                </Typography>
                              </Box>
                            </Box>
                            <Box display='flex' mb='14px'>
                              <Box className='Icon' mt={0.3}>
                                <Tooltip title='Holiday Date'>
                                  <Calender />
                                </Tooltip>
                              </Box>
                              <Box className='content' sx={{ paddingTop: '3px' }}>
                                <Typography variant='body1' fontWeight={500} lineHeight='1.2'>
                                  <span>Check In: </span>
                                  <span>
                                    {bookingData?.hotel?.check_in}{" "}
                                  </span>
                                  <span>
                                    (1500 hrs)
                                  </span>
                                </Typography>
                                <Typography variant='body1' fontWeight={500} lineHeight='1.2'>
                                  <span>Check Out: </span>
                                  <span>
                                    {bookingData?.hotel?.check_out}{" "}
                                  </span>
                                  <span>
                                    (1000 hrs)
                                  </span>
                                </Typography>
                              </Box>
                            </Box>

                            <Box display='block'>
                              {bookingData?.hotel?.room.map((r: any, index: number) => (
                                <React.Fragment key={index}>
                                  <Box display='flex' mb='5px'>
                                    <Box alignItems={"center"} className='Icon' mt={0.3}>
                                      <Tooltip title='Room'>
                                        {/* Assuming the Bed component is imported */}
                                        <Bed />
                                      </Tooltip>
                                    </Box>
                                    <Box className='content' sx={{
                                      paddingTop: '3px',
                                      "& p:not(:last-child)": {
                                        paddingBottom: '8px'
                                      }
                                    }}>
                                      <Typography textTransform={"capitalize"} variant='body1' fontWeight={500} lineHeight='1.2'>
                                        Room {index + 1} -  {capitalizeFirstLetter(r?.room_name)}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box alignItems={"center"} display={'flex'}>
                                    <Box className='Icon' mt={0.4}>
                                      <Tooltip title='Guest'>
                                        {/* Assuming the Person component is imported */}
                                        <Person />
                                      </Tooltip>
                                    </Box>
                                    <Box>
                                      <Typography variant='body1' fontWeight={500} lineHeight='1.2'>
                                        {r?.passangers_info}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box alignItems={"center"} mt={0.5} mb={0.5} display={'flex'}>
                                    <Box className='Icon' mt={0.3}>
                                      <Tooltip title='Board'>
                                        {/* Assuming the Spoon component is imported */}
                                        <Spoon />
                                      </Tooltip>
                                    </Box>
                                    <Box className='content'>
                                      <Box
                                        sx={{
                                          '& p': {
                                            fontFamily: 'Raleway',
                                            fontStyle: 'normal',
                                            fontWeight: 500,
                                            fontSize: 16,
                                            lineHeight: '19px',
                                            color: colors.basics.black,
                                          },
                                          fontFamily: 'Raleway',
                                          fontStyle: 'normal',
                                          fontWeight: 500,
                                          fontSize: 16,
                                          lineHeight: '19px',
                                          color: colors.basics.black,
                                          textTransform: "capitalize"
                                        }}
                                      >
                                        <span dangerouslySetInnerHTML={{ __html: RoomMealWrapper(r?.meal_name?.toLowerCase()) }} />
                                      </Box>
                                    </Box>
                                  </Box>
                                </React.Fragment>
                              ))}
                            </Box>
                          </li>
                          <li />
                          <li>
                            {isEmpty(bookingData?.deposit?.due_date) !== "" && <Box alignItems={"center"} display='flex' className='content'>
                              <Box className='Icon' mt={0.3}>
                                <Tooltip title='Holiday Date'>
                                  <Calender />
                                </Tooltip>
                              </Box>
                              <Typography variant='body1' fontWeight={500} lineHeight='130%'>
                                {"Balance Due Date : " + dayjs(dateSplit(bookingData?.deposit?.due_date, "/")).format("DD MMM, YYYY")}
                              </Typography>
                            </Box>}

                            <Box mt={2} display='flex'>
                              <Box className='Icon'>
                                {process.env.REACT_APP_PROJECT_COUNTRY === "uk" && <Pound height={24} width={24} fill={colors.basics.primary} />}
                                {process.env.REACT_APP_PROJECT_COUNTRY === "ie" && <Euro />}
                                {process.env.REACT_APP_PROJECT_COUNTRY === "us" && <Dollar />}
                              </Box>

                              <Box className='content'>
                                <Typography variant='body1' fontSize={"20px"} fontWeight={500} lineHeight='130%'>
                                  {bookingData?.deposit?.isDeposit === 'true' ? `Paid: 
                                ${signForPricing +
                                    bookingData?.deposit?.deposit_payment} (Deposit)` : `Paid: ${signForPricing + bookingData?.deposit?.deposit_payment} (Full Payment)`}
                                </Typography>
                              </Box>
                            </Box>

                            <Box mt={2} display='flex'>
                              <Box className='Icon'>
                                {process.env.REACT_APP_PROJECT_COUNTRY === "uk" && <Pound height={24} width={24} fill={colors.basics.primary} />}
                                {process.env.REACT_APP_PROJECT_COUNTRY === "ie" && <Euro />}
                                {process.env.REACT_APP_PROJECT_COUNTRY === "us" && <Dollar />}
                              </Box>

                              <Box className='content'>
                                <Typography variant='body1' fontSize={"20px"} fontWeight={500} lineHeight='130%'>
                                  {`Total Price: 
                                ${signForPricing + bookingData?.deposit?.total_price}`}
                                </Typography>
                              </Box>
                            </Box>
                          </li>
                        </ul>
                      </Box>
                    </AccordionDetails>) : (
                    !loading && <AccordionDetails><Typography>Folder not found</Typography>
                    </AccordionDetails>
                  )
                  }
                </Accordion>
              </Box>

            </Box>
          </Box>
        </CustomContainer >
      </Box> : <></>
        : <Typography my={"120px"}
          variant='h1' textAlign='center' color={colors.basics.primary} >
          No Folder Found
        </Typography>}

      {open && <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} sx={{ marginTop: { md: "90px", xs: "60px" } }}>
        <Alert variant='filled' severity='success' sx={{ verticalAlign: "top", }}>
          Email confirmation has been sent to {email}
        </Alert>
      </Snackbar>
      }
      {Object.keys(bookingData).length > 0 && <Box sx={{ display: "none" }}>{BookingConfirmationEmail(bookingData, email)}</Box>}
    </>
  );
};

export default BookingSuccessfulSummary;
