import { createSlice } from '@reduxjs/toolkit';

export interface DialogState {
  dialogState: {
    filterDialog: boolean;
    mapDialog: boolean;
    priceChangeDialog: boolean;
    emailEnquiryDialog: boolean;
    emailItinerary: boolean;
    customiseModal: boolean;
  };
}

const initialState: DialogState = {
  dialogState: {
    filterDialog: false,
    mapDialog: false,
    priceChangeDialog: false,
    emailEnquiryDialog: false,
    emailItinerary: false,
    customiseModal: false,
  },
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setDialogState: (state, { payload }) => {
      state.dialogState = payload;
    },
  },
});

export const { setDialogState } = dialogSlice.actions;
export default dialogSlice.reducer;
