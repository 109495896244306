import { Box, Button, Checkbox, MenuItem, Typography } from '@mui/material';
import { ReactComponent as ArrowDown } from '../assets/icons/ArrowDown.svg';
import { ReactComponent as ArrowUp } from '../assets/icons/ArrowUp.svg';
import { colors } from '../styles/colors';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { isEmpty } from '../helpers/isEmpty';

interface openModals {
    date: boolean,
    departure: boolean,
    destination: boolean,
    class: boolean,
    room: boolean,
}

interface Props {
    children?: undefined | ReactNode,
    data?: any,
    checkbox?: boolean | undefined,
    dropdownWidth?: string | undefined,
    disabled?: boolean | undefined,
    value?: any,
    name?: string | undefined,
    onChange?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>, name: string | undefined, value: any) => void,
    boxStyle?: React.CSSProperties,
    menu?: boolean | undefined,
    error?: string | undefined,
    idIncluded?: boolean | undefined,
    ids?: string,
    menuItemStyle?: React.CSSProperties,
    subData?: any,
    StartIcon?: React.ReactElement | undefined,
    itemId?: string | number | undefined,
    menuStyle?: React.CSSProperties | undefined,
    menuOnClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void | undefined,
    capitilize?: boolean | undefined,
    handleSubmit?: () => void | undefined,
    placeholder?: string | undefined,
    multiple?: boolean | undefined,
    keys?: keyof openModals | undefined;
    className?:any;
    isPlataniumHotel?: any;
}

const CustomSelectInput = (props: Props) => {
    const [open, setOpen] = useState(false)
    const { children, data, checkbox, dropdownWidth, disabled, value, name, onChange, boxStyle, menu, error, idIncluded, ids, menuItemStyle, subData, StartIcon, itemId, menuStyle, menuOnClick, capitilize, handleSubmit, placeholder, multiple, keys,className,   isPlataniumHotel,
         } = props
    let checkboxContainer: Element | null = document.querySelector(`#${ids}`) || null;
    const [isModalOpen, setIsModalOpen] = useState(open)
    const val = Array.isArray(value) ? (value.length === 0 ? placeholder ? placeholder : value : value.join(", "))
        : value === "" ? placeholder ? placeholder : value : value

    // for close dropdown
    document.addEventListener('mouseup', function (e: MouseEvent) {
        setTimeout(() => {
            if (checkboxContainer !== null) {
                const dropdownContent = checkboxContainer.querySelector('.dropdown-menu ') || null;
                if (dropdownContent !== null) {
                    if (!checkboxContainer.contains(e.target as Element) && !dropdownContent.contains(e.target as Element)) {
                        setTimeout(() => {
                            if (open) {
                                setOpen(false)
                            }                            
                        }, 100)
                    }
                }
            }
        }, 2)
    });

    useEffect(() => {
        setIsModalOpen(open)
    }, [open])
    // useEffect(() => {
    //     if (keys !== undefined && openModals && openModals[keys]) {
    //         setTimeout(() => {
    //             adjustMenu(checkboxContainer as Element)
    //         }, 0.1)
    //     }
    // }, [openModals, keys])

    useEffect(() => {
        let checkboxContainer: Element | null = document.querySelector(`#${ids}`)

        const handleCheckboxContainerClick = () => {
            setTimeout(() => {
                adjustMenu(checkboxContainer as Element)
            }, 0.1)
        };

        if (checkboxContainer !== null && keys === undefined) {
            checkboxContainer.addEventListener('click', handleCheckboxContainerClick);
            return () => {
                checkboxContainer !== null && checkboxContainer.removeEventListener('click', handleCheckboxContainerClick);
            };

        }

    }, [ids, open, keys, dropdownWidth]);

    const adjustMenu = (checkboxContainer: Element) => {
        const dropdownMenu: Element | null = checkboxContainer.querySelector(`.dropdown-menu`)
        if (dropdownMenu instanceof HTMLElement && dropdownMenu !== null && checkboxContainer instanceof HTMLElement && checkboxContainer !== null) {
            const dropdownItems = dropdownMenu.querySelectorAll('li');
            let maxWidth = 0;
            dropdownItems.forEach((item) => {
                const width = item.offsetWidth;
                if (width > maxWidth) {
                    maxWidth = width;
                }
            });

            dropdownMenu.style.width = "100%"
            const rect = dropdownMenu.getBoundingClientRect();
            const windowWidth = window.innerWidth;
            const halfwayPoint = windowWidth / 2;
            const menuRight = windowWidth - rect.right;

            if (menuRight < halfwayPoint) {
                dropdownMenu.style.left = `auto`;
                dropdownMenu.style.right = `0`;
            } else {
                dropdownMenu.style.left = `0`;
                dropdownMenu.style.right = `auto`;
            }

            if (dropdownWidth) {
                dropdownMenu.style.width = dropdownWidth
            } else {
                dropdownMenu.style.width = "max-content"
            }

            dropdownMenu.style.minWidth = checkboxContainer.offsetWidth + "px"
        }
    }

    const onOpen = () => {
        setOpen(!open)
        // if (openModals === undefined || !openModals?.date) {
        //     if (openModals === undefined) {

        //     } else {
        //         let obj = { ...openModals } as openModals
        //         if (keys !== undefined) {
        //             for (let key in obj) {
        //                 if (obj.hasOwnProperty(key)) {
        //                     if (key !== keys) {
        //                         obj[key as keyof openModals] = false;
        //                     }

        //                 }
        //             }

        //             obj[keys] = !obj[keys]
        //             setTimeout(() => {
        //                 setOpenModals && setOpenModals(obj)
        //             }, 200)

        //         }
        //     }
        // }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            setTimeout(() => {
                onOpen()
            }, 1)
            setTimeout(() => {
                adjustMenu(checkboxContainer as Element)
            }, 2)

        }
    };

    const onKeyDownTabs = (e: React.KeyboardEvent<HTMLUListElement>, ids: string) => {
        const items = document.querySelectorAll(`#${ids} .dropdown-menu.show li`);
        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
            e.preventDefault()
            e.stopPropagation()

            let currentIndex = -1;

            items.forEach((item, index) => {
                if (item === document.activeElement) {
                    currentIndex = index;
                }
            });

            let nextIndex =
                e.key === "ArrowDown" ? (currentIndex + 1) % items.length : (currentIndex - 1 + items.length) % items.length;

            const nextItem = items[nextIndex] as HTMLElement;
            nextItem.focus();
        }
    }

    const handleBlur = () => {
        setOpen(!open)
        // if (keys !== undefined && openModals && openModals[keys]) {
        //     setOpenModals?.({
        //         ...openModals,
        //         [keys]: false
        //     })
        // }
    };

    return (
        <Box onKeyDown={handleKeyDown} tabIndex={0} id={ids} sx={{
            cursor: "pointer",
            '& .Mui-focusVisible': {
                backgroundColor: ids === "rooms" ? "" : `${colors.basics.pink} !important`,
                outline: "none",
                borderRadius: ids === "rooms" ? 0 : "5px",
                border: ids === "rooms" ? "" : "none"
            }
        }
        } className={`btn-group custom-dropdown select-drop dropdown ${disabled && "disabled"}`}>
            <Box sx={boxStyle}
                border={disabled ? `1px solid ${colors.disable.grey}` : menu ? "none" : isEmpty(error) !== "" ? `1px solid ${colors.basics.red}`  : `1px solid ${colors.grey[30]}`}
                onClick={onOpen}
                className="btn dropdown-toggle d-flex" alignItems="center" justifyContent="center" position="relative">
                <Box sx={{
                    '& svg': {
                        // marginTop: "3px"
                    }
                }} display="flex" alignItems={"center"} justifyContent="start">
                    {StartIcon && StartIcon}
                    {!menu && <span style={{
                        textTransform: capitilize ? "capitalize" : "unset"
                    }} className="select-drop-text text-start">{val}</span>}
                </Box>

                <Box display={{ xs: 'none', sm: 'flex' }}
                    sx={{
                        transform: "translate(0, -50%)"
                    }}
                    position='absolute' right='8px !important' top="50%" zIndex={0}>
                    {isModalOpen ? <ArrowUp /> : <ArrowDown />}
                </Box>
            </Box>

            <Box>
                {isModalOpen && <ul tabIndex={0}
                    onKeyDown={(e) => { ids !== undefined && onKeyDownTabs(e, ids) }}
                    onBlur={(e) => {
                        const relatedTarget = e.relatedTarget;
                        const dropdownMenu = document.querySelector(`#${ids} .dropdown-menu`) || null;

                        if (dropdownMenu !== null && dropdownMenu.contains(relatedTarget) === false) {
                            handleBlur()
                        }
                    }}
                    style={{
                        marginTop: "4px",
                        ...menuStyle
                    }} onChange={(e) => {
                    }} className={`${className} ? ${className} : dropdown-menu show `}>
                    {children ? (
                        <Box className='selectChildren'>{children}</Box>
                    ) : (
                        data?.map((item: any, i: number) => {
                            return (
                                <MenuItem
                                    style={{
                                        width: "auto"
                                    }}
                                    onClick={(e) => {
                                        if (onChange) {
                                            onChange(e, name, idIncluded === true ? item.id : item)
                                        } else {
                                            if (menuOnClick) {
                                                menuOnClick(e)
                                            }
                                        }

                                        if (!Array.isArray(value)) {
                                            setTimeout(() => {
                                                setOpen(false)
                                            }, 100)

                                        }

                                    }}
                                    key={i} value={idIncluded === true ? item.id : item}
                                    sx={{
                                        '& .MuiCheckbox-root': {
                                            paddingRight: "10px"
                                        },
                                        my: '2px',
                                        '&:hover': {
                                            backgroundColor: isPlataniumHotel ? "#89806420 !important" : colors.basics.pink,
                                        },
                                        background: (idIncluded === true ? itemId === item.id : value === item)  ? isPlataniumHotel
                                        ? colors.basics.plataniumGreenAccentColor + '!important'
                                        : colors.basics.pink + '!important' : colors.basics.white,
                                        borderRadius: (idIncluded === true ? itemId === item.id : value === item) ? "5px!important" : 0,
                                        ...menuItemStyle
                                    }}
                                >
                                    {checkbox && <Checkbox checked={value.indexOf(item) > -1} disableRipple />}
                                    <Box display='flex' flexDirection='column'>
                                        <Typography sx={{ textTransform: capitilize ? 'capitalize' : '', }} variant='body2' mb={'2px'}
                                            dangerouslySetInnerHTML={{ __html: idIncluded ? item.name : item }}
                                        />
                                        {subData && <Typography>{subData[i]}</Typography>}
                                    </Box>
                                </MenuItem>
                            );
                        })
                    )}

                    {multiple && (
                        <Box mt={1} display='flex' justifyContent='flex-end' >
                            <Button variant='contained' sx={{ height: '40px' }} onClick={() => {
                                if (handleSubmit) {
                                    handleSubmit()
                                }
                                setOpen(false)
                            }}>
                                Apply
                            </Button>
                        </Box>
                    )
                    }
                </ul>}
            </Box>

            {
                isEmpty(error) !== "" && <Typography
                    my={"2px !important"}
                    variant='h4'
                    sx={{ color: colors.basics.red, fontSize: '12px !important', lineHeight: 1.5 + " !important" }}
                    className='MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained Mui-required css-1de7kq0-MuiFormHelperText-root'
                >
                    {error}
                </Typography>
            }
        </Box >
    )
}

export default CustomSelectInput