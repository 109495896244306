import { Button, Checkbox, FormGroup, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GuestAccountDetailsTabs } from '../../../common/tab';
import { TabComponent } from '../../../components/TabComponent';
import { TabPanel } from '../../../components/TabPanel';
import TextInput from '../../../components/TextInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { colors } from '../../../styles/colors';
import { ReactComponent as Vactor } from '../../../assets/icons/Vactor.svg';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { checkUserEmailIsExist } from '../../../features/actions';
import { userLoginAPI } from '../../../features/userAuthentication/userLoginSlice';
import { userRegisterAPI } from '../../../features/userAuthentication/userRegisterSlice';
import CancelIcon from '@mui/icons-material/Cancel';
import { useAppSelector } from '../../../hooks/useAppReducer';
import { useAuth } from '../../../context/AuthContext';
import { branchCode } from '../../../constants/branchCode';
export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(7, 'char')
    .matches(/(?=.*\d)/, 'number')
    .matches(/(?=.*[@$!%*#?&])/, 'special'),
  repassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

const GuestAccountDetails = () => {
  let { tab } = useParams();
  const dispatch = useDispatch();
  const { setIsUserLoggedIn } = useAuth();
  const { passenger } = useAppSelector((state) => state.passenger);

  const [currentTab, setCurrentTab] = useState(tab || 'Guest');
  const [value, setValue] = useState('createAccount');
  const [data, setData] = useState(false);
  const [login, setLogin] = useState(true);
  const [formData, setFormData] = useState<any>({ password: '', email: '', repassword: '' });
  const [formError, setFormErrors] = useState<any>({});

  const totalLength7 = /^.{7,}$/.test(formData?.password);
  const oneNumber = /(?=.*\d)/.test(formData?.password);
  const specialChar = /(?=.*[@$!%*#?&])/.test(formData?.password);

  useEffect(() => {
    handleDataChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleDataChange = () => {
    setData(!data);
  };

  const handleLoginData = (e: any) => {
    e.preventDefault();
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(async () => {
        setFormErrors({});
        const resp = await dispatch(checkUserEmailIsExist(formData?.email));
        if (resp?.payload === '1') {
          // login user account
          // eslint-disable-next-line
          const resp = await dispatch(
            userLoginAPI([
              {
                email: formData?.email,
                password: formData?.password,
                check_branch_code: 1,
                branch_code: branchCode,
              },
            ])
          );
        } else {
          console.log('🚀 ~ file: GuestAccountDetails.tsx:92 ~ .then ~ login user credential wrong:');
        }
      })
      .catch((err) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        console.log('🚀 ~ file: GuestAccountDetails.tsx:114 ~ err.inner.forEach ~ errors:', errors);
        setFormErrors(errors);
      });
  };
  const handleRegisterData = (e: any) => {
    e.preventDefault();
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(async () => {
        console.log(formData, 'with validation');
        setFormErrors({});
        const resp = await dispatch(checkUserEmailIsExist(passenger[0]?.email));
        if (resp?.payload === '2') {
          // register user account
          const response = await dispatch(
            userRegisterAPI([
              {
                name: passenger[0]?.sname,
                first_name: passenger[0]?.fname,
                username: passenger[0]?.email,
                password: formData.password,
                address1: 'Makati',
                address2: '',
                city_name: 'Manila',
                country_code: '',
                mobile_no: passenger[0]?.pnumber,
                telephone_no: '',
                email: passenger[0]?.email,
                create_customer: '1',
                check_branch_code: '1',
                branch_code: branchCode,
                customer_type: 'C',
                register: true,
              },
            ])
          );
          if (response) {
            setIsUserLoggedIn(true);
            setLogin(!login);
          }
        } else {
          console.log('🚀 ~ file: GuestAccountDetails.tsx:116 ~ .then ~ else: Invalid Email');
        }
      })
      .catch((err) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          console.log(e, 'errors1');
          errors[e.path] = e.message;
        });
        setFormErrors(errors);
      });
  };

  const handleChange = (newValue: string) => {
    setCurrentTab(newValue);
  };

  const handleRegisterFormData = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Box mb='30px'>
      <Box ml='10px'>
        <Typography variant='body1' fontWeight={500} lineHeight='130%'>
          To complete an order you can proceed as a Guest, or create an account, or use an existing account.
        </Typography>
      </Box>
      <form>
        <Box>
          <Box
            bgcolor={colors.basics.secondary}
            mb='30px'
            sx={{
              '& .MuiTabs-root': {
                '& .Mui-selected': {
                  '& #tabText': {
                    borderBottom: `3px solid ${colors.basics.primary}`,
                  },
                  backgroundColor: colors.basics.secondary,
                },
                backgroundColor: colors.basics.white,

                pl: 0,
                '& .MuiButtonBase-root ': {
                  '&:hover': {
                    backgroundColor: colors.basics.secondary,
                    '& #tabText': {
                      borderBottom: `3px solid ${colors.basics.primary}`,
                      transition: 'border .2s ease-in-out',
                    },
                  },
                  height: 0,
                  pl: '18px',
                },
              },
            }}
          >
            <Box>
              <TabComponent
                tabs={GuestAccountDetailsTabs}
                value={currentTab}
                handleChange={handleChange}
                variant='scrollable'
                scrollButtons='auto'
              />
            </Box>
            <Box pl={{ sm: '16px', xs: '10px' }} pr={{ sm: '16px', xs: '10px' }}>
              <TabPanel currentTab={currentTab?.replace(/ /g, '')} tab='Guest'>
                <Box>
                  <Box mt='30px'>
                    <Typography variant='body1' fontWeight={500} lineHeight='130%'>
                      We will send booking details to the following email:
                    </Typography>
                  </Box>
                  <Box mt='30px' pb='30px'>
                    <Box width={{ md: '60%', xs: '100%' }}>
                      <TextInput title='EMAIL ADDRESS' type='email' />
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel
                currentTab={currentTab?.replace(/ /g, '')}
                tab={GuestAccountDetailsTabs[1]?.replace(/ /g, '')}
              >
                {login ? (
                  <Box>
                    <Box mt='30px'>
                      <FormControl>
                        <RadioGroup
                          onChange={handleValueChange}
                          value={value}
                          row
                          aria-labelledby='demo-row-radio-buttons-group-label'
                          name='row-radio-buttons-group'
                        >
                          <FormControlLabel
                            value='createAccount'
                            control={<Radio />}
                            label='Create Account'
                            sx={{ mr: '50px' }}
                          />
                          <FormControlLabel value='login' control={<Radio />} label='Login' />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    {value === 'createAccount' ? (
                      <Box>
                        <Box mt='30px'>
                          <Typography variant='body1' fontWeight={500} lineHeight='130%'>
                            The email for your account will be that of the lead passenger by default.
                          </Typography>
                          <Typography variant='body1' fontWeight={500} lineHeight='130%'>
                            Please set your password below and click Create Account. You will be logged in and
                            can continue with your order.{' '}
                          </Typography>
                          <Typography
                            variant='body1'
                            fontWeight={500}
                            lineHeight='130%'
                            mt='20px'
                            color={colors.basics.black}
                          >
                            You will receive an email at <b>{passenger[0]?.email}</b> confirming your new
                            account details.
                          </Typography>
                          <Typography
                            variant='body1'
                            fontWeight={500}
                            lineHeight='130%'
                            color={colors.basics.primary}
                          >
                            <span style={{ color: colors.basics.primary, fontWeight: 'bold' }}>
                              Important:
                            </span>{' '}
                            You must click the link in the email within 24 hours to validate and confirm your
                            account.
                          </Typography>
                        </Box>
                        <Box display={{ xs: 'block', md: 'flex' }} width='100%'>
                          <Box flex='0 0 60%'>
                            <Box mt='10px'>
                              <TextInput
                                title='EMAIL ADDRESS'
                                type='email'
                                name='email'
                                value={passenger[0]?.email}
                                disabled={true}
                              />
                            </Box>
                            <Box mt='20px'>
                              <TextInput
                                title='PASSWORD'
                                name='password'
                                onChange={(evt) => {
                                  handleRegisterFormData(evt);
                                }}
                              />
                            </Box>
                            <Box mt='20px'>
                              <TextInput
                                title='RE-ENTER PASSWORD'
                                name='repassword'
                                onChange={(evt) => {
                                  handleRegisterFormData(evt);
                                }}
                                error={formError && formError['repassword'] ? true : false}
                                helperText={
                                  formError && formError['repassword'] ? formError['repassword'] : ''
                                }
                              />
                            </Box>
                          </Box>

                          <Box
                            flex='0 0 40%'
                            display='flex'
                            alignItems='center'
                            justifyContent={{ md: 'center', xs: 'left' }}
                            ml={{ lg: 0, md: 0, sm: '30px', xs: '30px' }}
                            mt={{ lg: 0, md: 0, sm: '10px', xs: '10px' }}
                            sx={{
                              '& .vactor-icon': {
                                listStyle: 'none',
                              },
                            }}
                          >
                            <Box>
                              <Typography variant='body1' ml='-30px' fontSize='20px' lineHeight='24px'>
                                Password Rules
                              </Typography>

                              <ul>
                                <li>
                                  <Typography variant='body1' fontWeight={500} lineHeight='130%'>
                                    Minimumum of 7 characters
                                  </Typography>
                                </li>
                                <li>
                                  <Typography variant='body1' fontWeight={500} lineHeight='130%'>
                                    At least one special character
                                  </Typography>
                                </li>
                                <li>
                                  <Typography variant='body1' fontWeight={500} lineHeight='130%'>
                                    At least 1 number
                                  </Typography>
                                </li>
                              </ul>
                            </Box>
                            <Box mt='10px' ml='2px'>
                              <ul className='vactor-icon'>
                                <li>
                                  <Typography
                                    variant='body1'
                                    fontWeight={500}
                                    lineHeight='130%'
                                    visibility='hidden'
                                  >
                                    1
                                  </Typography>
                                </li>
                                <li>
                                  <Typography variant='body1' fontWeight={500} lineHeight='130%'>
                                    {!totalLength7 ? <CancelIcon color='primary' /> : <Vactor />}
                                  </Typography>
                                </li>
                                <li>
                                  <Typography variant='body1' fontWeight={500} lineHeight='130%'>
                                    {!specialChar ? <CancelIcon color='primary' /> : <Vactor />}
                                  </Typography>
                                </li>
                                <li>
                                  <Typography variant='body1' fontWeight={500} lineHeight='130%'>
                                    {!oneNumber ? <CancelIcon color='primary' /> : <Vactor />}
                                  </Typography>
                                </li>
                              </ul>
                            </Box>
                          </Box>
                        </Box>
                        <Box mt='30px'>
                          <Box ml='8px'>
                            <FormControl component='fieldset' variant='standard'>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox sx={{ pr: '12px' }} disableRipple />}
                                  label='If you would like to receive email marketing from us, please tick here'
                                />
                              </FormGroup>
                            </FormControl>
                          </Box>
                          <Box
                            display='flex'
                            justifyContent={{ sm: 'right', xs: 'center' }}
                            mt={{ md: 0, xs: '20px' }}
                          >
                            <Button
                              sx={{
                                height: '49px',
                                mb: '40px',
                                width: { sm: 'auto', xs: '100%' },
                              }}
                              onClick={handleRegisterData}
                            >
                              Create Account
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        {data ? (
                          <>
                            <Box mt='30px'>
                              <Typography variant='body1' fontWeight={500} lineHeight='130%'>
                                Please enter your password below and click Login. You will then be logged in
                                and can continue with your order. If you have forgotten your password, please
                                enter your email and click forgotten password.
                              </Typography>
                            </Box>
                            <Box mt='10px' width={{ md: '60%', xs: '100%' }}>
                              <TextInput title='EMAIL ADDRESS' type='email' />
                            </Box>
                            <Box mt='20px' width={{ md: '60%', xs: '100%' }}>
                              <TextInput title='PASSWORD' />
                            </Box>
                          </>
                        ) : (
                          <Box mb='103px'>
                            <Box display='flex' border='1px solid #4ECB71' p='30px 0 20px 20px'>
                              <Box ml='10px' mr='15px' mt='3px'>
                                <Vactor />
                              </Box>
                              <Box>
                                <Typography variant='body2' lineHeight='130%'>
                                  Your reset password email has been sent to {passenger[0]?.email}.
                                </Typography>
                                <Typography variant='body2' lineHeight='130%'>
                                  Once you have reset your password, please login here to continue with your
                                  order.
                                </Typography>
                              </Box>
                            </Box>
                            <Box mt='20px' width={{ md: '60%', xs: '100%' }}>
                              <TextInput title='PASSWORD' />
                            </Box>
                          </Box>
                        )}
                        <Box
                          display={{ sm: 'flex', xs: 'block' }}
                          justifyContent={{
                            sm: 'space-between',
                            xs: 'center',
                          }}
                          mt='30px'
                        >
                          <Button
                            sx={{
                              height: '50px',
                              width: { sm: 'auto', xs: '100%' },
                              mb: '30px',
                            }}
                            onClick={handleDataChange}
                          >
                            Forgotten Password
                          </Button>
                          <Button
                            sx={{
                              height: '50px',
                              width: { sm: 'auto', xs: '100%' },
                              mb: '30px',
                            }}
                            onClick={handleLoginData}
                          >
                            Login
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box bgcolor={colors.basics.secondary} p='30px' pl='40px'>
                    <Typography variant='body1' fontWeight={500} lineHeight='130%'>
                      You are currently logged in as <b>{passenger[0]?.email}</b>
                    </Typography>
                    <Typography variant='body1' fontWeight={500} lineHeight='130%' mb='30px'>
                      All order details and updates will be sent to this email address.
                    </Typography>
                  </Box>
                )}
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default GuestAccountDetails;
