import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useAppReducer';
import { CustomContainer } from '../../../../components/CustomContainer';
import TextInput from '../../../../components/TextInput';
import { colors } from '../../../../styles/colors';
import { useNavigate } from 'react-router-dom';
import { setLoading } from '../../../../features/loading/loadingSlice';
import { useDispatch } from 'react-redux';
import { setUserDetails } from '../../../../features/userAuthentication/userInformation';
import { useCookies } from 'react-cookie';
// import { getFolderDetails } from '../../../../features/profileDetails/getFolderSlice';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { API } from '../../../../api/services';
import { API_METHOD } from '../../../../api/services/apiMethods';
import * as yup from 'yup';
import { resetPassword } from '../../../../features/resetPassword/resetPassword';
import { removeLocalStorage } from '../../../../helpers/storage';

const MyAccount = () => {
  // * variables
  // const dispatch = useAppDispatch();
  const { userDetails } = useAppSelector((state) => state.userInformation);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [_, __, removeCookie] = useCookies(['user-email']);

  const [accountData, setAccountData] = useState({
    title: '',
    fname: '',
    lname: '',
    email: '',
    password: '',
    id: '',
    pnumber: "",
    street: "",
    city: "",
    country: "",
    houseNumber: ""
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [formError, setFormError] = useState({
    password: "",
    confirmPassword: ""
  })
  const [isHiddenPasswords, setIsHiddenPasswords] = useState({
    password: true,
    confirmPassword: true
  })
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: ""
  })
  const [saveButtonClick, setSaveButtonClick] = useState(false)
  const [isEditPasswordClicked, setisEditPasswordClicked] = useState(false)
  const [isChangedData, setisChangedData] = useState(false)
  const [accountDataError, setAccountDataError] = useState<any>({
    fname: '',
    lname: '',
    street: "",
    city: "",
    houseNumber: ""
  })
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .nullable()
      .min(7, 'Password length should be minimum 7')
      .matches(/(?=.*\d)/, '1 Number Required')
      .matches(/(?=.*[@$!%*#?&])/, '1 Special character Required'),
    // .required("Password is required"),
    confirmPassword: yup
      .string()
      .nullable()
      .oneOf([yup.ref('password')], 'Passwords must match')
    // .required("Confirm Password is required"),
  });

  const formDataValidationSchema = yup.object().shape({
    fname: yup
      .string()
      .required("First name is required")
      .test(
        "letters-only",
        "First name must contain only letters",
        (value) => {
          if (value && value.length > 0) {
            return /^[A-Za-z][A-Za-z\s]*$/.test(value);
          }
          return true;
        }
      ),
    lname: yup
      .string()
      .min(3, "Last name must be at least 3 characters")
      .test("letters-only", "Last name must contain only letters", (value) => {
        if (value && value.length > 0) {
          return /^[A-Za-z][A-Za-z\s]*$/.test(value);
        }
        return true;
      })
      .required("Last name is required"),
    street: yup.string()
      .required('Street is required'),
    city: yup.string()
      .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, 'City must contain only alphabets')
      .required('City is required'),
    houseNumber: yup.string()
      .required('House number is required'),
  });

  useEffect(() => {
    if (userDetails?.CustomerUser) {
      const { first_name, name, email, password, title, id, contact_person, address1, city_name, address2 } = userDetails.CustomerUser;
      setAccountData({
        fname: first_name,
        lname: name,
        email,
        password,
        title,
        id,
        pnumber: contact_person,
        street: address2,
        city: city_name,
        country: "",
        houseNumber: address1
      });
    }
  }, [userDetails]);

  useEffect(() => {
    const { first_name, name, email, address1, city_name, address2 } = userDetails.CustomerUser;

    const isFirstNameMatch = accountData.fname === first_name
    const isLastNameMatch = accountData.lname === name
    const isEmailMatch = accountData.email === email
    const isStreetMatch = accountData.street === address2
    const isCityMatch = accountData.city === city_name
    const isHouseNumberMatch = accountData.houseNumber === address1
    const isPasswordChanged = !isEditPasswordClicked ? true :
      formData.password === "" && formData.confirmPassword === "" ? true :
        formData.password === "" ? true : formData.password !== accountData.password ? false : formData.confirmPassword === "" ? true : formData.confirmPassword !== accountData.password ? false : true



    // (formData.password === "" && formData.confirmPassword === "") ? true : false : true

    if (isFirstNameMatch && isLastNameMatch && isEmailMatch && isStreetMatch && isCityMatch && isPasswordChanged && isHouseNumberMatch) {
      setisChangedData(false)
    } else {
      setisChangedData(true)
    }

  }, [accountData, userDetails, isEditPasswordClicked, formData.password, formData.confirmPassword])

  // !functions
  const updateUserDataHandler = (evt: InputEvent) => {
    const { name, value } = evt.target;
    let val = { ...accountData, [name]: value }
    setAccountData(val);

    if (saveButtonClick) {
      formDataValidationSchema
        .validate(val, { abortEarly: false })
        .then(async (response) => {
          setAccountDataError({
            fname: '',
            lname: '',
            street: "",
            city: "",
            houseNumber: ""
          })
        })
        .catch((err) => {
          const errors: any = {};
          err.inner.forEach((e: any) => {
            errors[e.path] = e.message;
          });
          setAccountDataError(errors);
        })
    }
  };

  const generateKeys = async () => {
    const body = [1, { "email_address": accountData.email }]
    const response = await dispatch(resetPassword(body))
    const data = await response.payload
    return data
  }

  const validateKeys = async (key: string) => {
    const body = [2, { key, id: userDetails.CustomerUser.id }];
    const response = await API.post(API_METHOD.Misc.RESET_PASSWORD, body);
    return await response?.data || false;
  }

  const resetPasswordApi = async () => {
    dispatch(setLoading(true));
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(async (response) => {
        setFormError({
          password: "",
          confirmPassword: ""
        });

        const resp1 = await generateKeys()

        if (resp1.key) {
          const resp2 = await validateKeys(resp1.key)
          if (resp2) {
            const body = [null, { password: formData.password, id: userDetails.CustomerUser.id }];
            const res = await API.post(API_METHOD.Misc.RESET_PASSWORD, body);
            if (res.data) {
              await updateUserDetails()
              await dispatch(setLoading(false));
              await dispatch(setUserDetails({}))
              await removeCookie('user-email');
              await removeLocalStorage("userData")
              await navigate("/signin")
            } else {
              await dispatch(setLoading(false));
            }
          } else {
            await dispatch(setLoading(false));
          }
        } else {
          await dispatch(setLoading(false));
        }
      })
      .catch((err: any) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setFormError(errors);
        dispatch(setLoading(false));
      });
  };

  const handleChangeResetPassword = (e: any) => {
    const { name, value } = e.target
    const data: any = { ...formData }
    data[name] = value
    setFormData(data)

    if (saveButtonClick) {
      if (data.password === "" && data.confirmPassword === "") {
        setFormError({
          password: "",
          confirmPassword: ""
        });
      } else {
        validationSchema
          .validate(data, { abortEarly: false })
          .then(async (response) => {
            setFormError({
              password: "",
              confirmPassword: ""
            });
          })
          .catch((err: any) => {
            const errors: any = {};
            err.inner.forEach((e: any) => {
              errors[e.path] = e.message;
            });
            setFormError(errors);
          });
      }
    }
  }

  const updateUserDetails = async () => {
    dispatch(setLoading(true))
    const body = [{
      "id": accountData.id,
      "first_name": accountData.fname,
      "name": accountData.lname,
      "address1": accountData.houseNumber,
      "city_name": accountData.city,
      "address2": accountData.street
    }]

    const response = await API.post(API_METHOD.Misc.UPDATE_USER_INFORMATION, body);
    if (response?.data.success === 1) {
      const data = response.data.data

      dispatch(setUserDetails({
        CustomerUser: {
          ...userDetails.CustomerUser,
          "id": data.id,
          "first_name": data.first_name,
          "name": data.name,
          "address1": accountData.houseNumber,
          city_name: data.city_name,
          "address2": accountData.street
        }
      }))
    }
  }

  const updateProfile = async () => {
    setSaveButtonClick(true)
    formDataValidationSchema
      .validate(accountData, { abortEarly: false })
      .then(async (response) => {
        setAccountDataError({
          fname: '',
          lname: '',
          street: "",
          city: "",
        })

        if (isEditPasswordClicked) {
          await resetPasswordApi()
        } else {
          await updateUserDetails()
          await dispatch(setLoading(false))
        }
      })
      .catch((err) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setAccountDataError(errors);
      })
  }

  return (
    <Box>
      <Box bgcolor={colors.grey[20]} height='54px' display='flex' alignItems='center' mb='32px'>
        <CustomContainer>
          <Typography variant='h1' fontSize='28px' fontWeight={600}>
            MY ACCOUNT
          </Typography>
        </CustomContainer>
      </Box>
      <CustomContainer>
        <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
          <Box mr={{ xl: '0px' }} width='100%'>
            <Box sx={{
              '& .Mui-error': {
                marginLeft: "0px",
              }
            }}>
              <form>
                {/* <TextInput
                  title='Title'
                  style={{ maxWidth: '700px' }}
                  name='title'
                  value={accountData.title}
                  onChange={updateUserDataHandler}
                /> */}
                <Grid container spacing={5}>
                  <Grid item sm={6} xs={12}>
                    <TextInput
                      title='FIRST NAME (as per passport)'
                      name='fname'
                      value={accountData.fname}
                      onChange={updateUserDataHandler}
                      error={accountDataError && accountDataError['fname'] ? true : false}
                      helperText={accountDataError && accountDataError['fname'] ? accountDataError['fname'] : ''}
                    />
                  </Grid>
                  <Grid pt={{ xs: accountDataError.fname ? "30px !important" : "20px !important", sm: "40px !important" }} item sm={6} xs={12}>
                    <TextInput
                      title='LAST NAME'
                      value={accountData.lname}
                      name='lname'
                      onChange={updateUserDataHandler}
                      error={accountDataError && accountDataError['lname'] ? true : false}
                      helperText={accountDataError && accountDataError['lname'] ? accountDataError['lname'] : ''}
                    />
                  </Grid>
                </Grid>

                <Grid pt={
                  (accountDataError['lname'] || accountDataError['fname']) ? "30px !important" : "20px !important"
                } p={"26px 0px 0px"} container spacing={5}>
                  <Grid item md={6} sm={6} xs={12}>
                    <TextInput
                      title='EMAIL ADDRESS'
                      type='email'
                      name='email'
                      value={accountData.email}
                      disabled
                    />
                  </Grid>
                  {/* <Grid item md={6} sm={6} xs={12}>
                  </Grid> */}
                </Grid>

                <Grid pt={{ xs: "20px !important", sm: "0px" }} p={"26px 0px 0px"} container spacing={5}>
                  <Grid sx={{
                    '& svg': {
                      cursor: "pointer"
                    }
                  }} item md={6} sm={6} xs={12}>
                    <TextInput
                      title='PASSWORD'
                      type={showPassword ? 'text' : 'password'}
                      name='password'
                      disabled
                      value={accountData.password}
                      onChange={updateUserDataHandler}
                      EndIcon={
                        <IconButton onClick={() => {
                          setShowPassword(!showPassword)
                        }}>
                          {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                        </IconButton>
                      }
                    />
                  </Grid>
                  <Grid sx={{
                    '& .MuiButtonBase-root:hover': {
                      background: isEditPasswordClicked ? colors.basics.red : colors.grey[30]
                    }
                  }} mt={{ xs: "-22px", sm: "30px" }} item md={6} sm={6} xs={12}>
                    <Button onClick={() => {
                      if (!isEditPasswordClicked) {
                        setFormData({
                          password: "",
                          confirmPassword: ""
                        })
                      }
                      setisEditPasswordClicked(!isEditPasswordClicked)
                    }} sx={{ whiteSpace: 'nowrap', bgcolor: isEditPasswordClicked ? colors.basics.red : colors.grey[30] }}>
                      <Typography color={colors.basics.white} variant='subtitle1'> {isEditPasswordClicked ? "Cancel" : "Change Password"} </Typography>
                    </Button>
                  </Grid>
                </Grid>

                {isEditPasswordClicked &&
                  <Box>
                    <Grid container spacing={5}>
                      <Grid item sm={6} xs={12}>
                        <TextInput
                          autoComplete='new-password'
                          style={{ marginTop: '25px' }}
                          EndIcon={
                            <IconButton onClick={() => {
                              setIsHiddenPasswords({
                                ...isHiddenPasswords,
                                "password": !isHiddenPasswords.password
                              })
                            }}>
                              {!isHiddenPasswords.password ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                            </IconButton>
                          }
                          type={isHiddenPasswords.password ? "password" : "text"}
                          title='Change Password *'
                          required
                          onChange={(evt) => handleChangeResetPassword(evt)}
                          name={`password`}
                          value={formData.password}
                          error={formError && formError['password'] ? true : false}
                          helperText={formError && formError['password'] ? formError['password'] : ''}
                        />
                      </Grid>
                      <Grid pt={{ xs: formError.password ? "5px !important" : "0px !important", sm: "40px !important" }} item sm={6} xs={12}>
                        <TextInput
                          autoComplete='new-password'
                          style={{ marginTop: '25px', marginBottom: formError['confirmPassword'] ? "30px" : '25px', maxWidth: '700px' }}
                          EndIcon={
                            <IconButton onClick={() => {
                              setIsHiddenPasswords({
                                ...isHiddenPasswords,
                                "confirmPassword": !isHiddenPasswords.confirmPassword
                              })
                            }}>
                              {!isHiddenPasswords.confirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                            </IconButton>
                          }
                          type={isHiddenPasswords.confirmPassword ? "password" : "text"}
                          title='Confirm Password *'
                          required
                          value={formData.confirmPassword}
                          onChange={(evt) => handleChangeResetPassword(evt)}
                          name='confirmPassword'
                          error={formError && formError['confirmPassword'] ? true : false}
                          helperText={formError && formError['confirmPassword'] ? formError['confirmPassword'] : ''}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                }


                <Grid p={!isEditPasswordClicked ? "26px 0px 0px" : "0"} container spacing={5}>
                  <Grid item sm={6} xs={12}>
                    <TextInput
                      title='House Number'
                      value={accountData.houseNumber}
                      name='houseNumber'
                      onChange={updateUserDataHandler}
                      error={accountDataError && accountDataError['houseNumber'] ? true : false}
                      helperText={accountDataError && accountDataError['houseNumber'] ? accountDataError['houseNumber'] : ''}
                    />
                  </Grid>
                  <Grid pt={{ xs: accountDataError.street ? "30px !important" : "20px !important", sm: "40px !important" }} item sm={6} xs={12}>
                    <TextInput
                      title='Street Address'
                      value={accountData.street}
                      name='street'
                      onChange={updateUserDataHandler}
                      error={accountDataError && accountDataError['street'] ? true : false}
                      helperText={accountDataError && accountDataError['street'] ? accountDataError['street'] : ''}
                    />
                  </Grid>
                </Grid>

                <Grid p={"26px 0px 0px"} container spacing={5}>
                  <Grid item sm={6} xs={12}>
                    <TextInput
                      title='City'
                      value={accountData.city}
                      name='city'
                      onChange={updateUserDataHandler}
                      error={accountDataError && accountDataError['city'] ? true : false}
                      helperText={accountDataError && accountDataError['city'] ? accountDataError['city'] : ''}
                    />
                  </Grid>
                  <Grid pt={{ xs: accountDataError.street ? "30px !important" : "20px !important", sm: "40px !important" }} item sm={6} xs={12}>
                  </Grid>
                </Grid>

                <Box display='flex' my='35px'>
                  {/* <Button variant='outlined' sx={{ mr: 2 }}>
                    Cancel
                  </Button> */}
                  <Button disabled={!isChangedData} onClick={() => {
                    updateProfile()
                  }}>Save</Button>
                </Box>
              </form>
            </Box>
          </Box >
        </Box >
      </CustomContainer >
    </Box >
  );
};
export default MyAccount;
