
import * as yup from "yup";

export const validationSchema = yup.array().of(
  yup.object().shape({
    isLoggedIn: yup.boolean(),
    arrayIndex: yup.number(),
    email: yup.string().when(["isLoggedIn", "arrayIndex"], {
      is: (isLoggedIn: any, arrayIndex: any) => {
        return arrayIndex === 1;
      },
      then: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
      otherwise: yup.string(), // Validation without the required constraint
    }),
    fname: yup.string().when(["isLoggedIn", "arrayIndex"], {
      is: (isLoggedIn: any, arrayIndex: any) => {
        return isLoggedIn && arrayIndex === 1;
      },
      then: yup
        .string()
        .required("First name is required")
        .test(
          "letters-only",
          "First name must contain only letters",
          (value) => {
            if (value && value.length > 0) {
              return /^[A-Za-z][A-Za-z\s]*$/.test(value);
            }
            return true;
          }
        ),
      otherwise: yup.string().when(["isLoggedIn", "arrayIndex"], {
        is: (isLoggedIn: any, arrayIndex: any) =>
          isLoggedIn && arrayIndex !== 1,
        then: yup
          .string()
          .test(
            "letters-only",
            "First name must contain only letters",
            (value) => {
              if (value && value.length > 0) {
                return /^[A-Za-z][A-Za-z\s]*$/.test(value);
              }
              return true;
            }
          ),
        otherwise: yup
          .string()
          .required("First name is required")
          .test(
            "letters-only",
            "First name must contain only letters",
            (value) => {
              if (value && value.length > 0) {
                return /^[A-Za-z][A-Za-z\s]*$/.test(value);
              }
              return true;
            }
          ),
      }),
    }),
    mname: yup
      .string()
      .test(
        "letters-only",
        "Middle name must contain only letters",
        (value) => {
          if (value && value.length > 0) {
            return /^[A-Za-z][A-Za-z\s]*$/.test(value);
          }
          return true;
        }
      ),
    title: yup.string().when(["isLoggedIn", "arrayIndex"], {
      is: (isLoggedIn: any, arrayIndex: any) => {
        return isLoggedIn && arrayIndex === 1;
      },
      then: yup.string().required("Title is required"),
      otherwise: yup.string().when(["isLoggedIn", "arrayIndex"], {
        is: (isLoggedIn: any, arrayIndex: any) =>
          isLoggedIn && arrayIndex !== 1,
        then: yup.string(),
        otherwise: yup.string().required("Title is required"),
      }),
    }),
    sname: yup.string().when(["isLoggedIn", "arrayIndex"], {
      is: (isLoggedIn: any, arrayIndex: any) => {
        return isLoggedIn && arrayIndex === 1;
      },
      then: yup
        .string()
        .min(3, "Surname must be at least 3 characters")
        .test("letters-only", "Surname must contain only letters", (value) => {
          if (value && value.length > 0) {
            return /^[A-Za-z][A-Za-z\s]*$/.test(value);
          }
          return true;
        })
        .required("Surname is required"),
      otherwise: yup.string().when(["isLoggedIn", "arrayIndex"], {
        is: (isLoggedIn: any, arrayIndex: any) =>
          isLoggedIn && arrayIndex !== 1,
        then: yup.string().when("sname", {
          is: (surname: any) => surname && surname.length > 0,
          then: yup
            .string()
            .min(3, "Surname must be at least 3 characters")
            .test(
              "letters-only",
              "Surname must contain only letters",
              (value) => {
                if (value && value.length > 0) {
                  return /^[A-Za-z][A-Za-z\s]*$/.test(value);
                }
                return true;
              }
            ),
          otherwise: yup
            .string()
            .test(
              "letters-only",
              "Surname must contain only letters",
              (value) => {
                if (value && value.length > 0) {
                  return /^[A-Za-z][A-Za-z\s]*$/.test(value);
                }
                return true;
              }
            ),
        }),
        // .required("Surname is required"),
        otherwise: yup
          .string()
          .min(3, "Surname must be at least 3 characters")
          .test(
            "letters-only",
            "Surname must contain only letters",
            (value) => {
              if (value && value.length > 0) {
                return /^[A-Za-z][A-Za-z\s]*$/.test(value);
              }
              return true;
            }
          )
          .required("Surname is required"),
      }),
    }),
    pnumber: process.env.REACT_APP_PROJECT_COUNTRY === "ie" ?

      yup.string().when(["isLoggedIn", "arrayIndex"], {
        is: (isLoggedIn: any, arrayIndex: any) => arrayIndex === 1,
        then: yup
          .string()
          .nullable()
          .matches(
            /^(\+[0-9]{1,4}[ \-]*)?([0-9]{8,10})$/, // Updated regex pattern for 5 to 10 digits
            "Contact number should be between 8 and 10 digits"
          )
          .required("Contact number is required"),
        otherwise: yup.string(), // Validation without the required constraint
      }) :

      yup.string().when(["isLoggedIn", "arrayIndex"], {
        is: (isLoggedIn: any, arrayIndex: any) => {
          return arrayIndex === 1;
        },
        then: yup
          .string()
          .nullable()
          .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            "Contact number is not valid"
          )
          .min(11, "Contact number should be 11 digit")
          .max(11, "Contact number should be 11 digit")
          .required("Contact number is required"),
        otherwise: yup.string(), // Validation without the required constraint
      })
    ,
    day: yup.number().when(["isLoggedIn", "arrayIndex"], {
      is: (isLoggedIn: any, arrayIndex: any) => {
        return isLoggedIn && arrayIndex === 1;
      },
      then: yup
        .number()
        .integer("Invalid day")
        .min(1, "Invalid day")
        .max(31, "Invalid day")
        .nullable()
        .required("Day is required"),
      otherwise: yup.number().when(["isLoggedIn", "arrayIndex"], {
        is: (isLoggedIn: any, arrayIndex: any) =>
          isLoggedIn && arrayIndex !== 0,
        then: yup.number().when("day", {
          is: (day: any) => day === null,
          then: yup.number().nullable(),
          otherwise: yup
            .number()
            .integer("Invalid day")
            .min(1, "Invalid day")
            .max(31, "Invalid day")
            .nullable()
            .required("Day is required"),
        }),
        otherwise: yup
          .number()
          .integer("Invalid day")
          .min(1, "Invalid day")
          .max(31, "Invalid day")
          .nullable()
          .required("Day is required"),
      }),
    }),
    month: yup.number().when(["isLoggedIn", "arrayIndex"], {
      is: (isLoggedIn: any, arrayIndex: any) => {
        return isLoggedIn && arrayIndex === 1;
      },
      then: yup
        .number()
        .integer("Invalid month")
        .min(1, "Invalid month")
        .max(12, "Invalid month")
        .nullable()
        .required("Month is required"),
      otherwise: yup.number().when(["isLoggedIn", "arrayIndex"], {
        is: (isLoggedIn: any, arrayIndex: any) =>
          isLoggedIn && arrayIndex !== 1,
        then: yup.number().when("month", {
          is: (day: any) => day === null,
          then: yup.number().nullable(),
          otherwise: yup
            .number()
            .integer("Invalid month")
            .min(1, "Invalid month")
            .max(12, "Invalid month")
            .nullable()
            .required("Month is required"),
        }),
        otherwise: yup
          .number()
          .integer("Invalid month")
          .min(1, "Invalid month")
          .max(12, "Invalid month")
          .nullable()
          .required("Month is required"),
      }),
    }),
    year: yup
      .mixed()
      .nullable()
      .when("pax_type", {
        is: "ADT",
        then: yup
          .string()
          .nullable()
          .when(["isLoggedIn", "arrayIndex"], {
            is: (isLoggedIn: any, arrayIndex: any) => {
              return isLoggedIn && arrayIndex === 1;
            },
            then: yup
              .string()
              .nullable()
              .test(
                "valid-year",
                "Must be 18 years or older",
                function (value) {
                  if (!/^\d{4}$/.test(value || "")) {
                    return this.createError({
                      message: "Year must be in YYYY format",
                    });
                  }
                  const year = parseInt(value || "", 10);
                  const currentYear = new Date().getFullYear();
                  return year <= currentYear - 18;
                }
              )
              .required("Year is required"),
            otherwise: yup
              .string()
              .nullable()
              .when(["isLoggedIn", "arrayIndex"], {
                is: (isLoggedIn: any, arrayIndex: any) =>
                  isLoggedIn && arrayIndex !== 1,
                then: yup
                  .string()
                  .nullable()
                  .when("year", {
                    is: (year: any) => year === null,
                    then: yup.string().nullable(),
                    otherwise: yup
                      .string()
                      .nullable()
                      .test(
                        "valid-year",
                        "Must be 18 years or older",
                        function (value) {
                          if (!/^\d{4}$/.test(value || "")) {
                            return this.createError({
                              message: "Year must be in YYYY format",
                            });
                          }
                          const year = parseInt(value || "", 10);
                          const currentYear = new Date().getFullYear();
                          return year <= currentYear - 18;
                        }
                      )
                      .required("Year is required"),
                  }),
                otherwise: yup
                  .string()
                  .nullable()
                  .test(
                    "valid-year",
                    "Must be 18 years or older",
                    function (value) {
                      if (!/^\d{4}$/.test(value || "")) {
                        return this.createError({
                          message: "Year must be in YYYY format",
                        });
                      }
                      const year = parseInt(value || "", 10);
                      const currentYear = new Date().getFullYear();
                      return year <= currentYear - 18;
                    }
                  )
                  .required("Year is required"),
              }),
          }),
        otherwise: yup
          .string()
          .nullable()
          .when(["isLoggedIn", "arrayIndex"], {
            is: (isLoggedIn: any, arrayIndex: any) => {
              return isLoggedIn && arrayIndex === 1;
            },
            then: yup
              .string()
              .nullable()
              .test(
                "valid-year",
                "Must be younger than 18 years",
                function (value) {
                  if (!/^\d{4}$/.test(value || "")) {
                    return this.createError({
                      message: "Year must be in YYYY format",
                    });
                  }
                  const year = parseInt(value || "", 10);
                  const currentYear = new Date().getFullYear();
                  return year > currentYear - 18;
                }
              )
              .required("Year is required"),
            otherwise: yup
              .string()
              .nullable()
              .when(["isLoggedIn", "arrayIndex"], {
                is: (isLoggedIn: any, arrayIndex: any) =>
                  isLoggedIn && arrayIndex !== 1,
                then: yup
                  .string()
                  .nullable()
                  .when("year", {
                    is: (year: any) => year === null,
                    then: yup.string().nullable(),
                    otherwise: yup
                      .string()
                      .nullable()
                      .test(
                        "valid-year",
                        "Must be younger than 18 years",
                        function (value) {
                          if (!/^\d{4}$/.test(value || "")) {
                            return this.createError({
                              message: "Year must be in YYYY format",
                            });
                          }
                          const year = parseInt(value || "", 10);
                          const currentYear = new Date().getFullYear();
                          return year > currentYear - 18;
                        }
                      )
                      .required("Year is required"),
                  }),
                otherwise: yup
                  .string()
                  .nullable()
                  .test(
                    "valid-year",
                    "Must be younger than 18 years",
                    function (value) {
                      if (!/^\d{4}$/.test(value || "")) {
                        return this.createError({
                          message: "Year must be in YYYY format",
                        });
                      }
                      const year = parseInt(value || "", 10);
                      const currentYear = new Date().getFullYear();
                      return year > currentYear - 18;
                    }
                  )
                  .required("Year is required"),
              }),
          }),
      })
      .when("pax_type", {
        is: "INF",
        then: yup
          .string()
          .nullable()
          .when(["isLoggedIn", "arrayIndex"], {
            is: (isLoggedIn: any, arrayIndex: any) => {
              return isLoggedIn && arrayIndex === 1;
            },
            then: yup
              .string()
              .nullable()
              .test(
                "valid-age",
                "Must be 23 months or younger",
                function (value) {
                  if (!/^\d{4}$/.test(value || "")) {
                    return this.createError({
                      message: "Year must be in YYYY format",
                    });
                  }
                  const year = parseInt(value || "", 10);
                  const currentYear = new Date().getFullYear();
                  const maxAgeYear = currentYear - 2;
                  return year >= maxAgeYear;
                }
              )
              .required("Year is required"),
            otherwise: yup
              .string()
              .nullable()
              .when(["isLoggedIn", "arrayIndex"], {
                is: (isLoggedIn: any, arrayIndex: any) =>
                  isLoggedIn && arrayIndex !== 1,
                then: yup
                  .string()
                  .nullable()
                  .when("year", {
                    is: (year: any) => year === null,
                    then: yup.string().nullable(),
                    otherwise: yup
                      .string()
                      .nullable()
                      .test(
                        "valid-age",
                        "Must be 23 months or younger",
                        function (value) {
                          if (!/^\d{4}$/.test(value || "")) {
                            return this.createError({
                              message: "Year must be in YYYY format",
                            });
                          }
                          const year = parseInt(value || "", 10);
                          const currentYear = new Date().getFullYear();
                          const maxAgeYear = currentYear - 2;
                          return year >= maxAgeYear;
                        }
                      )
                      .required("Year is required"),
                  }),
                otherwise: yup
                  .string()
                  .nullable()
                  .test(
                    "valid-age",
                    "Must be 23 months or younger",
                    function (value) {
                      if (!/^\d{4}$/.test(value || "")) {
                        return this.createError({
                          message: "Year must be in YYYY format",
                        });
                      }
                      const year = parseInt(value || "", 10);
                      const currentYear = new Date().getFullYear();
                      const maxAgeYear = currentYear - 2;
                      return year >= maxAgeYear;
                    }
                  )
                  .required("Year is required"),
              }),
          }),
      }),
  })
);

export const validationSchemaPostalAddress = yup.object().shape({
  postcode: yup.string()
    .matches(/^[a-zA-Z0-9]+(?:[ -][a-zA-Z0-9]+)*$/, 'Postcode must contain only numbers and alphabets')
    .required('Postcode is required'),
  house: yup.string()
    .required('House number is required'),
  street: yup.string()
    .required('Street is required'),
  city: process.env.REACT_APP_PROJECT_COUNTRY === "ie" ?
    yup.string()
    .matches(/^[a-zA-Z0-9]+(?:[.\- ]+[a-zA-Z0-9]+)*[.\- ]?$/, 'City must contain only numbers and alphabets')
      .required('City is required')
    : yup.string()
    .matches(/^[a-zA-Z]+(?:[.\- ]+[a-zA-Z]+)*[.\- ]?$/, 'City must contain only alphabets')
      .required('City is required'),
      country: yup.string()
    .matches(/^[a-zA-Z]+(?:[ -][a-zA-Z]+)*$/, 'Country must contain only alphabets')
    .required('Country is required'),
})

