import { createSlice } from '@reduxjs/toolkit';

interface Filters {
  filters: {
    holidayType: string[];
    starRating: any[];
    regionType: string[];
    hotelName: string;
    sorting: string;
    price: any;
  };
}

const initialState: Filters = {
  filters: {
    holidayType: [],
    starRating: [],
    hotelName: '',
    price: [],
    sorting: 'Popularity',
    regionType: [],
  },
};

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilterState: (state, { payload }) => {
      state.filters = payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
});

export const { setFilterState, resetFilters } = filterSlice.actions;
export default filterSlice.reducer;
