import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isClickedFilters: false,
  isHolidayApiCall: false,
  position: { top: 0, left: 0 },
  selectedRegion: {},

};

const commonStateSlice = createSlice({
  name: "commonState",
  initialState: initialState,
  reducers: {
    setIsClickedFilters(state, action) {
      state.isClickedFilters = action?.payload;
    },
    setIsHolidayApiCall(state, action) {
      state.isHolidayApiCall = action?.payload;
    },
    setPosition(state, action) {
      state.position = action?.payload;
    },
    setSelectedRegion(state, action) {
      state.selectedRegion = action?.payload;
    },
  },
});

export const { setIsClickedFilters, setIsHolidayApiCall,setSelectedRegion, setPosition } =
  commonStateSlice.actions;
export default commonStateSlice.reducer;
