import { useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { ReactComponent as ArrowDown } from '../assets/icons/ArrowDown.svg';
import { ReactComponent as ArrowUp } from '../assets/icons/ArrowUp.svg';
import { colors } from '../styles/colors';
import dayjs from 'dayjs';
import { isEmpty } from '../helpers/isEmpty';
import useWindowWidth from '../hooks/useWindowWidth';

interface customeDatePickerProps {
  setDate: (date: any) => void;
  onDateConfirm?: () => void;
  date: any;
  onlyMonthPicker?: boolean;
  onlyYearPicker?: boolean;
  numberOfMonths?: number;
  range?: boolean;
  format?: any;
  openModals?: any;
  setOpenModals?: any;
  handleChangeDatePicker?: any;
  datePickerRef?: any;
  onOpenDatePicker?: any;
  onCloseDatePicker?: any;
  isButtonShown?: boolean;
  initialDates?: any;
}

export const CustomeDatePicker = ({
  setDate,
  date,
  onlyMonthPicker,
  onlyYearPicker,
  numberOfMonths,
  range,
  format,
  onDateConfirm,
  openModals,
  setOpenModals,
  handleChangeDatePicker,
  datePickerRef,
  onOpenDatePicker,
  onCloseDatePicker,
  isButtonShown,
  initialDates
}: customeDatePickerProps) => {
  //! Hooks
  const [open, setOpen] = useState<boolean>(false);
  const [pickerDate, setPickerDate] = useState<any>(initialDates);
  const windowWidth = useWindowWidth()

  let currentDate = new Date();
  let maxDate = new Date(currentDate);
  maxDate.setMonth(currentDate.getMonth() + 11);
  let minDate = new Date();
  minDate.setDate(currentDate.getDate() + 11);
  const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const style = {
    position: 'relative',
    marginBottom: '3px',
    height: '47px',
    display: 'flex',
    alignItems: 'center',
    '& .rmdp-shadow': {
      boxShadow: 'none',
      border: `1px solid ${colors.grey[30]}`,
    },
    "& .rmdp-day-picker > div:first-of-type": {
      marginRight: windowWidth > 1023 ? "20px !important" : 0,
    },
    '& .rmdp-calendar': {
      padding: '24px',
      '& .rmdp-header': {
        marginBottom: { sm: 0, xs: '10px' }
      }
    },

    '& .rmdp-day-picker ': {
      display: { sm: 'flex !important', xs: 'block !important' },
    },

    '& .rmdp-day span': {
      left: '0 !important',
      right: '0 !important',
      top: '0 !important',
      borderRadius: '0 !important',
      bottom: '0 !important',
    },
    ' & .rmdp-range': {
      backgroundColor: '#e5e5e5',
      borderColor: colors.basics.lightmain,
      color: colors.basics.black,
    },
    '& .rmdp-day': {
      borderColor: '#d5d5d5 !important',
      backgroundColor: `${colors.basics.white}`,
      borderLeft: `0.5px solid ${colors.basics.lightmain}`,
      borderTop: `0.5px solid ${colors.basics.lightmain}`,
      boxShadow: 'none !important',
      borderRight: `0.5px solid ${colors.basics.lightmain}`,
      borderBottom: `0.5px solid ${colors.basics.lightmain}`,
      '& span': {
        backgroundColor: `${colors.basics.white} !important`,
        borderColor: 'transparent',
        color: `${colors.basics.black} !important`,
      },
    },

    '& .rmdp-day-hidden': {
      visibility: 'hidden',
    },
    '& .rmdp-day span:hover': {
      backgroundColor: '#c4c4c4 !important',
      color: `${colors.basics.black} !important`,
      borderRadius: '0 !important',
      top: '0 !important',
      left: '0 !important',
      bottom: '0 !important',
      right: '0 !important',
    },
    '& .rmdp-day.rmdp-range': {
      backgroundColor: '#e5e5e5 !important',
      borderRadius: '0',
    },

    '& .rmdp-arrow-container': {
      '&:hover': {
        backgroundColor: `${colors.basics.white} !important`,
        boxShadow: 'none',
        '& .rmdp-arrow': {
          borderColor: colors.grey[30],
        },
      },
    },

    '& .rmdp-day.rmdp-range span': {
      backgroundColor: '#e5e5e5 !important',
    },
    '& .rmdp-day.rmdp-range.start span': {
      backgroundColor: '#c4c4c4 !important',
      left: '0 !important',
      right: '0 !important',
      top: '0 !important',
      borderRadius: '0 !important',
      bottom: '0 !important',
    },
    ' & .rmdp-day.rmdp-range.end span': {
      backgroundColor: '#c4c4c4 !important',
      left: '0 !important',
      right: '0 !important',
      top: '0 !important',
      borderRadius: '0 !important',
      bottom: '0 !important',
    },

    '& .rmdp-week-day': {
      color: colors.basics.black,
    },
    '& .rmdp-arrow ': {
      height: { sm: '15px', xs: '10px' },
      width: { sm: '15px', xs: '10px' },
      borderColor: colors.grey[30],
    },
    '& .cal-icon': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: '10px',
      display: 'flex',

      '& .arrow': {
        position: 'absolute',
        top: '50%',
        right: '17px',
        cursor: 'pointer',
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
      },
      '& .arrow svg': {
        fill: colors.grey[30],
      },
      '& .rmdp-container .rmdp-input': {
        width: '100%',
        height: '56px',
        borderRadius: '5px',
        border: isEmpty(openModals) !== "" && openModals.date === true ? `1px solid ${colors.basics.black}` : `1px solid ${colors.grey[30]}`,
        padding: '10px',
        color: colors.grey[10],
        pl: '42px',
        pr: '37px',
        fontFamily: 'Raleway',
        fontSize: '14px'
      },

      '& .rmdp-container': {
        width: '100%',
      },
      '& input.rmdp-input': {
        pl: '45px',
      },
    },

    '& .arrow': {
      position: 'absolute',
      top: '50%',
      right: '17px',
      cursor: 'pointer',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
    },
    '& .arrow svg': {
      fill: colors.grey[30],
    },
    '& .rmdp-container .rmdp-input': {
      width: '100%',
      height: '56px',
      borderRadius: '5px',
      border: isEmpty(openModals) !== "" && openModals.date === true ? `1px solid ${colors.basics.black}` : `1px solid ${colors.grey[30]}`,
      padding: '10px',
      color: colors.grey[10],
      pl: '42px',
      pr: '37px',
      fontFamily: 'Raleway',
      fontSize: '14px',
    },

    '& .rmdp-container': {
      width: '100%',
    },
    '& input.rmdp-input': {
      paddingLeft: '45px',
    },

    '& .rmdp-week, & .rmdp-ym': {
      justifyContent: 'unset',
    },
    '& .rmdp-disabled span': {
      backgroundColor: "#f2f2f261 !important"
    }
  };

  useEffect(() => {
    if (date?.[0]) {
      let startingDate: any = new DateObject(date?.[0]);
      let endingDate: any = new DateObject(date?.[1]);

      setPickerDate([
        new DateObject(dayjs(new Date(startingDate)).format()),
        new DateObject(dayjs(new Date(endingDate)).format()),
      ]);
    }
  }, [date]);

  useEffect(() => {
    const handleBodyClick = (event: any) => {
      // event propation is for open or close calender
      // event.stopPropagation();
    }

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick)
    }
  }, [])

  useEffect(() => {
    if (!open) {
      onDateConfirm && onDateConfirm()
    }
  }, [open, date])

  return (
    <Box sx={style}>
      <Box className='cal-icon'>
        <EventAvailableIcon sx={{ color: colors.basics.primary }} />
      </Box>

      <Box className='datepicker'
        sx={{
          '& input': {
            textOverflow: "ellipsis"
          },
          width: '100%', '& .ep-arrow ~ div': {
            zIndex: '9999'
          },
        }}>
        <DatePicker
          value={pickerDate}
          ref={datePickerRef}
          numberOfMonths={numberOfMonths}
          weekDays={weekDays}
          months={months}
          range={range}
          fixMainPosition={true}
          onChange={(date: any) => {
            range ? setPickerDate(date) : setDate(date)
          }}
          maxDate={maxDate}
          minDate={minDate}
          editable={false}
          onOpen={() => {
            onOpenDatePicker && onOpenDatePicker()
            setOpen(true)
          }}
          onClose={() => {
            range && setDate(pickerDate)
            onCloseDatePicker && onCloseDatePicker()
            setOpen(false)
          }}
          // format='DD MMM YY'
          format={format}
          style={{ height: '48px', cursor: 'pointer' }}
          onlyMonthPicker={onlyMonthPicker}
          onlyYearPicker={onlyYearPicker}
          weekStartDayIndex={1}
          calendarPosition={(windowWidth > 1023 && windowWidth < 1171) || (windowWidth > 599 && windowWidth < 950) ? "bottom-right" : "bottom-left"}
        >
          <Box display='flex' textAlign={"end"} p={"0 24px 24px"}>
            <Box display={"none"} className='btn-cancel'>
              {/* <Button variant='outlined' onClick={() => {
                datePickerRef.current.closeCalendar()
                setOpenModals({
                  ...openModals,
                  date: false
                })
                setPickerDate(date)
              }}>
                Cancel
              </Button> */}
            </Box>
            {isButtonShown && <Box className='btn-continue'>
              <Button
                variant='contained'
                onClick={() => {
                  onDateConfirm && onDateConfirm();
                  setDate(pickerDate);
                  setOpenModals({
                    ...openModals,
                    date: false
                  })
                  handleChangeDatePicker()
                  datePickerRef.current.closeCalendar();
                }}
              >
                Continue
              </Button>
            </Box>}
          </Box>
        </DatePicker>
      </Box>
      <Box className='arrow'>{open ? <ArrowUp /> : <ArrowDown />}</Box>
    </Box>
  );
};
