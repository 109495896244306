import { Box, Button, Dialog, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { setDialogState } from '../../features/dialog/dialogSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppReducer';
import TextInput from '../../components/TextInput';
import * as yup from 'yup';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';
// import { useParams } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { colors } from '../../styles/colors';
import { isEmpty } from '../../helpers/isEmpty';
import { ReactComponent as CloseModal } from '../../assets/icons/CloseModal.svg';
import { setLoading } from '../../features/loading/loadingSlice';
import { getSessionStorage, setSessionStorage } from '../../helpers/storage';

export const emailItinerarySchema = yup.object().shape({
  email: yup.string().email("Invalid email address").required("Email is required"),
  fname: yup.string().required("First name is required").test('letters-only', 'First name must contain only letters', (value) => {
    if (value && value.length > 0) {
      return /^[A-Za-z][A-Za-z\s]*$/.test(value);
    }
    return true;
  }),
  lname: yup.string().required("Last name is required").test('letters-only', 'Last name must contain only letters', (value) => {
    if (value && value.length > 0) {
      return /^[A-Za-z][A-Za-z\s]*$/.test(value);
    }
    return true;
  }),
});

interface formData {
  fname: string;
  lname?: string;
  email: string;
}

const EmailItinerary = () => {
  const { dialogState } = useAppSelector((state) => state.dialog);
  const { hotelDetails } = useAppSelector((state) => state.hotelDetails);
  const dispatch = useAppDispatch();
  const { flightRoom } = useAppSelector((state) => state.flightRoom);
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  const [basketId, setBasketId] = useState(getSessionStorage("basketId"));
  const getId = window?.location?.pathname?.split("/")[2]

  const [formErrors, setFormErrors] = useState<any>({});
  const [formData, setFormData] = useState<formData>({ fname: '', email: '', lname: '' });
  const [isEmailSent, setIsEmailSent] = useState<boolean | null | undefined | 'failed'>(false);
  const [isError, setIsError] = useState(false);
  const [validation, setValidation] = useState(false);

  const isPlataniumHotel = Number(hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel) !== 0

  useEffect(() => {
    if (getSessionStorage("basketId")) {
      setBasketId(getSessionStorage("basketId"))
    } else if (window.location.search.includes("?email")) {
      setBasketId(window.location.search.replace("?email=", ""))
    } else {
      setBasketId(null)
    }

  }, [getSessionStorage("basketId"), window.location.search])

  const handleFormChangeData = (evt: InputEvent) => {
    const { value, name } = evt.target;
    setFormData({ ...formData, [name]: value });
    setIsError(true);
  };

  useEffect(() => {
    isError && validation &&
      emailItinerarySchema
        .validate(formData, { abortEarly: false })
        .then(() => {
          setFormErrors({});
        })
        .catch((err: any) => {
          const errors: any = {};
          err.inner.forEach((e: any) => {
            errors[e.path] = e.message;
          });
          setFormErrors(errors);
        });
  }, [formData]);

  const saveBasket = useCallback(async () => {
    let id = await basketId

    let flightObj = {}

    flightObj = {
      ...flightRoom,
      roomData: null
    }


    const body = [
      {
        sessionId: getId,
        agent_id: "1",
        agency_user_id: "396379",
        basket: JSON.stringify({
          branch: 'UK',
          "cart": {
            "flights": {
              [flightRoom?.selectedFlights?.outbound[0]?.FlightPswFlight?.psw_result_id]: JSON.stringify(flightObj)
            },
            "holiday": JSON.stringify(holiday)
          },
          sessionId: getId,
          current_stage: 0,
          last_stage: null,
        }),
        folder_no: -1,
      },
    ];
    try {
      const response = await API.post(API_METHOD.Basket.SAVE_BASKET_TO_DB, body);

      if (response.data.data) {
        setSessionStorage("basketId", response.data.data.id)
        id = await response.data.data.id
      }
    } catch (error) {
    }
    return id
  }, [flightRoom, holiday, basketId])

  const sendEmail = async (id: any) => {
    await API.post(API_METHOD.Email.SEND_EMAIL, {
      firstName: formData.fname,
      lastName: formData.lname || '',
      email: formData.email,
      link: window.location.origin + window.location.pathname + `?email=${id}`,
      "holidayDetailLogId": flightRoom.holidayDetailLogId
    })
      .then(async (res: AxiosResponse) => {
        if (res.data.item1) {
          setIsEmailSent(true);
          setTimeout(() => {
            dispatch(setDialogState({ ...dialogState, emailItinerary: false }));
          }, 2500);
        } else {
          setIsEmailSent('failed');
          setTimeout(() => {
            dispatch(setDialogState({ ...dialogState, emailItinerary: false }));
          }, 2500);
        }
      })
      .catch((err: AxiosError) => {
        setIsEmailSent('failed');
        setTimeout(() => {
          dispatch(setDialogState({ ...dialogState, emailItinerary: false }));
        }, 2500);
        console.log('email send error', err.message)
      });
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setValidation(true)
    emailItinerarySchema
      .validate(formData, { abortEarly: false })
      .then(async () => {
        setFormErrors({});
        setIsEmailSent(null);
        const id = await saveBasket()
        console.log(id)
        await sendEmail(id)
      })
      .catch((err: any) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setFormErrors(errors);
      });
  };

  return (
    <Box>
      <Dialog
        open={dialogState.emailItinerary}
        onClose={() => dispatch(setDialogState({ ...dialogState, emailItinerary: true }))}
        fullWidth
        hideBackdrop={false}
        sx={{
          '& .MuiDialog-paper': {
            border: 'none',
            borderRadius: 2,
          },
        }}
      >
        <Box minHeight={370} height={'100%'} position={'relative'}>
          {isEmailSent === false && (
            <form onSubmit={handleSubmit}>
              <Box display={'flex'} justifyContent={'right'} sx={{
                'svg': {
                  width: "24px",
                  height: "auto",
                },
                '& .MuiButton-root': {
                  p: "0",
                  minWidth: "0"
                }
              }}>
                <Button variant='text' onClick={() => dispatch(setDialogState({ ...dialogState, emailItinerary: false }))}> <CloseModal /> </Button>
              </Box>
              <Typography sx={{ textAlign: 'center', m: '10px 0', fontSize: '26px' }} variant='h1'>
                Email Itinerary
              </Typography>
              <Typography sx={{ textAlign: 'center', m: '10px 0' }} variant='body1'>
                To email this itinerary, please enter your name and email address.
                <br /> We will send you a unique link to this itinerary which will be valid for 14 days.
              </Typography>
              <Box display={'flex'}>
                <Box sx={{
                  mt: '10px', width: '100%', mr: 0.5,
                  '& .Mui-error': {
                    marginLeft: "0px"
                  }
                }}>
                  <TextInput
                    title='FIRST NAME *'
                    // required
                    onChange={handleFormChangeData}
                    name={'fname'}
                    error={formErrors && formErrors['fname'] ? true : false}
                    helperText={formErrors && formErrors['fname'] ? formErrors['fname'] : ''}
                  />
                </Box>

                <Box sx={{
                  mt: '10px ', width: '100%', ml: 0.5, '& .Mui-error': {
                    marginLeft: "0px"
                  }
                }}>
                  <TextInput
                    title='LAST NAME *'
                    // required
                    margin='none'
                    onChange={handleFormChangeData}
                    name={'lname'}
                    error={formErrors && formErrors['lname'] ? true : false}
                    helperText={formErrors && formErrors['lname'] ? formErrors['lname'] : ''}
                  />
                </Box>
              </Box>
              <Box sx={{
                mt: isEmpty(formErrors['fname']) !== "" ? '25px' : '15px ', '& .Mui-error': {
                  marginLeft: "0px"
                }
              }}>
                <TextInput
                  title='EMAIL *'
                  // required
                  onChange={handleFormChangeData}
                  name={'email'}
                  error={formErrors && formErrors['email'] ? true : false}
                  helperText={formErrors && formErrors['email'] ? formErrors['email'] : ''}
                />
              </Box>

              <Button sx={{
                float: 'right', mt: '20px',
                backgroundColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                "&:hover": {
                  backgroundColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                }
              }} type='submit' disabled={isEmailSent !== false}>
                {isEmailSent ? 'Sent' : isEmailSent === false ? 'Send' : 'Sending...'}
              </Button>
            </form>
          )}

          {isEmailSent === null && (
            <Box
              display={'flex'}
              justifyContent={'center'}
              position={'absolute'}
              top={'50%'}
              left={0}
              right={0}
              style={{
                transform: 'translateY(-50%)',
              }}
            >
              <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                <CircularProgress />
                <Typography variant='h1' mt={1}>
                  Sending email to {formData.email}
                </Typography>
              </Box>
            </Box>
          )}

          {isEmailSent === true && (
            <Box
              display={'flex'}
              justifyContent={'center'}
              position={'absolute'}
              top={'50%'}
              left={0}
              right={0}
              style={{
                transform: 'translateY(-50%)',
              }}
            >
              <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                <CheckCircleOutlineIcon sx={{ height: 100, width: 100, color: '#28bf3f' }} />
                <Typography variant='h1' fontSize={28} mt={1}>
                  Email Sent
                </Typography>
              </Box>
            </Box>
          )}

          {isEmailSent === 'failed' && (
            <Box
              display={'flex'}
              justifyContent={'center'}
              position={'absolute'}
              top={'50%'}
              left={0}
              right={0}
              style={{ transform: 'translateY(-50%)' }}
            >
              <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                <SmsFailedIcon sx={{ height: 100, width: 100, color: colors.basics.primary }} />

                <Typography variant='h1' mt={1}>
                  Sending email Failed
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

export default EmailItinerary;
