export const getSiteID = () => {
    switch (process.env.REACT_APP_PROJECT_COUNTRY) {
        case "uk":
            return 1;
        case "ie":
            return 2;
        case "us":
            return 9;
        default:
            return 2;
    }
};