import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as RightChevron } from '../assets/icons/RightChervon.svg';
import { colors } from '../styles/colors';
import { countries, linkBaseUrl } from '../constants/menuItems';
import Image from './Image';
import React, { createContext, useContext } from 'react';

interface MenuProps {
  children: any;
  name?: any;
  isMaxHeightAdded?: boolean
}

let context = createContext(null);

export const Layout = ({ children }: MenuProps) => {
  const { isMaxHeightAdded } = useContext<any>(context);
  return (
    <>
      <Box
        sx={{
          backgroundColor: colors.basics.white,
          borderColor: '#ddd',
          borderLeft: '0',
          padding: '1.25rem',
          height: '100%',
          // minHeight: '600px',
          maxHeight: isMaxHeightAdded ? "600px" : "auto",
          overflow: "auto",
          '& .text-decoration:hover': {
            textDecoration: 'underline'
          },
          '& .right_icon ': {
            height: '10px',
            width: '10px',
          },
          // SubHeading css
          '& .side_ul': {
            padding: '0 0 30px 30px',
          },
          '& .li_title': {
            paddingTop: '20px',
          },
          '& li .bottom_title': {
            color: colors.basics.primary,
          },
          //For SubHeading arrow css
          '& ul li .bottom_title': {
            '& svg': {
              '& path': { fill: 'red' },
            },
            '&:hover': {
              textDecoration: 'underline',
            },
          },
          '& .subdata li': {
            paddingBottom: "25px"
          },
          '& .title li': {
            paddingBottom: "10px"
          }, '& .main_heading>a': {
            '&:hover': {
              textDecoration: 'underline',
              color: colors.basics.primary,
              fontFamily: 'Raleway,sans-serif',
            },

          },
          '& .main_heading a': {
            fontWeight: 700
          },
          '& .heading': { color: colors.basics.primary, fontSize: '20px', fontWeight: 500, mb: 2, mt: 1 },
          '& .heading:hover': {
            textDecoration: "underline"
          },
          '& a': {
            textDecoration: 'none',
          },
          //Right heading css
          '& .heading_right>a': { '&:hover': { textDecoration: 'underline', color: '#303030' } },
          // LiComponent css
          '& ul': { '& li': { padding: '0 0 20px', '& a': { fontSize: '16px', color: '#303030' } } },
          '& ul li a': {
            '&:hover': {
              color: colors.basics.primary,
            },
          },
        }}
      >
        {children}
      </Box>
    </>
  );
};

export const DesignLayout = ({ children }: any) => {
  const { name, link } = useContext<any>(context);
  return (<>
    <Layout>
      <Box >
        <Box>
          <MainHeading text={name} link={link} />
          <Box display='flex' pt={"10px"}   >
            <Box display='flex' width={"100%"} sx={{ '& img': { maxWidth: '140px' } }}>
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout >
  </>
  )
}

const CommonDesign = (arr: any, name: string, link: string, isMaxHeightAdded?: boolean) => {
  return (
    <context.Provider value={{ isMaxHeightAdded, name, link } as any} >
      <DesignLayout>
        <Grid container>
          {arr.map((a: any, aIndex: number) => {
            return <Grid key={aIndex} item lg={12 / arr.length} >
              <ul style={{ width: "100%" }}>
                {a.map((country: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Box className="title">
                        <LiComponent isHeading={true} name={country.name} href={country.link} key={index} />
                      </Box>
                      <Box pb={country.subData.length === 0 ? "30px" : "auto"}>
                        {country.subData.map((c: any, i: number) => {
                          return (
                            <Box className="subdata">
                              <LiComponent name={c.name} href={c.link} key={i} />
                            </Box>
                          )
                        })
                        }
                      </Box>
                    </React.Fragment>
                  )
                })}
              </ul>
            </Grid>
          })}
        </Grid>
      </DesignLayout>
    </context.Provider >
  );
}

export const Alaska = () => {
  return CommonDesign(countries.alaska, "Alaska Destinations", `${linkBaseUrl}alaska-holidays`)
};

export const American = () => {
  return CommonDesign(countries.american, 'American Rockies & Prairies Destinations', `${linkBaseUrl}america-s-rockies-prairies-holidays`)
};

export const BostonAndNewEngland = () => {
  return CommonDesign(countries.bostonAndNewEngland, "Boston & New England Destinations", `${linkBaseUrl}new-england-holidays`)
}

export const California = () => {
  return CommonDesign(countries.california, "California & The West Destinations", `${linkBaseUrl}america-s-west-coast-holidays`, true)
}

export const DeepSouth = () => {
  return CommonDesign(countries.deepSouth, "Deep South Destinations", `${linkBaseUrl}deep-south-holidays`)
}

export const Florida = () => {
  return CommonDesign(countries.florida, "Florida Destinations", `${linkBaseUrl}florida-holidays`)
}

export const Hawai = () => {
  return CommonDesign(countries.hawai, "Hawaii Destinations", `${linkBaseUrl}hawaii-holidays`)
}

export const NewYork = () => {
  return CommonDesign(countries.newYork, "New York & The East Coast Destinations", `${linkBaseUrl}america-s-east-coast-holidays`)
}

const handleClickCanada = () => {
  window.open("https://www.canadiansky.co.uk/", "_blank");
}
export const Canada = () => {
  return (
    <context.Provider value={{
      isMaxHeightAdded: false,
      name: "Canada Destinations",
      link: "https://www.canadiansky.co.uk/"
    } as any} >
      <DesignLayout >
        <Grid container>
          {countries.canada.map((a: any, aIndex: number) => {
            return <Grid
              sx={{
                '& img': {
                  cursor: "pointer"
                },
                '& h3:hover': {
                  cursor: "pointer",
                  textDecoration: "underline"
                }

              }}
              key={aIndex} item lg={3.5} >
              <ul style={{ width: "100%" }}>
                <React.Fragment >
                  <Typography onClick={() => { handleClickCanada() }} mb={1} fontWeight={700} variant='h3'>
                    {a.name}
                  </Typography>
                  <img onClick={() => { handleClickCanada() }} style={{ marginBottom: "15px" }} src={a.img} alt="Canada" />
                  <Typography fontSize={"13px"}>
                    {a.text}
                  </Typography>
                </React.Fragment>
              </ul>
            </Grid>
          })}
        </Grid>
      </DesignLayout>
    </context.Provider>
  )
}

export const MainHeading = (props: any) => {
  const { text, link } = props;
  return (
    <a href={link}>
      <Typography color={colors.destinationHeading.pink} fontWeight={500} variant='subtitle1' className='heading '>
        {text}
      </Typography>
    </a>
  );
};

export const SubHeading = (props: any) => {
  const { text, href } = props;
  return (
    <li className='li_title'>
      <a href={href} target='_blank' rel='noreferrer' className='bottom_title'>
        {text}
        <RightChevron className='right_icon' />
      </a>
    </li>
  );
};

export const LiComponent = (props: any) => {
  const { name, link, href, isHeading } = props;
  return (
    <li>
      {href ? (
        <a className={isHeading ? "text-decoration" : ""} style={{
          fontWeight: isHeading ? 700 : 500
        }} href={href} target='_blank' rel='noreferrer'>
          {name}
        </a>
      ) : (
        <Link to={link}>{name}</Link>
      )}
    </li>
  );
};