import { TextareaAutosize } from '@mui/material';

const TextArea = (props: any) => {
    const { placeholder, value, name, onChange, style, minRow, maxRow } = props

    const defaultCss = {
        resize: "none",
        fontFamily: "Raleway",
        lineHeight: "130%",
        fontSize: "14px",
        fontStyle: "normal",
        background: "transparent"
    }

    let newStyle = style ? {
        ...defaultCss,
        ...style
    } : defaultCss

    return <TextareaAutosize style={newStyle} minRows={minRow} maxRows={maxRow} value={value} name={name || ""} onChange={(e) => onChange(e)} placeholder={placeholder || ""} />;
}

export default TextArea