import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { HotelComponent } from './components/HotelComponent';
import { StepperCard } from '../../components/StepperComponent';
import { CustomContainer } from '../../components/CustomContainer';
import { useDispatch } from 'react-redux';
import { setStepperState } from '../../features/stepper/stepperSlice';

export default function Home() {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setStepperState(0));
  }, [dispatch]);

  return (
    <Box position='relative'>
      <Box mb={{ xs: 1, lg: '35px', sm: '20px' }}    >
      </Box>
      <Box>
        <CustomContainer >
          <StepperCard />
        </CustomContainer>
        <HotelComponent />
      </Box>
    </Box>
  );
}
