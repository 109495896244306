import React, { createContext, useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../styles/colors';
import BackButton from './BackButton';
import MobileDestinations from './MobileDestinations';
import { MainHeading, SubHeading, LiComponent } from '../components/Menus';
import { countries, linkBaseUrl } from '../constants/menuItems';
import Image from './Image';
import useWindowWidth from '../hooks/useWindowWidth';

let context = createContext(null);
interface MenuProps {
  children: any;
}

export const Layout = ({ children }: MenuProps) => {
  const [showResults, setShowResults] = React.useState(false);
  return (
    <>
      {!showResults ? (
        <Box
          p="1.25rem 1.875rem"
          sx={{
            backgroundColor: colors.basics.lightmain,
            '& .right_icon ': {
              height: '10px',
              width: '10px',
            },
            // SubHeading css
            '& .side_ul': {
              pb: '60px',
            },

            '& li .bottom_title': {
              color: colors.basics.primary,
            },
            //For SubHeading arrow css
            '& ul li .bottom_title': {
              '& svg': {
                '& path': { fill: 'red' },
              },
              '&:hover': {
                textDecoration: 'underline',
              },
            },
            '& .main_heading': {
              // padding: '0px 15px',
            },

            '& .main_heading>a': {
              '&:hover': {
                textDecoration: 'underline',
                color: colors.basics.primary,
                fontFamily: 'Raleway,sans-serif',
              },
            },

            '& .heading': {
              color: colors.basics.primary,
              fontSize: '20px',
              fontWeight: 500,
              mb: '10px',
              mt: 1,
              lineHeight: 1.5,
            },
            '& a': {
              textDecoration: 'none',
            },
            //Right heading css
            '& .heading_right>a': { '&:hover': { textDecoration: 'underline', color: '#303030' } },
            // LiComponent css
            '& ul': { '& li': { padding: '0 10px 20px', '& a': { fontSize: '16px', color: '#303030' } } },
            '& ul li a': {
              '&:hover': {
                color: colors.basics.primary,
              },
            },
            '& .li_title': {
              padding: '0px 0px 30px 0px',
            },
            '& .back_button': {
              m: '10px 0px 0px 0px',
              pb: '10px',
              pl: '0px',
            },
          }}
        >
          <Box pl="10px" pt="10px" className="back_button" onClick={() => setShowResults(true)}>
            <BackButton />
          </Box>
          {children}
        </Box>
      ) : (
        <MobileDestinations />
      )}
    </>
  );
};

export const DesignLayout = ({ children, maxWidth }: any) => {
  const { title, arr, link } = useContext<any>(context);
  return (
    <Layout>
      <Box
        sx={{
          '& ul': { listStyle: 'none' },
          '& .text-decoration': {
            padding: 0,
          },
          '& .title ': {
            paddingBottom: '10px',
          },
          '& .title li': {
            padding: '0px',
          },
          '& .subdata li': {
            padding: '10px  !important',
          },
          '& .title li a': {
            color: colors.basics.primary,
            fontWeight: 500 + ' !important',
            fontSize: '1.25rem',
            paddingTop: '10px',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
          '& a': {
            cursor: 'pointer',
          },
          '& .first_ul': {
            padding: '10px 30px 0 0',
          },
          '& .li_title': {
            position: 'relative',
            '&:after': {
              content: '""',
              height: '1px',
              width: '230px',
              position: 'absolute',
              bgcolor: '#939292',
              top: '35px',
              left: '0',
            },
          },
        }}
      >
        <Box maxWidth={maxWidth} className="main_heading">
          <MainHeading text={title} link={link} />
          <Box p="0 0 0">{children}</Box>
        </Box>
      </Box>
    </Layout>
  );
};

const CommonDesign = (arr: any, name: string, link: string) => {
  const windowWidth = useWindowWidth();
  return (
    <context.Provider value={{ title: name, arr, link } as any}>
      <DesignLayout>
        <Grid flexDirection={'column'} container>
          {arr.map((a: any, aIndex: number) => {
            return (
              <Grid key={aIndex} pb={arr.length - 1 === aIndex ? '60px' : ''} item lg={12 / arr.length}>
                <ul style={{ width: '100%' }}>
                  {a.map((country: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <Box className="title">
                          <LiComponent name={country.name} href={country.link} key={index} />
                        </Box>
                        <Box
                          pb={windowWidth > 1200 ? (country.subData.length === 0 ? '30px' : 'auto') : '5px'}
                        >
                          {country.subData.map((c: any, i: number) => {
                            return (
                              <Box className="subdata">
                                <LiComponent name={c.name} href={c.link} key={i} />
                              </Box>
                            );
                          })}
                        </Box>
                      </React.Fragment>
                    );
                  })}
                </ul>
              </Grid>
            );
          })}
        </Grid>
      </DesignLayout>
    </context.Provider>
  );
};

export const Alaska = () => {
  return CommonDesign(countries.alaska, 'Alaska Destinations', `${linkBaseUrl}alaska-holidays`);
};

export const American = () => {
  return CommonDesign(
    countries.american,
    'American Rockies & Prairies Destinations',
    `${linkBaseUrl}america-s-rockies-prairies-holidays`
  );
};

export const BostonAndNewEngland = () => {
  return CommonDesign(
    countries.bostonAndNewEngland,
    'Boston & New England Destinations',
    `${linkBaseUrl}new-england-holidays`
  );
};

export const California = () => {
  return CommonDesign(
    countries.california,
    'California & The West Destinations',
    `${linkBaseUrl}america-s-west-coast-holidays`
  );
};

export const DeepSouth = () => {
  return CommonDesign(countries.deepSouth, 'Deep South Destinations', `${linkBaseUrl}deep-south-holidays`);
};

export const Florida = () => {
  return CommonDesign(countries.florida, 'Florida Destinations', `${linkBaseUrl}florida-holidays`);
};

export const Hawai = () => {
  return CommonDesign(countries.hawai, 'Hawaii Destinations', `${linkBaseUrl}hawaii-holidays`);
};

export const NewYork = () => {
  return CommonDesign(
    countries.newYork,
    'New York & The East Coast Destinations',
    `${linkBaseUrl}america-s-east-coast-holidays`
  );
};

const handleClickCanada = () => {
  window.open('https://www.canadiansky.co.uk/', '_blank');
};

export const Canada = () => {
  return (
    <context.Provider
      value={
        {
          title: 'Canada Destinations',
          arr: countries.canada,
          link: 'https://www.canadiansky.co.uk/',
        } as any
      }
    >
      <DesignLayout maxWidth={'240px'}>
        <Grid flexDirection={'column'} container>
          {countries.canada.map((a: any, aIndex: number) => {
            return (
              <Grid
                sx={{
                  '& img': {
                    cursor: 'pointer',
                  },
                  '& h3:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  },
                }}
                key={aIndex}
                item
                lg={3.5}
              >
                <ul style={{ width: '100%', maxWidth: '245px' }}>
                  <React.Fragment>
                    <Typography
                      borderTop={'1px solid #939292'}
                      onClick={() => {
                        handleClickCanada();
                      }}
                      fontSize={'1.25rem !important'}
                      pt={'20px'}
                      pb={'10px'}
                      fontWeight={400}
                      variant="h3"
                    >
                      {a.name}
                    </Typography>
                    <img
                      onClick={() => {
                        handleClickCanada();
                      }}
                      style={{ marginBottom: '15px', width: '140px' }}
                      src={a.img}
                      alt="Canada"
                    />
                    <Typography fontSize={'13px'}>{a.text}</Typography>
                  </React.Fragment>
                </ul>
              </Grid>
            );
          })}
        </Grid>
      </DesignLayout>
    </context.Provider>
  );
};
