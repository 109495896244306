const Image = (props: any) => {
    const { src, style, alt } = props
    return (
        <img
            src={src}
            style={style}
            alt={alt}
        />
    )
}

export default Image