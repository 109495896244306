import axios from 'axios';
import { onRequest, onRequestError, onResponse, onResponseError } from './clientHelper';

export function setupInterceptorsTo(axiosInstance) {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

const baseURL = process.env.REACT_APP_BASE_URL

const instance = setupInterceptorsTo(
  axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  })
);

export { instance };
