import { Box, Button, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

interface MenuProps {
  onClick?: React.MouseEventHandler;
}

const BackButton = ({ onClick }: MenuProps) => {
  return (
    <Box id="isha" sx={{
      "& p": {
        cursor: "pointer",
        fontSize: "14px !important"
      },
    }} display='flex ' alignItems='center' onClick={onClick}>
      <Box
        flex='0 0 6%'
        sx={{

          '& button': {
            p: 0,
            pl: 1,
            borderRadius: "3px",
            minWidth: '24px',
            height: '24px',
            width: '24px',
            alignItem: 'center',
            justifyContent: 'space-between',
            border: 0,
            '& svg': {
              width: '12px',
            },
          },
        }}
      >
        <Button>
          <ArrowBackIosIcon />
        </Button>
      </Box>

      <Box ml={1} >
        <Typography width={"fit-content"} variant='body2'>Back</Typography>
      </Box>
    </Box >
  );
};

export default BackButton;
