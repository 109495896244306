import { Box, Typography } from '@mui/material';
import { MenuOverlay } from './MenuOverlay';
import { colors } from '../styles/colors';
import { linkBaseUrl } from '../constants/menuItems';
import Loader from './Loader';

export const holidayContent = [{
  title: "Tours",
  data: [
    {
      name: "Escorted Tours",
      link: "escorted-tours"
    }, {
      name: "Fly Drive & Self Drive",
      link: "fly-drive-self-drive"
    }, {
      name: "All Tours",
      link: "tours"
    }
  ]
}, {
  title: "Holidays",
  data: [{
    name: "Adventure Holidays",
    link: "adventure-holidays"
  }, {
    name: "Cruise Holidays",
    link: "cruises"
  }, {
    name: "Family Holidays",
    link: "family-holidays"
  }, {
    name: "Luxury Holidays",
    link: "luxury-holidays"
  }, {
    name: "Motorhome Holidays",
    link: "motorhome-holidays"
  }, {
    name: "Rail Holidays",
    link: "rail-holidays"
  }, {
    name: "Ranching Holidays",
    link: "ranching-holidays"
  }, {
    name: "Twin & Multi Centre Holidays",
    link: "twin-multi-centre-holidays"
  }, {
    name: "Weddings",
    link: "weddings"
  },
  ]
}]
export const HoliDayType = () => {
  return (
    <>
      <MenuOverlay style={{ height: 'auto' }}>
        <Box
          sx={{
            '& ul': { listStyle: 'none' },
            '&>ul': { display: 'flex' },
            '& .heading': { fontSize: '20px' },
            '& a': {
              textDecoration: 'none',
            },
            "& .inner-data li ": {
              padding: "5px 0 !important",
              lineHeight: 1.3
            },
            '& .side_ul': {
              padding: '0 0 30px 30px',
              borderLeft: '1px solid #939292',
            },
            '& .bottom_title': {
              color: colors.basics.primary,
              pt: '15px',
            },
          }}
        >
          <Box>
            <Typography variant='subtitle2' fontWeight={500} className='heading' fontSize='20px' color={colors.basics.primary}>
              Choose a holiday type
            </Typography>
            <Box
              display={"grid"}
              gridTemplateColumns={"repeat(2, 1fr)"}
              p='20px 0 0'
              justifyContent=' space-between'
              sx={{
                '& ul': {
                  width: '100%',
                  '& li ': {
                    width: '100%',
                    maxWidth: '296px',
                    padding: '0 0 1.25rem',
                    '& a': { fontSize: '16px', color: '#303030' },
                  },
                },
                'ul li a': {
                  '&:hover': {
                    color: colors.basics.primary,
                  },
                },
              }}
            >
              {holidayContent.map((h, i) => {
                return (
                  <Box key={i}>
                    <Typography mb={"10px"} variant='h3' fontWeight={700}>
                      {h.title}
                    </Typography>
                    <ul className='first_ul'>
                      {h.data.map((d, dIndex) => {
                        return (
                          <Box className="inner-data">
                            <LiComponent key={dIndex} name={d.name} href={`${linkBaseUrl}${d.link}`} />
                          </Box>
                        )
                      })}

                    </ul>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
      </MenuOverlay>
    </>
  );
};
export const LiComponent = (props: any) => {
  const { name, href, isHeading } = props;
  return (
    <li>
      <a style={{ fontWeight: isHeading ? 800 : 500 }} href={href} target='_blank' rel='noreferrer'>
        {name}
      </a>
    </li>
  );
};

export const MenuLoader = ({ loading }: any) => {
  return <Box
    sx={{
      marginBottom: "0px",
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      // backgroundColor: "red",
      display: 'flex',
      flexFlow: 'column wrap',
      justifyContent: 'space-around',
      alignItems: 'center',
      color: '#fff',
      zIndex: 9999,
    }}
  >
    <svg
      className='loader__icon__svg'
      width='120'
      height='30'
      viewBox='0 0 120 30'
      xmlns='http://www.w3.org/2000/svg'
      fill={colors.basics.primary}
    >
      <circle cx='15' cy='15' r='15'>
        <animate
          attributeName='r'
          from='15'
          to='15'
          begin='0s'
          dur='0.8s'
          values='15;9;15'
          calcMode='linear'
          repeatCount='indefinite'
        ></animate>
        <animate
          attributeName='fillOpacity'
          from='1'
          to='1'
          begin='0s'
          dur='0.8s'
          values='1;.5;1'
          calcMode='linear'
          repeatCount='indefinite'
        ></animate>
      </circle>
      <circle cx='60' cy='15' r='9' fillOpacity='.3'>
        <animate
          attributeName='r'
          from='9'
          to='9'
          begin='0s'
          dur='0.8s'
          values='9;15;9'
          calcMode='linear'
          repeatCount='indefinite'
        ></animate>
        <animate
          attributeName='fillOpacity'
          from='.5'
          to='.5'
          begin='0s'
          dur='0.8s'
          values='.5;1;.5'
          calcMode='linear'
          repeatCount='indefinite'
        ></animate>
      </circle>
      <circle cx='105' cy='15' r='15'>
        <animate
          attributeName='r'
          from='15'
          to='15'
          begin='0s'
          dur='0.8s'
          values='15;9;15'
          calcMode='linear'
          repeatCount='indefinite'
        ></animate>
        <animate
          attributeName='fillOpacity'
          from='1'
          to='1'
          begin='0s'
          dur='0.8s'
          values='1;.5;1'
          calcMode='linear'
          repeatCount='indefinite'
        ></animate>
      </circle>
    </svg>
  </Box>
}
