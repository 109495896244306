import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { colors } from '../styles/colors';
import {
  Alaska,
  American,
  BostonAndNewEngland,
  California,
  Canada,
  DeepSouth,
  Florida,
  Hawai,
  NewYork,
} from './SmallDrawer';
import BackButton from './BackButton';
import LeftDrawer from './LeftDrawer';
import { useDispatch } from 'react-redux';
import { setDestinations } from '../features/destination/destinationSlice';
import { countries } from '../constants/menuItems';

const data = [
  {
    name: 'Alaska',
    component: <Alaska />,
    isName: true,
    index: 1,
    menus: countries.alaska,
  },
  {
    name: 'American Rockies & Prairies',
    component: <American />,
    isName: true,
    index: 2,
    menus: countries.american,
  },
  {
    name: 'Boston & New England',
    component: <BostonAndNewEngland />,
    isName: true,
    index: 3,
    menus: countries.bostonAndNewEngland,
  }, {
    name: 'California & The West',
    component: <California />,
    isName: true,
    index: 4,
    menus: countries.california,
  }, {
    name: 'Deep South',
    component: <DeepSouth />,
    isName: true,
    index: 5,
    menus: countries.deepSouth,
  }, {
    name: 'Florida',
    component: <Florida />,
    isName: true,
    index: 6,
    menus: countries.florida,
  }, {
    name: 'Hawaii',
    component: <Hawai />,
    isName: true,
    index: 7,
    menus: countries.hawai,
  }, {
    name: 'New York & The East Coast',
    component: <NewYork />,
    isName: true,
    index: 8,
    menus: countries.newYork,
  }, {
    name: 'Canada',
    component: <Canada />,
    isName: true,
    index: 9,
    menus: countries.canada,
  }
];

const MobileDestinations = () => {
  const [toggle, setToggle] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(null || 0);
  const [showResults, setShowResults] = React.useState(false);
  const dispatch = useDispatch();

  const handleContinentClick = (item: any) => {
    setCurrentIndex(item.index);
    dispatch(setDestinations(item.menus));
    item.index !== currentIndex ? setToggle(true) : setToggle(!toggle);
  };
  return (
    <>
      <Box >
        {showResults ? (
          <LeftDrawer />
        ) : (
          <Box flex='0 0 25%'>
            <Box p={"0 10px"}>
              <Box width={"fit-content"} pb='20px' pt='20px' onClick={() => setShowResults(true)}>
                <BackButton />
              </Box>
              <Box>
                <Typography variant='h1' fontSize={"20px !important"} fontWeight={400} fontFamily='Raleway,sans-serif' mb='0.625rem'>
                  Choose a destination
                </Typography>
              </Box>
              <Box pl='10px'>
                {data?.map((item: any) => {
                  return (
                    item.isName && (
                      <Box
                        key={item.index}
                        flexGrow={1}
                        justifyContent='center'
                        alignSelf='flex-end'
                        sx={{
                          '& button': {
                            minWidth: 'auto',
                          },
                        }}
                      >
                        <Button
                          sx={{
                            display: 'block',
                            p: '.625rem 0',
                            mr: {
                              // xs: '16px',
                              lg: '20px'
                            },

                            '& h6': {
                              color:
                                item.index === currentIndex && toggle && [1, 2].includes(item.index)
                                  ? colors.basics.primary
                                  : '#303030',
                            },
                          }}
                          disableRipple={true}
                          variant='text'
                          onClick={() => {
                            handleContinentClick(item);
                          }}
                        >
                          <Typography
                            variant='subtitle1'
                            fontWeight={500}
                            lineHeight='130%'
                            color={colors.grey[10]}
                            fontSize='16px !important'
                            sx={{
                              '&:hover': {
                                color: colors.basics.primary,
                              },
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Button>

                        <div hidden={!toggle}>
                          <Box
                            position='absolute'
                            zIndex={1}
                            width='100%'
                            left={0}
                            height='100%'
                            top={0}
                            bgcolor={colors.basics.lightmain}
                            display={item.index === currentIndex ? 'block' : 'none'}
                            sx={{
                              '&>.MuiBox-root': {
                                p: '1.25rem 1.875rem',
                                '&>.MuiBox-root': {
                                  bgcolor: 'transparent',
                                },
                              },
                              listStyle: 'none',
                            }}
                          >
                            {item.index === currentIndex && item.component}
                          </Box>
                        </div>
                      </Box>
                    )
                  );
                })}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default MobileDestinations;
