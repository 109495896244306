import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { ReactComponent as SearchIcon } from '../assets/icons/Search.svg';
import { colors } from '../styles/colors';

interface searchInputProps {
  onChange: (value: any) => void;
  onSearchButtonClick: () => void;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
}

export const SearchInput = ({
  onChange,
  onSearchButtonClick,
  placeholder,
  value,
  defaultValue,
}: searchInputProps) => {
  return (
    <Box display='flex' alignItems='center' sx={{ maxHeight: '48px' }}>
      <Box width='100%'>
        <TextField
          sx={{
            height: 'inherit',
            '& fieldset': {
              borderTopRightRadius: '0px !important',
              borderBottomRightRadius: '0px !important',
              height: 'inherit',
            },
          }}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
        />
      </Box>
      <div>
        <Button
          variant='contained'
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            border: `1px solid ${colors.grey[30]}`,
            borderLeftWidth: 0,
            maxWidth: '46px',
            minWidth: 'auto',
            height: '100%',
            maxHeight: '48px',
            marginBottom: '1px',
          }}
          onClick={onSearchButtonClick}
        >
          <Box
            display='flex'
            sx={{
              '& svg path': {
                fill: colors.basics.white,
              },
            }}
          >
            <SearchIcon />
          </Box>
        </Button>
      </div>
    </Box>
  );
};
