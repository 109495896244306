import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  isClose: false
};

const modelHideSlice = createSlice({
  name: 'isModelClose',
  initialState,
  reducers: {
    setModelHide: (state, { payload }) => {
      state.isClose = payload;
    },
  },
});

export default modelHideSlice.reducer;
export const { setModelHide } = modelHideSlice.actions;
