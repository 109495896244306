import { useCallback, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { Box, FormControl, Grid } from '@mui/material';
import { colors } from '../styles/colors';
import { SelectInput } from './SelectInput';
import CustomSelectInput from './CustomSelectInput';

const RoomAllocation = ({ flightRoom, formData, validation, validateAdultsChildrenInfants, roomErrorsArray, selectedOptions, setSelectedOptions }: any) => {
    const adultData = formData.filter((item: any) => item.pax_type === 'ADT');
    const childData = formData.filter((item: any) => item.pax_type === 'CHD');
    const infantData = formData.filter((item: any) => item.pax_type === 'INF');

    const optionRender = useMemo(() => {
        const adults = adultData.map((item: any) => {
            return {
                id: item.arrayIndex,
                name: (item.fname + " " + item.mname + " " + item.sname).trim()
            }
        }).filter((f: any) => f.name !== "")

        const children = childData.map((item: any) => {
            return {
                id: item.arrayIndex,
                name: (item.fname + " " + item.mname + " " + item.sname).trim()
            }
        }).filter((f: any) => f.name !== "")

        const infant = infantData.map((item: any) => {
            return {
                id: item.arrayIndex,
                name: (item.fname + " " + item.mname + " " + item.sname).trim()
            }
        }).filter((f: any) => f.name !== "")
        return {
            adults,
            children,
            infant
        }
    }, [formData])

    const splitPersonRoomWise = useCallback((roomIndex: number, data: any, perPerson: number) => {
        const groupedPassengers = [];
        for (let i = 0; i < data.length; i += perPerson) {
            const group = data.slice(i, i + perPerson);
            groupedPassengers[roomIndex] = group
        }
        return groupedPassengers[roomIndex] ? groupedPassengers[roomIndex] : []
    }, [])

    const renderAdults = useCallback((num: number, guestIndex: number) => {
        return adultData.length > 0 && splitPersonRoomWise(guestIndex, adultData, num).map((a: any, index: number) => {
            return (
                <>
                    <Box mb={{ xs: "10px", md: "" }} display={"flex"} key={a.id} gap={1} sx={{
                        '& .MuiFormHelperText-root': {
                            m: 0
                        }
                    }}>
                        <Typography variant='subtitle2' sx={{ display: 'flex', flexShrink: 0, marginTop: "3px", }}>
                            ADULT {index + 1} :
                        </Typography>
                        <Box sx={{' & .custom-dropdown':{
                                        mt:"6px"
                                    }, maxWidth: { sm: '60%', xs: '100%' }, width: '100%' }} display={'flex'} gap={1}>
                            <FormControl fullWidth>
                                <CustomSelectInput
                                    ids={`adult${guestIndex}-select${index}`}
                                    idIncluded={true}
                                    data={optionRender.adults}
                                    itemId={Number(selectedOptions[guestIndex]?.adults[index]?.id) || ""}
                                    value={selectedOptions[guestIndex]?.adults[index]?.name || ""}
                                    onChange={(e: any, n: any, v: any) => handleSelect(e, guestIndex, index, "adults", n, v)}
                                    error={roomErrorsArray[guestIndex]?.adults[index]}
                                />
                            </FormControl>
                        </Box>
                    </Box >
                </>
            )
        })
    }, [adultData])
    const renderChildren = useCallback((num: number, guestIndex: number) => {
        return childData.length > 0 && splitPersonRoomWise(guestIndex, childData, num).map((a: any, index: number) => {
            return (
                <Box display={"flex"} key={a.arrayIndex} mb={{ xs: "10px", md: "" }}
                    sx={{
                        justifyContent: { sm: 'flex-end', md: 'inherit' }, '& .MuiFormHelperText-root': {
                            m: 0
                        }
                    }} gap={1}>
                    <Typography variant='subtitle2' sx={{ display: 'flex', flexShrink: 0, marginTop: "3px", }}>
                        CHILD {index + 1} :
                    </Typography>
                    <Box sx={{ maxWidth: { sm: '60%', xs: '100%' }, width: '100%',' & .custom-dropdown':{
                                        mt:"6px"
                                    } }} display={'flex'} gap={1} 
                    >
                        <FormControl fullWidth>
                            <CustomSelectInput
                                data={optionRender.children}
                                idIncluded={true}
                                ids={`child-${guestIndex}-select${index}`}
                                itemId={Number(selectedOptions[guestIndex]?.children[index]?.id) || ""}
                                value={selectedOptions[guestIndex]?.children[index]?.name || ''}
                                onChange={(e: any, n: any, v: any) => handleSelect(e, guestIndex, index, "children", n, v)}
                                error={roomErrorsArray[guestIndex]?.children[index]}
                            />
                        </FormControl>
                    </Box>
                </Box>
            );
        })
    }, [childData])

    const renderInfants = useCallback((num: number, guestIndex: number) => {
        return infantData.length > 0 && splitPersonRoomWise(guestIndex, infantData, num).map((a: any, index: number) => {
            return (
                <Box mb={{ xs: "10px", md: "" }} display={"flex"} key={index} gap={1} sx={{
                    '& .MuiFormHelperText-root': {
                        m: 0
                    }
                }}>
                    <Typography variant='subtitle2' sx={{ display: 'flex', flexShrink: 0, marginTop: "3px", }}>
                        INFANT {index + 1} :
                    </Typography>
                    <Box sx={{' & .custom-dropdown':{
                                        mt:"6px"
                                    },
                        maxWidth: { sm: '60%', xs: '100%' }, justifyContent: { xs: 'flex-end', sm: 'inherit' }, width: '100%'
                    }} display={'flex'} gap={1}>
                        <FormControl fullWidth>
                            <CustomSelectInput
                                ids={`infants-${guestIndex}-select${index}`}
                                data={optionRender.infant}
                                idIncluded={true}
                                itemId={Number(selectedOptions[guestIndex]?.infants[index]?.id) || ""}
                                value={selectedOptions[guestIndex]?.infants[index]?.name || ''}
                                onChange={(e: any, n: any, v: any) => handleSelect(e, guestIndex, index, "infants", n, v)}
                                error={roomErrorsArray[guestIndex]?.infants[index]}
                            />
                        </FormControl>
                    </Box>
                </Box>
            )
        })
    }, [infantData])

    const handleSelect = (event: any, guestIndex: any, arrayIndex: any, type: any, name: any, v: any) => {
        const newArr = [...selectedOptions]
        const value = v.toString()

        newArr.map((a) => {
            const index = a[type].map((m: any) => m.id.toString().indexOf(value));
            index.length > 0 && index.map((s: any, i: number) => {
                if (s.toString() !== "-1") {
                    a[type][i] = {
                        id: "",
                        name: ""
                    }
                }
            })
            return <></>
        })

        const filterData = formData.filter((f: any) => f.arrayIndex.toString() === value)
        newArr[guestIndex][type][arrayIndex] = {
            id: value,
            name: (filterData[0].fname + " " + filterData[0].mname + " " + filterData[0].sname).trim()
        }
        setSelectedOptions(newArr)
        validation && validateAdultsChildrenInfants(newArr)
    }

    return (
        <div>
            {flightRoom.searchCriteria.guest.map((item: any, i: number) => {
                const numberOfAdults = item.adults;
                const numberOfChildren = item.children.length;
                const numberOfInfants = item.infants.length;

                return (
                    <Box>
                        <Typography mt={2} mb={"5px"} variant='h1' fontSize={'21px'}>Room {i + 1}</Typography>
                        <Grid container>
                            <Grid item md={4} xs={12} sm={6} gap={'10px'} >
                                <Box mb={"10px"} >
                                    {renderAdults(numberOfAdults, i)}
                                </Box>
                            </Grid>

                            <Grid ml={"auto"}
                                item md={4} xs={12} sm={6} >
                                {numberOfChildren > 0 && <Box>
                                    {renderChildren(numberOfChildren, i)}
                                </Box>}
                            </Grid>

                            {numberOfInfants > 0 && <Grid item md={4} xs={12} sm={6} >
                                <Box>
                                    {renderInfants(numberOfInfants, i)}
                                </Box>
                            </Grid>}
                        </Grid>
                    </Box>
                );
            })}
        </div >
    );
}

export default RoomAllocation;