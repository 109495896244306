import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { colors } from '../styles/colors';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface IconProps {
  label: JSX.Element | string;
  onClick?: () => void;
  icon?: any;
  bgcolor?: string;
  className?: string;
  IconClassName?: string;
  style?: React.CSSProperties;
  type?: any;
  fullClassName?:string;
}

export const CustomIconButton = ({
  label,
  icon,
  onClick,
  bgcolor,
  className,
  IconClassName,
  style,
  type,
  fullClassName
}: IconProps) => {
  return (
    <Button
    className={fullClassName}
      sx={{
        width: "100% !important",
        height: "100%  !important",
        position: 'relative',
        padding: '0',
        color: colors.basics.white,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '14px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
      fullWidth
      onClick={onClick}
      variant='text'
      disableTouchRipple={true}
      type={type}
    >
      <Box
        borderRadius='5px 0px 0px 5px'
        bgcolor={bgcolor || colors.grey[10]}
        padding='14px'
        // width='75%'
        width='100%'
        className={className}
      >
        {typeof label === 'string' ? (
          <Typography variant='body2' color={colors.basics.white} whiteSpace='nowrap'>
            {label}
          </Typography>
        ) : (
          label
        )}
      </Box>
      <Box
        bgcolor={colors.basics.primary}
        borderRadius='0 5px 5px 0'
        alignSelf='stretch'
        // width='25%'
        className={IconClassName}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='100%'
          minWidth='35px'
          sx={{
            '& svg': {
              width: '15px',
              height: '15px',
              '& path': {
                fill: colors.basics.white,
              },
            },
          }}
        >
          {icon || <ChevronRightIcon />}
        </Box>
      </Box>
    </Button>
  );
};
