import { Box, Button, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import { DepartureInterface, IE_DEPARTURE, US_DEPARTURE, UK_DEPARTURE } from '../constants/departure';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { clearFlightRoomState, setFlightRoomState } from '../features/flightRoom/flightRoomSlice';
import {
  fetchAsyncHolidayPackages,
  setFilteredHolidayPackage,
  setHolidayPackage,
  setHolidayTypes,
  setRegionsTypes,
} from '../features/holidayPackage/holidayPackageSlice';
import AutoComplete from './AutoComplete';
import { CustomeDatePicker } from './CustomeDatePicker';
import { DateObject } from 'react-multi-date-picker';
import { DestinationList } from '../constants/Destinations';
import FlightClassIcon from '@mui/icons-material/FlightClass';
import { ReactComponent as GuestsIcon } from '../assets/icons/Guests.svg';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { clearFlights } from '../features/holidayPackage/changeFlight';
import { colors } from '../styles/colors';
import dayjs from 'dayjs';
import { fetchAsyncDestinations } from '../features/destination/destinationSlice';
import { resetFilters, setFilterState } from '../features/filter/filterSlice';
import { setLoading } from '../features/loading/loadingSlice';
import { useAppSelector } from '../hooks/useAppReducer';
import { useDispatch } from 'react-redux';
import { setFindButtonClick } from '../features/findButtonClicked/findButtonClicked';
import { directFlights, directFlightsIE } from '../constants/directFlights';
import { isEmpty } from '../helpers/isEmpty';
import { capitalizeFirstLetter } from '../helpers/capitalizeFirstLetter';
import { setHolidayDetailsState } from '../features/holidaySummary/holidaySummarySlice';
import { retrieveBasket } from '../features/actions';
import CustomSelectInput from './CustomSelectInput';
import GuestDropdown from './GuestDropdown';
import useWindowWidth from '../hooks/useWindowWidth';
import { getSessionStorage, setSessionStorage } from '../helpers/storage';
import { removeSessionElements } from '../common/removeSessionElements';
import { setLottieLoading } from '../features/lottieLoader/lottieLoader';
import ScrollToTop from '../helpers/ScrollToTop';
import { dateSplit } from '../helpers/dateSplit';
import { API } from '../api/services';
import { API_METHOD } from '../api/services/apiMethods';
import { convertToDDMMYYYY } from '../helpers/convertToDDMMYYYY';
import { useNavigate } from 'react-router-dom';
import {  phoneCodeByRegionUK } from '../constants/phoneCode';
import { setSelectedRegion } from '../features/commonState';
import { phoneCodeByRegionIE } from '../constants/phoneCodeIE';
interface Room {
  room: number;
  adults: number;
  children: { child: number; age: string }[];
  infants: { child: number; age: string }[];
}

interface FindHolidayWidgetProps {
  getPosition: (p: any, b: boolean, f: boolean) => void;
  setIsClickedFilters: any;
  setIsHolidayApiCall: any;
}

interface CABIN_CLASS_ENUM_TYPE {
  Any: string;
  Economy: string;
  Premium: string;
  'Business Class': string;
  'First Class': string;
}

const CABIN_CLASS_ENUM: CABIN_CLASS_ENUM_TYPE = {
  Any: '',
  Economy: 'M',
  Premium: 'W',
  'Business Class': 'C',
  'First Class': 'F',
};

type CabinClass = keyof typeof CABIN_CLASS_ENUM;

interface ISERROR {
  type: 'date' | 'age' | 'destination' | 'dubaiError' | 'infantAge' | 'departure' | 'guest' | 'class';
}
type LocationArrays = {
  [key: string]: string[];
};

function ErrorMessage({ type }: ISERROR) {
  return (
    <Box
      sx={{
        position: 'absolute',
        border: '1px solid #ee8369',
        zIndex: 99,
        backgroundColor: '#ee8369',
        top: '100%',
        left: '0',
        transform: 'translateY(10px)',
        '&:before': {
          content: "''",
          height: '15px',
          width: '15px',
          position: 'absolute',
          backgroundColor: '#ee8369',
          top: '-9px',
          // right: '10px',
          left: '50%',
          borderTop: `#ee8369 solid 1px`,
          borderLeft: `#ee8369 solid 1px`,
          transform: 'rotate(45deg)',
          zIndex: '9',
        },
        overflow: 'visible', // maxWidth: '200px',
      }}
    >
      <Typography
        sx={{
          p: 2,
          color: colors.basics.white,
          fontWeight: 500,
        }}
      >
        {type === 'age' && 'Please select a valid child age'}
        {/* {type === 'maxDays' && 'Please select day after 10 days from today'} */}
        {type === 'date' && 'Minimum range should be 3 nights'}
        {/* {type === 'dubaiError' && 'Minimum range should be 5 nights for Dubai location'} */}
        {type === 'destination' && 'Please Select Destination'}
        {type === 'infantAge' && 'Please select a valid infant age'}
        {type === 'departure' && 'Please select Departure'}
        {type === 'guest' && 'Guest should not be 0'}
        {type === "class" && "Please Select Class"}
      </Typography>
    </Box>
  );
}

export const FindHolidayWidget: FC<FindHolidayWidgetProps> = ({ getPosition, setIsClickedFilters, setIsHolidayApiCall }) => {
  const { flightRoom } = useAppSelector((state) => state.flightRoom);
  const navigate = useNavigate()
  //! Hooks
  const [openModals, setOpenModals] = useState({
    date: false,
    departure: false,
    destination: false,
    class: false,
    room: false
  })
  const [isErrors, setIsErrors] = useState(false)
  const [departure, setDeparture] = React.useState<DepartureInterface[]>();
  const [selectedDestination, setSelectedDestination] = React.useState<DestinationListType>(
    flightRoom.searchCriteria.destination
  );
  const [isError, setIsError] = React.useState<ISERROR | null>();
  const [date, setDate] = useState<any>([]);
  const [cabin_class, set_cabin_class] = useState<CabinClass>('Economy');
  const [room, setRoom] = useState<Room[]>([]);
  const [basketId, setBasketId] = useState<any>(null);
  const [firstTimeModalOpenDone, setFirstTimeModalDone] = useState({
    destination: false,
    date: false,
    room: false,
    class: false
  })
  //! Refs
  //! Variables
  const dispatch = useDispatch();
  const datePickerRef = useRef<any>();
  const windowWidth = useWindowWidth()
  const adults = room?.reduce((a: number, b) => a + b?.adults, 0);
  const childrens = room?.reduce(
    (a: number, b) => a + b?.children?.filter((item: { age: string }) => Number(item?.age) >= 2)?.length || 0,
    0
  );
  const infants = room?.reduce((a: number, b) => a + b?.infants.length, 0);
  const totalChildren = room.flatMap((el: any) => el.children);
  let destinations: string[] = DestinationList?.map(dest => `${dest["Display Name #1"]} ${dest["airport_code"]}`);
  const startDate = dayjs(date[0]);
  const endDate = dayjs(date[1]);
  const hasAgeSelected = totalChildren?.every((el: any) => el.age);
  const totalInfantsChildren = room.flatMap((el: any) => el.infants);
  const hasInfantAgeSelected = totalInfantsChildren?.every((el: any) => el.age);
  const rooms = JSON.parse(getSessionStorage('room') || '{}');
  const depatureKey = getSessionStorage('more-details-click')
  const isRetrieveBasketApiCall = !window.location.search.includes("departure") && window.location.search.includes("?email") && basketId !== null && flightRoom.psw_result_id === null && getSessionStorage("more-details-click") === "false"
  const totalGuests = adults + childrens + infants
  const search = window.location.search

  //! Functions

  useEffect(() => {
    setSessionStorage("stepper", "0")
    let data = process.env.REACT_APP_PROJECT_COUNTRY === "ie" ? [...IE_DEPARTURE] : process.env.REACT_APP_PROJECT_COUNTRY === "us" ? [...US_DEPARTURE] : [...UK_DEPARTURE];
    data = data.map((item: any, index: number) => {
      return {
        ...item,
        selected: depatureKey === 'true' ? flightRoom.searchCriteria.departure.full_name === item.full_name : index === 0 ? true : false,
      };
    });
    setDeparture(data || []);
  }, [depatureKey]);

  useEffect(() => {
    if (Object.keys(rooms).length > 0) {
      const roomArr = [...rooms.room]
      roomArr.map((r: any) => {
        if (r.infants === undefined || r.infants === 0) {
          r.infants = []
        }
        return r
      })
      setRoom(roomArr);
      setDate([new DateObject(new Date(rooms.date[0])), new DateObject(new Date(rooms.date[1]))]);
    } else {
      setRoom([
        {
          room: 1,
          adults: 2,
          children: [],
          infants: []
        },
      ]);

      const currentDate = dayjs();
      const startDate = currentDate.add(1, 'month');
      const endDate = startDate.add(7, 'days');
      const date = [
        new DateObject(dayjs(startDate).format("YYYY/MM/DD")),
        new DateObject(dayjs(endDate).format("YYYY/MM/DD"))
      ];
      setDate(date)
    }
    set_cabin_class(flightRoom.searchCriteria.class as CabinClass)
  }, []);

  useEffect(() => {
    if (totalGuests > 0 && isError?.type === "guest") {
      setIsError(null);
    }
  }, [totalGuests, isError])

  useEffect(() => {
    if (hasAgeSelected) {
      setIsError(null);
    }
  }, [hasAgeSelected]);

  useEffect(() => {
    if (hasInfantAgeSelected) {
      setIsError(null);
    }
  }, [hasInfantAgeSelected])

  useEffect(() => {
    if (!destinations?.length) {
      dispatch(fetchAsyncDestinations([]));
    }
  }, []);

  // useEffect(() => {
  //   if (!isErrors) {
  //     onDateConfirm();
  //   }
  // }, [date, isErrors]);

  useEffect(() => {
    if (isRetrieveBasketApiCall) {
      retrieveDB()
    }
  }, [basketId, window.location.search, getSessionStorage("more-details-click"), flightRoom.psw_result_id])

  useEffect(() => {
    if (((search.includes("departure") || search.includes("destination")))) {
      const urlSearchParams = new URLSearchParams(search);
      const queryData: any = {};
      urlSearchParams.forEach((value, key) => {
        queryData[key] = value;
      });

      if (getSessionStorage("apiCall") !== "true") {
        dispatch(setLottieLoading(true))
      }
      setTimeout(() => {
        renderOnLoadHolidayPackageApi(queryData)
      }, 1)
    }
  }, [])

  useEffect(() => {
    if (getSessionStorage("basketId")) {
      const storedBasketId = getSessionStorage("basketId");
      setBasketId(storedBasketId)
    } else if (window.location.search.includes("?email")) {
      setBasketId(window.location.search.replace("?email=", ""))
    } else {
      setBasketId(null)
    }
  }, [getSessionStorage("basketId"), window.location.search])

  useEffect(() => {
    if ((!search.includes("departure") && !search.includes("destination")) && flightRoom.psw_result_id !== null && getSessionStorage("apiCall") !== "true") {
      dispatch(setLottieLoading(true))
      setSessionStorage("apiCall", "true")
      basketId === null ? initialHolidayPackageSearchApi(flightRoom, "0") : initialHolidayPackageSearchApi(flightRoom, basketId)
    }
  }, [basketId, selectedDestination, flightRoom, getSessionStorage("apiCall")])

  const renderOnLoadHolidayPackageApi = async (queryData: any) => {
    // html page and payment microsite when comes on refresh
    removeSessionElements()
    getPosition(undefined, true, false)
    const adult = queryData?.adult?.split(",")
    const cabinClass: CabinClass = capitalizeFirstLetter(queryData.cabin_class.split("_").join(" ")) as CabinClass
    const child_age = queryData?.child_age && queryData?.child_age?.length > 0 ? JSON.parse(queryData.child_age) : []
    const children = queryData?.children_count?.split(",")
    const startDate = queryData?.daterange?.split(",")[0]
    const endDate = queryData?.daterange?.split(",")[1]
    let destination = queryData?.destination
    const infantAge = (queryData?.infant_age && queryData.infant_age !== 'undefined' && queryData.infant_age.length > 0) ? JSON.parse(queryData.infant_age) : []
    const infantCount = queryData?.infant_count?.split(",")
    const roomCount = queryData?.room_count
    const DestinationFrom = queryData?.departure.toUpperCase()
    const des = queryData?.destinationId || null

    if (des) {
      const destinationData = DestinationList.filter((f) => Number(des) === f["Searc ID"])
      if (destinationData.length > 0) {
        destination = destinationData[0].airport_code + "_;" + destinationData[0]["Searc ID"]
      }
    }
    const destinationCode = destination.split("_").join("").toUpperCase()
    const desParts = destinationCode?.split(";");
    const desCodeName = desParts[0] + " ;" + desParts[1];

    let isDirectFlight: boolean = false

    if (process.env.REACT_APP_PROJECT_COUNTRY === "ie") {
      directFlightsIE.map((d: any) => {
        if (d.dep_code === DestinationFrom && d.des_code === isEmpty(desCodeName)?.toUpperCase()) {
          isDirectFlight = true
          return d
        } else {
          return <></>
        }
      })
    } else if (process.env.REACT_APP_PROJECT_COUNTRY === "uk") {
      directFlights.map((d: any) => {
        if (d.dep_code === DestinationFrom && d.des_code === isEmpty(desCodeName)?.toUpperCase()) {
          isDirectFlight = true
          return d
        } else {
          return <></>
        }
      })
    }

    // Set Rooms 
    let roomArr: any = []
    for (let i = 0; i < Number(roomCount); i++) {
      let childArr: any = []
      let infantArr: any = []

      for (let j = 0; j < Number(children[i]); j++) {
        let child = {
          child: j + 1,
          age: Number(child_age[i][j + 1])
        }

        childArr.push(child)
      }

      for (let j = 0; j < Number(infantCount[i]); j++) {
        let child = {
          child: j + 1,
          age: Number(infantAge[i][j + 1])
        }

        infantArr.push(child)
      }

      let obj = {
        room: i + 1,
        adults: Number(adult[i]),
        children: childArr,
        infants: infantArr
      }
      roomArr.push(obj)
    }
    setRoom(roomArr)
    // Set Cabin Class 
    set_cabin_class(cabinClass)

    // Set Departure 
    const dep = setInitialDeparture(DestinationFrom, true)

    // Set Dates 
    const date = [
      new DateObject(dayjs(reverseString(startDate)).format("YYYY/MM/DD")),
      new DateObject(dayjs(reverseString(endDate)).format("YYYY/MM/DD"))
    ]
    setDate(date);

    // Set Destination
    const selectedDestination = DestinationList.filter((f) => f.airport_code.toUpperCase() + ";" + f["Searc ID"] === destinationCode)
    setSelectedDestination(selectedDestination[0])

    // set individual adults , infants and childrens
    const adults = roomArr?.reduce((a: number, b: any) => a + b?.adults, 0);
    const childrens = roomArr?.reduce(
      (a: number, b: any) => a + b?.children?.filter((item: { age: string }) => Number(item?.age) >= 2)?.length || 0,
      0
    );
    const infants = roomArr?.reduce((a: number, b: any) => a + b?.infants.length, 0);
    let totalGuests = adults + childrens + infants

    const date1: any = new Date(reverseString(startDate));
    const date2: any = new Date(reverseString(endDate));

    const timeDifference = date2 - date1;
    let nights = timeDifference / (1000 * 60 * 60 * 24);
    nights = Math?.floor(nights);
    const totalChildren = roomArr.flatMap((el: any) => el.children);
    const hasAgeSelected = totalChildren?.every((el: any) => el.age);
    const totalInfantsChildren = roomArr.flatMap((el: any) => el.infants);
    const hasInfantAgeSelected = totalInfantsChildren?.every((el: any) => el.age);

    let isError = false
    if (DestinationFrom === "") {
      isError = true
      setIsErrors(true)
      setIsError({ type: 'departure' });
    } else if (selectedDestination.length === 0) {
      isError = true
      setIsErrors(true)
      setIsError({ type: 'destination' });
    } else if (!(nights >= 3)) {
      isError = true
      setIsErrors(false)
      setIsError({ type: 'date' });
    } else if (!hasAgeSelected) {
      setIsErrors(true)
      isError = true
      setIsError({ type: 'age' });
    } else if (!hasInfantAgeSelected) {
      setIsErrors(true)
      isError = true
      setIsError({ type: 'infantAge' });
    } else if (totalGuests === 0) {
      setIsErrors(true)
      setIsError({ type: 'guest' });
      isError = true
    } else if (isEmpty(cabinClass) === "") {
      isError = true
      setIsErrors(true)
      setIsError({ type: 'class' });
    } else if (roomCount === "") {
      isError = true
      setIsErrors(true)
      setIsError({ type: 'guest' });
    } else if (queryData.adult === "") {
      isError = true
      setIsErrors(true)
      setIsError({ type: 'guest' });
    } else {
      setIsErrors(false)
      isError = false
      setIsError(null);
    }

    if (hasAgeSelected && hasInfantAgeSelected && selectedDestination.length > 0 && !isError && getSessionStorage("apiCall") !== "true") {
      setSessionStorage("apiCall", true)
      // dispatch(setLoading(true))
      dispatch(setIsHolidayApiCall(true))
      let body: any = [
        {
          DestinationFrom,
          Destination: selectedDestination.length > 0 ? selectedDestination[0].airport_code?.toUpperCase() + " ;" + selectedDestination[0]['Searc ID'] : "",
          departure_date: startDate,
          rooms: roomCount,
          nights: String(nights),
          cabin_class: CABIN_CLASS_ENUM[cabinClass],
          adults: adult,
          children: children,
          infants: infantCount,
          siteId: process.env.REACT_APP_SITE_ID,
          only_it_fares: true,
          cheapestFareCat: 'IT',
          direct_flight_only: isDirectFlight ? 1 : 0,
          basketId: "0",
          only_pu_fares: false
        },
      ];

      if (childrens !== 0) {
        body[0].child_age = child_age
      }
      if (infants !== 0) {
        body[0].infant_ages = infantAge
      }

      setSessionStorage('search', JSON.stringify(body));
      setSessionStorage('room', JSON.stringify({ room: roomArr, date }));
      if (queryData.UAT !== '0') {
      const { payload } = await dispatch(fetchAsyncHolidayPackages(body));

      const selectedDeparture = dep.filter((f) => f.selected)
      const { depart_airport_name, departure_airport, full_name } = selectedDeparture[0];
      // dispatch(setHolidayPackage(payload));

      if (payload?.Packages) {
        dispatch(
          setFlightRoomState({
            ...flightRoom,
            packageSearchId: payload?.packageSearchId || "",
            flightSearchCriteriaId: payload?.SearchCriteria?.[1].ids.flightSearchCriteriaId,
            hotelSearchCriteriaIds: payload.SearchCriteria?.[1].ids.hotelSearchCriteriaIds,
            hotelSearchCriteriaId_nextDay: payload.SearchCriteria?.[1].ids.hotelSearchCriteriaId_nextDay,
            searchCriteria: {
              child_age: child_age,
              departure: {
                depart_airport_name,
                departure_airport,
                full_name,
                selected: true,
              },
              destination: selectedDestination[0],
              date: [startDate, endDate],
              guest: roomArr,
              class: cabin_class,
              night: nights,
              adults,
              childrens,
              infants,
            },
          })
        );
   
        if (payload?.Packages) {
          if (payload?.Packages?.results.length > 0) {
            await fetchAllHotelsOffers(payload)
          } else {
            dispatch(setLoading(false))
            dispatch(setLottieLoading(false))
            dispatch(setHolidayPackage(payload));
          }
          getPosition(payload, true, false)
        } else {
          dispatch(setHolidayPackage({}));
          dispatch(setLoading(false))
          dispatch(setLottieLoading(false))
          getPosition({}, true, false)
        }
      } else {
        dispatch(setHolidayPackage({}));
        dispatch(setLoading(false))
        getPosition({}, true, false)
        dispatch(setLottieLoading(false))
      }
    } else {
      dispatch(setHolidayPackage({}));
      dispatch(setLoading(false))
      dispatch(setLottieLoading(false))
    }
  }
}

  const handleFind = async () => {
    navigate(window.location.pathname, { replace: true });
    setBasketId(null)
    removeSessionElements()
    setSessionStorage("click", "true")
    setSessionStorage("apiCall", "true")
    setSessionStorage("folderApiCall", "false")

    if (!openModals.date) {
      const numberOfNights = endDate.diff(startDate, 'day');
    ;
      let error = false
      if (!selectedDestination) {
        setIsError({ type: 'destination' });
        error = true
      } else if (!(numberOfNights >= 3)) {
        setIsError({ type: 'date' });
        error = true
      } else if (!hasAgeSelected) {
        setIsError({ type: 'age' });
        error = true
      } else if (!hasInfantAgeSelected) {
        setIsError({ type: 'infantAge' });
        error = true
      } else if (totalGuests === 0) {
        setIsErrors(true)
        setIsError({ type: 'guest' });
        error = true
      } else if (isEmpty(cabin_class) === "") {
        setIsErrors(true)
        setIsError({ type: 'class' });
        error = true
      } else {
        setIsError(null);
        error = false
      }

      if (hasAgeSelected && hasInfantAgeSelected && selectedDestination?.airport_code && !error) {
        dispatch(setIsHolidayApiCall(true))
        dispatch(setLottieLoading(true))
        dispatch(setIsClickedFilters(false))
        getPosition(undefined, true, false)
        dispatch(setHolidayPackage({}));
        dispatch(setFlightRoomState({}))
        dispatch(setFilterState({
          holidayType: [],
          starRating: [],
          hotelName: '',
          price: [],
          sorting: 'Popularity',
          regionType: [],
        }))
        dispatch(setFilteredHolidayPackage({}))
        dispatch(setHolidayTypes([]))
        dispatch(setRegionsTypes([]))
        dispatch(setFindButtonClick(true))

        dispatch(clearFlights());
        dispatch(clearFlightRoomState());
        // dispatch(setLoading(true));
        dispatch(resetFilters());
        // dispatch(clearHolidayPackageState());

        const DestinationFrom = departure?.find((item: any) => item.selected)?.departure_airport;
        const departure_date = dayjs(date[0]).format("DD/MM/YYYY")
        const adultPerRoom: any = []
        const childPerRoom: any = []
        const childAge: any = [];
        const infantAge: any = []
        const infantPerRoom: any = [];

        if (room.length > 0) {
          room?.map((r: any) => {
            adultPerRoom.push(r.adults.toString())
            childPerRoom.push(r.children.length.toString())
            infantPerRoom.push(r.infants.length.toString())
            const childrenAges: any = {};
            const infantsAges: any = {};

            if (r?.children?.length > 0) {
              r?.children.forEach((child: any) => {
                childrenAges[child.child.toString()] = child.age;
              });
              childAge.push(childrenAges);
            } else {
              childAge.push({});
            }

            if (r?.infants?.length > 0) {
              r?.infants.forEach((child: any) => {
                infantsAges[child.child.toString()] = child.age;
              });
              infantAge.push(infantsAges);
            } else {
              infantAge.push({});
            }

            return r
          })
        }

        let isDirectFlight: boolean = false
        const desCode = `${selectedDestination?.airport_code} ;${selectedDestination?.['Searc ID']}`

        if (process.env.REACT_APP_PROJECT_COUNTRY === "ie") {
          directFlightsIE.map((d: any) => {
            if (d.dep_code === DestinationFrom && d.des_code === isEmpty(desCode)?.toUpperCase()) {
              isDirectFlight = true
              return d
            } else {
              return <></>
            }
          })
        } else if (process.env.REACT_APP_PROJECT_COUNTRY === "uk") {
          directFlights.map((d: any) => {
            if (d.dep_code === DestinationFrom && d.des_code === isEmpty(desCode)?.toUpperCase()) {
              isDirectFlight = true
              return d
            } else {
              return <></>
            }
          })
        }

        let body: any = [
          {
            DestinationFrom,
            Destination: `${selectedDestination.airport_code.toUpperCase()} ;${selectedDestination?.['Searc ID']}`,
            departure_date,
            rooms: String(room?.length),
            nights: String(numberOfNights),
            cabin_class: CABIN_CLASS_ENUM[cabin_class],
            adults: adultPerRoom,
            children: childPerRoom,
            infants: infantPerRoom,
            siteId: process.env.REACT_APP_SITE_ID,
            only_it_fares: true,
            cheapestFareCat: 'IT',
            direct_flight_only: isDirectFlight ? 1 : 0,
            basketId: "0",
            only_pu_fares: false,
          },
        ];

        if (childrens !== 0) {
          body[0].child_age = childAge
        }
        if (infants !== 0) {
          body[0].infant_ages = infantAge
        }

        setSessionStorage('search', JSON.stringify(body));
        setSessionStorage('room', JSON.stringify({ room, date }));

        const { payload } = await dispatch(fetchAsyncHolidayPackages(body));
        const dep:
          | {
            depart_airport_name: string;
            departure_airport: string;
            full_name: string;
          }
          | undefined = departure?.find((item) => item.selected);
        if (dep) {
          const { depart_airport_name, departure_airport, full_name } = dep;
          dispatch(
            setFlightRoomState({
              ...flightRoom,
              packageSearchId: payload?.packageSearchId || "",
              flightSearchCriteriaId: payload?.SearchCriteria?.[1].ids.flightSearchCriteriaId,
              hotelSearchCriteriaIds: payload?.SearchCriteria?.[1].ids.hotelSearchCriteriaIds,
              hotelSearchCriteriaId_nextDay: payload?.SearchCriteria?.[1].ids.hotelSearchCriteriaId_nextDay,
              searchCriteria: {
                child_age: childAge,
                infant_age: infantAge,
                departure: {
                  depart_airport_name,
                  departure_airport,
                  full_name,
                  selected: true,
                },
                destination: selectedDestination,
                // date: [dayjs(date[0]).toISOString(), dayjs(date[1]).toISOString()],
                // date: [startDate.toISOString(), endDate.toISOString()],
                date: [dayjs(date[0]).format("DD/MM/YYYY"), dayjs(date[1]).format("DD/MM/YYYY")],
                guest: room,
                class: cabin_class,
                night: numberOfNights,
                adults,
                childrens,
                infants,
              },
            })
          );
        } else {
          console.log('Invalid Departure', dep);
        }

        if (payload?.Packages) {
          if (payload?.Packages?.results.length > 0) {
            await fetchAllHotelsOffers(payload)
          } else {
            dispatch(setHolidayPackage(payload));
            dispatch(setLottieLoading(false))
            dispatch(setLoading(false))
            dispatch(setLoading(false))
          }
          getPosition(payload, true, false)
        } else {
          dispatch(setLottieLoading(false))
          dispatch(setHolidayPackage({}));
          dispatch(setLoading(false))
          getPosition({}, true, false)
        }
        navigate("/holiday-search")
      }
    }
  };

  const handleOnChangeDestination = async (value: string) => {
    const selectedDest: DestinationListType | undefined = DestinationList.find(
      (item) => `${item["Display Name #1"]} ${item["airport_code"]}` === value
    );

    // setFirstTimeModalDone({
    //   ...firstTimeModalOpenDone, destination: true
    // })

    // if (!firstTimeModalOpenDone.date) {
    //   setTimeout(() => {
    //     setOpenModals({
    //       ...openModals,
    //       destination: false,
    //       date: true
    //     })
    //     datePickerRef.current.openCalendar()
    //   }, 50)
    // } else if (!firstTimeModalOpenDone.class) {
    //   setTimeout(() => {
    //     setOpenModals({
    //       ...openModals,
    //       destination: false,
    //       class: true
    //     })
    //   }, 10)
    // } else if (!firstTimeModalOpenDone.room) {
    //   setTimeout(() => {
    //     setOpenModals({
    //       ...openModals,
    //       destination: false,
    //       room: true
    //     })
    //   }, 10)
    // } else {
    //   setTimeout(() => {
    //     setOpenModals({
    //       ...openModals,
    //       destination: false,
    //     })
    //   }, 10)
    // }

    if (selectedDest) {
      setSelectedDestination(selectedDest);
      onDateConfirm();
      dispatch(
        setFlightRoomState({
          ...flightRoom,
          searchCriteria: {
            ...flightRoom.searchCriteria,
            destination: selectedDest,
            adults,
            childrens,
            infants,
          },
        })
      );
      const numberOfNights = endDate.diff(startDate, 'day');
      // const isDubai = selectedDest && selectedDest['Display Name #1'].includes('Dubai');
     if (!(numberOfNights >= 3)) {
        setIsError({ type: 'date' });
      } else {
        setIsError(null);
      }
    }
  };

  const retrieveDB = async () => {
    dispatch(setLottieLoading(true))
    let res = await dispatch(retrieveBasket(basketId));
    res = res.payload;

    let data: any = {}
    if (res?.data?.length > 0) {
      data = res.data[0]
      const flightData: any = JSON.parse(
        Object.values(data.basket.cart.flights)[0] as string
      );
      removeSessionElements()
      const holidayData: any = JSON.parse(data.basket.cart.holiday);
      dispatch(setFlightRoomState(flightData));
      dispatch(setHolidayDetailsState(holidayData));
      // initialHolidayPackageSearchApi(flightData, basketId)
      // dispatch(setLoading(false));
    } else {
      dispatch(setLottieLoading(false))
      dispatch(setLoading(false));
    }
  }

  const reverseString = (str: any) => {
    var splitString = str.split("/");
    var reverseArray = splitString.reverse();
    var joinArray = reverseArray.join("/");
    return joinArray;
  }

  const initialHolidayPackageSearchApi = async (flightRoom: any, basketId: any) => {
    dispatch(setLottieLoading(true))
    const DestinationFrom = flightRoom.searchCriteria.departure.departure_airport
    const destinationCode = flightRoom.searchCriteria.destination.airport_code
    const adultPerRoom: any = []
    const childPerRoom: any = []
    const childAge: any = [];
    const cabinClass: CabinClass = flightRoom.searchCriteria.class
    const room = flightRoom.searchCriteria.guest
    const childrens = room?.reduce(
      (a: number, b: any) => a + b?.children?.filter((item: { age: string }) => Number(item?.age) >= 2)?.length || 0,
      0
    );
    const infants = room?.reduce((a: number, b: any) => a + b?.infants.length, 0);
    const infantAge: any = []
    const infantPerRoom: any = [];

    if (room.length > 0) {
      room?.map((r: any) => {
        adultPerRoom.push(r.adults.toString())
        childPerRoom.push(r.children.length.toString())
        infantPerRoom.push(r.infants.length.toString())
        const childrenAges: any = {};
        const infantsAges: any = {};
        if (r?.children?.length > 0) {
          r?.children.forEach((child: any) => {
            childrenAges[child.child.toString()] = child.age;
          });
          childAge.push(childrenAges);
        } else {
          childAge.push({});
        }
        if (r?.infants?.length > 0) {
          r?.infants.forEach((child: any) => {
            infantsAges[child.child.toString()] = child.age;
          });
          infantAge.push(infantsAges);
        } else {
          infantAge.push({});
        }
        return r
      })
    }

    let isDirectFlight: boolean = false

    if (process.env.REACT_APP_PROJECT_COUNTRY === "ie") {
      directFlightsIE.map((d: any) => {
        if (d.dep_code === DestinationFrom && d.des_code === isEmpty(destinationCode.toUpperCase())) {
          isDirectFlight = true
          return d
        } else {
          return <></>
        }
      })
    } else if (process.env.REACT_APP_PROJECT_COUNTRY === "uk") {
      directFlights.map((d: any) => {
        if (d.dep_code === DestinationFrom && d.des_code === isEmpty(destinationCode.toUpperCase())) {
          isDirectFlight = true
          return d
        } else {
          return <></>
        }
      })
    }

    let body: any = [
      {
        DestinationFrom,
        Destination: `${flightRoom.searchCriteria.destination.airport_code?.toUpperCase()} ;${flightRoom.searchCriteria.destination?.['Searc ID']}`,
        departure_date: flightRoom.searchCriteria.date[0],
        rooms: flightRoom.searchCriteria.guest.length.toString(),
        nights: String(flightRoom.searchCriteria.night),
        cabin_class: CABIN_CLASS_ENUM[cabinClass],
        adults: adultPerRoom,
        children: childPerRoom,
        infants: infantPerRoom,
        siteId: process.env.REACT_APP_SITE_ID,
        only_it_fares: true,
        cheapestFareCat: 'IT',
        direct_flight_only: isDirectFlight ? 1 : 0,
        basketId: basketId,
        only_pu_fares: false
      },
    ];

    if (basketId !== "0") {
      body[0].packageSearchId = flightRoom.packageSearchId
    }

    if (childrens !== 0) {
      body[0].child_age = childAge
    }
    if (infants !== 0) {
      body[0].infant_ages = infantAge
    }
    setInitialDeparture(flightRoom.searchCriteria.departure.full_name)
    setSelectedDestination(flightRoom.searchCriteria.destination)

    setDate([
      new DateObject(dayjs(reverseString(flightRoom.searchCriteria.date[0])).format("YYYY/MM/DD")),
      new DateObject(dayjs(reverseString(flightRoom.searchCriteria.date[1])).format("YYYY/MM/DD"))
    ]);

    const date = [
      new DateObject(dayjs(reverseString(flightRoom.searchCriteria.date[0])).format("YYYY/MM/DD")),
      new DateObject(dayjs(reverseString(flightRoom.searchCriteria.date[1])).format("YYYY/MM/DD"))
    ]
    dispatch(setIsHolidayApiCall(true))
    setSessionStorage('search', JSON.stringify(body));
    setSessionStorage('room', JSON.stringify({ room, date }));
    setRoom(room)
    set_cabin_class(flightRoom.searchCriteria.class)
    setSessionStorage('search', JSON.stringify(body));
    setSessionStorage('room', JSON.stringify({ room, date }));
    // if (Object.keys(holidayPackage).length === 0) {
    // dispatch(setLoading(true));
    const { payload } = await dispatch(fetchAsyncHolidayPackages(body));
    if (payload) {
      if (payload?.Packages) {
        if (payload?.Packages?.results.length > 0) {
          await fetchAllHotelsOffers(payload)
          
        } else {
          dispatch(setHolidayPackage(payload));
          dispatch(setLottieLoading(false))
          dispatch(setLoading(false))
        }
        getPosition(payload, true, false)
      } else {
        dispatch(setHolidayPackage({}));
        dispatch(setLottieLoading(false))
        dispatch(setLoading(false))
        getPosition({}, true, false)
      }
      setFlightRoomState({
        ...flightRoom,
        packageSearchId: payload?.packageSearchId || "",
        flightSearchCriteriaId: payload.SearchCriteria[1].ids.flightSearchCriteriaId,
        hotelSearchCriteriaIds: payload.SearchCriteria[1].ids.hotelSearchCriteriaIds,
        hotelSearchCriteriaId_nextDay: payload.SearchCriteria[1].ids.hotelSearchCriteriaId_nextDay,
      })
      setSessionStorage("isChangeFlightDone", "false")
      dispatch(setLottieLoading(false))
    } else {
      dispatch(setLottieLoading(false))
    }

    // }
  }

  const handleSetDeparture = (e: string) => {
    let d = departure?.map((item) => {
      item.full_name === e ? (item.selected = true) : (item.selected = false);
      return item;
    });

    setDeparture(d);
    if (d && d.length > 0) {
      // Remove the .dropdown-menu.show class
      const dropdownMenu = document.querySelector('.dropdown-menu.show');
      if (dropdownMenu) {
        dropdownMenu.classList.remove('show');
      }}
    // if (selectedDestination === null) {
    //   if (!firstTimeModalOpenDone.destination) {
    //     setFirstTimeModalDone({
    //       ...firstTimeModalOpenDone
    //       , destination: true
    //     })
    //   }

    //   setTimeout(() => {
    //     setOpenModals({
    //       ...openModals,
    //       destination: true,
    //       departure: false
    //     })
    //   }, 100)
    // } else {
    //   setTimeout(() => {
    //     setOpenModals({
    //       ...openModals,
    //       departure: false
    //     })
    //   }, 100)
    // }

  };

  const setInitialDeparture = (e: string, code?: boolean) => {
    let data = process.env.REACT_APP_PROJECT_COUNTRY === "ie" ? [...IE_DEPARTURE] : process.env.REACT_APP_PROJECT_COUNTRY === "us" ? [...US_DEPARTURE] : [...UK_DEPARTURE];
    data = data.map((item: any, index: number) => {
      return {
        ...item,
        selected: index === 0 ? true : false,
      };
    });

    let d = data?.map((item) => {
      code === true ? item.departure_airport === e ? (item.selected = true) : (item.selected = false) : item.full_name === e ? (item.selected = true) : (item.selected = false);
      return item;
    });

    setDeparture(d)
    return d
  }

  const guestRoomSubmit = () => {
    // let obj: any = { ...firstTimeModalOpenDone }
    // obj["room"] = true
    // setFirstTimeModalDone(obj)
    // if (selectedDestination === null) {
    //   setTimeout(() => {
    //     setOpenModals({
    //       ...openModals,
    //       room: false,
    //       destination: true
    //     })
    //   }, 100)
    // } else {
    //   setTimeout(() => {
    //     setOpenModals({
    //       ...openModals,
    //       room: false,
    //     })
    //   }, 100)
    // }

  }

  const onDateConfirm = () => {
    const numberOfNights = endDate.diff(startDate, 'day');
    if (!(numberOfNights >= 3)) {
      setIsError({ type: 'date' });
    } else if (!hasAgeSelected) {
      setIsError({ type: 'age' });
    } else {
      setIsError(null);
    }
  };

  const handleChangeDatePicker = () => {
    // setFirstTimeModalDone({
    //   ...firstTimeModalOpenDone,
    //   date: true,
    // })

    // if (selectedDestination === null) {
    //   setTimeout(() => {
    //     setOpenModals({
    //       ...openModals,
    //       date: false,
    //       destination: true
    //     })
    //   }, 100)
    // } else {
    //   if (!firstTimeModalOpenDone.class) {
    //     setTimeout(() => {
    //       setOpenModals({
    //         ...openModals,
    //         class: true,
    //         date: false
    //       })
    //     }, 100)
    //   } else if (!firstTimeModalOpenDone.room) {
    //     setTimeout(() => {
    //       setOpenModals({
    //         ...openModals,
    //         date: false,
    //         room: true
    //       })
    //     }, 100)
    //   }
    //   else {
    //     setTimeout(() => {
    //       setOpenModals({
    //         ...openModals,
    //         date: false
    //       })
    //     }, 100)
    //   }

    // }
  }

  const handleSelectClass = (e: any) => {
    setIsErrors(false)
    setIsError(null);
    set_cabin_class(e.target.textContent)

    // setFirstTimeModalDone({
    //   ...firstTimeModalOpenDone,
    //   class: true
    // })

    // if (selectedDestination === null) {
    //   setTimeout(() => {
    //     setOpenModals({
    //       ...openModals,
    //       destination: true,
    //       class: false
    //     })
    //   }, 100)
    // } else {
    //   if (!firstTimeModalOpenDone.room) {
    //     setTimeout(() => {
    //       setOpenModals({
    //         ...openModals,
    //         class: false,
    //         room: true
    //       })
    //     }, 100)
    //   } else {
    //     setTimeout(() => {
    //       setOpenModals({
    //         ...openModals,
    //         class: false,
    //       })
    //     }, 100)
    //   }
    // }
  }

  const onOpenDatePicker = () => {
    setTimeout(() => {
      let obj: any = { ...openModals }
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          obj[key] = false;
        }
      }

      if ("date") {
        obj["date"] = true
      }

      // setOpenModals(obj)
    }, 10)
  }

  const onCloseDatePicker = () => {
    // setOpenModals({
    //   ...openModals,
    //   date: false
    // })
  }

  const fetchAllHotelsOffers = async (payload: any) => {
    const holidayPackageCopy = await { ...JSON.parse(JSON.stringify(payload)) };
    const hotelIdArray = await holidayPackageCopy?.Packages?.results.map((item: any, index: number) => Number(item.SearchResult.hotel_id))
    const offerDetailsData = await getOffers(hotelIdArray)
    const promises = await holidayPackageCopy.Packages.results.map(async (item: any, index: number) => {
    
        if (offerDetailsData.length > 0) {
          const offerDetailsHotelWise = await offerDetailsData?.filter((itemm: any) => itemm.hotelId == item.SearchResult.hotel_id)
        item.SearchResult.offerDetails = await offerDetailsHotelWise;
          return  await item;
        } else {
          return await item;
        }
    });

    const response: any = await Promise.all(promises);
    if (await response?.length) {
    await  dispatch(setHolidayPackage(holidayPackageCopy));
      setTimeout(() => {
        dispatch(setLottieLoading(false))
        return <ScrollToTop />
      }, 10)

      return holidayPackageCopy;
    } else {
      dispatch(setLottieLoading(false))
      dispatch(setLoading(false));
    }
  }

  const getOffers = async (hotelId: any) => {
    const body = [{ 'hotel_ids': hotelId }];
    let arr: any = [];
    try {
      const result = await API.post(API_METHOD.Misc.GET_CMS_OFFERS, body);
      const res = result?.data;

      if (Object.keys(res).length > 0) {
        const currentDate = new Date();
       const formattedStartDate= convertToDDMMYYYY(flightRoom.searchCriteria.date[0])
       const formattedEndDate= convertToDDMMYYYY(flightRoom.searchCriteria.date[1])
        const departureStartDate = new Date(dateSplit(formattedStartDate, "/"));
        const departureEndDate = new Date(dateSplit(formattedEndDate, "/"));
        Object.keys(res).forEach((id) => {
          const offers = res[id];
          // Filter with branchCode
          const filterBranchCode = offers?.filter((r: any) => r.key === process.env.REACT_APP_PROJECT_COUNTRY?.toUpperCase());

          // Filter with offerEndDate
          const filterOfferEndDate = filterBranchCode?.filter((offer: any) =>
          new Date(offer.bookFrom) <= currentDate && new Date(offer.bookTo) >= currentDate
          );
          // Filter with the dept start and end date
          const filterInBetweenDepDatesOffer = filterOfferEndDate?.filter((d: any) => {
            const start = new Date(d.validFrom);
            start.setHours(0, 0, 0, 0);
            const end = new Date(d.validTo);
            end.setHours(0, 0, 0, 0);
            departureStartDate.setHours(0, 0, 0, 0);
            departureEndDate.setHours(0, 0, 0, 0);
            return start <= departureStartDate && end >= departureEndDate;
          });
          filterInBetweenDepDatesOffer?.map((item:any) =>{
          arr.push(item)
          })
        })
      }
    } catch (error) {
      console.error('Error fetching offers:', error);
    }

    return await arr;
  };

  const GuestComponent = useMemo(() => {
    return <GuestDropdown
      setRoom={setRoom}
      room={room}
      totalInfantsChildren={totalInfantsChildren}
      totalChildren={totalChildren}
      guestRoomSubmit={guestRoomSubmit}
      selectedDestination={selectedDestination}
      handleFind={handleFind}
    />
  }, [room, totalChildren, selectedDestination])

  let displayText = selectedDestination
    ? `${selectedDestination["Display Name #1"]} ${selectedDestination["airport_code"]}`
    : "";
  displayText = displayText?.slice(0, -3) + displayText?.slice(-3).toUpperCase();

  return (
    <>
      <Box
        display='flex'
        alignItems='flex-end'
        flexWrap={{ xl: 'nowrap', xs: 'wrap' }}
        justifyContent={{ xl: 'space-between', xs: 'none' }}
      >
        <Grid container columnSpacing={1} justifyContent={{ xl: 'space-between', xs: 'none' }}>
          <Grid xl={2.1} lg={3} md={3} xs={6} sm={4} item>
            <Typography variant='body1' fontWeight={500} lineHeight='130%' color={colors.grey[30]} mb='8px'>
              Departure
            </Typography>
            <Box position={'relative'} mt={"10px"} sx={{
              '& .css-r4743b ': {
                mr: "6px"
              },
              '& .custom-dropdown .dropdown-toggle span': {
                maxWidth: "calc(100% - 60px)"
              }
            }}>
              <CustomSelectInput
                // openModals={openModals}
                // setOpenModals={setOpenModals}
                ids={"departure"}
                keys={"departure"}
                // dropdownWidth={"100%"}
                menuStyle={{
                  maxHeight: 300
                }}
                StartIcon={<LocationOnIcon sx={{ color: colors.basics.primary }} />}
                children={departure?.map(
                  ({ depart_airport_name, departure_airport, full_name }: any, index: number) => {
                    let val = departure?.find(({ selected }) => selected)?.full_name || ''
                    return (
                      <MenuItem sx={{
                        my: '2px', background: val === full_name ? colors.basics.pink + "!important" : colors.basics.white,
                        borderRadius: val === full_name ? "5px!important" : "unset"
                      }} key={index} value={full_name} onClick={() => {
                        setIsError(null)
                        setIsErrors(false)
                        handleSetDeparture(full_name)
                      }
                      }>
                        <Typography variant='body2'>
                          {depart_airport_name} <b>{departure_airport}</b>
                        </Typography>
                      </MenuItem>
                    );
                  }
                )}
                value={departure?.find(({ selected }) => selected)?.full_name || ''}
              />
              {isError?.type === 'departure' && <ErrorMessage type={isError.type} />}
            </Box>
          </Grid>

          <Grid xl={2.9} lg={3} md={3} xs={6} sm={4} item>
            <Typography variant='body1' fontWeight={500} lineHeight='130%' color={colors.grey[30]} mb='8px'>
              Destination
            </Typography>
            <Box position={'relative'} sx={{
              '& .css-r4743b ': {
                mr: "6px"
              }
            }}>
              <AutoComplete
                openModals={openModals}
                keys={"destination"}
                setOpenModals={setOpenModals}
                data={destinations}
                StartIcon={<LocationOnIcon sx={{ color: colors.basics.primary }} />}
                value={displayText}
                disableClearable
                onChange={(value) =>{
                const destination = value?.slice(0, value?.length - 4).trim();
                // {
                //   process.env.REACT_APP_PROJECT_COUNTRY === "ie" ? dispatch(setSelectedRegion(phoneCodeByRegionIE?.find((dest: any) => dest.Destination === value.split(',')[0]))) :
                //     dispatch(setSelectedRegion(phoneCodeByRegionUK?.find((dest: any) => dest.Destination === value.split(',')[0])))
                // }
                {
                  process.env.REACT_APP_PROJECT_COUNTRY === "ie" ?  dispatch(setSelectedRegion(phoneCodeByRegionIE.find((dest: any) => dest.Destination === destination))):dispatch(setSelectedRegion(phoneCodeByRegionUK.find((dest: any) => dest.Destination === destination)))}
                  handleOnChangeDestination(value)}} />
              {isError?.type === 'destination' && <ErrorMessage type={isError.type} />}
            </Box>
          </Grid>

          <Grid xl={2.2} lg={3} md={3} xs={12} sm={4} item mt={'3px'}>
            <Typography
              variant='body1'
              fontWeight={500}
              lineHeight='130%'
              color={colors.grey[30]}
              mb='8px'
              mt={{ sm: 0, xs: '10px' }}
            >
              Date
            </Typography>
            <Box position='relative'>
              <CustomeDatePicker
                initialDates={[
                  new DateObject().add(7, 'days'),
                  new DateObject().add(14, 'days'),
                ]}
                isButtonShown={true}
                onCloseDatePicker={onCloseDatePicker}
                onOpenDatePicker={onOpenDatePicker}
                datePickerRef={datePickerRef}
                handleChangeDatePicker={handleChangeDatePicker}
                openModals={openModals}
                setOpenModals={setOpenModals}
                setDate={(date: any) => setDate(date)}
                date={date}
                onDateConfirm={onDateConfirm}
                numberOfMonths={windowWidth > 1023 ? 2 : 1}
                range={true}
                format={'DD MMM YY'}
              />
              {(isError?.type === 'date') && (
                <ErrorMessage type={isError.type} />
              )}
            </Box>
          </Grid>

          <Grid
            xl={1.9}
            lg={3}
            md={3}
            xs={6}
            sm={4}
            item
            sx={{
              position: "relative",
              '&>.MuiInputBase-root': {
                whiteSpace: 'pre',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              },
              '& .custom-dropdown': {
                marginTop: 0
              }
            }}
          >
            <Typography
              variant='body1'
              mb='8px'
              fontWeight={500}
              lineHeight='130%'
              color={colors.grey[30]}
              mt={{ md: "2px", xs: '10px' }}
            >
              Class
            </Typography>
            <CustomSelectInput
              ids={"class"}
              dropdownWidth={"100%"}
              // setOpenModals={setOpenModals}
              keys={"class"}
              // openModals={openModals}
              data={Object.keys(CABIN_CLASS_ENUM)}
              menuOnClick={(e: any) => handleSelectClass(e)}
              // onChange={(e) => handleSelectClass(e)}
              value={cabin_class}
              multiple={false}
              StartIcon={<FlightClassIcon sx={{ color: colors.basics.primary }} />}
              checkbox={false}
            />
            {(isError?.type === 'class') && (
              <ErrorMessage type={isError.type} />
            )}
          </Grid>

          <Grid sx={{
            '& .custom-dropdown': {
              marginTop: 0
            }
          }} xl={1.9} lg={3} md={3} xs={6} sm={4} item >
            <Typography
              variant='body1'
              fontWeight={500}
              lineHeight='130%'
              color={colors.grey[30]}
              mb='8px'
              mt={{ md: "2px", xs: '10px' }}
            >
              Guests
            </Typography>
            <Box sx={{
              position: 'relative',
              '& .MuiPaper-elevation': {
                maxHeight: "500px !important",
                overflow: "auto"
              }

            }}>
              <CustomSelectInput
               className='guest-dropdown'
                menuStyle={{
                  maxHeight: "321px",
                  maxWidth: windowWidth < 576 ? "286px" : "318px",
                }}

                dropdownWidth={windowWidth < 576 ? "286px" : "321px"}
                ids={"rooms"}
                // setOpenModals={setOpenModals}
                keys={"room"}
                // openModals={openModals}
                value={`${totalGuests} ${(totalGuests) > 1 ? "Guests" : "Guest"}  ${room.length}  ${room.length >= 2 ? "Rooms" : "Room"}`}
                StartIcon={
                  <Box height='24px' width='24px' display='flex' justifyContent='center' alignItems='center'>
                    <GuestsIcon />
                  </Box>
                }
              >{GuestComponent}
              </CustomSelectInput>
              {isError?.type === 'age' && <ErrorMessage type={isError.type} />}
              {isError?.type === 'infantAge' && <ErrorMessage type={isError.type} />}
              {isError?.type === 'guest' && <ErrorMessage type={isError.type} />}

            </Box>
          </Grid>
          <Grid xl={1} lg={3} md={3} sx={{ width: { sm: 'auto', xs: '100%' } }} sm={4} item>
            <Button
              className='btn-olb-search-dest'
              sx={{
                height: '49px',
                mb: '2px',
                mt: { md: '30px', sm: "37px", xs: '40px' },
                width: { xl: '100%', sm: "auto", xs: '100%' },
              }}
              onClick={() => isError === null && handleFind()}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box >
    </>
  );
};

export default FindHolidayWidget;
