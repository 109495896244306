import React from 'react';
import { Box } from '@mui/material';
import { colors } from '../styles/colors';

interface OverlayProps {
  children: any;
  style?: React.CSSProperties;
}

export const MenuOverlay = ({ children, style }: OverlayProps) => {
  return (
    <>
      <Box
        border={`1.25rem solid ${colors.basics.white}`}
        bgcolor={colors.basics.lightmain}
        pl='120px'
        pt='1.25rem'
        pb='1.25rem'
        pr='1.25rem'
        height='100%'
        maxHeight='721px'
        width='100%'
        maxWidth='1200px'
        sx={{ ...style }}
      >
        {children}
      </Box>
    </>
  );
};
