import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { DrawerRoot } from './Drawer';
import { colors } from '../styles/colors';
import LeftDrawerBottom from './LeftDrawerBottom';
import MobileDestinations from './MobileDestinations';
import MobileHolidayTypes from './MobileHolidayTypes';
import { useSelector } from 'react-redux';

export const LeftDrawer = () => {
  //! States
  const drawerState = useSelector((state: any) => state.drawer.drawerState);

  //! Hooks
  const [currentIndex, setCurrentIndex] = React.useState<number>(null || 0);
  const [toggle, setToggle] = useState(false);

  //! Functions
  useEffect(() => {
    drawerState.left && setCurrentIndex(0);
  }, [drawerState]);

  const data = [
    {
      name: 'Holiday Types',
      component: <MobileHolidayTypes />,
      index: 1,
    },
    {
      name: 'Destinations',
      component: <MobileDestinations />,
      index: 2,
    },

  ];

  return (
    <Box>
      <DrawerRoot direction='left'>
        <Box height='100%'>
          <Box bgcolor={colors.basics.lightmain} p={"20px 40px"}>
            {data.map((item) => {
              return (
                <Box key={item.index} flexGrow={1} mb='5px' justifyContent='center' alignSelf='flex-end'>
                  <Button
                    sx={{
                      display: 'block',
                      p: '.625rem 0',
                      mr: { xs: '16px', lg: '20px' },
                      '& h6': {
                        color:
                          item.index === currentIndex && toggle && [1, 2].includes(item.index)
                            ? colors.basics.primary
                            : '#303030',
                      },
                    }}
                    disableRipple={true}
                    variant='text'
                    onClick={() => {
                      setCurrentIndex(item.index);
                      item.index !== currentIndex ? setToggle(true) : setToggle(!toggle);
                    }}
                  >
                    <Typography
                      variant='subtitle1'
                      fontWeight={500}
                      lineHeight='130%'
                      color={colors.grey[10]}
                      fontSize='16px !important'
                      sx={{
                        '&:hover': {
                          color: colors.basics.primary,
                        },
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Button>

                  <div hidden={!toggle}>
                    <Box
                      position='absolute'
                      zIndex={1}
                      width='100%'
                      left={0}
                      height='100%'
                      top='-20px'
                      bgcolor={colors.basics.lightmain}
                      display={item.index === currentIndex ? 'block' : 'none'}
                      sx={{
                        '&>.MuiBox-root': {
                          padding: currentIndex === 2 ? '20px' : null,
                          '&>.MuiBox-root': {
                            bgcolor: 'transparent',
                          },
                        },
                        listStyle: 'none',
                      }}
                    >
                      {item.index === currentIndex && item.component}
                    </Box>
                  </div>
                </Box>
              );
            })}
            <Box flexGrow={1} mb='5px' justifyContent='center' alignSelf='flex-end'>
              <Button
                sx={{ display: 'block', p: '.625rem 0', mr: { xs: '16px', lg: '20px' } }}
                disableRipple={true}
                variant='text'
              >
                <Typography
                  variant='subtitle1'
                  fontWeight={500}
                  lineHeight='130%'
                  color={colors.grey[10]}
                  fontSize='16px !important'
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_REDIRECT_URL}deals`
                  }}
                  sx={{
                    '&:hover': {
                      color: colors.basics.primary,
                    },
                  }}
                >
                  Offers & Deals
                </Typography>
              </Button>
            </Box>
            {/* <Box flexGrow={1} mb='5px' justifyContent='center' alignSelf='flex-end'>
              <Button
                sx={{ display: 'block', p: '.625rem 0', mr: { xs: '16px', lg: '20px' } }}
                disableRipple={true}
                variant='text'
              >
                <Typography
                  variant='subtitle1'
                  fontWeight={500}
                  lineHeight='130%'
                  color={colors.grey[10]}
                  fontSize='16px'
                  sx={{
                  '&:hover': {
                      color: colors.basics.primary,
                    },
                  }}
                >
                  Shortlist (0)
                </Typography>
              </Button>
            </Box> */}
            <Box flexGrow={1} mb='5px' justifyContent='center' alignSelf='flex-end'>
              <Button
                sx={{
                  display: 'block',
                  p: '.625rem 0',
                  mr: { xs: '16px', lg: '20px' },
                }}
                disableRipple={true}
                variant='text'
              >
                <Typography
                  variant='subtitle1'
                  fontWeight={500}
                  lineHeight='130%'
                  color={colors.grey[10]}
                  fontSize='16px !important'
                  sx={{
                    '&:hover': {
                      color: colors.basics.primary,
                    },
                  }}
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_REDIRECT_URL}travel-inspiration`
                  }}
                >
                  Inspiration
                </Typography>
              </Button>
            </Box>
          </Box>

          <Box>
            <LeftDrawerBottom />
          </Box>
        </Box>
      </DrawerRoot>
    </Box>
  );
};

export default LeftDrawer;
