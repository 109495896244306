import { createSlice } from "@reduxjs/toolkit";

interface state {
    sliderContent: string[] | null
}

const initialState: state = {
    sliderContent: null
};

const sliderContentSlicer = createSlice({
    name: 'slider',
    initialState,
    reducers: {
        setSliderContent: (state, { payload }): void => {
            state.sliderContent = payload;
        },

    }
});

export const { setSliderContent } = sliderContentSlicer.actions;
export default sliderContentSlicer.reducer;