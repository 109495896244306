import './App.css';
import { AuthContextProvider } from './context/AuthContext';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Dialog from './dialog/dialog';
import Footer from './components/Footer';
import Loader from './components/Loader';
import Navbar from './components/Navbar';
import ScrollToTop from './helpers/ScrollToTop';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './styles/theme';
import { browserName, browserVersion } from "react-device-detect"
import { useEffect, useLayoutEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { routes } from './routes/routes';
import { clearSessionValues } from './helpers/storage';
import LottieAnimation from './components/Lottie';
import { useAppSelector } from './hooks/useAppReducer';
import { useDispatch } from 'react-redux';
import { setUserDetails } from './features/userAuthentication/userInformation';

const App = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const { lottieLoading, lottieLoadingConfirm } = useAppSelector((state) => state.lottieLoader)
  const userDataString = localStorage.getItem('userData');
  const userInfo: any = userDataString ? JSON.parse(userDataString) : {};
  
  useLayoutEffect(() => {
    dispatch(setUserDetails(userInfo))
    // Check if the browser is Safari and the version is less than 16.6.1
    if (((browserName === 'Safari' || browserName === 'Mobile Safari') && browserVersion < '16')) {
      alert(`Your safari version is ${browserVersion} Please upgrade your Safari browser to version 16 or higher for the best experience.`);
      setOpen(true)
    }
    clearSessionValues()
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AuthContextProvider>
          <Navbar />
          {((browserName === 'Safari' || browserName === 'Mobile Safari') && browserVersion < '16') ?
            <Snackbar open={open} autoHideDuration={6000} >
              <Alert variant='filled' severity='error'>
                {`Your safari version is ${browserVersion} Please upgrade your Safari browser to version 16 or higher for the best experience.`}
              </Alert>
            </Snackbar>
            : <>
              < ScrollToTop />
              <Dialog />
              <Routes>
                {routes.map((r, i) => {
                  return (
                    <Route path={r.path} key={i} element={r.element} />
                  )
                })}
              </Routes>
            </>
          }
          <Loader />
          {lottieLoading ? <LottieAnimation /> : lottieLoadingConfirm ? <LottieAnimation textChange={true} /> : ""}
          <Footer />
        </AuthContextProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
