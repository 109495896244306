import { Autocomplete, TextField, Box } from '@mui/material';
import React, { useState, ChangeEvent } from 'react';
import { makeStyles } from '@mui/styles';
import { colors } from '../styles/colors';

const useStyles = makeStyles({
  paper: {
    boxShadow: 'none !important',
    background: colors.basics.white,
    border: `1px solid ${colors.grey[30]}`,
    borderRadius: '5px',
    paddingTop: '22px',
    paddingBottom: '22px',
    paddingLeft: '8px',
    paddingRight: '8px',
    maxHeight: '347px',
    '& ul': {
      maxHeight: 'none',
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& li': {
      paddingRight: '8px !important',
      paddingLeft: '8px !important',
      borderRadius: 5,
      fontFamily: 'Raleway',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      color: colors.basics.black,
      '&:hover': {
        background: colors.basics.primary + '20 !important',
      },
    },
  },
});
interface openModals {
  date: boolean,
  departure: boolean,
  destination: boolean,
  class: boolean,
  room: boolean,
}

interface autoCompleteProps {
  data: string[];
  value: string[] | string | any;
  StartIcon?: JSX.Element;
  disableClearable?: boolean;
  onChange: (e: string) => void;
  style?: React.CSSProperties;
  keys?: keyof openModals | undefined;
  openModals?: openModals | undefined;
  setOpenModals?: (open: openModals) => void;
}

export default function AutoComplete(props: autoCompleteProps) {
  const { data, value = '', StartIcon, disableClearable, onChange, style, openModals, setOpenModals, keys } = props;
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = useState('')
  const [timer, setTimer] = useState<string | number | NodeJS.Timeout | null>(null)

  //! Variables
  const classes = useStyles();

  const onOpen = () => {
    if (keys && openModals) {
      setOpenModals?.({
        ...openModals,
        [keys]: true
      })
    }

    setOpen(true)
    if (openModals === undefined || !openModals?.date) {
      let obj = { ...openModals } as openModals
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          obj[key as keyof openModals] = false;
        }
      }

      if (keys) {
        obj[keys] = true
      }

      setOpenModals?.(obj)
      const inputElement: Element | null = document.querySelector('.MuiAutocomplete-input');
      if (inputElement instanceof HTMLInputElement) {
        inputElement.addEventListener('click', () => {
          inputElement.select();
        })
      }
    }
  }

  const onClose = () => {
    if (keys && openModals) {
      setOpenModals?.({
        ...openModals,
        [keys]: false
      })
    }
    setOpen(false)
  }

  const onInputChange = (newInputValue: string) => {
    timer !== null && clearTimeout(timer)
    setInputValue(newInputValue)
    if (newInputValue === "") {
      const newTimer = setTimeout(() => {
        setInputValue(value)
      }, 1500)
      setTimer(newTimer)
    }
  }

  return (
    <Autocomplete
      freeSolo
      value={value}
      open={keys && openModals !== undefined ? openModals[keys] : open}
      onOpen={() => { onOpen() }}
      onClose={() => { onClose() }}
      disableClearable={disableClearable}
      options={data?.map((option: {}) => option)}
      // loading={loading}
      // renderOption={(props, option) => (
      //   <Box component='li' {...props}>
      //     {option}
      //   </Box>
      // )}
      renderOption={(props, option) => {
        // Convert option to string if it's not already (assuming options are strings)
        const optionText = String(option);
        // Get the last 3 characters
        const lastThreeCharacters = optionText.slice(-3);
        // Get the rest of the string
        const restOfString = optionText.slice(0, -3);
        return (
          <Box component='li' {...props}>
            <span style={{ marginRight: "2px" }}>{restOfString}</span>
            <span style={{ fontWeight: "700" }}>{`${lastThreeCharacters.toLocaleUpperCase()}`}</span>
          </Box >
        );
      }}
      classes={{ paper: classes.paper }}
      onChange={(event, newValue) => {
        onChange(newValue)
        setInputValue(newValue)
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => { onInputChange(newInputValue) }}
      sx={{
        pointerEvents: openModals?.date ? "none" : "",
        width: '100%',
        background: colors.basics.white,
        '& .MuiOutlinedInput-input': {
          fontSize: '14px',
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
          textOverflow: "ellipsis"
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: keys && openModals !== undefined && openModals[keys] === true ? colors.basics.black : colors.grey[30],
          },
          "&.Mui-focused fieldset": {
            borderColor: keys && openModals !== undefined && openModals[keys] === true ? colors.basics.black : colors.grey[30],
          },
        },
        ...style,
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            type='text'
            InputProps={{
              ...params.InputProps,
              startAdornment: StartIcon ? (
                <Box display='flex' sx={{ marginRight: { sm: '6px', xs: '5px' } }}>
                  {StartIcon}
                </Box>
              ) : null,
              // endAdornment: loading ? <CircularProgress color='inherit' size={20} /> : null,
            }}
          />
        );
      }}
    />
  );
}
