import { configureStore } from "@reduxjs/toolkit";
import drawerReducer from "./drawer/drawerSlice";
import stepperReducer from "./stepper/stepperSlice";
import dialogReducer from "./dialog/dialogSlice";
import countryReducer from "./country/country";
import filterReducer from "./filter/filterSlice";
import destinationReducer from "./destination/destinationSlice";
import loadingReducer from "./loading/loadingSlice";
import holidayPackageReducer from "./holidayPackage/holidayPackageSlice";
import packageDetailsReducer from "./holidayPackage/packageDetail";
import changeFlightReducer from "./holidayPackage/changeFlight";
import mapSlice from "./map/mapSlice";
import flightRoomReducer from "./flightRoom/flightRoomSlice";
import hotelDetailsReducer from "./holidayPackage/hotelDetails";
import folderNumberReducer from "./profileDetails/createFolderSlice";
import holidaySummaryReducer from "./holidaySummary/holidaySummarySlice";
import passengerDetailsReducer from "./userAuthentication/userRegisterSlice";
import userInformationReducer from "./userAuthentication/userInformation";
import summarySessionReducer from "./session/sessionSlice";
import getFolderDetailsReducer from "./profileDetails/getFolderSlice";
import onModalClose from "./onModelClose/onModalClose";
import findClickReducer from "./findButtonClicked/findButtonClicked";
import lottieLoader from "./lottieLoader/lottieLoader";
import depositeConfiguration from "./paymentConfiguration/paymentConfiguration"
import sliderContent from "./sliderApi/sliderApi"
import staticSiteAccordianData from "./staticSiteAccordianData/staticSiteAccordianData"
import commonState from "./commonState";
import holidayDetailsState from "./holidayDetailsState";

export const store = configureStore({
  reducer: {
    onModalClose: onModalClose,
    drawer: drawerReducer,
    stepper: stepperReducer,
    dialog: dialogReducer,
    currentCountry: countryReducer,
    filters: filterReducer,
    destinations: destinationReducer,
    loading: loadingReducer,
    holidayPackage: holidayPackageReducer,
    packageDetails: packageDetailsReducer,
    changeFlight: changeFlightReducer,
    map: mapSlice,
    flightRoom: flightRoomReducer,
    hotelDetails: hotelDetailsReducer,
    folderNumber: folderNumberReducer,
    holidaySummary: holidaySummaryReducer,
    passenger: passengerDetailsReducer,
    userInformation: userInformationReducer,
    summarySession: summarySessionReducer,
    folderDetails: getFolderDetailsReducer,
    findButtonClicked: findClickReducer,
    lottieLoader: lottieLoader,
    depositeConfiguration: depositeConfiguration,
    sliderContent: sliderContent,
    staticSiteAccordianData: staticSiteAccordianData,
    commonState: commonState,
    holidayDetailsState: holidayDetailsState
  },
});

export type ApplicationStateType = ReturnType<typeof store.getState>;
export type ApplicationDispatch = typeof store.dispatch;
export default store;
