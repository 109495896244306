export const tabs: string[] = ['Hotels', 'All Inclusive'];

export type Tab = {
  title: string;
  subTitle: string;
};

export const tabBound: Tab[] = [
  { title: 'Outbound', subTitle: 'Jul 10' },
  { title: 'Inbound', subTitle: 'Jul 17' },
];

// export const Dashboardtabs: string[] = ['Dashboard', 'Travel Documents', 'My Account', 'Payment'];
export const Dashboardtabs: string[] = ['Dashboard', 'My Account', 'Payment'];
export const GuestAccountDetailsTabs: string[] = ['Guest', 'Create Account / Login'];
