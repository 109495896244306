import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { API } from "../../api/services";
import { API_METHOD } from "../../api/services/apiMethods";

export const fetchAsyncHolidayPackages: any = createAsyncThunk(
  "holiday_package/fetchAsyncHolidayPackages",
  async (term) => {
    const response = await API.post(
      API_METHOD.Package.HOLIDAY_PACKAGE_SEARCH,
      term
    );
    return response.data;
  }
);

interface Package {
  holidayPackage: Record<string, any>;
  filteredHolidayPackage: Record<string, any>;
  holidayTypes: string[];
  regionTypes: string[];
  status: string;
  filterCounts?: any,
  regionCounts?: any
}

const initialState: Package = {
  holidayPackage: {},
  filteredHolidayPackage: {},
  holidayTypes: [],
  regionTypes: [],
  status: "",
  filterCounts: {},
  regionCounts: {}
};

const holidayPackageSlice = createSlice({
  name: "holiday_package",
  initialState,
  reducers: {
    setFilteredHolidayPackage: (state, { payload }): void => {
      state.filteredHolidayPackage = payload;
      state.status = "SUCCESS";
    },
    setHolidayPackage: (state, { payload }): void => {
      state.holidayPackage = payload;
      state.filteredHolidayPackage = payload;
      state.status = "SUCCESS";
    },
    setHolidayTypes: (state, { payload }): void => {
      state.holidayTypes = payload;
    },
    setRegionsTypes: (state, { payload }): void => {
      state.regionTypes = payload;
    },
    clearHolidayPackageState: (state): void => {
      state.filteredHolidayPackage = {};
      state.holidayPackage = {};
      state.holidayTypes = [];
      state.status = "";
    },
    setFilterCounts: (state, { payload }): void => {
      state.filterCounts = payload
    },
    setRegionCounts: (state, { payload }): void => {
      state.regionCounts = payload
    },
  },
  extraReducers: {
    [fetchAsyncHolidayPackages.pending]: (state): void => {
      state.status = "PENDING";
    },
    [fetchAsyncHolidayPackages.fulfilled]: (state, { payload }): void => {
      state.filteredHolidayPackage = payload;
      // if (payload?.Packages?.results?.length) {
      //   // payload?.Packages?.results?.forEach((item: any) => {
      //   //   let res = item?.SearchResult.Rooms[1].sort(
      //   //     (a: any, b: any) => parseFloat(a?.total_package) - parseFloat(b?.total_package)
      //   //   );
      //   //   item.SearchResult.Rooms[1] = res;
      //   // });
      //   // payload?.Packages?.results?.sort((a: any, b: any) => {
      //   //   return (
      //   //     parseFloat(a?.SearchResult?.Rooms['1'][0]?.total_package) -
      //   //     parseFloat(b?.SearchResult?.Rooms['1'][0]?.total_package)
      //   //   );
      //   // });
      //   // // payload.Packages.results = payload?.Packages?.results?.filter(
      //   // //   (item: any) =>
      //   // //     Object.keys(item?.HotelInfo).includes('onlineBookable') && item?.HotelInfo?.onlineBookable !== '0'
      //   // // );
      //   // payload.Packages.results = payload?.Packages?.results.sort(
      //   //   (a: any, b: any) => parseInt(a.HotelInfo.preferredOnSites) - parseInt(b.HotelInfo.preferredOnSites)
      //   // );
      //   state.holidayPackage = payload;
      //   // state.filteredHolidayPackage = payload;
      // } else {
      //   state.holidayPackage = {};
      //   state.filteredHolidayPackage = {};
      // }
      state.status = 'SUCCESS';
    },
    [fetchAsyncHolidayPackages.rejected]: (state) => {
      // state.holidayPackage = {};
      // state.filteredHolidayPackage = {};
      state.status = "REJECTED";
    },
  },
});

export const {
  setFilteredHolidayPackage,
  setHolidayTypes,
  setHolidayPackage,
  setFilterCounts,
  setRegionCounts,
  setRegionsTypes,
  clearHolidayPackageState,
} = holidayPackageSlice.actions;

export default holidayPackageSlice.reducer;
