import { Box, Divider, Typography } from '@mui/material';
import { colors } from '../styles/colors';
import { CustomIconButton as IconButton } from './CustomIconButton';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import { setDialogState } from '../features/dialog/dialogSlice';
import { useAppSelector } from '../hooks/useAppReducer';
import { useDispatch } from 'react-redux';

const LeftDrawerBottom = () => {
  const dialogState = useAppSelector((state: any) => state.dialog.dialogState);
  const dispatch = useDispatch();

  return (
    <Box p='1.25rem 1.875rem' lineHeight='2'>
      <Box>
        <Typography fontSize={"16px !important"} variant='body1' pb='15px'>
          Or talk to one of our friendly team
        </Typography>
        <Typography sx={{
          '& span:hover': {
            textDecoration: "underline"
          }
        }} variant='body1' fontWeight={600} fontFamily={"El Messiri"} mb={"6px"} fontSize='24px !important'>
          <a href={`tel:${process.env.REACT_APP_CONTACT_US_NO}`} rel='nofollow' style={{ textDecoration: 'none' }}>
            <span style={{ color: colors.basics.primary }}>{process.env.REACT_APP_CONTACT_US_NO}</span>
          </a>
        </Typography>
        <Box pb='15px'>
          <Typography fontSize={"16px !important"} display='inline' variant='subtitle1'>
            Open{' '}
            <Typography fontSize={"16px !important"} display='inline' variant='body1'>
              9am-7pm Mon-Fri / 9am-5pm Sat-Sun
            </Typography>
          </Typography>
        </Box>
        <IconButton
          label={
            <Box display='flex' alignItems='center'>
              <EmailIcon sx={{ width: '18px', fill: colors.basics.white }} />
              <Typography variant='body2' fontSize={"14px !important"} color={colors.basics.white} fontWeight={600} ml='0.625rem'>
                Email enquiry
              </Typography>
            </Box>
          }
          onClick={() => dispatch(setDialogState({ ...dialogState, emailEnquiryDialog: true }))}
          bgcolor={colors.basics.primary}
        />
      </Box>
      <Divider sx={{ borderColor: '#393939', mb: '15px', mt: '20px' }} />
      <Box>
        <Box pb='15px'>
          <Typography fontSize={"16px !important"} display='inline' variant='subtitle1'>
            Stay Updated.{' '}
            <Typography fontSize={"16px !important"} display='inline' variant='body1'>
              Be the first to hear about exclusive offers and holiday inspiration by email.
            </Typography>
          </Typography>
        </Box>
        <Box sx={{ pb: '15px' }}>
          <IconButton
            label={
              <Box display='flex' alignItems='center'>
                <EditIcon sx={{ width: '18px', fill: colors.basics.white }} />
                <Typography
                  variant='body2'
                  fontSize={"14px !important"}
                  fontWeight={600}
                  sx={{ color: colors.basics.white, ml: '0.625rem' }}
                >
                  Sign up
                </Typography>
              </Box>
            }
            onClick={() => { }}
            bgcolor={colors.basics.primary}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LeftDrawerBottom;
