import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';


export const getPostalAddress: any = createAsyncThunk(
    'postalAddress/postalAddress',
    async (res) => {
        const response = await API.post(process.env.REACT_APP_PROJECT_COUNTRY === "ie" ? API_METHOD.Misc.GET_POSTAL_ADDRESS_IE : API_METHOD.Misc.GET_POSTAL_ADDRESS, res);
        return response.data;
    }
);