import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  holidayDetials: [],
};

const holidayDetailsStateSlice = createSlice({
  name: "holidayDetailsState",
  initialState: initialState,
  reducers: {
    setHolidayDetials(state, action) {
      state.holidayDetials = action?.payload;
    },
  },
});

export const { setHolidayDetials } = holidayDetailsStateSlice.actions;
export default holidayDetailsStateSlice.reducer;
