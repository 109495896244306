export interface DepartureInterface {
  depart_airport_name: string;
  departure_airport: string;
  full_name: string;
  selected?: boolean;
}

export const UK_DEPARTURE: DepartureInterface[] = [
  {
    depart_airport_name: "London All Airports",
    departure_airport: "LON",
    full_name: "London All Airports LON",
  },
  {
    depart_airport_name: "London Gatwick",
    departure_airport: "LGW",
    full_name: "London Gatwick LGW",
  },
  {
    depart_airport_name: "London Heathrow",
    departure_airport: "LHR",
    full_name: "London Heathrow LHR",
  },
  {
    depart_airport_name: "London Stansted",
    departure_airport: "STN",
    full_name: "London Stansted STN",
  },
  {
    depart_airport_name: "Manchester",
    departure_airport: "MAN",
    full_name: "Manchester MAN",
  },
  {
    depart_airport_name: "Birmingham",
    departure_airport: "BHX",
    full_name: "Birmingham BHX",
  },
  {
    depart_airport_name: "Newcastle",
    departure_airport: "NCL",
    full_name: "Newcastle NCL",
  },
  {
    depart_airport_name: "Glasgow",
    departure_airport: "GLA",
    full_name: "Glasgow GLA",
  },
  {
    depart_airport_name: "Cardiff",
    departure_airport: "CWL",
    full_name: "Cardiff CWL",
  },
  {
    depart_airport_name: "Belfast",
    departure_airport: "BFS",
    full_name: "Belfast BFS",
  },
];

export const IE_DEPARTURE: DepartureInterface[] = [
  {
    depart_airport_name: "Dublin",
    departure_airport: "DUB",
    full_name: "Dublin DUB",
  },
  {
    depart_airport_name: "Cork",
    departure_airport: "ORK",
    full_name: "Cork ORK",
  },
  {
    depart_airport_name: "Shannon",
    departure_airport: "SNN",
    full_name: "Shannon SNN",
  },
  {
    depart_airport_name: "Knock",
    departure_airport: "NOC",
    full_name: "Knock NOC",
  },
];
export const US_DEPARTURE: DepartureInterface[] = [
  {
    depart_airport_name: "New York",
    departure_airport: "JFK",
    full_name: "New York JFK",
  },
  {
    depart_airport_name: "Los Angeles",
    departure_airport: "LAX",
    full_name: "Los Angeles LAX",
  },
  {
    depart_airport_name: "Chicago",
    departure_airport: "ORD",
    full_name: "Chicago ORD",
  },
  {
    depart_airport_name: "Miami",
    departure_airport: "MIA",
    full_name: "Miami MIA",
  },
  {
    depart_airport_name: "San Francisco",
    departure_airport: "SFO",
    full_name: "San Francisco SFO",
  },
  {
    depart_airport_name: "Washington",
    departure_airport: "IAD",
    full_name: "Washington IAD",
  },
  //NEW LIST
  {
    depart_airport_name: "Hartsfield-Jackson Atlanta",
    departure_airport: "ATL",
    full_name: "Hartsfield-Jackson Atlanta ATL",
  },
  {
    depart_airport_name: "Dallas/Fort Worth",
    departure_airport: "DFW",
    full_name: "Dallas/Fort Worth DFW",
  },
  {
    depart_airport_name: "Denver",
    departure_airport: "DEN",
    full_name: "Denver DEN",
  },
  {
    depart_airport_name: "San Francisco",
    departure_airport: "SFO",
    full_name: "San Francisco SFO",
  },
  {
    depart_airport_name: "Seattle-Tacoma",
    departure_airport: "SEA",
    full_name: "Seattle-Tacoma SEA",
  },
  {
    depart_airport_name: "Seattle-Tacoma",
    departure_airport: "SEA",
    full_name: "Seattle-Tacoma SEA",
  },
  {
    depart_airport_name: "Orlando",
    departure_airport: "MCO",
    full_name: "Orlando MCO",
  },
];
