export function formatStringTime(timeString: any) {
    const [hours, minutes] = timeString.split(' ').map((part: any) => parseInt(part, 10));

    if (hours === 0) {
        return `${minutes}m`;
    }
    //  if (minutes === 0) {
    //     return `${hours}hr`;
    // }

    return `${hours}hr ${minutes}m`;
}