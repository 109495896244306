import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/useAppReducer';

const PrivateRoute = ({ children }: any) => {
  const { userDetails } = useAppSelector((state) => state.userInformation);
  const isUserLoggedIn = Object.keys(userDetails).length > 0;
  return isUserLoggedIn ? children : <Navigate to={'/'} />;
};

export default PrivateRoute;
