import { getSessionStorage, removeSessionStorage } from "../helpers/storage"

export const removeSessionElements = () => {
    if (getSessionStorage("passenger-info") !== null) {
        removeSessionStorage("passenger-info")
    }

    if (getSessionStorage("passenger-address") !== null) {
        removeSessionStorage("passenger-address")
    }

    if (getSessionStorage("passenger-billing-info") !== null) {
        removeSessionStorage("passenger-billing-info")
    }

    if (getSessionStorage("instruction") !== null) {
        removeSessionStorage("instruction")
    }

    if (getSessionStorage("passenger-info-hidden") !== null) {
        removeSessionStorage("passenger-info-hidden")
    }
}